import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import axios from "axios";
import { Checkbox } from "primereact/checkbox";
import Loader from "../components/Loader";
import SuccessDialog from "../components/SuccessDialog";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import api from "../service/ApiService";
import { baseUrl } from "../service/ApiUrls";
let updateJson = {
  isEnableInputUrl: false,
  isEnableMultiTab: false,
  isEnableBrowserHistory: false,
  isEnableBrowserSettings: false,
  isEnableListings: false,
  isWhite: false,
};
export class BrowserSetting extends Component {
  constructor() {
    super();
    this.state = { checked: false, cities: [], updateJson: updateJson };
    this.onCityChange = this.onCityChange.bind(this);
  }

  componentDidMount = () => {
    this.callBrowserBarierAPI();
  };

  callBrowserBarierAPI = () => {
    api
      .get(baseUrl + "/browsing-barrier/browser-settings")
      .then((res) => {
        console.log(res.data);
        if (res.data.result.isError === false) {
          this.setState({ resData: res.data.data }, () => {
            let cities = [];
            if (this.state.resData.isEnableBrowserHistory) {
              cities.push("Enable Browser History");
            }
            if (this.state.resData.isEnableBrowserSettings) {
              cities.push("Enable Browser Setting");
            }
            if (this.state.resData.isEnableInputUrl) {
              cities.push("Allow user to type url");
            }
            if (this.state.resData.isEnableListings) {
              cities.push("Enable Listing");
            }
            if (this.state.resData.isEnableMultiTab) {
              cities.push("Allow New Tab");
            }
            this.setState({
              cities: cities,
              city: this.state.resData.isWhite === true,
            });
            // if (this.state.resData.isWhite) {
            //   cities.push("Enable Browser History");
            // }
          });
        }
      })
      .catch((err) => {
        console.log("Error in data");
      });
  };

  callBrowserUpdateApi = (id) => {
    api
      .put(baseUrl + "/browsing-barrier/browser-settings/" + id, updateJson)
      .then((res) => {
        console.log(res.data);
        this.setState({ resData: res.data.data });
      })
      .catch((err) => {
        console.log("Error in data");
      });
  };

  onCityChange(e) {
    let selectedCities = [...this.state.cities];
    updateJson = this.state.updateJson;
    if (e.checked) selectedCities.push(e.value);
    else {
      selectedCities.splice(selectedCities.indexOf(e.value), 1);
      switch (e.value) {
        case "Allow user to type url":
          updateJson["isEnableInputUrl"] = false;
          break;
        case "Allow New Tab":
          updateJson["isEnableMultiTab"] = false;
          break;
        case "Enable Browser History":
          updateJson["isEnableBrowserHistory"] = false;
          break;
        case "Enable Browser Setting":
          updateJson["isEnableBrowserSettings"] = false;
          break;
        case "Enable Listing":
          updateJson["isEnableListings"] = false;
          break;
      }
    }
    selectedCities.map((ev, i) => {
      console.log("ev", ev);
      switch (ev) {
        case "Allow user to type url":
          updateJson["isEnableInputUrl"] = true;
          break;
        case "Allow New Tab":
          updateJson["isEnableMultiTab"] = true;
          break;
        case "Enable Browser History":
          updateJson["isEnableBrowserHistory"] = true;
          break;
        case "Enable Browser Setting":
          updateJson["isEnableBrowserSettings"] = true;
          break;
        case "Enable Listing":
          updateJson["isEnableListings"] = true;
          break;
      }
    });
    this.setState({ cities: selectedCities, updateJson: updateJson });
  }
  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: " Browser Barrier", url: "browsing-barrier" },
      { label: " Browser Setting" },
    ];

    const home = { icon: "pi pi-home", url: "dashboard" };
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 ">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="p-grid bg-col p-lg-12">
          <div className="p-col">
            <h2 style={{ paddingLeft: "12px" }}>Browser Setting</h2>
          </div>
          <div className="p-col-12" style={{ padding: "5px 20px" }}>
            <div className="p-field-checkbox">
              <Checkbox
                inputId="typeUrl"
                name="setting"
                value="Allow user to type url"
                onChange={this.onCityChange}
                checked={
                  this.state.cities.indexOf("Allow user to type url") !== -1
                }
              />
              <label htmlFor="typeUrl">Allow user to type url</label>
            </div>
            <div className="p-field-checkbox">
              <Checkbox
                inputId="newTab"
                name="setting"
                value="Allow New Tab"
                onChange={this.onCityChange}
                checked={this.state.cities.indexOf("Allow New Tab") !== -1}
              />
              <label htmlFor="newTab">Allow New Tab</label>
            </div>
            <div className="p-field-checkbox">
              <Checkbox
                inputId="bhistory"
                name="setting"
                value="Enable Browser History"
                onChange={this.onCityChange}
                checked={
                  this.state.cities.indexOf("Enable Browser History") !== -1
                }
              />
              <label htmlFor="bhistory">Enable Browser History</label>
            </div>
            <div className="p-field-checkbox">
              <Checkbox
                inputId="bsetting1"
                name="setting"
                value="Enable Browser Setting"
                onChange={this.onCityChange}
                checked={
                  this.state.cities.indexOf("Enable Browser Setting") !== -1
                }
              />
              <label htmlFor="bsetting1">Enable Browser Setting</label>
            </div>
            <div className="p-field-checkbox">
              <Checkbox
                inputId="bsetting2"
                name="setting"
                value="Enable Listing"
                onChange={this.onCityChange}
                checked={this.state.cities.indexOf("Enable Listing") !== -1}
              />
              <label htmlFor="bsetting2">Enable Listing</label>
            </div>
            {this.state.cities.includes("Enable Listing") ? (
              <div style={{ marginLeft: "20px" }}>
                <div className="p-field-radiobutton">
                  <RadioButton
                    inputId="Whitelist"
                    name="city"
                    value="Whitelist"
                    onChange={(e) =>
                      this.setState({
                        city: e.value,
                        updateJson: {
                          ...updateJson,
                          isWhite: e.value === "Whitelist",
                        },
                      })
                    }
                    checked={this.state.city === "Whitelist"}
                  />
                  <label htmlFor="Whitelist">Whitelist</label>
                </div>
                <div className="p-field-radiobutton">
                  <RadioButton
                    inputId="Blacklist"
                    name="city"
                    value="Blacklist"
                    onChange={(e) =>
                      this.setState({
                        city: e.value,
                        updateJson: {
                          ...updateJson,
                          isWhite: e.value === "Whitelist",
                        },
                      })
                    }
                    checked={this.state.city === "Blacklist"}
                  />
                  <label htmlFor="Blacklist">Blacklist</label>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="p-col-12 mt-4" style={{ textAlign: "center" }}>
            <button
              className="btn btn-primary mr-3"
              onClick={(e) => {
                this.callBrowserUpdateApi(this.state.resData.id);
              }}
            >
              Submit
            </button>
            <button className="btn btn-danger">Cancel</button>
          </div>
        </div>
      </div>
    );
  }
}
