import React, { Component } from "react";
import { Row, Col, Nav, Container, Tab, Sonnet } from "react-bootstrap";
import { BreadCrumb } from "primereact/breadcrumb";
import { Link } from "react-router-dom";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Fieldset } from "primereact/fieldset";
import api from "../service/ApiService";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import { confirmAlert } from "react-confirm-alert";
import {
  baseUrl,
  getThemeListUrl,
  getAppManagemnetUrl,
  deleteThemeUrl,
  postAddProfileUrl,
  putAddProfileUrl,
  getProfileListUrl,
} from "../service/ApiUrls";
import {
  AppTypeEnum,
  OSTypeEnum,
  ControlSettingsEnum,
  EMMControlSettingsEnum,
  ActionCopyOREdit,
} from "../Enums";
import { Paginator } from "primereact/paginator";
import { Dialog } from "primereact/dialog";
import Loader from "../components/Loader";
import SuccessDialog from "../components/SuccessDialog";
import ErrorDialog from "../components/ErrorDialog";
import "../assets/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
const google = require("../assets/google.png");
const chrome = require("../assets/chrome.png");
const firefox = require("../assets/firefox.png");
const gmap = require("../assets/gmap.png");
const fb = require("../assets/fb.png");
const yahoo = require("../assets/yahoo.png");
const twitter = require("../assets/twitter.png");
const gmail = require("../assets/gmail.png");
const fmanager = require("../assets/fmanager.png");
const customapp = require("../assets/customapp.png");
const dlogo = require("../assets/dummy_logo.png");
const defaultbg = require("../assets/lockscreen.png");
const citySelectItems = [
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "30", value: 30 },
  { label: "40", value: 40 },
  { label: "60", value: 60 },
];
export class NewDeviceProfile extends Component {
  constructor(props) {
    super(props);
    //console.log("props in NewDevice", { ...props.history.location.state.from });
    if (
      props.history.location.state !== undefined &&
      props.history.location.state.e !== undefined
    ) {
      console.log("In here ");
      this.state = {
        checked: false,
        cities: [],
        applist: [],
        themelist: [],
        closable: false,
        showHeader: false,
        selectedTheme: [],
        dfirst: 0,
        currentPage: 1,
        rows: 6,
        first: 0,
        rows1: 6,
        first1: 0,
        eventrows: 6,
        eventrows1: 8,
        isEdit: props.history.location.state.from,
        ...props.history.location.state.e,
        // id: props.history.location.state.e.id,
        // name: props.history.location.state.e.name,
        // passcode: props.history.location.state.e.passcode,
        emmSettings: JSON.parse(` [
          {
              "type": 11,
              "config": {
                  "screenCaptureEnabled": true,
                  "cameraEnabled": true,
                  "adjustVolumeEnabled": true
              },
              "isVisible": true
          },
          {
              "type": 12,
              "config": {
                  "keyguardEnabled": false,
                  "keyguardCameraEnabled": false,
                  "keyguardNotificationsEnabled": false,
                  "keyguardUnredactedNotificationsEnabled": false,
                  "keyguardTrustAgentsEnabled": false,
                  "keyguardRemoteInputEnabled": false
              },
              "isVisible": true
          },
          {
              "type": 13,
              "config": {
                  "allowUserToConfigVpn": false,
                  "alwaysOnVpnApp": null
              },
              "isVisible": true
          },
          {
              "type": 14,
              "config": {
                  "safeBootEnabled": false,
                  "usbFileTransferEnabled": false,
                  "factoryResetProtectionEnabled": false,
                  "frpActivationStatus": false
              },
              "isVisible": true
          }
      ]`),
        //hasEnterpriseSetup: false,
      };
    } else {
      this.state = {
        checked: false,
        cities: [],
        applist: [],
        themelist: [],
        closable: false,
        showHeader: false,
        dfirst: 0,
        rows1: 6,
        first1: 0,
        currentPage: 1,
        rows: 6,
        first: 0,
        eventrows: 6,
        eventrows1: 8,
        osType: OSTypeEnum.ANDROID,
        generalSettings: JSON.parse(`[
          {
              "type": 0,
              "config": {
                  "allowUserToUninstall": false,
                  "showExitOption": false,
                  "allowToClearData": false
              },
              "isVisible": true
          },
          {
              "type": 1,
              "config": {
                  "allowUserToTurnOnOffWiFi": false,
                  "allowUserToSwitchWiFiNetwork": false
              },
              "isVisible": true
          },
          {
              "type": 2,
              "config": {
                  "allowUserToTurnOnOffMobileData": false,
                  "allowUserToTurnOnOffHotspot": false
              },
              "isVisible": true
          },
          {
              "type": 3,
              "config": {
                  "sleepIntervalInMinute": 5,
                  "allowUserToChangeBrightness": false
              },
              "isVisible": true
          },
          {
              "type": 4,
              "config": {
                  "allowUserToAccessTimezone": false,
                  "allowUserToUpdateClock": false,
                  "allowUserToUpdateLanguage": false
              },
              "isVisible": true
          },
          {
              "type": 5,
              "config": {
                  "allowUserToTurnOnOffBluetooth": false,
                  "allowUserToSwitchBluetoothDevice": false
              },
              "isVisible": true
          },
          {
              "type": 6,
              "config": {
                  "enableLocationTracking": false,
                  "forceGPSOn": false,
                  "locationTrackerIntervalInSecond": 60
              },
              "isVisible": false
          }]`),
        emmSettings: JSON.parse(` [
            {
                "type": 11,
                "config": {
                    "screenCaptureEnabled": true,
                    "cameraEnabled": true,
                    "adjustVolumeEnabled": true
                },
                "isVisible": true
            },
            {
                "type": 12,
                "config": {
                    "keyguardEnabled": false,
                    "keyguardCameraEnabled": false,
                    "keyguardNotificationsEnabled": false,
                    "keyguardUnredactedNotificationsEnabled": false,
                    "keyguardTrustAgentsEnabled": false,
                    "keyguardRemoteInputEnabled": false
                },
                "isVisible": true
            },
            {
                "type": 13,
                "config": {
                    "allowUserToConfigVpn": false,
                    "alwaysOnVpnApp": null
                },
                "isVisible": true
            },
            {
                "type": 14,
                "config": {
                    "safeBootEnabled": false,
                    "usbFileTransferEnabled": false,
                    "factoryResetProtectionEnabled": false,
                    "frpActivationStatus": false
                },
                "isVisible": true
            }
        ]`),
        hasEnterpriseSetup: false,
      };
    }
    this.onCityChange = this.onCityChange.bind(this);
  }
  webCallGetThemeList = async ({ first = 0, limit = 6, filter = "" } = {}) => {
    try {
      this.setState({ visible: true, textInLoader: "Loading ...." });
      let {
        data: {
          data,
          result: { isError, errorMsg, statusCode },
        },
      } = await api.get(getThemeListUrl, {
        params: {
          first: first,
          limit: limit,
          filter: filter,
        },
      });
      //console.log("response", response.data);
      if (isError === false) {
        this.setState({
          ...data,
          totalRecords: data.totalRecords,
          visible: false,
        });
      } else console.log("Reason", errorMsg);
    } catch (err) {
      console.log("error", err);
      this.setState({ visible: false });
      //localStorage.setItem("loggedIn", false);
      //window.location = process.env.PUBLIC_URL + "/login";
    }
  };
  callgetAppListByType = async ({
    apptype = AppTypeEnum.SYSTEMAPP,
    first = 0,
    limit = 8,
    filter = "",
  } = {}) => {
    return api
      .get(getAppManagemnetUrl, {
        params: {
          // apptype,
          first,
          limit,
          filter: filter,
        },
      })
      .then((res) => {
        const {
          data,
          result: { isError, errMsg, statusCode },
        } = res.data;
        //console.log(data, "isError", isError, "error msg", errMsg);
        //this.setState({ systemApps: data.content, isLoading: false });
        if (isError === false)
          return { data, result: { isError, errMsg, statusCode } };
        // else return { result: { isError, errMsg, statusCode } };
      })
      .catch((err) => {
        console.log("err", err);
        //localStorage.setItem("loggedIn", false);
        //window.location = process.env.PUBLIC_URL + "/login";
      });
  };
  callAllApps = async ({ first = 0, limit = 8, filter = "" } = {}) => {
    try {
      this.setState({ visible: true, textInLoader: "Loading ...." });
      let {
        data: {
          data,
          result: { isError, errorMsg, statusCode },
        },
      } = await api.get(getAppManagemnetUrl, {
        params: {
          first: first,
          limit: limit,
          filter: filter,
        },
      });
      //console.log("response", response.data);
      if (isError === false) {
        this.setState({
          applist: data.content,
          totalRecords1: data.totalRecords,
          visible: false,
        });
      } else if (isError === true) {
        confirmAlert({
          title: "Error Message",
          message: <p className="mod-p">{errorMsg}</p>,
          buttons: [
            {
              label: "Ok",
              onClick: () => {},
            },
          ],
        });
      }
    } catch (err) {
      console.log("error", err);
      this.setState({ visible: false });
      //localStorage.setItem("loggedIn", false);
      //window.location = process.env.PUBLIC_URL + "/login";
    }
  };
  // callAllApps = async () => {
  //   this.setState({ visible: true });
  //   let response = await this.callgetAppListByType({
  //     // apptype: AppTypeEnum.SYSTEMAPP,
  //   });
  //   this.setState(
  //     {
  //       applist: response.data.content,
  //       totalRecords1: response.data.totalRecords,
  //       visible: false,
  //     },
  //     () => {
  //       // this.webCallGetThemeList();
  //     }
  //   );
  // };

  callProfileDetailsWithID = (id) => {
    let url = getProfileListUrl + "/" + id;
    if (this.state.isEdit === ActionCopyOREdit.COPY) {
      url = url + "/" + "copy";
    }
    api
      .get(url)
      .then((res) => {
        if (res.data.result.isError === false) {
          let appNames = [];
          this.setState(
            {
              ...res.data.data,
              applist2: res.data.data.appList,
              generalSettings: res.data.data.generalSettings,
              deviceTheme: res.data.data.deviceTheme,
              emmSettings: res.data.data.emmSettings,
              hasEnterpriseSetup: res.data.data.hasEnterpriseSetup,
            },
            () => {
              this.state.applist2 !== undefined &&
                this.state.applist2.map((e, v) => {
                  appNames.push({
                    name: e.appInfo.appName,
                    id: e.id,
                    appId: e.appInfo.appId,
                  });
                });
              let selectedObject =
                this.state.emmSettings[2].config["alwaysOnVpnApp"] !== null
                  ? this.state.emmSettings[2].config["alwaysOnVpnApp"]
                  : this.state.allowdappsval === null ||
                    this.state.allowdappsval === undefined
                  ? null
                  : null;
              let appSelected = null;
              if (selectedObject !== null) {
                appSelected = appNames.find((v) => {
                  return selectedObject.id === v.id;
                });
              }

              this.setState(
                {
                  val: this.state.generalSettings[3].config[
                    "sleepIntervalInMinute"
                  ],
                  textData: this.state.generalSettings[6].config[
                    "locationTrackerIntervalInSecond"
                  ],
                  allowdappsval: appSelected,
                },
                () => {
                  this.callAllApps();
                }
              );
            }
          );
        } else {
          confirmAlert({
            title: "Error Message",
            message: <p className="mod-p">{res.data.result.errorMsg}</p>,
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.props.history.push("/device-profile-list");
                },
              },
            ],
          });
        }
      })
      .catch((err) => this.props.history.location);
  };

  callGetProfileListAdd = () => {
    api.get(baseUrl + "/profile-list/add").then((res) => {
      if (res.data.result.isError === false) {
        this.setState(
          {
            ...res.data.data,
            // hasEnterpriseSetup: true,
          },
          () => {
            this.callAllApps();
          }
        );
      } else {
        // this.setState({
        //   //...res.data.data,
        //   hasEnterpriseSetup: true,
        // });
        confirmAlert({
          title: "Error Message",
          message: <p className="mod-p">{res.data.result.errorMsg}</p>,
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.props.history.push("/device-profile-list");
              },
            },
          ],
        });
      }
    });
  };

  componentDidMount() {
    //if (this.state.isEdit === ActionCopyOREdit.EDIT) {

    //}
    if (
      this.props.history.location.state !== undefined &&
      this.state.isEdit === ActionCopyOREdit.EDIT
    ) {
      this.callProfileDetailsWithID(this.props.history.location.state.e.id);
    } else if (
      this.props.history.location.state !== undefined &&
      this.state.isEdit === ActionCopyOREdit.COPY
    ) {
      this.callProfileDetailsWithID(this.props.history.location.state.e.id);
    } else {
      this.callGetProfileListAdd();
    }
    //console.log("this", JSON.stringify(this.state.generalSettings));
  }
  onCityChange(e) {
    let selectedCities = [...this.state.cities];

    if (e.checked) selectedCities.push(e.value);
    else selectedCities.splice(selectedCities.indexOf(e.value), 1);

    this.setState({ cities: selectedCities });
    console.log("checked ");
  }
  onDialogueHide = () => {
    this.setState({ visible: false });
  };
  onDialogueHideError = () => {
    this.setState({ isError: false });
  };
  deleteTheme = (e) => () => {
    console.log("in delete ", e);
    this.setState({ visible: true, textInLoader: "Deleting ..." });
    api
      .delete(deleteThemeUrl + "/" + e.id)
      .then((res) => {
        this.setState({ visible: false });
        this.webCallGetThemeList();
      })
      .catch((err) => this.setState({ visible: false }));
  };

  handleSelect = async (key) => {
    console.log("value of key ", key);
    switch (key) {
      case "themelist":
        this.webCallGetThemeList();
        break;
      case "applist":
        this.callAllApps();
        break;
      default:
        break;
    }
  };

  convertSentenceWord(text) {
    var result = text.replace(/([A-Z])/g, " $1");
    var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    //console.log(finalResult);
    return finalResult;
  }

  handleCheckbox = (type) => (e) => {
    console.log("inside handle check", e.target);
    let name = e.target.name;
    console.log("name ", name, "checked value", e.target.checked);

    let newArray = [...this.state.generalSettings];
    newArray[type] = {
      ...newArray[type],
      config: {
        ...this.state.generalSettings[type].config,
        [name]: e.target.checked,
      },
    };

    this.setState({ generalSettings: newArray });
  };

  handleEMMCheckbox = (type) => (e) => {
    console.log(type, "inside handle check", e.target);
    let name = e.target.name;
    console.log("name ", name, "checked value", e.target.checked);

    let newArray = [...this.state.emmSettings];
    console.log("newArray initial ", newArray);

    console.log("newArray before ", newArray);
    newArray[type] = {
      ...newArray[type],
      config: {
        ...this.state.emmSettings[type].config,
        [name]: e.target.checked,
      },
    };

    console.log("newArray after", newArray);

    this.setState({ emmSettings: newArray });
  };

  submitToServer = () => {
    try {
      this.setState({ visible: true, textInLoader: "Submitting...." }, () => {
        let methodtoChoose =
          this.state.isEdit === ActionCopyOREdit.EDIT ? "put" : "post";
        let urlModify =
          methodtoChoose === "put"
            ? putAddProfileUrl + "/" + this.state.id
            : postAddProfileUrl;

        let sendingData;
        if (this.state.hasEnterpriseSetup === true) {
          let selectDTheme =
            this.state.deviceTheme === undefined ||
            this.state.deviceTheme === null
              ? null
              : { id: this.state.deviceTheme.id };
          sendingData = {
            generalSettings: this.state.generalSettings,
            emmSettings: this.state.emmSettings,
            deviceTheme: selectDTheme,
            deviceIds: this.state.deviceIds,
            id: this.state.id,
            name: this.state.name,
            osType: OSTypeEnum.ANDROID,
            osTypeSt: "Android",
            passcode: this.state.passcode,
            appList: this.state.applist2,
          };
        } else {
          sendingData = {
            generalSettings: this.state.generalSettings,
            deviceTheme: this.state.deviceTheme,
            deviceIds: this.state.deviceIds,
            id: this.state.id,
            name: this.state.name,
            osType: OSTypeEnum.ANDROID,
            osTypeSt: "Android",
            passcode: this.state.passcode,
            appList: this.state.applist2,
          };
        }
        api({ method: methodtoChoose, url: urlModify, data: sendingData }).then(
          (res) => {
            console.log("res from post api ", res.data);
            if (res.data.result.isError === false) {
              this.setState({ visible: false }, () => {
                confirmAlert({
                  title: "Success Message",
                  message: (
                    <p className="mod-sp">
                      {this.state.isEdit === ActionCopyOREdit.EDIT
                        ? "Updated Device Profile Successfully"
                        : this.state.isEdit === ActionCopyOREdit.COPY
                        ? "Copy Device Profile Successfully"
                        : "Created Device Profile Successfully"}
                    </p>
                  ),
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => {
                        this.props.history.push("/device-profile-list");
                      },
                    },
                  ],
                });
              });
              // this.props.history.push("/device-profile-list");
            } else if (res.data.result.isError === true) {
              this.setState(
                {
                  textInLoader: res.data.result.errorMsg,
                  // isError: true,
                  visible: false,
                },
                () => {
                  confirmAlert({
                    title: "Error Message",
                    message: (
                      <p className="mod-p">{res.data.result.errorMsg}</p>
                    ),
                    buttons: [
                      {
                        label: "Ok",
                        onClick: () => {},
                      },
                    ],
                  });
                }
              );
            }
          }
        );
      });
    } catch (error) {
      console.log("network", error);
      this.setState({ visible: false });
    }
  };
  onPageChange = (event) => {
    console.log(event);
    this.setState(
      {
        dfirst: event.first,
        page: event.page,
        eventrows: event.rows,
        rows: event.rows,
        currentPage: event.page + 1,
      },
      () => {
        this.webCallGetThemeList({
          first: this.state.page,
          limit: this.state.eventrows,
        });
      }
    );
  };

  onPageChange1 = (event) => {
    console.log(event);
    this.setState(
      {
        first1: event.first,
        page: event.page,
        eventrows1: event.rows,
        rows1: event.rows,
        currentPage: event.page + 1,
      },
      () => {
        this.callAllApps({
          first: event.page,
          limit: this.state.eventrows1,
        });
      }
    );
  };
  themeSelection = (e) => {
    //  console.log("deviceTheme selected ", e.target.value);
    // let selectedTheme = this.state.content.find((obj) => {
    //   return obj.id === e.target.value;
    // });
    //  console.log("Selected Theme", selectedTheme);
    // this.setState({ deviceTheme: selectedTheme });
    // console.log("Selected Theme", this.state.selectedTheme);

    this.state.content.map((v, i) => {
      if (e.target.id !== v.id) document.getElementById(v.id).checked = false;
    });

    let selectedTheme = this.state.content.find((obj) => {
      return obj.id === e.target.value;
    });

    console.log("select theme", selectedTheme);

    if (e.target.checked) {
      // this.state.content.map((v,i)=>{

      // });
      this.setState(
        {
          ...this.state,
          selectedTheme: { id: selectedTheme.id },
        },
        () => {
          this.setState({ deviceTheme: this.state.selectedTheme });
        }
      );
    } else {
      this.setState(
        {
          ...this.state,
          selectedTheme: "",
        },
        () => {
          this.setState({ deviceTheme: null });
        }
      );
    }
  };

  onCheckBoxChange = (e) => {
    console.log("value", e.value);
    // let selectedAppCheckBoxes = [...this.state.applist2];

    // if (e.checked) selectedAppCheckBoxes.push(e.value);
    // else
    //   selectedAppCheckBoxes.splice(selectedAppCheckBoxes.indexOf(e.value), 1);

    // this.setState({ applist2: selectedAppCheckBoxes });
    // console.log("onCheckBoxChange ", e.target, e.checked); //console.log("inside on change ", tag, e.checked, e.value);
    let selectedAppCheckBoxes;
    if (
      this.state.applist2 !== undefined &&
      this.state.applist2 !== null &&
      Array.isArray(this.state.applist2)
    ) {
      selectedAppCheckBoxes = [...this.state.applist2];
      selectedAppCheckBoxes.map((v, i) => {
        if (e.order === 0) {
          selectedAppCheckBoxes[i]["order"] = i;
          selectedAppCheckBoxes[i]["id"] = v.id;
          selectedAppCheckBoxes[i]["appInfo"] = v;
        }
      });
    } else {
      selectedAppCheckBoxes = [];
    }
    console.log("selected checks", selectedAppCheckBoxes);
    //let { type, id, value: appInfo, checked } = e.target;
    if (e.checked) {
      selectedAppCheckBoxes.push({
        appInfo: e.value,
        order: selectedAppCheckBoxes.length,
        id: e.value.id,
      });
    } else {
      selectedAppCheckBoxes.splice(
        selectedAppCheckBoxes.findIndex((f) => f.id === e.value.id),
        1
      );
    }

    console.log("selected checks", selectedAppCheckBoxes);
    this.setState({ applist2: selectedAppCheckBoxes });
  };

  selectedCountryTemplate(option, props) {
    console.log("options: ", option, props);
    if (option) {
      return (
        <div>
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  }

  render() {
    const getKeyByValue = (obj, value) =>
      Object.keys(obj).find((key) => obj[key] === value);
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Device Outline", url: "device-outline" },
      { label: "Device Profile List", url: "device-profile-list" },
      {
        label: `${
          this.state.isEdit !== undefined &&
          this.state.isEdit === ActionCopyOREdit.EDIT
            ? "Edit"
            : this.state.isEdit === ActionCopyOREdit.COPY
            ? "Copy"
            : "Create"
        } Device Profile`,
      },
    ];
    const home = { icon: "pi pi-home", url: "dashboard" };
    const appdata = this.state.applist2 !== undefined && this.state.applist2;
    const selectApp =
      this.state.applist !== undefined &&
      this.state.applist !== null &&
      this.state.applist.map((e, i) => {
        return (
          <div
            className="p-col-12 p-md-6 p-xl-6 p-sm-6"
            style={{
              display: "inline-block",
              padding: "5px 15px",
              position: "relative",
            }}
            key={i}
          >
            <Checkbox
              inputId={"app" + i}
              name={e.appName}
              value={e}
              id={e.id}
              onChange={this.onCheckBoxChange}
              checked={
                this.state.applist2 !== undefined &&
                this.state.applist2 !== null &&
                // this.state.applist2.indexOf(e) !== -1
                this.state.applist2.some((obj) => obj.id === e.id)
              }
              // checked={
              //   this.state.applist2 !== undefined &&
              //   this.state.applist2 !== null &&
              //   this.state.applist2.some((obj) => {
              //     console.log(obj.id, e.id);
              //     return obj.id === e.id;
              //   })
              // }
            />
            <label className="app-icon" htmlFor={"app" + i}>
              <div className="list-blcok">
                <img
                  src={
                    e.appLogoBase64 !== null
                      ? `data:image/png;base64,${e.appLogoBase64}`
                      : `${process.env.PUBLIC_URL}/data/icons/androidapk` +
                        ".png"
                  }
                  className="list-img"
                  width="40px"
                  height="40px"
                />
              </div>
              <Tooltip
                target=".icon-title"
                position="bottom"
                mouseTrack
                mouseTracktop={25}
              />
              <span
                className="icon-title app-title"
                data-pr-tooltip={e.appName}
              >
                {e.appName}
              </span>
              <Tooltip
                target=".icon-des"
                position="bottom"
                mouseTrack
                mouseTracktop={25}
              />
              <span className="icon-des app-title" data-pr-tooltip={e.appId}>
                {e.appId}
              </span>
            </label>
          </div>
        );
      });

    const main_app =
      this.state.applist2 !== undefined &&
      this.state.applist2 !== null &&
      this.state.applist2.map((e, i) => {
        let objectToShow = this.state.applist.filter(
          (obj) => obj.id === e.id
        )[0];
        //console.log("object To Show", objectToShow);
        return (
          <>
            <div
              className="p-col-3"
              style={{
                display: "inline-block",

                marginTop: "5px",
              }}
              key={i}
            >
              <div
                style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                key={i + 1}
              >
                <img
                  src={
                    objectToShow !== undefined &&
                    objectToShow.appLogoBase64 !== null
                      ? `data:image/png;base64,${objectToShow.appLogoBase64}`
                      : `${process.env.PUBLIC_URL}/data/icons/androidapk` +
                        ".png"
                  }
                  width="35px"
                  className=""
                  alt={objectToShow !== undefined && objectToShow.appName}
                />
                <span className="app_title">
                  {objectToShow !== undefined && objectToShow.appName
                    ? objectToShow.appName
                    : "N/A"}
                </span>
              </div>

              {/* <span className="icon-des">{e.versionName}</span> */}
            </div>
          </>
        );
      });

    let appNames = [];
    this.state.applist2 !== undefined &&
      this.state.applist2.map((e, v) => {
        appNames.push({
          name: e.appInfo.appName,
          id: e.id,
          appId: e.appInfo.appId,
        });
      });
    // let bgImage = `${process.env.PUBLIC_URL}/data/icons/`;
    const controlSetting =
      this.state.generalSettings !== undefined &&
      this.state.generalSettings !== null &&
      this.state.generalSettings.map((e, i) => {
        // console.log("generalSettings ", e.config, e.type, e.isVisible, "name");
        return (
          e.isVisible === true && (
            <Fieldset
              key={i}
              legend={this.convertSentenceWord(
                getKeyByValue(ControlSettingsEnum, e.type)
              )}
              toggleable={true}
              style={{ marginBottom: "10px" }}
            >
              {e.config !== undefined &&
                Object.entries(e.config).map(([key, val]) => {
                  return (
                    <div className="cus-p-1 custom-checkbox" key={key}>
                      {typeof val === "boolean" ? (
                        <>
                          <Checkbox
                            inputId={key + ""}
                            //value={val}
                            onChange={this.handleCheckbox(e.type)}
                            checked={
                              typeof val === "number"
                                ? true
                                : this.state.generalSettings[e.type].config[key]
                            }
                            name={key}
                          ></Checkbox>
                          <label
                            htmlFor={key + ""}
                            className="p-checkbox-label"
                          >
                            {this.convertSentenceWord(key)}
                          </label>
                        </>
                      ) : e.type === 3 ? (
                        <>
                          <div className="form-group col-4 p-z">
                            <label>Sleep Interval In Minutes</label>
                            <Dropdown
                              value={this.state.val}
                              options={[1, 2, 5, 10, 30]}
                              onChange={(v) => {
                                let newArray = [...this.state.generalSettings];
                                newArray[e.type] = {
                                  ...newArray[e.type],
                                  config: {
                                    ...this.state.generalSettings[e.type]
                                      .config,
                                    [key]: v.value,
                                  },
                                };

                                this.setState({
                                  generalSettings: newArray,
                                  val: v.value,
                                });
                              }}
                              placeholder="Select Sleep Interval"
                            />
                          </div>
                        </>
                      ) : e.type === 6 ? (
                        <>
                          <div className="form-group col-4 mt-2 p-z">
                            <label>Location Interval In Seconds</label>
                            <InputText
                              value={this.state.textData}
                              placeholder="Enter Location Interval"
                              onChange={(ev) => {
                                let newArray = [...this.state.generalSettings];
                                newArray[e.type] = {
                                  ...newArray[e.type],
                                  config: {
                                    ...this.state.generalSettings[e.type]
                                      .config,
                                    [key]: Number(ev.target.value),
                                  },
                                };

                                this.setState({
                                  generalSettings: newArray,
                                  textData: Number(ev.target.value),
                                });
                              }}
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
            </Fieldset>
          )
        );
      });

    const emmSettings =
      this.state.emmSettings !== undefined &&
      this.state.emmSettings !== null &&
      this.state.emmSettings.map((e, i) => {
        return (
          e.isVisible === true && (
            <Fieldset
              key={i}
              legend={this.convertSentenceWord(
                getKeyByValue(EMMControlSettingsEnum, e.type)
              )}
              toggleable={true}
              style={{ marginBottom: "10px" }}
            >
              {e.config !== undefined &&
                Object.entries(e.config).map(([key, val]) => {
                  //console.log("key", key, "v", val, typeof val);
                  return (
                    <div className="cus-p-1 custom-checkbox" key={key}>
                      {key === "factoryResetProtectionEnabled" ? (
                        <>
                          <Checkbox
                            inputId={key + ""}
                            //value={val}
                            disabled={
                              !this.state.emmSettings[14 % 11].config[
                                "frpActivationStatus"
                              ]
                            }
                            onChange={this.handleEMMCheckbox(e.type % 11)}
                            checked={
                              this.state.emmSettings[e.type % 11].config[key]
                            }
                            name={key}
                          ></Checkbox>
                          <label
                            htmlFor={key + ""}
                            className="p-checkbox-label"
                          >
                            {this.convertSentenceWord(key)}
                          </label>
                        </>
                      ) : typeof val === "boolean" &&
                        e.type === 12 &&
                        key !== "keyguardEnabled" ? (
                        <>
                          <Checkbox
                            inputId={key + ""}
                            //value={val}
                            onChange={this.handleEMMCheckbox(e.type % 11)}
                            disabled={
                              this.state.emmSettings[e.type % 11].config[
                                "keyguardEnabled"
                              ] === false
                            }
                            checked={
                              this.state.emmSettings[e.type % 11].config[key]
                            }
                            name={key}
                          ></Checkbox>
                          <label
                            htmlFor={key + ""}
                            className="p-checkbox-label"
                          >
                            {this.convertSentenceWord(key)}
                          </label>
                        </>
                      ) : typeof val === "boolean" &&
                        key !== "frpActivationStatus" ? (
                        <>
                          <Checkbox
                            inputId={key + ""}
                            //value={val}
                            onChange={this.handleEMMCheckbox(e.type % 11)}
                            checked={
                              this.state.emmSettings[e.type % 11].config[key]
                            }
                            name={key}
                          ></Checkbox>
                          <label
                            htmlFor={key + ""}
                            className="p-checkbox-label"
                          >
                            {this.convertSentenceWord(key)}
                          </label>
                        </>
                      ) : typeof val === "object" ? (
                        <>
                          <div className="form-group col-4 p-z">
                            <label>Always-on VPN app</label>
                            <Dropdown
                              value={
                                this.state.allowdappsval !== undefined &&
                                this.state.allowdappsval
                              }
                              id="dd"
                              options={appNames}
                              optionLabel="name"
                              filter
                              showClear
                              filterBy="name"
                              valueTemplate={this.selectedCountryTemplate}
                              placeholder="Select 'always-on VPN"
                              onChange={(ev) => {
                                console.log("v", ev);
                                let object = this.state.applist2.find((v) => {
                                  return v !== undefined &&
                                    ev !== undefined &&
                                    ev.value !== undefined
                                    ? v.id === ev.value.id
                                    : null;
                                  // return v.id === ev.value.id
                                });
                                console.log("object", object);
                                if (object !== undefined) {
                                  let newArray = [...this.state.emmSettings];
                                  newArray[e.type % 11] = {
                                    ...newArray[e.type % 11],
                                    config: {
                                      ...this.state.emmSettings[e.type % 11]
                                        .config,
                                      [key]: object.appInfo,
                                    },
                                  };
                                  this.setState({
                                    emmSettings: newArray,
                                    allowdappsval: ev.value,
                                  });
                                } else if (object === undefined) {
                                  let newArray = [...this.state.emmSettings];
                                  newArray[e.type % 11] = {
                                    ...newArray[e.type % 11],
                                    config: {
                                      allowUserToConfigVpn: false,
                                      alwaysOnVpnApp: null,
                                      lockDownEnabled: false,
                                    },
                                  };
                                  this.setState({
                                    emmSettings: newArray,
                                    allowdappsval: undefined,
                                  });
                                  // this.setState({
                                  //   allowdappsval: undefined,
                                  // });
                                }
                              }}
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
            </Fieldset>
          )
        );
      });

    let theme_app =
      this.state.content !== undefined &&
      this.state.content.map((e, i) => {
        let picture =
          e.androidProperties === null ||
          e.androidProperties.backgroudWallpaperbase64 === undefined ||
          e.androidProperties.backgroudWallpaperbase64 === null
            ? { defaultbg }
            : `data:image/jpeg;base64,${e.androidProperties.backgroudWallpaperbase64}`;
        //console.log("i", i, e.androidProperties.topBarFontColorHexCode);
        let topBar;
        if (e.androidProperties !== null && e.androidProperties !== undefined) {
          if (
            e.androidProperties.topBarColorHexCode !== null &&
            e.androidProperties.topBarColorHexCode !== undefined
          ) {
            if (e.androidProperties.topBarColorHexCode.startsWith("#")) {
              topBar = e.androidProperties.topBarColorHexCode;
            } else {
              topBar = "#" + e.androidProperties.topBarColorHexCode;
            }
          }
        }
        let topBarFontColor;
        if (e.androidProperties !== null && e.androidProperties !== undefined) {
          if (
            e.androidProperties.topBarFontColorHexCode !== null &&
            e.androidProperties.topBarFontColorHexCode !== undefined
          ) {
            if (e.androidProperties.topBarFontColorHexCode.startsWith("#")) {
              topBarFontColor = e.androidProperties.topBarFontColorHexCode;
            } else {
              topBarFontColor =
                "#" + e.androidProperties.topBarFontColorHexCode;
            }
          }
        }
        return (
          <>
            <div
              key={i}
              className="cus-1 p-col-2"
              style={{ display: "inline-block", textAlign: "center" }}
            >
              <div key={i} className="phonebg">
                <input
                  className="customcheck"
                  type="checkbox"
                  name="selectTheme"
                  value={e.id}
                  // name={e.name}
                  // value={e.id}
                  checked={
                    this.state.deviceTheme !== undefined &&
                    this.state.deviceTheme !== null &&
                    this.state.deviceTheme.id === e.id
                  }
                  onChange={this.themeSelection}
                  id={e.id}
                />
                <label className="smartphone-theme" htmlFor={e.id}>
                  <div
                    className="content"
                    style={{
                      backgroundImage: "url(" + picture + ")",
                    }}
                  >
                    <div
                      className="theme-headerbg"
                      style={{
                        backgroundColor: topBar,
                      }}
                    >
                      <span
                        className="device-name"
                        style={{
                          color: topBarFontColor,
                        }}
                      >
                        <img
                          // src={`data:image/jpeg;base64,${e.androidProperties.brandLogobase64}`}
                          src={
                            e.androidProperties === null ||
                            e.androidProperties.brandLogobase64 === undefined ||
                            e.androidProperties.brandLogobase64 === null
                              ? dlogo
                              : `data:image/jpeg;base64,${e.androidProperties.brandLogobase64}`
                          }
                          width="15px"
                          style={{ paddingRight: "5px" }}
                          alt={e.brandName}
                        />
                        {e.brandName}
                      </span>
                      <div
                        style={{
                          display: "inline-block",
                          padding: "10px 3px",
                          marginTop: "5px",
                        }}
                      >
                        <img src={chrome} className="theme-icon" alt="" />
                        <img src={firefox} className="theme-icon" alt="" />
                        <img src={gmail} className="theme-icon" alt="" />
                        <img src={yahoo} className="theme-icon" alt="" />
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          padding: "3px 3px",
                        }}
                      >
                        <img src={google} className="theme-icon" alt="" />
                        <img src={gmap} className="theme-icon" alt="" />
                        <img src={customapp} className="theme-icon" alt="" />
                        <img src={fmanager} className="theme-icon" alt="" />
                      </div>
                    </div>
                  </div>
                </label>
                <span className="theme_title">{e.name}</span>
              </div>
            </div>
          </>
        );
      });
    let arr = [];
    for (let key in OSTypeEnum) {
      arr.push(
        <option
          //selected={OSTypeEnum[key] === OSTypeEnum.QR}
          key={OSTypeEnum[key]}
          value={OSTypeEnum[key]}
        >
          {key}
        </option>
      );
    }
    let osTypeList = arr;

    return (
      <div className="p-grid">
        <div className="p-col-12 p-lg-12 ">
          <Dialog
            visible={this.state.visible}
            modal={true}
            style={{ width: "20vw" }}
            closable={this.state.closable}
            showHeader={this.state.showHeader}
            onHide={this.onDialogueHide}
          >
            <Loader textToShow={this.state.textInLoader} />
          </Dialog>
          <Dialog
            header="Error!"
            visible={this.state.isError}
            modal
            style={{ width: "25vw" }}
            closable
            showHeader
            onHide={this.onDialogueHideError}
          >
            <ErrorDialog errorMessage={this.state.textInLoader} />
          </Dialog>
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="p-col-12">
          <div className="card">
            <div className="p-col-12 p-lg-12 ">
              <div
                className="form-group p-col-12 p-md-4 p-lg-4"
                style={{ display: "inline-block" }}
              >
                <label htmlFor="formGroupExampleInput">Profile Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="formGroupExampleInput"
                  placeholder="Enter Here"
                  onChange={(e) => {
                    this.setState({ name: e.target.value });
                  }}
                  value={this.state.name}
                />
              </div>
              <div
                className="form-group p-col-12 p-md-4 p-lg-4"
                style={{ display: "inline-block" }}
              >
                <label htmlFor="exampleFormControlSelect1">OS Type</label>
                <select
                  value={this.state.osType}
                  onChange={(e) => this.setState({ osType: e.target.value })}
                  className="form-control"
                  id="exampleFormControlSelect1"
                >
                  <option>Select</option>
                  {osTypeList}
                </select>
              </div>
              <div
                className="form-group p-col-12 p-md-4 p-lg-4"
                style={{ display: "inline-block" }}
              >
                <label htmlFor="formGroupExampleInput">Passcode</label>
                <input
                  type="text"
                  className="form-control"
                  id="formGroupExampleInput"
                  placeholder="Enter Here"
                  onChange={(e) => {
                    this.setState({ passcode: e.target.value });
                  }}
                  value={this.state.passcode}
                />
              </div>
            </div>
            <div className="p-col-12 p-lg-12">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="applist"
                onSelect={this.handleSelect}
              >
                <Row>
                  <Col sm={2} style={{ padding: "0px", background: "#2DBE60" }}>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="applist"
                          style={{ color: "#ffffff" }}
                        >
                          Select Apps
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="themelist"
                          style={{ color: "#ffffff" }}
                        >
                          Select Theme
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="setting"
                          style={{ color: "#ffffff" }}
                        >
                          General Settings
                        </Nav.Link>
                      </Nav.Item>
                      {this.state.hasEnterpriseSetup === true ? (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="emm-setting"
                            style={{ color: "#ffffff" }}
                          >
                            EMM Settings
                          </Nav.Link>
                        </Nav.Item>
                      ) : (
                        ""
                      )}
                    </Nav>
                    <button
                      className="btn btn-primary"
                      style={{ margin: "10px" }}
                      onClick={this.submitToServer}
                    >
                      Submit
                    </button>
                  </Col>
                  <Col sm={10}>
                    <Tab.Content>
                      <Tab.Pane eventKey="applist">
                        <div className="row align-items-start">
                          <div
                            className="p-col-12 p-md-8 p-lg-8 p-xl-8"
                            style={{
                              display: "inline-block",
                              paddingLeft: "0px",
                            }}
                          >
                            <div
                              className="p-col-6"
                              style={{
                                display: "inline-block",
                              }}
                            >
                              <h2>Select Apps</h2>
                            </div>
                            <div
                              className="p-col-6"
                              style={{
                                display: "inline-block",
                              }}
                            >
                              <div className="form-inline d-flex justify-content-center md-form form-sm mt-0 b-input">
                                <input
                                  className="form-control form-control-sm mr-2 b-n"
                                  type="text"
                                  placeholder="Enter Search Terms"
                                  aria-label="Search"
                                  style={{ width: "85%" }}
                                  onChange={(e) => {
                                    this.setState(
                                      { textFilter: e.target.value },
                                      () => {
                                        if (this.state.textFilter === "") {
                                          this.callAllApps({
                                            filter: "",
                                          });
                                        }
                                      }
                                    );
                                  }}
                                  onKeyDown={(e) =>
                                    // this.setState({ globalFilter: e.target.value })
                                    {
                                      console.log("e", e.key);
                                      if (e.key === "Enter") {
                                        this.callAllApps({
                                          filter: this.state.textFilter,
                                        });
                                      } else {
                                        if (this.state.textFilter === "") {
                                          this.callAllApps({
                                            filter: "",
                                          });
                                        }
                                      }
                                    }
                                  }
                                  // onChange={(e) => {
                                  //   if (
                                  //     this.state.applist !== null &&
                                  //     this.state.applist !== undefined
                                  //   ) {
                                  //     let oldData = [...this.state.applist];
                                  //     console.log("e.target", e.target.value);
                                  //     this.state.applist.map((v, i) => {
                                  //       if (
                                  //         e.target.value !== "" &&
                                  //         e.target.value
                                  //           .toLowerCase()
                                  //           .match(v.appName.toLowerCase())
                                  //       ) {
                                  //         this.setState({
                                  //           applist: [v],
                                  //           oldData: oldData,
                                  //         });
                                  //       }
                                  //     });
                                  //     if (e.target.value === "") {
                                  //       this.setState({
                                  //         applist: this.state.oldData,
                                  //       });
                                  //     }
                                  //   }
                                  // }}
                                />
                                <i
                                  className="pi pi-search"
                                  style={{ cursor: "pointer" }}
                                  aria-hidden="true"
                                  onClick={() => {
                                    this.callAllApps({
                                      filter: this.state.textFilter,
                                    });
                                  }}
                                ></i>
                              </div>
                            </div>
                            <div
                              id="checkboxes"
                              className="p-col-12"
                              // style={{
                              //   height: "410px",
                              //   overflowY: "auto",
                              //   overflowX: "hidden",
                              // }}
                            >
                              <form
                                style={{
                                  height: "380px",
                                  overflowY: "auto",
                                  overflowX: "hidden",
                                }}
                                action="#"
                              >
                                {selectApp}
                              </form>
                              <Paginator
                                first={this.state.first1}
                                rows={this.state.eventrows1}
                                totalRecords={this.state.totalRecords1}
                                rowsPerPageOptions={[8, 12, 18, 50, 100]}
                                onPageChange={this.onPageChange1}
                                style={{ marginTop: "10px" }}
                              ></Paginator>
                            </div>
                          </div>
                          <div
                            className="p-col-12 p-md-4 p-lg-4 p-xl-4 gird-p"
                            style={{ display: "inline-block" }}
                          >
                            <div className="bgcolor">
                              <div className="smartphone">
                                <div className="content">
                                  <div
                                    className="headerbg"
                                    style={{
                                      paddingLeft: "8px",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    <img src={dlogo} width="25px" alt="" />
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        padding: "5px",
                                        color: "#ffffff",
                                      }}
                                    >
                                      Brand name
                                    </span>
                                  </div>
                                  <div className="bodycontent">{main_app}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="themelist">
                        <div className="p-grid">
                          <div className="p-col-12 row justify-content-between">
                            <div
                              className="p-col-4"
                              style={{ display: "inline-block" }}
                            >
                              <h2>Select Theme</h2>
                            </div>
                            {/* <div
                              className="p-col-4"
                              style={{ display: "inline-block" }}
                            >
                              <Link
                                to="/new-theme"
                                style={{ textDecoration: "underline" }}
                              >

                                + Create New Theme

                              </Link>
                            </div> */}
                            <div
                              className="p-col-4"
                              // style={{ display: "inline-block" }}
                              style={{ display: "none" }}
                            >
                              <div className="form-inline d-flex justify-content-center md-form form-sm mt-0 b-input">
                                <input
                                  className="form-control form-control-sm mr-2 b-n"
                                  type="text"
                                  placeholder="Enter Search Terms"
                                  aria-label="Search"
                                />
                                <i
                                  className="pi pi-search"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                          </div>
                          <div
                            className="p-col-12"
                            style={
                              {
                                // height: "315px",
                                // overflowY: "scroll",
                                // overflowX: "hidden",
                              }
                            }
                          >
                            <div className="phonebg">{theme_app}</div>
                            <Paginator
                              first={this.state.dfirst}
                              rows={this.state.eventrows}
                              totalRecords={this.state.totalRecords}
                              rowsPerPageOptions={[6, 12, 18, 50, 100]}
                              onPageChange={this.onPageChange}
                              style={{ marginTop: "10px" }}
                            ></Paginator>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="setting">
                        <div className="p-col-12">{controlSetting}</div>
                      </Tab.Pane>
                      {this.state.hasEnterpriseSetup === true ? (
                        <Tab.Pane eventKey="emm-setting">
                          <div className="p-col-12">{emmSettings}</div>
                        </Tab.Pane>
                      ) : (
                        ""
                      )}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
