import React, { Component } from "react";
import { baseUrl } from "../service/ApiUrls";
import api from "../service/ApiService";
import { confirmAlert } from "react-confirm-alert";

export default class RedirectionEmail extends Component {
  constructor(props) {
    super(props);
    console.log(props.location.search.split("email=")[1]);
    this.state = {
      email: props.location.search.split("email=")[1],
    };
  }
  componentDidMount = () => {
    api
      .post(baseUrl + "/ae-frp-emails", this.state)
      .then((res) => {
        if (res.data.result.isError === false) {
          window.location =
            process.env.PUBLIC_URL + "/factory-reset-protection";
        } else {
          confirmAlert({
            title: "Error Message",
            message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  window.location =
                    process.env.PUBLIC_URL + "/factory-reset-protection";
                },
              },
            ],
          });
        }
      })
      .catch((err) => {
        console.log(err);
        window.location = process.env.PUBLIC_URL + "/factory-reset-protection";
      });
  };

  render() {
    return <div></div>;
  }
}
