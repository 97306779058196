import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AllData } from "../service/AllData";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import AddNewDevice from "../components/AddNewDevice";
import { OSTypeEnum } from "../Enums";
import { Dialog } from "primereact/dialog";
import ErrorDialog from "../components/ErrorDialog";
import api from "../service/ApiService";
import SuccessDialog from "../components/SuccessDialog";
import Loader from "../components/Loader";
import {
  getGroupListUrl,
  getGroupListDevice,
  getDeviceSearchData,
  postGroupListAdd,
  getGroupListAssign,
} from "../service/ApiUrls";
import { Link } from "react-router-dom";

const osTypes = [
  { name: "ANDROID", code: OSTypeEnum.ANDROID },
  { name: "IOS", code: OSTypeEnum.IOS },
];
const unique = (
  newDevicesinGroup,
  encoder = JSON.stringify,
  decoder = JSON.parse
) =>
  [...new Set(newDevicesinGroup.map((item) => encoder(item)))].map((item) =>
    decoder(item)
  );

let addedDeviceIdList = [],
  removededDeviceIdList = [];
export class CreateDeviceGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      liked: false,
      errorMessage: "Something went wrong!",
      isError: false,
      isLoadding: false,
      isToggleOn: true,
    };
    this.handleClick = this.handleClick.bind(this);
    if (props.history.location.state !== undefined) {
      if (
        props.history.location.state.rowData !== undefined &&
        props.history.location.state.stateData !== undefined
      ) {
        this.state = {
          osTypeDropDown: osTypes[0],
          osType: osTypes[0].code,
          configType: "Default Config",
          headertitle: props.history.location.state.rowData.name,
          isEdit: props.history.location.state.isEdit,
          ...props.history.location.state.rowData,
          ...props.history.location.state.stateData,
          listofDevices: [],
          showMinus: true,
          showPlus: false,
          showText: "Added",
        };
      }
    } else {
      this.state = {
        configType: "Default Config",
        osTypeDropDown: osTypes[0],
        osType: osTypes[0].code,
        headertitle: "",
        listofDevices: [],
        isEdit: false,
        showMinus: true,
        showPlus: false,
        showText: "No Decision",
      };
    }
    this.alldata = new AllData();
  }

  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  workAfter = (removededDeviceIdList, addedDeviceIdList, rowData) => {
    removededDeviceIdList = unique(removededDeviceIdList);
    let array = [...this.state.group.deviceInfoList];
    var index = array.indexOf(rowData);
    if (index !== -1) {
      // let x = this.checkIsServerDevice(rowData.id);
      // console.log("Checking the condition ", x);
      this.state.serverDeviceList !== undefined &&
        this.state.serverDeviceList !== null &&
        this.state.serverDeviceList.forEach((obj) => {
          if (obj.id === rowData.id) {
          } else {
            // console.log("If server list is not empty");
            array.splice(index, 1);
            addedDeviceIdList.splice(addedDeviceIdList.indexOf(rowData.id), 1);
          }
        });
      //console.log("serverlist ", this.state.serverDeviceList);

      if (
        this.state.serverDeviceList === undefined ||
        this.state.serverDeviceList.length === 0 ||
        this.state.serverDeviceList === null
      ) {
        //console.log("If server list is empty");
        array.splice(index, 1);
        addedDeviceIdList.splice(addedDeviceIdList.indexOf(rowData.id), 1);
      }
      this.setState(
        {
          group: { deviceInfoList: array },
          removededDeviceIdList: removededDeviceIdList,
        },
        () => {
          addedDeviceIdList = [...addedDeviceIdList];
          addedDeviceIdList = unique(addedDeviceIdList);
          // console.log(
          //   "addedDeviceIdList in state function ",
          //   addedDeviceIdList
          // );
          this.setState({ addedDeviceIdList: addedDeviceIdList });
        }
      );
    }
  };

  onDialogueHide = () => {
    this.setState({ isError: false });
  };

  callDeviceSearchData = () => {
    api
      .get(getDeviceSearchData)
      .then((res) => {
        if (res.data.result.isError === false) {
          console.log("Inside false");
          this.setState(
            {
              profileList: res.data.data.profileList,
              groupList: res.data.data.groupList,
            },
            () => {
              if (this.state.isEdit) {
                this.callGroupListDevices();
                this.callGroupDataByRowId({ id: this.state.id });
                let selectedProfile =
                  this.state.profileList.length > 0
                    ? this.state.profileList.find((obj) => {
                        return obj.id === this.state.profileId;
                      })
                    : null;
                this.setState({ selectedProfile: selectedProfile });
              } else {
                this.callGroupListDevices({ profileId: null });
              }
              if (this.state.group !== undefined) {
                if (this.state.group.deviceInfoList !== undefined) {
                  this.state.group.deviceInfoList.map((e, i) => {
                    e["addStatus"] = "Not added";
                  });
                  this.setState({
                    group: { deviceInfoList: this.state.group.deviceInfoList },
                  });
                }
              }
              // this.setState({ selectedProfile: this.state.profileList[0] });
            }
          );
        } else if (res.data.result.isError) {
          this.setState({
            isError: true,
            errorMessage: res.data.result.errorMsg,
          });
        }
      })
      .catch((err) => {
        // localStorage.setItem("loggedIn", false);
        // window.location = process.env.PUBLIC_URL + "/login";
      });
  };
  onDialogueHideSuccess = () => {
    this.setState({ isSuccess: false });
  };
  callGroupDataByRowId = ({ id }) => {
    api
      .get(getGroupListUrl + "/" + id)
      .then((res) => {
        if (res.data.result.isError === false) {
          let dataToprocess = res.data.data.deviceInfoList;
          dataToprocess.map((e, i) => {
            e["addStatus"] = "Added";
          });
          this.setState({
            group: res.data.data,
            serverDeviceList: dataToprocess,
          });
        } else if (res.data.result.isError) {
          this.setState({
            isError: true,
            errorMessage: res.data.result.errorMsg,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.callDeviceSearchData();
  }
  callGroupListDevices({ profileId } = {}) {
    api
      .get(getGroupListAssign, {
        params: { profileId: profileId, criteria: 2 },
      })
      .then((res) => {
        if (res.data.result.isError === false) {
          this.setState({ listofDevices: res.data.data.content });
        } else if (res.data.result.isError) {
          this.setState({
            isError: true,
            errorMessage: res.data.result.errorMsg,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  callPostGroupAdd = ({
    name,
    osType = OSTypeEnum.ANDROID,
    profileId,
    addedDeviceIdList,
  }) => {
    api
      .post(postGroupListAdd, { name, osType, profileId, addedDeviceIdList })
      .then((res) => {
        if (res.data.result.isError === false) {
          window.location = process.env.PUBLIC_URL + "/device-group";
        } else if (res.data.result.isError) {
          this.setState({
            isError: true,
            errorMessage: res.data.result.errorMsg,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  callPutGroupAdd = ({
    id,
    name,
    osType = OSTypeEnum.ANDROID,
    profileId,
    addedDeviceIdList,
    removededDeviceIdList,
  }) => {
    api
      .put(getGroupListUrl + "/" + id, {
        id,
        name,
        osType,
        profileId,
        addedDeviceIdList,
        removededDeviceIdList,
      })
      .then((res) => {
        if (res.data.result.isError === false) {
          window.location = process.env.PUBLIC_URL + "/device-group";
        } else if (res.data.result.isError) {
          this.setState({
            isError: true,
            errorMessage: res.data.result.errorMsg,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  checkIsServerDevice = (id) => {
    let x = false;
    this.state.serverDeviceList.forEach((obj) => {
      //console.log("inside checking ", obj.id, id, obj.id === id, x);
      if (obj.id === id) {
        x = true;
      }
    });
    console.log("function to send", x);
    return x;
  };

  actionTemplate(rowData, column) {
    //console.log("rowdata", rowData, "column ", column);
    return (
      <>
        <i
          className="pi pi-plus-circle icon-p"
          style={{ fontSize: "1.3em", color: "green", padding: "2px 5px" }}
          onClick={(e) => {
            this.state.serverDeviceList !== undefined &&
              this.state.serverDeviceList !== null &&
              this.state.serverDeviceList.forEach((obj) => {
                if (obj.id === rowData.id) {
                  removededDeviceIdList.splice(
                    removededDeviceIdList.indexOf(rowData.id),
                    1
                  );
                  addedDeviceIdList = [...addedDeviceIdList, rowData.id];
                  addedDeviceIdList = unique(addedDeviceIdList);
                  removededDeviceIdList = unique(removededDeviceIdList);
                  addedDeviceIdList = unique(addedDeviceIdList);
                  this.setState({
                    removededDeviceIdList: removededDeviceIdList,
                    addedDeviceIdList: addedDeviceIdList,
                  });
                }
              });
            //this.workAfter(removededDeviceIdList, addedDeviceIdList, rowData);
            rowData["addStatus"] = "Added";
          }}
        ></i>
        <i
          className="pi pi-minus-circle icon-p"
          style={{ fontSize: "1.3em", color: "red", padding: "2px 5px" }}
          onClick={(e) => {
            let flag = false;
            this.state.serverDeviceList !== undefined &&
              this.state.serverDeviceList !== null &&
              this.state.serverDeviceList.forEach((obj) => {
                if (obj.id === rowData.id) {
                  flag = true;
                  removededDeviceIdList.push(rowData.id);
                  addedDeviceIdList.splice(
                    addedDeviceIdList.indexOf(rowData.id),
                    1
                  );
                  removededDeviceIdList = unique(removededDeviceIdList);
                  addedDeviceIdList = unique(addedDeviceIdList);
                  this.setState({
                    removededDeviceIdList: removededDeviceIdList,
                    addedDeviceIdList: addedDeviceIdList,
                  });
                }
              });
            if (!flag) {
              console.log("check point");
              this.workAfter(removededDeviceIdList, addedDeviceIdList, rowData);
            }
            //this.workAfter(removededDeviceIdList, addedDeviceIdList, rowData);
            rowData["addStatus"] = "Removed";
            //this.setState({ showText: "Removed" });
          }}
        ></i>
      </>
    );
  }

  // actionTemplateMinus(rowData, column) {
  //   //console.log("rowdata", rowData, "column ", column);
  //   return (
  //     <Link>
  //       <i
  //         className={"pi pi-minus-circle"}
  //         style={{ fontSize: "1.3em", color: "red" }}
  //         onClick={(e) => {
  //           let flag = false;
  //           this.state.serverDeviceList !== undefined &&
  //             this.state.serverDeviceList !== null &&
  //             this.state.serverDeviceList.forEach((obj) => {
  //               if (obj.id === rowData.id) {
  //                 flag = true;
  //                 removededDeviceIdList.push(rowData.id);
  //                 addedDeviceIdList.splice(
  //                   addedDeviceIdList.indexOf(rowData.id),
  //                   1
  //                 );
  //                 removededDeviceIdList = unique(removededDeviceIdList);
  //                 addedDeviceIdList = unique(addedDeviceIdList);
  //                 this.setState({
  //                   removededDeviceIdList: removededDeviceIdList,
  //                   addedDeviceIdList: addedDeviceIdList,
  //                 });
  //               }
  //             });
  //           if (!flag) {
  //             this.workAfter(removededDeviceIdList, addedDeviceIdList, rowData);
  //           }
  //           //this.workAfter(removededDeviceIdList, addedDeviceIdList, rowData);
  //           rowData["addStatus"] = "Removed";
  //           //this.setState({ showText: "Removed" });
  //         }}
  //       ></i>
  //     </Link>
  //   );
  // }
  configTypeChange = (e) => {
    this.setState({ configType: e.value });
  };
  osTypeChange = (e) => {
    this.setState({ osTypeDropDown: e.value, osType: e.value.code });
  };

  getSelectedDevices = (selectedDevices) => {
    console.log("getSelected Devices", selectedDevices);
    addedDeviceIdList = [];
    selectedDevices.forEach((obj) => {
      addedDeviceIdList.push(obj.id);
    });

    selectedDevices.map((e, i) => {
      e["addStatus"] = "Added";
    });

    let newDevicesinGroup = [];
    if (this.state.group !== undefined) {
      newDevicesinGroup = [
        ...this.state.group.deviceInfoList,
        ...selectedDevices,
      ];
      //console.log("newDeviceGroup before unique", newDevicesinGroup);

      newDevicesinGroup = unique(newDevicesinGroup);

      //console.log("newDeviceGroup after unique", unique(newDevicesinGroup));
    } else {
      newDevicesinGroup = [...selectedDevices];
    }

    this.setState({
      addedDeviceIdList: addedDeviceIdList,
      group: { deviceInfoList: newDevicesinGroup },
    });
  };
  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Device Outline", url: "device-outline" },
      { label: "Device Group", url: "device-group" },
      {
        label:
          (this.state.isEdit !== undefined && this.state.isEdit
            ? "Edit "
            : "Create ") + "Device Group",
      },
    ];

    const profileList = this.state.profileList;
    let profileOptions =
      profileList !== undefined &&
      profileList.length > 0 &&
      profileList.map((e, i) => {
        return (
          <option key={i} value={e.id} selected>
            {e.name}
          </option>
        );
      });

    const home = { icon: "pi pi-home", url: "dashboard" };
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 ">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
          <Dialog
            visible={this.state.isLoading}
            modal={true}
            closable={false}
            showHeader={false}
            style={{ width: "20vw" }}
            onHide={this.onDialogueHide}
          >
            <Loader />
          </Dialog>
          <Dialog
            visible={this.state.isSuccess}
            modal={true}
            style={{ width: "25vw" }}
            closable
            showHeader
            onHide={this.onDialogueHideSuccess}
          >
            <SuccessDialog successMessage="Created Group Successfully" />
          </Dialog>
        </div>
        <div className="p-grid bg-col p-col-12 p-lg-12 ">
          <div className="p-lg-8">
            <div className="p-col">
              <h2>
                {this.state.isEdit !== undefined && this.state.isEdit
                  ? "Edit "
                  : "Create "}
                New Group
              </h2>
            </div>
            <div className="p-col-12 p-md-12">
              <div className="p-col-12 p-md-12">
                <DataTable
                  paginator={true}
                  emptyMessage="No records found"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  rows={10}
                  rowsPerPageOptions={[5, 10, 20]}
                  value={
                    this.state.group !== undefined
                      ? this.state.group.deviceInfoList
                      : null
                  }
                  style={{ marginBottom: "20px" }}
                  responsive={true}
                  //footer={this.displaySelection(this.state.selectedCars3)}
                  selection={this.state.selectedCars3}
                  onSelectionChange={(e) =>
                    this.setState({ selectedCars3: e.value })
                  }
                >
                  {/* <Column selectionMode="multiple" style={{ width: "3em" }} /> */}
                  {/* <Column field="deviceName" header="Device Name" /> */}
                  <Column
                    field="imei1"
                    header="IMEI1"
                    style={{ width: "auto", wordBreak: "break-all" }}
                  />
                  <Column
                    field="imei2"
                    header="IMEI2"
                    style={{ width: "auto", wordBreak: "break-all" }}
                  />
                  <Column
                    field="osVersion"
                    header="OS Version"
                    style={{ wordWrap: "break-word" }}
                  />
                  <Column field="model" header="Model" />

                  {/* <Column
                    header="Add"
                    body={this.actionTemplatePlus.bind(this)}
                    style={{ textAlign: "center", width: "4em" }}
                  /> */}
                  <Column
                    header="Action"
                    body={this.actionTemplate.bind(this)}
                    style={{ textAlign: "center", width: "7em" }}
                  />
                  <Column
                    field="addStatus"
                    header="Status"
                    // body={(e) => this.state.showText}
                    style={{ textAlign: "center", width: "7em" }}
                  />
                </DataTable>
              </div>
              <div className="p-col-12 p-md-12" style={{ textAlign: "center" }}>
                <AddNewDevice
                  listofDevices={this.state.listofDevices}
                  profileList={this.state.profileList}
                  sendSelections={this.getSelectedDevices}
                />
              </div>
            </div>
          </div>
          <div className="p-lg-4">
            <div className="bg-col p-col">
              <div className="p-col-12 p-md-12">
                <label>Group Name</label>
                <InputText
                  value={this.state.headertitle}
                  onChange={(e) =>
                    this.setState({ headertitle: e.target.value })
                  }
                  placeholder="Enter Here"
                />
              </div>
              <div className="p-col-12 p-md-12">
                <label>Select Profile</label>
                <select
                  className="form-control"
                  // id="exampleFormControlSelect1"
                  onChange={(e) => {
                    //console.log("e in profile ", e.target);
                    let profileObject = profileList.find((obj) => {
                      //console.log("object", obj.id);
                      return obj.id === e.target.value;
                    });
                    //console.log("profile object", profileObject);
                    this.setState(
                      {
                        ...this.state,
                        selectedProfile: profileObject,
                      },
                      () => {
                        this.callGroupListDevices({
                          profileId: this.state.selectedProfile.id,
                        });
                      }
                    );
                  }}
                  id="selectedProfile"
                  name="selectedProfile"
                  value={
                    this.state.selectedProfile !== null &&
                    this.state.selectedProfile !== undefined
                      ? this.state.selectedProfile.id
                      : ""
                  }
                >
                  <option>Select Profile</option>
                  {profileOptions}
                </select>
              </div>
              <div className="p-col-12 p-md-12">
                <label>Select OS Type</label>
                <Dropdown
                  value={this.state.osTypeDropDown}
                  options={osTypes}
                  onChange={this.osTypeChange}
                  placeholder="Select OS Type"
                  optionLabel="name"
                />
              </div>
              <Button
                type="button"
                label={this.state.isEdit ? "Update" : "Submit"}
                className="p-button-rounded p-button-success"
                style={{
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  marginTop: "15px",
                }}
                onClick={(e) => {
                  this.setState({ isLoading: true });
                  let dataToSubmit = {
                    name: this.state.headertitle,
                    osType: this.state.osType,
                    profileId: this.state.selectedProfile.id,
                    addedDeviceIdList: this.state.addedDeviceIdList,
                  };
                  let dataToUpdate;
                  if (this.state.isEdit) {
                    dataToUpdate = {
                      ...dataToSubmit,
                      removededDeviceIdList: this.state.removededDeviceIdList,
                      id: this.state.id,
                    };
                    this.callPutGroupAdd(dataToUpdate);
                  } else {
                    this.callPostGroupAdd(dataToSubmit);
                  }
                }}
              ></Button>
              <Dialog
                visible={this.state.isError}
                modal
                style={{ width: "20vw" }}
                closable
                showHeader
                onHide={this.onDialogueHide}
              >
                <ErrorDialog errorMessage={this.state.errorMessage} />
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
