import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";

export class AssignData extends Component {
  constructor(props) {
    super(props);
    //console.log("props from ", this.props.location.state);
    this.state = {
      checked: false,
      checkedgroupItems: [],
      checkedprofileItems: [],
      opt: null,
    };
    this.dropOption = [
      { name: "Group & Profile", code: "GP" },
      { name: "Device", code: "DV" },
    ];
    this.dropOptionChange = this.dropOptionChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      checkedgroupItems: this.populateGroupCheckedList(
        this.props.location.state.e.groupIds
      ),
      checkedprofileItems: this.populateGroupCheckedList(
        this.props.location.state.e.profileIds
      ),
    });
  }
  dropOptionChange(e) {
    this.setState({ opt: e.value });
  }

  onCheckBoxChange = (tag) => (e) => {
    //console.log("inside on change ", tag, e.checked, e.value);
    let selectedGroupCheckBoxes;
    selectedGroupCheckBoxes =
      tag === "group"
        ? [...this.state.checkedgroupItems]
        : [...this.state.checkedprofileItems];

    if (e.checked) selectedGroupCheckBoxes.push(e.target);
    else
      selectedGroupCheckBoxes.splice(
        selectedGroupCheckBoxes.indexOf(e.value),
        1
      );

    console.log("selected checks", selectedGroupCheckBoxes);

    switch (tag) {
      case "group":
        this.setState({ checkedgroupItems: selectedGroupCheckBoxes });
        break;
      case "profile":
        this.setState({ checkedprofileItems: selectedGroupCheckBoxes });
        break;
      default:
        break;
    }
  };

  populateGroupCheckedList = (groupList) => {
    let groupListChecked = [];
    groupList.forEach((e) => {
      groupListChecked.push({ ...e, checked: true });
    });
    return groupListChecked;
  };

  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "App Management", url: "app-management" },
      { label: "Assign" },
    ];
    const home = { icon: "pi pi-home", url: "dashboard" };
    const {
      location: {
        state: {
          e: { appLogoBase64, appName: title },
          groupList: group,
          profileList: profile,
        },
      },
    } = this.props;

    const divhome = (
      <div className="p-col-12 p-lg-12 ">
        <div className="content-section implementation">
          <BreadCrumb model={items} home={home} />
        </div>
      </div>
    );
    const groupList = group.map((e, i) => {
      return (
        <div className="custom-control custom-checkbox" key={i}>
          <Checkbox
            inputId={i + ""}
            value={e.value}
            onChange={this.onCheckBoxChange("group")}
            checked={this.state.checkedgroupItems.some(
              (obj) => obj.value === e.value
            )}
            name={e.name}
          ></Checkbox>
          <label htmlFor={i + ""} className="p-checkbox-label">
            {e.name}
          </label>
        </div>
      );
    });
    const profileList = profile.map((e, j) => {
      return (
        <div className="custom-control custom-checkbox" key={j}>
          <Checkbox
            inputId={j + ""}
            value={e.value}
            onChange={this.onCheckBoxChange("profile")}
            checked={this.state.checkedprofileItems.some(
              (obj) => obj.value === e.value
            )}
            name={e.name}
          ></Checkbox>
          <label htmlFor={j + ""} className="p-checkbox-label">
            {e.name}
          </label>
        </div>
      );
    });

    const loadGroupData = (
      <div className="p-col-12" style={{ width: "100%" }}>
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <a
              className="nav-item nav-link active"
              id="nav-home-tab"
              data-toggle="tab"
              href="#nav-home"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Group
            </a>
            <a
              className="nav-item nav-link"
              id="nav-profile-tab"
              data-toggle="tab"
              href="#nav-profile"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              Profile
            </a>
          </div>
        </nav>
        <div
          className="tab-content"
          id="nav-tabContent"
          style={{
            textAlign: "left",
            padding: "2px",
            marginTop: "3px",
          }}
        >
          <div
            className="tab-pane fade show active"
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
            style={{ marginTop: "10px" }}
          >
            {groupList}
          </div>
          <div
            className="tab-pane fade"
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="nav-profile-tab"
            style={{ marginTop: "10px" }}
          >
            {profileList}
          </div>
        </div>
      </div>
    );
    const loadDeviceData = (
      <div className="p-col-12" style={{ width: "100%" }}>
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <a
              className="nav-item nav-link active"
              id="nav-home-tab"
              data-toggle="tab"
              href="#nav-home"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Device
            </a>
          </div>
        </nav>
        <div
          className="tab-content"
          id="nav-tabContent"
          style={{
            textAlign: "left",
            padding: "2px",
            marginTop: "3px",
          }}
        >
          <div
            className="tab-pane fade show active"
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
            style={{ marginTop: "10px" }}
          >
            Device Data
          </div>
        </div>
      </div>
    );
    let component = null;
    switch (this.state.opt !== null && this.state.opt.code) {
      case "GP":
        component = loadGroupData;
        break;
      case "DV":
        component = loadDeviceData;
        break;
      default:
        break;
    }

    return (
      <div className="p-col-12 p-grid">
        {divhome}
        <div className="p-grid bg-col p-lg-12">
          <div className="p-col">
            <div className="col-lg-12" style={{ display: "inline-block" }}>
              <div className="assign-list">
                <div className="assign-blcok">
                  <img
                    src={`data:image/jpeg;base64,${appLogoBase64}`}
                    alt={title}
                    width="55px"
                    className="assign-img"
                  />
                </div>
                <span className="assign-title">{title}</span>
                <span className="assign-subtitle">
                  Default {title} App For Android
                </span>
                <span className="assign-link">
                  {/* <a href="#">Number of Device Configured : </a>
                  <a href="#" style={{ marginLeft: "3px" }}>
                    {" "}
                    40
                  </a> */}
                </span>
              </div>
            </div>
            <div className="row justify-content-start ">
              <div
                className="p-col-3 "
                style={{ display: "inline-block", padding: "5px" }}
              >
                {/* <div className="form-group">
                  <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                  >
                    <option>Group & Profile</option>
                    <option>Device</option>
                  </select>
                </div> */}
                <div className="form-group">
                  <Dropdown
                    value={this.state.opt}
                    options={this.dropOption}
                    onChange={this.dropOptionChange}
                    style={{ width: "100%", padding: "2px" }}
                    optionLabel="name"
                    placeholder="Select Option"
                  />
                </div>
              </div>
              <div
                className="p-col-4"
                style={{ display: "inline-block", padding: "5px" }}
              >
                <form
                  className="form-inline d-flex justify-content-center md-form form-sm mt-0 b-input"
                  style={{ padding: "3px 0px" }}
                >
                  <input
                    className="form-control form-control-sm mr-2 b-n"
                    type="text"
                    placeholder="Enter Search Terms"
                    aria-label="Search"
                    style={{ width: "80%" }}
                  />
                  <i className="pi pi-search" aria-hidden="true"></i>
                </form>
              </div>
            </div>
            {component}
            <div className="p-col-12">
              <button
                className="btn btn-info"
                style={{ padding: "5px 14px", margin: "5px" }}
              >
                Update
              </button>
              <button
                type="button"
                className="btn btn-outline-info"
                style={{ padding: "5px 14px", margin: "5px" }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
