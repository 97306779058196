import React from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { Button, Modal } from "react-bootstrap";
import { getAppManagemnetUrl } from "../service/ApiUrls";
import { OSTypeEnum, AppTypeEnum, ActionType, DeleteType } from "../Enums";
import api from "../service/ApiService";
import { Dialog } from "primereact/dialog";
import Loader from "../components/Loader";
import SuccessDialog from "../components/SuccessDialog";
import ErrorDialog from "../components/ErrorDialog";
import { Link } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";
import { confirmAlert } from "react-confirm-alert";
function PopupForm({ e, callParentApi, from }) {
  //console.log("props in Popup", e);
  const [show, setShow] = useState(false);
  const [dialogue, setDialogue] = useState(false);
  const [isSuccess, setSucess] = useState(false);
  const [isErrors, setError] = useState(false);
  const [isErrorsmsg, setErrormsg] = useState(false);

  const [textToLoader] = useState("Loading....");
  const [successText, setSuccessText] = useState("Updated Successfully");
  const [errorMessage, setErrorMessage] = useState("Something Worng!");
  let [inputAppName, setInputAppName] = React.useState(e.appName);
  let [inputAppId, setInputAppId] = React.useState(e.appId);
  let [inputAppVersion, setInputAppVersion] = React.useState(e.versionName);

  let callDeleteAppManagement = async () => {
    return api
      .delete(getAppManagemnetUrl, {
        data: {
          id: e.id,
          deleteType: DeleteType.APPVERSIONDELETE,
          actionType: ActionType.DELETE,
        },
      })
      .then((res) => {
        console.log("res", res);
        return res.data;
      })
      .catch((err) => this.props.history.push("/login"));
  };

  let callUpdateAppManagementApi = async ({
    appName = inputAppName,
    appId = inputAppId,
    versionName = inputAppVersion,
    versionCode,
    appType = AppTypeEnum.SYSTEMAPP,
    osType = OSTypeEnum.ANDROID,
    logoTempId,
    profileIds,
    groupIds,
    deviceIds,
    isLatest,
  } = {}) => {
    let data = {
      appName,
      appId,
      versionName,
      versionCode,
      appType: AppTypeEnum.SYSTEMAPP,
      osType: OSTypeEnum.ANDROID,
      logoTempId,
      profileIds,
      groupIds,
      deviceIds,
      isLatest,
    };

    return api
      .put(getAppManagemnetUrl + "/" + e.id, data)
      .then((res) => {
        console.log("res", res);
        return res.data;
      })
      .catch((err) => this.props.history.push("/login"));
  };

  let handleClose = (buttonType) => async () => {
    console.log("button Type", buttonType);

    setShow(false);
    setErrormsg(false);
    switch (buttonType) {
      case "update":
        console.log("update call");
        setDialogue(true);
        let {
          result: { isError, errorMsg },
        } = await callUpdateAppManagementApi();

        if (isError === false) {
          confirmAlert({
            title: "Success Message",
            message: <p className="mod-sp">Updated App Successfully</p>,
            buttons: [
              {
                label: "Ok",
                onClick: () => {},
              },
            ],
          });
          // setSucess(true);
          // setSuccessText("Updated App Successfully");
          callParentApi();
          setDialogue(false);
        } else if (isError === true) {
          setErrorMessage(errorMsg);
          setShow(true);
          setDialogue(false);
          setErrormsg(true);
          // confirmAlert({
          //   title: "Error Message",
          //   message: <p className="mod-p"> Error Occurs! </p>,
          //   buttons: [
          //     {
          //       label: "Ok",
          //       onClick: () => {},
          //     },
          //   ],
          // });
        }
        break;
      case "remove":
        console.log("remove call");
        setDialogue(true);
        let response = await callDeleteAppManagement();
        if (response.result.isError === false) {
          confirmAlert({
            title: "Success Message",
            message: <p className="mod-sp">Deleted App Successfully</p>,
            buttons: [
              {
                label: "Ok",
                onClick: () => {},
              },
            ],
          });
          // setSucess(true);
          // setSuccessText("Deleted App Successfully");
          setDialogue(false);
          if (from !== "ent") callParentApi();
          else {
            console.log("Calling EnterPrise APP");
            callParentApi({ apptype: AppTypeEnum.ENTERPRISEAPP });
          }
        } else if (response.result.isError) {
          confirmAlert({
            title: "Error Message",
            message: <p className="mod-p"> {response.result.errorMsg} </p>,
            buttons: [
              {
                label: "Ok",
                onClick: () => {},
              },
            ],
          });

          // setError(true);
          setDialogue(false);
          setErrormsg(false);
          // setErrorMessage(response.result.errorMsg);
        }

        break;
      default:
        console.log("default close");
        break;
    }
    setInputAppName(e.appName);
  };
  let handleShow = () => {
    setShow(true);
    setInputAppName(e.appName);
    setInputAppId(e.appId);
    setInputAppVersion(e.versionName);
  };
  let handleInputChange = (tag) => (event) => {
    console.log("tag", tag);
    switch (tag) {
      case "name":
        setInputAppName(event.target.value);
        break;
      case "version":
        setInputAppVersion(event.target.value);
        break;
      case "appId":
        setInputAppId(event.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Link
        className="icon-p"
        onClick={handleShow}
        style={{ paddingLeft: "10px" }}
      >
        <Tooltip
          target=".pi-list"
          position="top"
          mouseTrack
          mouseTracktop={20}
        />
        <i
          className="pi pi-list"
          data-pr-tooltip="Manage"
          style={{ fontSize: "1em" }}
        ></i>
      </Link>
      <Dialog
        visible={dialogue}
        modal={true}
        style={{ width: "20vw" }}
        closable={false}
        showHeader={false}
        onHide={() => {
          setDialogue(false);
        }}
      >
        <Loader textToShow={textToLoader} />
      </Dialog>
      <Dialog
        visible={isErrors}
        modal={true}
        style={{ width: "25vw" }}
        closable={true}
        showHeader={true}
        onHide={() => {
          setError(false);
        }}
      >
        <ErrorDialog errorMessage={errorMessage} />
      </Dialog>
      <Dialog
        visible={isSuccess}
        modal={true}
        style={{ width: "25vw" }}
        closable={true}
        showHeader={true}
        onHide={() => {
          setSucess(false);
        }}
      >
        <SuccessDialog successMessage={successText} />
      </Dialog>

      <Modal size="sm" show={show} onHide={handleClose()}>
        <Modal.Header closeButton>
          <p style={{ fontSize: "16px", fontWeight: "bold" }}>
            App Manage System
          </p>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="appName">
              <div className="form-group">
                <div
                  className={
                    isErrorsmsg === true
                      ? "alert alert-danger alert-dismissible fade show"
                      : "d-none"
                  }
                  role="alert"
                >
                  {errorMessage}
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <Form.Label>
                App Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={inputAppName}
                onChange={handleInputChange("text")}
                placeholder="Enter App Name"
              />
            </Form.Group>
            {/* <Form.Group controlId="Logo">
              <Form.Label>Logo</Form.Label>
              <Form.Control type="file" placeholder="Enter Logo" />
            </Form.Group> */}
            <Form.Group controlId="appId">
              <Form.Label>App ID</Form.Label>
              <Form.Control
                type="text"
                value={inputAppId}
                onChange={handleInputChange("appId")}
                placeholder="Enter App ID"
              />
            </Form.Group>
            <Form.Group controlId="version">
              <Form.Label>Version</Form.Label>
              <Form.Control
                type="text"
                value={inputAppVersion}
                onChange={handleInputChange("version")}
                placeholder="Enter Version"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ textAlign: "center", justifyContent: "center" }}>
          <Button variant="primary" onClick={handleClose("update")}>
            Update
          </Button>
          <Button variant="danger" onClick={handleClose("remove")}>
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default PopupForm;
