import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Form, ModalBody } from "react-bootstrap";
import { Button, Modal } from "react-bootstrap";
import Upload from "./Upload";
import { Dialog } from "primereact/dialog";
import Loader from "../components/Loader";
import { getAppManagemnetUrl } from "../service/ApiUrls";
import { OSTypeEnum, AppTypeEnum } from "../Enums";
import api from "../service/ApiService";
import { Tooltip } from "primereact/tooltip";
import { confirmAlert } from "react-confirm-alert";
const vDetails = require("../assets/view.png");
function EntPopupForm(props) {
  const { showDetails, ObjectToShow, textToShow } = props;
  const [show, setShow] = useState(false);
  const [buttondisable, setButtondisable] = useState(true);
  const [showDetailsFrom] = useState(
    showDetails === undefined ? false : showDetails
  );
  const [textToShowDiv] = useState(
    textToShow !== undefined ? textToShow : "Upload New"
  );
  const [description, setdescription] = useState(
    ObjectToShow !== undefined ? ObjectToShow.description : ""
  );
  const [releaseNote, setreleaseNote] = useState(
    ObjectToShow !== undefined ? ObjectToShow.releaseNote : ""
  );
  const [dataToUpload, setdataToUpload] = useState();
  const [dialogue, setDialogue] = useState(false);

  const [appName, setappName] = useState();
  const [appId, setappId] = useState();
  const [versionName, setVersionName] = useState();
  const [apkSize, setApkSize] = useState();
  const [appLogoBase64, setAppLogoBase64] = useState();
  const [textToLoader, settextToLoader] = useState("Loading....");
  const [versionCode, setVersionCode] = useState();

  let callPostAppManagementApi = async ({
    appName = this.state.appName,
    appId = this.state.appId,
    versionName = this.state.versionName,
    versionCode,
    appType = AppTypeEnum.ENTERPRISEAPP,
    osType = OSTypeEnum.ANDROID,
    logoTempId,
    profileIds,
    groupIds,
    deviceIds,
    isLatest,
    description,
    releaseNote,
    fileTempId,
  } = {}) => {
    let data = {
      appName,
      appId,
      versionName,
      versionCode,
      appType,
      osType,
      logoTempId,
      profileIds,
      groupIds,
      deviceIds,
      isLatest,
      description,
      releaseNote,
      fileTempId,
      apkSize,
    };

    return api
      .post(getAppManagemnetUrl + "/add", data)
      .then((res) => {
        console.log("res", res);
        return res.data;
      })
      .catch((err) => this.props.history.push("/login"));
  };

  const handleClose = () => {
    setdataToUpload(null);
    setappId(null);
    setappName(null);
    setAppLogoBase64(null);
    setVersionName(null);
    setVersionCode(null);
    setApkSize(null);
    setShow(false);
    setButtondisable(true);
  };
  const handleShow = (ObjectToShow) => () => {
    setShow(true);
    if (ObjectToShow !== undefined) {
      let data = ObjectToShow;
      setappId(data.appId);
      setappName(data.appName);
      setAppLogoBase64(data.appLogoBase64);
      setVersionName(data.versionName);
      setVersionCode(data.versionCode);
      setApkSize(data.apkSize);
      setdescription(data.description !== undefined && data.description);
      setreleaseNote(data.releasNote !== undefined && data.releasNote);
    }
  };
  const onChangeDescription = (e) => {
    setdescription(e.target.value);
    //console.log("value of description ", description);
  };
  const onChangeRelease = (e) => {
    setreleaseNote(e.target.value);
    //console.log("value of release", releaseNote);
  };
  const submitHandler = async ({ data, result }, AllFiles) => {
    console.log("response", { data, result }, "Allfiles", AllFiles);
    setDialogue(true);
    setButtondisable(true);
    if (result === null) {
      setdataToUpload(null);
      setDialogue(false);
      setShow(false);

      confirmAlert({
        title: "Error Message",
        message: (
          <p className="mod-p">
            {" "}
            {result !== null ? result.errorMsg : "Upload is not completed"}{" "}
          </p>
        ),
        buttons: [
          {
            label: "Ok",
            onClick: () => {},
          },
        ],
      });
    } else if (result.isError === false) {
      console.log("In success data ", data);
      setdataToUpload(data);
      setappId(data.appId);
      setappName(data.appName);
      setAppLogoBase64(data.appLogoBase64);
      setVersionName(data.versionName);
      setApkSize(data.apkSize);
      setVersionCode(data.versionCode);
      setDialogue(false);
      setButtondisable(false);
    } else if (result.isError === true) {
      setdataToUpload(null);
      setDialogue(false);
      setShow(false);
      confirmAlert({
        title: "Error Message",
        message: <p className="mod-p"> {result.errorMsg} </p>,
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              setShow(true);
            },
          },
        ],
      });
    }
  };
  const uploadApk = async () => {
    setDialogue(true);
    setShow(false);
    if (dataToUpload === undefined || dataToUpload === null) {
      setDialogue(false);
      setShow(false);
      confirmAlert({
        title: "Error Message",
        message: <p className="mod-p"> Error Occurs! </p>,
        buttons: [
          {
            label: "Ok",
            onClick: () => {},
          },
        ],
      });
      return;
    }
    let response = await callPostAppManagementApi({
      ...dataToUpload,
      description: description,
      releaseNote: releaseNote,
      appType: AppTypeEnum.ENTERPRISEAPP,
      apkSize: apkSize,
    });
    setShow(false);
    console.log("response", response);
    //window.location = process.env.PUBLIC_URL + "/app-management";
    if (response.result.isError === false) {
      setDialogue(false);
      setShow(false);
      confirmAlert({
        title: "Success Message",
        message: <p className="mod-sp">Added App Successfully</p>,
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              window.location = process.env.PUBLIC_URL + "/app-management";
            },
          },
        ],
      });
      console.log("propsss", props);
      // props.history.push("/app-management");
      // window.location = process.env.PUBLIC_URL + "/app-management";
      //this.callParentApi({ appType: AppTypeEnum.ENTERPRISEAPP });
    }
    if (response.result.isError === true) {
      console.log("In this error");
      setShow(false);
      setDialogue(false);
      confirmAlert({
        title: "Error Message",
        message: <p className="mod-p"> {response.result.errorMsg} </p>,
        buttons: [
          {
            label: "Ok",
            onClick: () => {},
          },
        ],
      });
      // settextToLoader(response.result.errorMsg);
      //this.props.history.push("/app-management");
      window.location = process.env.PUBLIC_URL + "/app-management";
      //callParentApi({ appType: AppTypeEnum.ENTERPRISEAPP });
    }
  };

  const fieldToShow = ({
    appLogoBase64_1,
    appName_1,
    appId_1,
    versionName_1,
    apkSize_1,
  } = {}) => {
    return (
      <div className="row align-items-start mb-3">
        <div className="col col-md-3">
          {" "}
          <img
            src={`data:image/jpeg;base64,${appLogoBase64}`}
            width="60px"
            className="mx-auto d-block"
            alt=""
          />
        </div>
        <div className="col-md-9">
          <table>
            <tbody>
              <tr>
                <td width="50%">
                  <b>App Name:</b>
                </td>
                <td width="50%">{appName}</td>
              </tr>
              <tr>
                <td width="50%">
                  <b>App ID:</b>
                </td>
                <td width="50%">{appId}</td>
              </tr>
              <tr>
                <td width="50%">
                  <b>Version:</b>
                </td>
                <td width="50%">{versionName}</td>
              </tr>
              <tr>
                <td width="50%">
                  <b>Version Code:</b>
                </td>
                <td width="50%">{versionCode}</td>
              </tr>
              <tr>
                <td width="50%">
                  <b>Size:</b>
                </td>
                <td width="50%">
                  {Number(apkSize === null ? 0 : apkSize).toFixed(2)} MB
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  if (showDetailsFrom === false) {
    return (
      <>
        <button
          onClick={handleShow()}
          className={
            textToShowDiv === "Upload New"
              ? "btn btn-primary"
              : "btn btn-primary cus-btn"
          }
        >
          {/* Upload New */}
          {textToShowDiv}
        </button>

        <Dialog
          visible={dialogue}
          modal
          style={{ width: "20vw" }}
          closable={false}
          showHeader={false}
          onHide={() => {
            setDialogue(false);
          }}
        >
          <Loader textToShow={textToLoader} />
        </Dialog>
        <Modal show={show} onHide={handleClose} backdrop="static">
          <Modal.Header closeButton>
            <p style={{ fontSize: "16px", fontWeight: "bold" }}>
              App Manage System
            </p>
          </Modal.Header>
          {/* <Form> */}
          <Modal.Body>
            {textToShowDiv === "Upload New" ? (
              <Upload
                handleSubmit={submitHandler}
                dialoguetoclose={setDialogue}
                from="entApp"
              />
            ) : (
              <Upload
                handleSubmit={submitHandler}
                from="entApp"
                ObjectToShow={ObjectToShow}
              />
            )}
            {dataToUpload !== undefined && dataToUpload !== null
              ? fieldToShow()
              : ""}
            <p
              style={{
                marginTop: "10px",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              ***Note:
            </p>
            <p>
              1) The Apk file needs to be signed
              <br />
              2) As per google policy, a debuggable version is not allowed.
              <br />
              3) Package names must be globally unique in Google Play, not just
              unique within your enterprise
            </p>
            <Form.Group controlId="appDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                value={description}
                onChange={onChangeDescription}
                placeholder="Enter Description "
              />
            </Form.Group>
            <Form.Group controlId="appName">
              <Form.Label>Release Note</Form.Label>
              <Form.Control
                type="text"
                value={releaseNote}
                onChange={onChangeRelease}
                placeholder="Enter Release Note"
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer
            style={{ textAlign: "center", justifyContent: "center" }}
          >
            <Button
              variant="primary"
              onClick={uploadApk}
              disabled={buttondisable}
            >
              Upload
            </Button>
          </Modal.Footer>
          {/* </Form> */}
        </Modal>
      </>
    );
  } else {
    return (
      <>
        <Link onClick={handleShow(ObjectToShow)} className="custom-a">
          <Tooltip
            target=".vDetails"
            position="top"
            mouseTrack
            mouseTracktop={20}
          />
          <i
            className="pi pi-eye vDetails"
            data-pr-tooltip="Details"
            style={{ fontSize: "1.2em" }}
          ></i>
          {/* <img
            src={vDetails}
            className="vDetails"
            data-pr-tooltip="Details"
            width="15px"
            alt="Details"
          /> */}
        </Link>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <p style={{ fontSize: "16px", fontWeight: "bold" }}>
              App Manage System
            </p>
          </Modal.Header>

          <ModalBody>{fieldToShow()}</ModalBody>
        </Modal>
      </>
    );
  }
}
export default EntPopupForm;
