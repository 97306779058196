export const EnrollmentTypeEnum = {
  QR: 0,
  IMEI: 1,
};

export const OSTypeEnum = {
  ANDROID: 0,
  IOS: 1,
};

export const SortListEnum = {
  ASC: 0,
  DESC: 1,
};
export const AppTypeEnum = {
  SYSTEMAPP: 0,
  ENTERPRISEAPP: 1,
  PLAYSTOREAPP: 3,
};

export const AppIconSizeEnum = {
  SMALL: 0,
  MEDIUM: 1,
  LARGE: 2,
};

export const UploadActionEnum = {
  ONLYUPLOAD: 0,
  APKPARSE: 1,
  IMAGEUPLOAD: 2,
};

export const ModuleNameEnum = {
  ENROLLMENT: "imeienroll",
  APP: "app",
  CONTENT: "content",
  THEME: "theme",
  PROFILE: "profile",
  GROUP: "group",
  DEVICES: "device",
  LOCATION: "location",
  GLOBAL: "global",
  REPORTS: "reports",
  USERPROFILE: "userprofile",
  WEBLISTING: "websitelisting",
};

export const ControlSettingsEnum = {
  homeSettings: 0,
  wifiSettings: 1,
  mobileNetworkSettings: 2,
  displaySettings: 3,
  timeAndLanguageSettings: 4,
  bluetoothSettings: 5,
  locationSettings: 6,
};

export const EMMControlSettingsEnum = {
  deviceSettings: 11,
  KeyguardSettings: 12,
  vpnSettings: 13,
  securitySettings: 14,
};

export const ActionCopyOREdit = {
  COPY: 2,
  EDIT: 1,
};

export const ActionType = {
  DELETE: 0,
  UNINSTALL: 1,
  DELETE_UNINSTALL: 2,
};

export const ActionAssign = {
  ASSIGN: 0,
  UNASSIGN: 2,
  UNINSTALL: 1,
};

export const DeleteType = {
  APPDELETE: 0,
  APPVERSIONDELETE: 1,
};

export const GlobalSettingsType = {
  PUSHMESSAGE: 10,
  FACTORYRESET: 11,
  LOCK: 12,
  DEACTIVATELAUNCHER: 13,
  CLEARAPPDATA: 14,
  CLEARFILE: 15,
};

export const ContentPushAction = {
  RENAME: 0,
  COPY: 1,
  MOVE: 2,
};

export const RemoteCastSessionStatus = {
  INITIATED: 0,
  PENDING: 1,
  ONGOING: 2,
  COMPLETED: 3,
  FAILED: 4,
};

export const MediaType = {
  WEB: 0,
  ANDROID: 1,
  IOS: 2,
};

export const RemoteCastMessageType = {
  LOGIN: 0,
  LOGOUT: 1,
  RTC: 2,
  STOP: 3,
  ACKSOCK: 4,
};

export const SocketConnectStatus = {
  CONNECTED: 0,
  CLOSE: 1,
  ERROR: 2,
};

export const ACTIVEINACTIVESTATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
};
export const LOCATIONZOOM = {
  ZOOM: 12,
};
