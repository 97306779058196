import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { ServicesofAxios } from "../webservices/servicesofaxios";
import { GlobalSettingsType } from "../Enums";
import AssignInDiv from "../components/AssignInDiv";

class Lock extends Component {
  constructor(props) {
    super(props);
    this.webservice = new ServicesofAxios();

    this.state = { isLock: true };
  }
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Global Settings", url: "global-settings" },
      { label: "Lock" },
    ];
    const home = { icon: "pi pi-home", url: "dashboard" };

    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 ">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="p-col-12 row align-items-start">
          <div className="col-12 bg-col2">
            <div className="col-12 p-left">
              <p>
                <b>Lock</b>
              </p>
              <p>
                It will lock devices corresponding to selected profiles / groups
                / devices.
              </p>
            </div>
            {/* <div className="row align-items-start p-left">
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="lockPassword">Lock Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="lockPassword"
                    name="lockPassword"
                    placeholder="Enter Lock Password"
                    onChange={this.changeHandler}
                  />
                </div>
              </div>
            </div> */}
            <div className="row align-items-start p-left">
              <div className="col-12  p-r">
                <p
                  className="col-12 mt-3"
                  style={{ borderTop: "1px solid #d8d5d5", paddingTop: "10px" }}
                >
                  <b>Choose Recipients</b>
                </p>
                <AssignInDiv
                  dataToSend={{
                    id: GlobalSettingsType.LOCK,
                    ...this.state,
                  }}
                  type={GlobalSettingsType.LOCK}
                  fromModule="global"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Lock;
