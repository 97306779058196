// navigator.getUserMedia =
//   navigator.getUserMedia ||
//   navigator.webkitGetUserMedia ||
//   navigator.mediaDevices.getUserMedia ||
//   navigator.mozGetUserMedia ||
//   navigator.msGetUserMedia;

var loggedIn = false;
var configuration = {
  iceServers: [
    {
      urls: "turn:180.210.129.214:3478?transport=udp",
      username: "citlrtc",
      credential: "c1tlr7c",
    },
  ],
};
var pc;
var peer;
var sock;
var stream;

function logError(error) {
  console.log(error.name + ": " + error.message);
}

export function closeConnection2() {
  let configurationVoice = settingsObj.enableVoiceCall
    ? {
        audio: {
          sampleRate: 48000,
          echoCancellation: true,
          noiseSuppression: true,
        },
        video: false, //always false.
      }
    : {
        video: false,
      };
  if (
    navigator.userAgent.toLowerCase().includes("firefox") ||
    navigator.userAgent.toLowerCase().includes("safari")
  ) {
    navigator.mediaDevices
      .getUserMedia(configurationVoice)
      .then(function (localStream) {
        localStream.getTracks().forEach((track) => track.stop());
      })
      .catch((err) => {
        console.log("error in the closing navigator", err);
      });
  } else {
    navigator.getUserMedia(
      configurationVoice,
      function (stream) {
        stream.getTracks().forEach((track) => track.stop());
      },
      logError
    );
  }
  pc && pc.close();
  if (document.getElementById("remoteView").srcObject) {
    document.getElementById("remoteView").srcObject = null;
  } else {
    document.getElementById("remoteView").src = null;
  }
}

export function closeConnection() {
  stream.getTracks().forEach(function (track) {
    // stopping every track
    track.stop();
  });
  if (
    document.getElementById("selfView") &&
    document.getElementById("selfView").srcObject
  ) {
    document.getElementById("selfView").srcObject = null;
  } else if (document.getElementById("selfView")) {
    document.getElementById("selfView").src = null;
  }
  if (document.getElementById("remoteView").srcObject) {
    document.getElementById("remoteView").srcObject = null;
  } else {
    document.getElementById("remoteView").src = null;
  }
  pc.close();
}

export function connect(username, settings) {
  console.log("connect with ", settings);
  settingsObj = settings;
  var loc = window.location;
  var uri = "ws://180.210.129.214:8080/signal";
  sock = new WebSocket(uri);

  sock.onopen = function (e) {
    console.log("open", e);
    sock.send(
      JSON.stringify({
        type: "login",
        data: username,
      })
    );
    // should check better here, it could have failed
    // moreover not logout implemented
    loggedIn = true;
  };

  sock.onclose = function (e) {
    console.log("close", e);
  };

  sock.onerror = function (e) {
    console.log(
      "error",
      e,
      "startToggelButton",
      document.getElementById("startToggleButton")
    );
  };

  sock.onmessage = function (e) {
    console.log(
      "message",
      e.data,
      "startToggelButton",
      document.getElementById("startToggleButton")
    );

    var message = JSON.parse(e.data);
    if (message.type === "acksock") {
      let element = document.getElementById("startToggleButton");
      element.classList.remove("p-disabled");
      element.classList.remove("p-togglebutton");
      element.disabled = false;
    }
    if (message.type === "rtc") {
      if (!pc) {
        startRTC();
      }
      if (message.data.sdp) {
        //  		alert('sdp');
        if (message.data.sdp.type === "OFFER") {
          message.data.sdp.type = "offer";
        }
        if (message.data.sdp.type === "ANSWER") {
          message.data.sdp.type = "answer";
        }
        if (message.data.sdp.description) {
          message.data.sdp.sdp = message.data.sdp.description;
          message.data.sdp.description = null;
        }

        pc.setRemoteDescription(
          new RTCSessionDescription(message.data.sdp),
          function () {
            // if we received an offer, we need to answer
            if (pc.remoteDescription.type === "offer") {
              console.log("answer");
              peer = message.dest;
              pc.createAnswer(localDescCreated, logError);
            }
          },
          logError
        );
      } else {
        //		alert('candidate');
        if (message.data.candidate.sdp) {
          message.data.candidate.candidate = message.data.candidate.sdp;
          message.data.candidate.sdp = null;
        }
        pc.addIceCandidate(new RTCIceCandidate(message.data.candidate));
      }
    }
  };

  //setConnected(true);
}

export function startRTC() {
  pc = new RTCPeerConnection(configuration);

  // send any ice candidates to the other peer
  pc.onicecandidate = function (evt) {
    if (evt.candidate) {
      console.log("candidate");
      var jsonOb = {
        sdp: evt.candidate.candidate,
        sdpMLineIndex: evt.candidate.sdpMLineIndex,
        sdpMid: evt.candidate.sdpMid,
        serverUrl: evt.candidate.serverUrl,
      };

      sendMessage({
        type: "rtc",
        dest: peer,
        data: {
          candidate: jsonOb,
        },
      });
    }
  };

  // once remote stream arrives, sho480w it in the remote video element
  pc.ontrack = function (evt) {
    try {
      document.getElementById("remoteView").srcObject = evt.streams[0];
    } catch (error) {
      document.getElementById("remoteView").src = window.URL.createObjectURL(
        evt.stream
      );
    }
    //      remoteView.src = URL.createObjectURL(evt.stream);
  };

  // get a local stream, show it in a self-view and add it to be sent
  let configurationVoice = settingsObj.enableVoiceCall
    ? {
        audio: {
          sampleRate: 48000,
          echoCancellation: true,
          noiseSuppression: true,
        },
        video: false, //always false.
      }
    : {
        video: false,
      };
  if (
    navigator.userAgent.toLowerCase().includes("firefox") ||
    navigator.userAgent.toLowerCase().includes("safari")
  ) {
    navigator.mediaDevices
      .getUserMedia(configurationVoice)
      .then(function (localStream) {
        // document.getElementById("selfView").srcObject = localStream;
        stream = localStream;
        stream.getTracks().forEach((track) => pc.addTrack(track, stream));
      })
      .catch((err) => {
        console.log("error in the navigator", err);
      });
  } else {
    navigator.getUserMedia(
      configurationVoice,
      function (lstream) {
        try {
          stream = lstream;
          //document.getElementById("selfView").srcObject = stream;
        } catch (error) {
          stream = lstream;
          // document.getElementById("selfView").src = window.URL.createObjectURL(
          //   stream
          // );
        }
        stream.getTracks().forEach((track) => pc.addTrack(track, stream));
      },
      logError
    );
  }
}

export function startRTC2() {
  pc = new RTCPeerConnection(configuration);

  // send any ice candidates to the other peer
  pc.onicecandidate = function (evt) {
    if (evt.candidate) {
      console.log("offer2");
      sendMessage({
        type: "rtc",
        dest: peer,
        data: {
          candidate: evt.candidate,
        },
      });
    }
  };

  // once remote stream arrives, show it in the remote video element
  pc.ontrack = function (evt) {
    try {
      document.getElementById("remoteView").srcObject = evt.streams[0];
    } catch (error) {
      document.getElementById("remoteView").src = window.URL.createObjectURL(
        evt.stream
      );
    }
    //      remoteView.src = URL.createObjectURL(evt.stream);
  };
}

export function offer(dest, settings) {
  peer = dest;
  pc.createOffer(localDescCreated, logError);
}
var settingsObj;
export function makeSettings({
  enableVoiceCall = false,
  enableFileShare = false,
  allowToStopCast = false,
} = {}) {
  settingsObj = {
    enableVoiceCall: enableVoiceCall,
    enableFileShare: enableFileShare,
    allowToStopCast: allowToStopCast,
  };
  return settingsObj;
}

function localDescCreated(desc) {
  console.log("offer", settingsObj);
  pc.setLocalDescription(
    desc,
    function () {
      // obj {type: offer, dest: 2, data: desc}
      console.log("pc description ", pc.localDescription);

      // pc.localDescription.description=pc.localDescription.sdp;

      var jsonObj = {
        type: pc.localDescription.type,
        description: pc.localDescription.sdp,
      };

      sendMessage({
        type: "rtc",
        dest: peer,
        data: {
          settingsObj: settingsObj,
          sdp: jsonObj,
        },
      });
    },
    logError
  );
}

export function sendMessage(payload) {
  var str = JSON.stringify(payload);
  str = str.replace("offer", "OFFER");
  str = str.replace("answer", "ANSWER");
  sock.send(str);
}

export function disconnect() {
  console.log("disconnect");
  closeConnection();
  if (sock != null) {
    sock.close();
  }
  // setConnected(false);
}
