import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";
// import ErrorDialog from "../components/ErrorDialog";
// import SuccessDialog from "../components/SuccessDialog";
// import { AppIconSizeEnum } from "../Enums";
import api from "../service/ApiService";
import { baseUrl, errorCompute } from "../service/ApiUrls";
import { confirmAlert } from "react-confirm-alert";

class PushMessageHistory extends Component {
  constructor(props) {
    super(props);

    this.state = { currentPage: 1, drows: 20, dfirst: 0, filter: null };
    //this.alldata = new AllData();
  }

  callDeletePushwithID = ({ msgid } = {}) => {
    api
      .delete(baseUrl + "/global/10/message-archive/" + msgid)
      .then((res) => {
        this.setState({ isLoading: false }, () => {
          if (res.data.result.isError === false) {
            this.callGetList();
          } else {
            confirmAlert({
              title: "Error Message",
              message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {},
                },
              ],
            });
          }
        });
      })
      .catch((err) => errorCompute(err));
  };

  callGetList = ({
    first = 0,
    limit = 10,
    filter = this.state.filter,
  } = {}) => {
    api
      .get(baseUrl + "/global/10/message-archive", {
        params: { first, limit, filter },
      })
      .then((res) => {
        if (res.data.result.isError === false) {
          if (res.data.data !== null) {
            this.setState({
              //...res.data.data,
              group: res.data.data.content,
              totalRecords: res.data.data.totalRecords,
            });
          }
        } else {
          confirmAlert({
            title: "Error Message",
            message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
            buttons: [
              {
                label: "Ok",
                onClick: () => {},
              },
            ],
          });
        }
      })
      .catch((err) => errorCompute(err));
  };

  onDialogueHide = () => {
    this.setState({ isLoading: false });
  };
  componentDidMount() {
    this.callGetList();
    //this.alldata.getGroupList().then((data) => this.setState({ group: data }));
  }
  actionTemplate(rowData, column) {
    return (
      <div>
        <Link>
          <i
            className="pi pi-trash"
            onClick={() => {
              // console.log("onClick", rowData);
              this.setState({ visible: true, rowData: rowData });
            }}
            style={{ fontSize: "1.1em", color: "red", paddingLeft: "5px" }}
          ></i>
        </Link>
      </div>
    );
  }
  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Global Settings", url: "global-settings" },
      { label: "Push Message", url: "push-message" },
      { label: "Push Message History" },
    ];
    const home = { icon: "pi pi-home", url: "dashboard" };
    const header = (
      <div id="cus-p" className="row justify-content-end">
        <div className="p-col-6 p-md-6 p-lg-6">
          {/* <div
            className="form-inline d-flex justify-content-center md-form form-sm mt-0 b-input"
            style={{ padding: "3px 0px" }}
          >
            <InputText
              className="form-control form-control-sm mr-2 b-n"
              type="search"
              style={{ width: "300px", marginTop: "5px" }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  this.callGetList({ filter: e.target.value });
                }
              }}
              placeholder="Enter Search Item"
              // style={{ width: "90%" }}
              aria-label="Search"
            />
            <i className="pi pi-search" aria-hidden="true"></i>
          </div> */}
          <div
            id="cus-from"
            className="form-inline d-flex justify-content-center md-form form-sm mt-0 b-input"
          >
            <input
              className="form-control mr-2 b-n"
              type="text"
              placeholder="Enter search data"
              aria-label="Search"
              style={{ width: "90%" }}
              onChange={(e) => {
                this.setState({ filter: e.target.value });
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  this.callGetList();
                }
              }}
            />
            <i
              className="pi pi-search"
              aria-hidden="true"
              style={{ cursor: "pointer" }}
              onClick={() => this.callGetList()}
            ></i>
          </div>
        </div>
      </div>
    );
    const footer = (
      <div>
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-success"
          onClick={() => {
            this.setState({ visible: false, isLoading: true }, () => {
              this.callDeletePushwithID({ msgid: this.state.rowData.id });
            });
          }}
        />
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ visible: false });
          }}
        />
      </div>
    );
    const deleteDialogue = (
      <Dialog
        header="Delete Push Message"
        visible={this.state.visible}
        style={{ width: "30vw" }}
        modal={true}
        footer={footer}
        onHide={() => this.setState({ visible: false })}
      >
        <p style={{ textAlign: "center", fontWeight: "bold" }}>
          Do you want to delete this Push Message?
        </p>
      </Dialog>
    );
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 p-ll">
          <div className="p-col-9 inline-b p-ll">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="p-col-12 row align-items-start">
          {deleteDialogue}
          <Dialog
            visible={this.state.isLoading}
            modal
            style={{ width: "20vw" }}
            closable={false}
            showHeader={false}
            onHide={this.onDialogueHide}
          >
            <Loader />
          </Dialog>
          <div className="col-12 bg-col2">
            <div className="col-12 p-left">
              <p>
                <b>Push Message</b>
              </p>
              <p>View previously sent push messages</p>
            </div>

            <div className="row align-items-start mt-4 p-left">
              <div className="col-12  p-r">
                <div className="datatable-paginator-demo">
                  <DataTable
                    value={this.state.group}
                    style={{ marginBottom: "20px" }}
                    responsive={true}
                    header={header}
                    editMode="row"
                    paginator={true}
                    emptyMessage="No records found"
                    lazy
                    onPage={(event) => {
                      console.log("Event", event);
                      this.callGetList({
                        first: event.page,
                        limit: this.state.drows,
                      });
                      setTimeout(() => {
                        const { first, rows, page } = event;

                        this.setState({
                          dfirst: first,
                          page,
                          drows: rows,
                          currentPage: page + 1,
                        });
                      }, 500);
                    }}
                    first={this.state.dfirst}
                    currentPage={this.state.currentPage}
                    totalRecords={this.state.totalRecords}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    rows={this.state.drows}
                    rowsPerPageOptions={[10, 20, 30, 50, 100]}
                  >
                    <Column
                      field="title"
                      header="Message Title"
                      style={{ width: "25%" }}
                    />
                    <Column
                      field="message"
                      header="Message Body"
                      style={{ width: "35%" }}
                    />
                    <Column
                      field="date"
                      header="Date"
                      style={{ width: "15%" }}
                    />
                    {/* <Column
                      field="recipents_group"
                      header="Recipents Group"
                      style={{ width: "15%" }}
                    /> */}
                    <Column
                      header="Action"
                      style={{
                        textAlign: "center",
                        color: "red",
                        width: "10%",
                      }}
                      body={this.actionTemplate.bind(this)}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PushMessageHistory;
