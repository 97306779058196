import React, { Component } from "react";
import { ColorPicker } from "primereact/colorpicker";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import "../assets/style.css";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { BreadCrumb } from "primereact/breadcrumb";
import { Dialog } from "primereact/dialog";
import Loader from "../components/Loader";
import SuccessDialog from "../components/SuccessDialog";
import ErrorDialog from "../components/ErrorDialog";
import axios from "axios";
import {
  postUploadFileManagementUrl,
  postAddThemeUrl,
  putAddThemeUrl,
  baseUrl,
} from "../service/ApiUrls";
import { ModuleNameEnum, UploadActionEnum, AppIconSizeEnum } from "../Enums";
import api from "../service/ApiService";
import ThemeUploader from "./ThemeUploader";
const defaultimg = require("../assets/dummy_logo.png");
const defaultlock = require("../assets/logo.png");
const defaultbg = require("../assets/lockscreen.png");
const upload = require("../assets/upload.png");

const sizes = [
  { name: "Small", code: AppIconSizeEnum.SMALL },
  { name: "Medium", code: AppIconSizeEnum.MEDIUM },
  { name: "Large", code: AppIconSizeEnum.LARGE },
];
export class CreateNewTheme extends Component {
  constructor(props) {
    super(props);

    //console.log("props in Create New Theme", props.location.state.e);

    this.state = {
      topBarColorHexCode: "#166a8f",
      topBarFontColorHexCode: "#ffffff",
      lockMessage: "Lock Screen Message",
      name: "Theme Name",
      brandName: "Brand Name",
      file: "",
      appIcon: sizes[0],
      bgImage: defaultbg,
      applist: [],
      isDefault: true,
      visible: false,
      isSuccess: false,
      androidProperties: {
        appIconSize: 0,
        topBarColorHexCode: "#166a8f",
        topBarFontColorHexCode: "#ffffff",
      },
      deviceThemeLicenseExpiryData: {
        showWarningBeforeInDays: 7,
        warningText: "Contact with your admin",
      },
      showLicenseExpiryDate: false,
      textInLoader: "Loading...",
    };
    //this.lockFileRef = React.createRef();

    this.appIconChange = this.appIconChange.bind(this);
    this._wallpaperChange = this._wallpaperChange.bind(this);
    this._brandLogoChange = this._brandLogoChange.bind(this);
    this._handleLockLogoChange = this._handleLockLogoChange.bind(this);
    this.upPictureToServer = this.upPictureToServer.bind(this);
  }

  uploadFileToServer = async (formData) => {
    console.log("formData", formData);
    return api
      .post(postUploadFileManagementUrl, formData)
      .then((res) => {
        console.log("res", res);
        return res.data;
      })
      .catch((err) => {
        console.log("err", err);
        return err.data;
      });
  };

  componentDidMount() {
    console.log("console log ", this.state.androidProperties);
    axios.get(`${process.env.PUBLIC_URL}/data/theme-app.json`).then((res) => {
      this.setState({ applist: res.data.data });
    });
    if (
      this.props.location.state !== undefined &&
      this.props.location.state !== null
    ) {
      api
        .get(baseUrl + "/theme-list/" + this.props.location.state.e.id)
        .then((res) => {
          if (res.data.result.isError === false) {
            this.setState({ ...res.data.data });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // this.setState({ ...this.props.location.state.e }, () => {
      //   this.setState(
      //     {
      //       androidProperties: {
      //         ...this.state.androidProperties,
      //         topBarColorHexCode: this.state.topBarColorHexCode,
      //         topBarFontColorHexCode: this.state.topBarFontColorHexCode,
      //       },
      //     },
      //     () => {
      //       // console.log(
      //       //   "Inside async console log ",
      //       //   this.state.androidProperties
      //       // );
      //     }
      //   );
      // });
    }
    if (this.props.location.state !== undefined) {
      this.setState(
        {
          topBarColorHexCode:
            this.props.location.state.e.androidProperties.topBarColorHexCode ===
            null
              ? "#166a8f"
              : this.props.location.state.e.androidProperties
                  .topBarColorHexCode,
          topBarFontColorHexCode:
            this.props.location.state.e.androidProperties
              .topBarFontColorHexCode === null
              ? "#ffffff"
              : this.props.location.state.e.androidProperties
                  .topBarFontColorHexCode,
          appIcon:
            sizes[this.props.location.state.e.androidProperties.appIconSize],
        },
        () => {
          this.setState({
            androidProperties: {
              ...this.state.androidProperties,
              topBarColorHexCode: this.state.topBarColorHexCode,
              topBarFontColorHexCode: this.state.topBarFontColorHexCode,
            },
          });
        }
      );
    }
  }
  _wallpaperChange(e) {}

  upPictureToServer = (type) => async () => {
    console.log("in UpPicture type ", type);
    this.setState({ visible: true });
    const formData = new FormData();
    formData.append("uploadActionType", UploadActionEnum.IMAGEUPLOAD);
    formData.append("moduleName", ModuleNameEnum.THEME);
    switch (type) {
      case "brand":
        formData.append("file", this.state.brandFile);
        break;
      case "wallpaper":
        formData.append("file", this.state.bgImgFile);
        break;
      case "lock":
        formData.append("file", this.state.lockFile);
        break;
      default:
        break;
    }
    try {
      this.setState({ visible: true });
      let { data, result } = await this.uploadFileToServer(formData);
      //console.log("data ", fileTempId, "isError ", isError);
      if (result.isError === false && data !== null) {
        this.setState({ visible: false });
        if (type === "lock") {
          this.setState({
            androidProperties: {
              ...this.state.androidProperties,
              lockLogobase64: data.imageBase64,
              lockLogoTempId: data.fileTempId,
              visible: false,
            },
          });
        } else if (type === "brand") {
          this.setState({
            androidProperties: {
              ...this.state.androidProperties,
              brandLogobase64: data.imageBase64,
              brandLogoTempId: data.fileTempId,
              visible: false,
            },
          });
        } else if (type === "wallpaper") {
          this.setState({
            androidProperties: {
              ...this.state.androidProperties,
              backgroudWallpaperbase64: data.imageBase64,
              backgroundLogoTempId: data.fileTempId,
              visible: false,
            },
          });
        }
      } else {
        this.setState({ visible: false }, () => {
          confirmAlert({
            title: "Error Message",
            message: <p className="mod-p"> {result.errorMsg} </p>,
            buttons: [
              {
                label: "Ok",
                onClick: () => {},
              },
            ],
          });
        });
      }
    } catch (err) {
      console.log("err", err);
      //window.location = process.env.PUBLIC_URL + "/new-theme";
    }
  };

  _handleLockLogoChange = (type) => async (e) => {
    e.preventDefault();

    switch (type) {
      case "brand":
        document.getElementById("brandCross").style.display = "block";
        this._brandLogoChange(e);
        break;
      case "wallpaper":
        document.getElementById("wallCross").style.display = "block";
        this._bgImageChange(e);
        break;
      case "lock":
        document.getElementById("lockCross").style.display = "block";
        this._lockLogoChange(e);
        break;
      default:
        break;
    }
  };
  clearData = (e) => {
    e.target.value = null;
  };
  appIconChange(e) {
    this.setState({
      appIcon: e.value,
      androidProperties: {
        ...this.state.androidProperties,
        appIconSize: e.value.code,
      },
    });
  }

  themeDefault = (e) => {
    this.setState({ isDefault: e.target.checked });
  };

  _brandLogoChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      let result = reader.result;
      if (result.substring(0, 22).includes("jpeg"))
        result = result.substring(23);
      else result = result.substring(22);

      this.setState(
        {
          brandFile: file,
          brandfileNameToShow: file.name,
          androidProperties: {
            ...this.state.androidProperties,
            brandLogobase64: result,
          },
        },
        () => {
          if (
            this.state.brandFile !== undefined &&
            this.state.brandFile !== null
          ) {
            console.log("brand");
            this.upPictureToServer("brand")(e);
          }
        }
      );
    };

    reader.readAsDataURL(file);
  };
  _lockLogoChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      let result = reader.result;
      if (result.substring(0, 22).includes("jpeg"))
        result = result.substring(23);
      else result = result.substring(22);

      this.setState(
        {
          lockFile: file,
          lockfileNameToShow: file.name,
          androidProperties: {
            ...this.state.androidProperties,
            lockLogobase64: result,
          },
        },
        () => {
          if (
            this.state.lockFile !== undefined &&
            this.state.lockFile !== null
          ) {
            console.log("lock");
            this.upPictureToServer("lock")(e);
          }
        }
      );
    };
    reader.readAsDataURL(file);
  };

  _bgImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      let result = reader.result;
      if (result.substring(0, 22).includes("jpeg"))
        result = result.substring(23);
      else result = result.substring(22);

      this.setState(
        {
          bgImgFile: file,
          bgImgNameToShow: file.name,
          androidProperties: {
            ...this.state.androidProperties,
            backgroudWallpaperbase64: result,
          },
        },
        () => {
          if (
            this.state.bgImgFile !== undefined &&
            this.state.bgImgFile !== null
          ) {
            console.log("wallpaper");
            this.upPictureToServer("wallpaper")(e);
          }
        }
      );
    };
    reader.readAsDataURL(file);
  };
  showDeviceNameListenter = (e) => {
    this.setState({
      androidProperties: {
        ...this.state.androidProperties,
        isVisibleDeviceName: e.target.checked,
      },
    });
  };

  saveTheme = () => {
    let data =
      this.state.showLicenseExpiryDate !== false
        ? this.state
        : { ...this.state, deviceThemeLicenseExpiryData: null };
    //console.log("posting data", data);
    this.setState({ visible: true });
    api
      .post(postAddThemeUrl, data)
      .then((res) => {
        if (res.data.result.isError === false) {
          this.setState(
            {
              visible: false,
            },
            () => {
              confirmAlert({
                title: "Success Message",
                message: (
                  <p className="mod-sp">New Theme Created Successfully</p>
                ),
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {
                      this.props.history.push("/device-theme");
                    },
                  },
                ],
              });
            }
          );
          // this.props.history.push("/device-theme");
          // window.location = process.env.PUBLIC_URL + "/device-theme";
        } else if (res.data.result.isError === true) {
          this.setState({ visible: false }, () => {
            confirmAlert({
              title: "Error Message",
              message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {},
                },
              ],
            });
          });
        }
      })
      .catch((err) => {
        localStorage.setItem("loggedIn", false);
        //window.location = process.env.PUBLIC_URL + "/login";
      });
  };

  editTheme = () => {
    let data = this.state;
    //console.log("Edit data ", data);
    this.setState({ visible: true });
    api
      .put(putAddThemeUrl + "/" + data.id, data)
      .then((res) => {
        if (res.data.result.isError === false) {
          this.setState(
            {
              visible: false,
            },
            () => {
              confirmAlert({
                title: "Success Message",
                message: <p className="mod-sp">Theme Update Successfully</p>,
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {
                      this.props.history.push("/device-theme");
                    },
                  },
                ],
              });
            }
          );

          // window.location = process.env.PUBLIC_URL + "/device-theme";
        } else if (res.data.result.isError === true) {
          this.setState({ visible: false }, () => {
            confirmAlert({
              title: "Error Message",
              message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {},
                },
              ],
            });
          });
        }
      })
      .catch((err) => {
        localStorage.setItem("loggedIn", false);
        // window.location = process.env.PUBLIC_URL + "/login";
      });
  };

  onDialogueHide = () => {
    this.setState({ visible: false, isSuccess: false });
  };

  resetBrandFile = () => {
    this.setState({
      brandFile: null,
      brandfileNameToShow: undefined,
      androidProperties: {
        ...this.state.androidProperties,
        brandLogobase64: null,
        brandLogoTempId: null,
      },
    });
  };

  resetWallpaperFile = () => {
    this.setState({
      bgImgFile: null,
      bgImgNameToShow: undefined,
      androidProperties: {
        ...this.state.androidProperties,
        backgroudWallpaperbase64: null,
        backgroundLogoTempId: null,
      },
    });
  };

  resetLockFile = () => {
    this.setState({
      lockFile: null,
      lockfileNameToShow: undefined,
      androidProperties: {
        ...this.state.androidProperties,
        lockLogobase64: null,
        lockLogoTempId: null,
      },
    });
  };

  render() {
    let { brandLogobase64 = defaultimg } =
      this.state.androidProperties !== undefined &&
      this.state.androidProperties;

    let $imagePreview = null;

    // $imagePreview = (imagePreviewUrl)
    $imagePreview = (
      <img
        src={
          brandLogobase64 !== defaultimg && brandLogobase64 !== null
            ? `data:image/jpeg;base64,${brandLogobase64}`
            : defaultimg
        }
        style={{
          maxHeight: "35px",
          width: "35px",
          height: "35px",
        }}
        className=""
        alt="brandLogobase64"
      />
    );

    let { lockLogobase64 = defaultlock } =
      this.state.androidProperties !== undefined &&
      this.state.androidProperties;

    let $lockLogoView = null;
    let uploadimg = <img src={upload} alt="" />;
    // $imagePreview = (imagePreviewUrl)
    $lockLogoView = (
      <img
        src={
          lockLogobase64 !== defaultlock && lockLogobase64 !== null
            ? `data:image/jpeg;base64,${lockLogobase64}`
            : defaultlock
        }
        style={{ maxHeight: "60px", width: "75px" }}
        className="brandimg"
        alt="lockLogobase64"
      />
    );

    let { backgroudWallpaperbase64 } =
      this.state.androidProperties !== undefined &&
      this.state.androidProperties;
    let $imgPath = null;
    if (backgroudWallpaperbase64 === null) {
      $imgPath = this.state.bgImage;
    }
    if (backgroudWallpaperbase64) {
      $imgPath = backgroudWallpaperbase64;
    }

    let decideEditOrCreate =
      (this.props.location.state === undefined ? "Create New " : "Edit ") +
      "Theme";

    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Device Outline", url: "device-outline" },
      { label: "Device Theme", url: "device-theme" },
      { label: decideEditOrCreate },
    ];
    const home = { icon: "pi pi-home", url: "dashboard" };
    const appdata = this.state.applist;
    const appIcon = appdata.map((e, i) => {
      return (
        <>
          <div
            className="p-col-3"
            style={{
              display: "inline-block",

              marginTop: "5px",
            }}
            key={i}
          >
            <img
              src={
                `${process.env.PUBLIC_URL}/data/icons/` + e.app_icon + ".png"
              }
              width={
                this.state.androidProperties !== undefined
                  ? this.state.androidProperties.appIconSize * 3 + 25
                  : undefined
              }
              className=""
              alt=""
              style={{ maxHeight: "35px" }}
            />
            <span className="app_title">{e.app_name}</span>
          </div>
        </>
      );
    });
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 ">
          <Dialog
            visible={this.state.visible}
            modal={true}
            style={{ width: "20vw" }}
            closable={false}
            showHeader={false}
            onHide={this.onDialogueHide}
          >
            <Loader />
          </Dialog>
          <Dialog
            visible={this.state.isSuccess}
            modal={true}
            style={{ width: "15vw" }}
            closable
            showHeader
            onHide={this.onDialogueHide}
          >
            <SuccessDialog successMessage={this.state.textInLoader} />
          </Dialog>
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="p-col-4">
          <div className="card contentgird">
            <div className="card">
              <div className="p-col-12">
                <p className="inline-b">
                  <b>{decideEditOrCreate}</b>
                </p>
                <button
                  className="inline-b btn10"
                  onClick={
                    this.props.location.state === undefined
                      ? this.saveTheme
                      : this.editTheme
                  }
                >
                  {this.props.location.state === undefined ? "Save" : "Update"}{" "}
                  Theme
                </button>
              </div>
              <h5 className="her">Theme Config</h5>
              <div className="p-grid">
                <div className="form-group p-col-12 mb-0">
                  <label htmlFor="themename">Theme Name</label>
                  <InputText
                    value={this.state.name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                    placeholder="Enter Here.."
                  />
                </div>
                <div className="form-group p-col-12 mb-0">
                  <label htmlFor="brandname">Brand Name</label>
                  <InputText
                    value={this.state.brandName}
                    onChange={(e) =>
                      this.setState({ brandName: e.target.value })
                    }
                    placeholder="Enter Here.."
                  />
                </div>
                <div className="form-group p-col-12 mb-0">
                  <label htmlFor="lockMess">Lock Screen Message</label>
                  <InputText
                    value={this.state.lockMessage}
                    onChange={(e) =>
                      this.setState({ lockMessage: e.target.value })
                    }
                    placeholder="Enter Here.."
                  />
                </div>
                {/* <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="checkox1"
                    name="checkox1"
                    onChange={this.themeDefault}
                    checked={this.state.isDefault}
                  />
                  <label className="custom-control-label" htmlFor="checkox1">
                    Mark This Theme As Default
                  </label>
                </div> */}
              </div>
              <h5 className="her mt-3" style={{ width: "100%" }}>
                Licence Expiry Warning
              </h5>
              <div
                className="form-check p-col-12 mb-0"
                style={{ paddingLeft: "30px" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck1"
                  checked={this.state?.showLicenseExpiryDate}
                  onClick={(e) => {
                    this.setState({
                      showLicenseExpiryDate: e.target.checked,
                    });
                  }}
                />
                <label className="form-check-label" for="exampleCheck1">
                  Show licence expiry warning
                </label>
              </div>
              {this.state?.showLicenseExpiryDate === true && (
                <>
                  <div className="form-group p-col-12 mb-0">
                    <label htmlFor="lockMess">
                      Show Warning Before In Days
                    </label>
                    <InputText
                      type="number"
                      value={
                        this.state.deviceThemeLicenseExpiryData
                          ?.showWarningBeforeInDays
                      }
                      onChange={(e) =>
                        this.setState({
                          ...this.state,
                          deviceThemeLicenseExpiryData: {
                            ...this.state.deviceThemeLicenseExpiryData,
                            showWarningBeforeInDays: Number(e.target.value),
                          },
                        })
                      }
                      placeholder="Enter Here.."
                    />
                  </div>
                  <div className="form-group p-col-12 mb-0">
                    <label htmlFor="lockMess">Warning Text</label>
                    <InputText
                      value={
                        this.state.deviceThemeLicenseExpiryData?.warningText
                      }
                      onChange={(e) =>
                        this.setState({
                          ...this.state,
                          deviceThemeLicenseExpiryData: {
                            ...this.state.deviceThemeLicenseExpiryData,
                            warningText: e.target.value,
                          },
                        })
                      }
                      placeholder="Enter Here.."
                    />
                  </div>
                </>
              )}
              <h5 className="her mt-3">Android Properties</h5>
              <div className="p-grid">
                <div className="form-group p-col-12 mb-0">
                  <label htmlFor="topBarColorHexCode">Theme Color</label>
                  <br />
                  <ColorPicker
                    value={this.state.topBarColorHexCode}
                    onChange={(e) =>
                      this.setState({
                        topBarColorHexCode: e.value,
                        androidProperties: {
                          ...this.state.androidProperties,
                          topBarColorHexCode: "#" + e.value,
                        },
                      })
                    }
                  ></ColorPicker>
                </div>
                <div className="form-group p-col-12 mb-0">
                  <label htmlFor="themefrontcolor">Theme Font Color</label>
                  <br />
                  <ColorPicker
                    value={this.state.topBarFontColorHexCode}
                    onChange={(e) =>
                      this.setState({
                        topBarFontColorHexCode: e.value,
                        androidProperties: {
                          ...this.state.androidProperties,
                          topBarFontColorHexCode: "#" + e.value,
                        },
                      })
                    }
                  ></ColorPicker>
                </div>
                <div className="form-group p-col-12 mb-0">
                  <label htmlFor="appsize">App Icon Size</label>
                  <Dropdown
                    value={
                      this.state.appIcon !== undefined && this.state.appIcon
                    }
                    options={sizes}
                    onChange={this.appIconChange}
                    placeholder="Select Logo Size"
                    optionLabel="name"
                  />
                </div>
                <ThemeUploader
                  name="Brand Logo"
                  id="brandlogo"
                  cross="brandCross"
                  handleLock={() => this._handleLockLogoChange("brand")}
                  brandfileNameToShow={this.state.brandfileNameToShow}
                  parentCall={() => {
                    this.resetBrandFile();
                  }}
                />
                <ThemeUploader
                  name="Wallpaper (Lock + Home)"
                  id="wallpaper"
                  cross="wallCross"
                  handleLock={() => this._handleLockLogoChange("wallpaper")}
                  brandfileNameToShow={this.state.bgImgNameToShow}
                  parentCall={() => {
                    this.resetWallpaperFile();
                  }}
                />
                <ThemeUploader
                  name="Lock Screen Logo"
                  id="lock"
                  cross="lockCross"
                  handleLock={() => this._handleLockLogoChange("lock")}
                  brandfileNameToShow={this.state.lockfileNameToShow}
                  parentCall={() => {
                    this.resetLockFile();
                  }}
                />
                {/* <div className="form-group p-col-12 mb-0">
                  <label>Brand Logo</label>
                  <div className="custom-file mb-3">
                    <input
                      type="file"
                      className="custom-file-input"
                      onChange={this._handleLockLogoChange("brand")}
                      id="brandlogo"
                      name="brandlogo"
                    />
                    <label className="custom-file-label" htmlFor="brandlogo">
                      {this.state.brandfileNameToShow === undefined
                        ? "Select File"
                        : this.state.brandfileNameToShow}
                    </label>
                  </div>
                  <i
                    id="brandCross"
                    className="pi pi-times"
                    onClick={(e) => {
                      document.getElementById("brandlogo").value = null;
                      document.getElementById("brandCross").style.display =
                        "none";
                      brandLogobase64 = defaultimg;
                      this.resetBrandFile();
                    }}
                    style={{
                      fontSize: "1em",
                      color: "red",
                      float: "right",
                      paddingRight: "10px",
                      display: "none",
                    }}
                  ></i>
                </div> */}
                {/* <div className="form-group p-col-12 mb-0">
                  <label htmlFor="brandlogo">Brand Logo</label>
                  <button
                    className="btn10"
                    style={{ float: "right", background: "#189b46" }}
                    onClick={
                      this.state.brandFile !== undefined
                        ? this.upPictureToServer("brand")
                        : undefined
                      //this.setState({ visible: true });
                    }
                  >
                    <i className="pi pi-upload" style={{ fontSize: "1em" }}></i>
                  </button>
                  <input
                    type="file"
                    onChange={this._handleLockLogoChange("brand")}
                    placeholder="Enter Here.."
                  />
                </div> */}
                {/* <div className="form-group p-col-12 mb-0">
                  <label htmlFor="kWallpaper">Wallpaper (Lock + Home)</label>
                  <button
                    className="btn10"
                    style={{ float: "right", background: "#189b46" }}
                    onClick={
                      this.state.bgImgFile !== undefined
                        ? this.upPictureToServer("wallpaper")
                        : undefined
                      //this.setState({ visible: true });
                    }
                  >
                    <i className="pi pi-upload" style={{ fontSize: "1em" }}></i>
                  </button>
                  <input
                    type="file"
                    onChange={this._handleLockLogoChange("wallpaper")}
                    placeholder="Enter Here.."
                  />
                </div> */}
                {/* <form ref={this.lockFileRef} onSubmit={this.submitLockLogo}> */}
                {/* <div className="form-group p-col-12 mb-0">
                  <label htmlFor="lockLogobase64">Lock Screen Logo</label>
                  <button
                    className="btn10"
                    style={{ float: "right", background: "#189b46" }}
                    onClick={
                      this.state.lockFile !== undefined
                        ? this.upPictureToServer("lock")
                        : undefined
                      //this.setState({ visible: true });
                    }
                  >
                    <i className="pi pi-upload" style={{ fontSize: "1em" }}></i>
                  </button>
                  <input
                    type="file"
                    onChange={this._handleLockLogoChange("lock")}
                    placeholder="Enter Here.."
                    name="file"
                  />
                </div> */}
                {/* </form> */}

                {/* <div className="custom-control custom-checkbox mt-2">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="showdevicename"
                    name="checkox1"
                    onChange={this.showDeviceNameListenter}
                    checked={
                      this.state.androidProperties !== undefined &&
                      this.state.androidProperties.isVisibleDeviceName
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="showdevicename"
                  >
                    Show Device Name
                  </label>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="p-col-8">
          <div className="p-grid">
            <div className="p-col-6">
              <div className="card">
                <div>
                  <h3 style={{ textAlign: "center" }}>LOCK SCREEN</h3>
                  <div className="phonebg">
                    <div className="bgcolor">
                      <div className="smartphone">
                        <div
                          className="content bgimage "
                          style={{
                            backgroundImage:
                              "url(data:image/png;base64," + $imgPath + ")",
                            // height: "325px",
                          }}
                        >
                          <div className="lockheadercontent">
                            {$lockLogoView}

                            <p className="lockmsg">{this.state.lockMessage}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-col-6">
              <div className="card">
                <div>
                  <h3 style={{ textAlign: "center" }}>HOME SCREEN</h3>
                  <div className="phonebg">
                    <div className="bgcolor">
                      <div className="smartphone">
                        <div
                          className="content"
                          style={{
                            backgroundImage:
                              "url(data:image/png;base64," + $imgPath + ")",
                          }}
                        >
                          <div
                            className="headerbg row align-items-start m-0"
                            style={{
                              paddingLeft: "3px",
                              backgroundColor:
                                this.state.topBarColorHexCode !== undefined &&
                                this.state.topBarColorHexCode !== null &&
                                this.state.topBarColorHexCode.startsWith("#")
                                  ? this.state.topBarColorHexCode
                                  : "#" + this.state.topBarColorHexCode,
                            }}
                          >
                            <div className="inline-b col-3 p-z">
                              {$imagePreview}
                            </div>
                            <div className="inline-b col-9 p-z">
                              <span
                                className="hdevice-name"
                                style={{
                                  color:
                                    this.state.topBarFontColorHexCode !==
                                      undefined &&
                                    this.state.topBarFontColorHexCode !==
                                      null &&
                                    this.state.topBarFontColorHexCode.startsWith(
                                      "#"
                                    )
                                      ? this.state.topBarFontColorHexCode
                                      : "#" + this.state.topBarFontColorHexCode,
                                }}
                              >
                                {" "}
                                {this.state.brandName}
                              </span>
                              <span
                                className="theme-name"
                                style={{
                                  color:
                                    this.state.topBarFontColorHexCode !==
                                      undefined &&
                                    this.state.topBarFontColorHexCode !==
                                      null &&
                                    this.state.topBarFontColorHexCode.startsWith(
                                      "#"
                                    )
                                      ? this.state.topBarFontColorHexCode
                                      : "#" + this.state.topBarFontColorHexCode,
                                }}
                              >
                                {this.state.androidProperties
                                  .isVisibleDeviceName
                                  ? this.state.name
                                  : ""}
                              </span>
                            </div>
                          </div>
                          <div>{appIcon}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
