/*global google*/
import React, { Component } from "react";
import { GMap } from "primereact/gmap";
import { BreadCrumb } from "primereact/breadcrumb";
import { loadGoogleMaps, removeGoogleMaps } from "../load/GoogleMaps";
import { baseUrl, getLocationHistory, errorCompute } from "../service/ApiUrls";
import api from "../service/ApiService";
var map;
export class LocationTracking2 extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      ...this.props.history.location.state.detail,
      imei: this.props.history.location.state.imei,
      id: this.props.history.location.state.id,
      googleMapsReady: false,
      dialogVisible: false,
      markerTitle: this.props.history.location.state.address,
      draggableMarker: false,
      overlays: null,
      selectedPosition: null,
      polylines: [],
      center: this.props.history.location.state.options,
      lat: Number(this.props.history.location.state.lat),
      lng: Number(this.props.history.location.state.long),
    };

    this.onMapReady = this.onMapReady.bind(this);
    this.onHide = this.onHide.bind(this);
    // this.addMarker = this.addMarker.bind(this);
  }

  componentDidMount() {
    this.callLiveTracking();
    loadGoogleMaps(() => {
      this.setState({ googleMapsReady: true }, () => {
        let clear;
        try {
          clear = setInterval(async () => {
            this.callLiveTracking();
          }, 10000);
          this.setState({ clear: clear });
        } catch (e) {
          console.log(e);
        }

        map = this.myRef.current.getMap();
      });
    });
  }
  callLiveTracking = () => {
    api
      .get(baseUrl + "/devices/" + this.state.id + "/latest-location")
      .then((res) => {
        if (res.data.result.isError === false) {
          console.log("Inside false", res.data);
          this.setState(
            {
              lastLocation: res.data.data.locationDetils,
              center: {
                lat: this.state.lat,
                lng: this.state.lng,
              },
            },
            () => {
              let polyline = {};
              let center = {};
              this.state.lastLocation !== undefined &&
                this.state.lastLocation !== null &&
                this.state.lastLocation.map((v, i) => {
                  let lastlocation = {
                    lat: Number(v.latitude),
                    lng: Number(v.longitude),
                    // lat: v.latitude,
                    // lng: v.longitude
                  };
                  center = { ...lastlocation };
                  // polyline.push(lastlocation);
                  polyline = { ...lastlocation };
                });

              this.setState(
                {
                  polylines: [...this.state.polylines, polyline],
                  center: center,
                },
                () => {
                  let polyupdate = [];
                  this.state.polylines.map((v, i) => {
                    polyupdate.push({
                      lat: Number(v.lat),
                      lng: Number(v.lng),
                    });
                  });
                  this.setState({ polylines: polyupdate }, () => {
                    console.log(
                      "polyline",
                      JSON.stringify(this.state.polylines)
                    );
                    if (this.state.polylines.length !== 0) {
                      let googlepoly = new google.maps.Polyline({
                        path: this.state.polylines,
                        geodesic: true,
                        strokeColor: "#FF0000",
                        strokeOpacity: 0.8,
                        strokeWeight: 5,
                      });

                      this.setState({
                        overlays: [...this.state.overlays, googlepoly],
                      });
                    }
                  });
                },
                () => {
                  console.log("polyline", JSON.stringify(this.state.polylines));
                }
              );

              //   this.callgetHistoryLocation();
            }
          );
        }
      })
      .catch((err) => {
        //console.log(err);
        errorCompute(err);
        //localStorage.setItem("loggedIn", false);
        // window.location = process.env.PUBLIC_URL + "/login";
      });
  };
  componentWillUnmount() {
    this.setState({
      options: { lat: this.state.lat, lng: this.state.lng },
      mapTypeId: "roadmap",
      zoom: 12,
    });
    if (this.state.clear !== undefined) {
      clearInterval(this.state.clear);
    }
    removeGoogleMaps();
  }

  // onMapReady = (event) => {
  //   this.setState({
  //     overlays: [
  //       // new google.maps.Marker({ position: this.state.polylines, title: "Konyaalti" }),
  //       // new google.maps.Marker({
  //       //     position: { lat: 23.756242, lng: 90.396959 },
  //       //     title: "Ataturk Park",
  //       // }),
  //     ],
  //   });
  //   //new google.maps.Marker({ position: { lat: 24.756242, lng: 90.396959 }, title: "Ataturk Park" }),
  // };

  onMapReady(event) {
    this.setState(
      {
        overlays: [
          //   new google.maps.Marker({
          //     position: this.state.polylines,
          //     title: "Konyaalti",
          //   }),
          new google.maps.Marker({
            position: {
              lat: Number(this.state.lat),
              lng: Number(this.state.lng),
            },
            title: this.state.markerTitle,
          }),
          // new google.maps.Polyline({
          //   path: this.state.polylines,
          //   geodesic: true,
          //   strokeColor: "#000000",
          //   strokeOpacity: 0.8,
          //   strokeWeight: 10,
          // }),
        ],
      },
      () => {
        console.log("onMap", event);
      }
    );
  }

  onHide(event) {
    this.setState({ dialogVisible: false });
  }

  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Location", url: "location" },
      { label: "Location Search", url: "location-search" },
      { label: "Live Location" },
    ];
    const options = {
      // center: { lat: 23.7820143, lng: 90.3730214 },
      center:
        this.state.center !== undefined
          ? { lat: Number(this.state.lat), lng: Number(this.state.lng) }
          : { lat: Number(this.state.lat), lng: Number(this.state.lng) },
      zoom: 12,
    };
    const home = { icon: "pi pi-home", url: "/" };
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 ">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="p-col-12" style={{ padding: "10px" }}>
          {this.state.googleMapsReady && (
            <div className="card">
              <GMap
                ref={this.myRef}
                overlays={this.state.overlays}
                options={options}
                style={{ width: "100%", minHeight: "320px" }}
                onMapReady={this.onMapReady}
              />
            </div>
          )}
        </div>
        <div className="col-lg-12">
          {this.state.lastLocation !== undefined &&
          this.state.lastLocation.address !== undefined
            ? this.state.lastLocation.address
            : ""}
          {this.state.lastLocation !== undefined &&
            this.state.lastLocation.map((item, i) => {
              return (
                <div
                  style={{
                    marginBottom: "10px",
                    background: "#ffffff",
                    padding: "15px",
                    width: "100%",
                  }}
                >
                  <p className="mb-1 col-md-3 inline-b" key={i}>
                    <b>Current Location</b>
                    <br />
                    {item.address}
                  </p>
                  <p className="mb-1 col-md-3 inline-b" key={i}>
                    <b>Current Latitude</b>
                    <br />
                    {item.latitude}
                  </p>
                  <p className="mb-1 col-md-3 inline-b" key={i}>
                    <b>Current Longitude</b>
                    <br />
                    {item.longitude}
                  </p>
                  <p className="mb-1 col-md-3 inline-b" key={i}>
                    <b>Latest Update Date & Time</b>
                    <br />
                    {item.updateTime}
                  </p>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
