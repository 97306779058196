import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import api from "../service/ApiService";
import { Dialog } from "primereact/dialog";
import ErrorDialog from "../components/ErrorDialog";
import Loader from "../components/Loader";
import {
  getGroupListUrl,
  getGroupListDevice,
  getDeviceSearchData,
  baseUrl,
} from "../service/ApiUrls";
import { OverlayPanel } from "primereact/overlaypanel";
import { confirmAlert } from "react-confirm-alert";
const helpIcon = require("../assets/icons/help_icon.png");

export class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: null,
      currentPage: 1,
      drows: 20,
      dfirst: 0,
    };
  }
  onDialogueHide = () => {
    this.setState({ isError: false });
  };

  componentDidMount() {
    //this.alldata.getUsers().then((data) => this.setState({ users: data }));
    this.webcallGetUSerList();
  }

  /* Row Editing */
  onEditorValueChangeForRowEditing(props, value) {
    let updatedGroup = [...props.value];
    updatedGroup[props.rowIndex][props.field] = value;
    this.setState({ group: updatedGroup });
  }

  editorForRowEditing(props, field) {
    return (
      <InputText
        type="text"
        value={props.rowData[field]}
        onChange={(e) =>
          this.onEditorValueChangeForRowEditing(props, e.target.value)
        }
      />
    );
  }
  onRowEditorValidator(rowData) {
    let value = rowData["group_name"];
    return value.length > 0;
  }
  onRowEditInit(event) {
    this.clonedGroup[event.data.group_name] = { ...event.data };
  }
  onRowEditSave(event) {
    if (this.onRowEditorValidator(event.data)) {
      delete this.clonedGroup[event.data.group_name];
      this.growl.show({
        severity: "success",
        summary: "Success",
        detail: "Group is updated",
      });
    } else {
      this.growl.show({
        severity: "error",
        summary: "Error",
        detail: "Profile is required",
      });
    }
  }
  onRowEditCancel(event) {
    let groups = [...this.state.group];
    groups[event.index] = this.cloneGroup[event.data.group_name];
    delete this.cloneGroup[event.data.group_name];
    this.setState({
      group: groups,
    });
  }

  rowDelete = (rowData, column) => {
    api
      .delete(baseUrl + "/user-list/" + rowData.id)
      .then((res) => {
        if (res.data.result.isError === false) {
          this.setState({ isLoading: false }, () => {
            confirmAlert({
              title: "Success Message",
              message: <p className="mod-sp">Delete User Successfully</p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {},
                },
              ],
            });
            this.webcallGetUSerList();
          });
          // window.location = process.env.PUBLIC_URL + "/user-management";
        } else {
          this.setState({ isLoading: false }, () => {
            confirmAlert({
              title: "Error Message",
              message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    this.webcallGetUSerList();
                  },
                },
              ],
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  webcallGetUSerList = ({ first = 0, limit = 10, filter = null } = {}) => {
    this.setState({ isLoading: true }, () => {
      api
        .get(baseUrl + "/user-list", {
          params: { first: first, limit: limit, filter: filter },
        })
        .then((res) => {
          if (res.data.result.isError === false) {
            this.setState({
              ...res.data.data,
              users: res.data.data.content,
              isLoading: false,
            });
          } else {
            confirmAlert({
              title: "Error Message",
              message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {},
                },
              ],
            });
          }
        });
    });
  };

  onPage = (event) => {
    //console.log("Event", event);

    setTimeout(() => {
      const { first, rows, page } = event;

      this.setState(
        {
          dfirst: first,
          page,
          drows: rows,
          currentPage: page + 1,
        },
        () => {
          this.webcallGetUSerList({
            first: event.page,
            limit: this.state.drows,
          });
        }
      );
    }, 500);
  };

  actionTemplate(rowData, column) {
    return (
      <div>
        <i
          className="pi pi-pencil icon-p"
          onClick={() => {
            this.props.history.push({
              pathname: "/new-user",
              state: {
                rowData: rowData,
                stateData: this.state,
                isEdit: true,
              },
            });
          }}
          style={{ fontSize: "1.1em", color: "#000000", paddingRight: "5px" }}
        ></i>
        <i
          className="pi pi-trash icon-p"
          onClick={() => {
            // console.log("onClick", rowData);
            this.setState({ visible: true, rowData: rowData });
          }}
          style={{ fontSize: "1.1em", color: "#000000", paddingLeft: "5px" }}
        ></i>
      </div>
    );
  }
  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Home Ground", url: "home-ground" },
      { label: "User Management" },
    ];
    const header = (
      <div className="row justify-content-end">
        <div className="p-col-12 p-md-4 p-lg-4">
          <h2 style={{ textAlign: "left", paddingTop: "10px" }}>User List</h2>
        </div>
        <div className="p-col-12 p-md-4 p-lg-4">
          <div id="search" className="p-inputgroup">
            <InputText
              placeholder="Enter Search Item"
              onChange={(e) => {
                this.setState({ filterData: e.target.value });
              }}
              onKeyDown={(e) => {
                if (e.keyCode == 13) {
                  this.webcallGetUSerList({ filter: e.target.value });
                }
              }}
            />
            <Button
              icon="pi pi-search"
              className="p-button-primary s-icon s-btn"
              onClick={() => {
                this.webcallGetUSerList({ filter: this.state.filterData });
              }}
            />
          </div>
        </div>
        <div className="p-col-12 p-md-4 p-lg-4" style={{ textAlign: "right" }}>
          <button
            className="btn btn-primary"
            style={{ textAlign: "right" }}
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            + New
          </button>
          <div className="dropdown-menu">
            <Link
              className="dropdown-item"
              to={{
                pathname: "/new-user",
                state: { admin: true },
              }}
            >
              <i className="pi pi-upload icon-p"></i>
              Admin
            </Link>

            <Link
              className="dropdown-item"
              to={{
                pathname: "/new-user",
                state: { admin: false },
              }}
            >
              <i className="pi pi-folder icon-p"></i>
              Service
            </Link>
          </div>
        </div>
      </div>
    );

    const footer = (
      <div>
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-success"
          onClick={() => {
            this.setState({ visible: false, isLoading: true });
            this.rowDelete(this.state.rowData);
          }}
        />
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ visible: false });
          }}
        />
      </div>
    );
    const deleteDialogue = (
      <Dialog
        header="Delete User"
        visible={this.state.visible}
        style={{ width: "35vw" }}
        modal={true}
        footer={footer}
        onHide={() => this.setState({ visible: false })}
      >
        <p
          style={{
            fontWeight: "bold",
            paddingBottom: "10px",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          Do you want to Delete This User?
        </p>
      </Dialog>
    );
    const home = { icon: "pi pi-home", url: "dashboard" };
    const paginatorLeft = <Button icon="pi pi-refresh" />;
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 p-ll">
          <div className="p-col-9 inline-b p-ll">
            <BreadCrumb model={items} home={home} />
          </div>
          <div className="p-col-3 inline-b p-zz">
            {/* <img
              src={helpIcon}
              width="20"
              height="20"
              onClick={(e) => this.op.toggle(e)}
              style={{
                marginBottom: "-10px",
                float: "right",
                cursor: "pointer",
              }}
            /> */}
          </div>
          <OverlayPanel
            ref={(el) => (this.op = el)}
            id="overlay_panel"
            showCloseIcon={true}
          >
            <div
              className="col"
              style={{
                width: "fit-content",
                maxWidth: "300px",
                padding: "0px",
              }}
            >
              <div className="helptip-title">
                <b>How to : Device Group</b>
              </div>
              <div className="helptip-des">
                Users have option to manage devices by group rather than
                individual. One device belongs to only one group and each group
                must have a device profile. Once devices are bound to a group,
                the device profile of that group are automatically applied on
                those devices.
              </div>
            </div>
          </OverlayPanel>
        </div>
        {deleteDialogue}
        <Dialog
          visible={this.state.isLoading}
          modal={true}
          closable={false}
          showHeader={false}
          style={{ width: "20vw" }}
          onHide={this.onDialogueHide}
        >
          <Loader />
        </Dialog>
        <div className="p-grid bg-col p-lg-12">
          <div className="p-col">
            <div className="p-grid bg-col p-lg-12">
              <div className="datatable-paginator-demo">
                <DataTable
                  value={this.state.users}
                  style={{ marginBottom: "20px" }}
                  responsive={true}
                  header={header}
                  editMode="row"
                  emptyMessage="No records found"
                  rowEditorValidator={this.onRowEditorValidator}
                  onRowEditInit={this.onRowEditInit}
                  onRowEditSave={this.onRowEditSave}
                  onRowEditCancel={this.onRowEditCancel}
                  paginator
                  totalRecords={this.state.totalRecords}
                  onPage={this.onPage}
                  first={this.state.dfirst}
                  currentPage={this.state.currentPage}
                  lazy
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  rows={this.state.drows}
                  rowsPerPageOptions={[10, 20, 30, 50, 100]}
                >
                  <Column
                    field="username"
                    header="UserName"
                    style={{ height: "3.5em", wordBreak: "break-word" }}
                  />
                  <Column
                    field="email"
                    header="Email"
                    style={{ height: "3.5em", wordBreak: "break-word" }}
                  />
                  <Column
                    field="phoneNo"
                    header="Phone"
                    style={{ height: "3.5em", wordBreak: "break-word" }}
                  />
                  <Column
                    field="accTypeSt"
                    header="Account Type"
                    style={{ height: "3.5em", wordBreak: "break-word" }}
                  />
                  <Column
                    header="Action"
                    style={{
                      textAlign: "center",
                      color: "#000000",
                      width: "10%",
                    }}
                    body={this.actionTemplate.bind(this)}
                  ></Column>
                </DataTable>
              </div>
            </div>
            <Dialog
              visible={this.state.isError}
              modal
              style={{ width: "20vw" }}
              closable
              showHeader
              onHide={this.onDialogueHide}
            >
              <ErrorDialog errorMessage={this.state.errorMessage} />
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}
