import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";

export class RemoteControl extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Remote Control" },
    ];
    const home = { icon: "pi pi-home", url: "dashboard" };
    return (
      <div className="p-col-12 p-grid">
        <div
          style={{ paddingBottom: "30px" }}
          className="p-grid bg-col p-col-12 p-lg-12 price justify-content-md-center"
        >
          <iframe
            src="https://mdmrtc.commlinkinfotech.com/web-admin"
            width={1000}
            height={1000}
            style={{ border: "none" }}
          ></iframe>
        </div>
      </div>
    );
  }
}
