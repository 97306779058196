import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Link } from "react-router-dom";
const group = require("../assets/device-group.png");
const profile = require("../assets/device-profile.png");
const theme = require("../assets/device-theme.png");

export class DeviceOutline extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  webMenuDynamic = () => {
    console.log("calling", "webMenuDynamic");

    let imageMap = {
      "/device-group": group,
      "/device-profile-list": profile,
      "/device-theme": theme,
    };

    return (
      this.props.extraProp !== null &&
      this.props.extraProp !== undefined &&
      this.props.extraProp.map((e, v) => {
        if (e.feature.category === 0) {
          if (e.feature.title !== "Dashboard") {
            let helpingMenu = imageMap[e.feature.protectedUrl];
            let icon =
              helpingMenu !== undefined ? helpingMenu : "pi pi-fw pi-dollar";
            return (
              <div key={"mo-" + v} className="p-col-12 p-md-3 p-xl-3 p-sm-6">
                <div key={"ch1-" + v} className="small-box bg-aqua">
                  <div key={"ch11-" + v} className="icon">
                    <img
                      src={icon}
                      width="60px"
                      style={{ width: "60px", height: "60px" }}
                      className="mx-auto d-block"
                    />
                    {/* <i
                      className={icon + " " + "mx-auto d-block"}
                      style={{
                        width: "60px",
                        height: "60px",
                        color: "#ffffff",
                        fontSize: "3.5em",
                      }}
                    ></i> */}
                  </div>
                  <Link
                    to={e.feature.protectedUrl}
                    className="small-box-footer"
                  >
                    {e.feature.title}
                  </Link>
                </div>
              </div>
            );
          }
        }
      })
    );
  };

  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Device Outline" },
    ];

    const home = { icon: "pi pi-home", url: "dashboard" };
    return (
      <div className="p-grid p-fluid dashboard">
        <div className="p-col-12 p-lg-12">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        {this.props.extraProp !== undefined && this.webMenuDynamic()}
        {/* <div className="p-col-12 p-md-3 p-xl-3 p-sm-6">
          <div className="small-box bg-aqua">
            <div className="icon">
              <img
                src={group}
                width="60px"
                style={{ width: "60px", height: "60px" }}
                className="mx-auto d-block"
              />
            </div>
            <Link to="/device-group" className="small-box-footer">
              Device Group
            </Link>
          </div>
        </div>
        <div className="p-col-12 p-md-3 p-xl-3 p-sm-6">
          <div className="small-box bg-aqua">
            <div className="icon">
              <img
                src={profile}
                width="60px"
                style={{ width: "60px", height: "60px" }}
                className="mx-auto d-block"
              />
            </div>
            <Link to="/device-profile-list" className="small-box-footer">
              DEVICE PROFILE
            </Link>
          </div>
        </div>
        <div className="p-col-12 p-md-3 p-xl-3 p-sm-6">
          <div className="small-box bg-aqua">
            <div className="icon">
              <img
                src={theme}
                width="60px"
                style={{ width: "60px", height: "60px" }}
                className="mx-auto d-block"
              />
            </div>
            <Link to="/device-theme" className="small-box-footer">
              DEVICE THEME
            </Link>
          </div>
        </div> */}
      </div>
    );
  }
}
