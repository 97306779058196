import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Link } from "react-router-dom";
import api from "../service/ApiService";
import { getUserProfileUrl } from "../service/ApiUrls";

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: [],
    };
    this.helpingMenu = [
      {
        label: "Dashboard",
        icon: "pi pi-fw pi-home",
        to: "/dashboard",
      },
      {
        label: "Enroll",
        icon: "pi pi-fw pi-plus",
        to: "/enroll",
      },
      {
        label: "Device Outline",
        icon: "pi pi-fw pi-desktop",
        to: "/device-outline",
      },
      {
        label: "App Management",
        icon: "pi pi-fw pi-sitemap",
        to: "/app-management",
      },
      {
        label: "Device Info",
        icon: "pi pi-fw pi-info-circle",
        to: "/device-info",
      },
      {
        label: "Location",
        icon: "pi pi-fw pi-map-marker",
        to: "/location",
      },
      {
        label: "Content Push",
        icon: "pi pi-fw pi-window-maximize",
        to: "/content-push",
      },
      {
        label: "Global Settings",
        icon: "pi pi-fw pi-globe",
        to: "/global-settings",
      },
      {
        label: "Reports",
        icon: "pi pi-fw pi-chart-line",
        to: "/reports",
      },
      {
        label: "Home Ground",
        icon: "pi pi-fw pi-palette",
        to: "/home-ground",
      },
      {
        label: "Remote Cast",
        icon: "pi pi-fw pi-camera",
        to: "/remote-cast",
      },
      {
        label: "Browsing Barrier",
        icon: "pi pi-fw pi-check-circle",
        to: "/website-listing",
      },
      {
        label: "Pricing & Plans",
        icon: "pi pi-fw pi-dollar",
        to: "/pricing-plan",
      },
    ];
  }

  webcallForProfile = async () => {
    try {
      let {
        data: {
          data,
          result: { isError, errorMsg, statusCode },
        },
      } = await api.get(getUserProfileUrl);
      if (isError === false) this.setState({ ...data });
      else this.props.history.replace("/login");
      //console.log("res ", res.data);
    } catch (err) {}
  };
  componentDidMount() {
    //this.webcallForProfile();
  }

  findObject = (v) => {
    return this.helpingMenu.find((e) => {
      return e.to === v;
    });
  };

  webMenuDynamic = () => {
    console.log("calling", "webMenuDynamic");
    return (
      this.props.extraProp !== null &&
      this.props.extraProp !== undefined &&
      this.props.extraProp.map((e, v) => {
        if (e.feature.category === 0) {
          if (e.feature.title !== "Dashboard") {
            let helpingMenu = this.findObject(e.feature.protectedUrl);
            let icon =
              helpingMenu !== undefined
                ? helpingMenu.icon
                : "pi pi-fw pi-dollar";
            return (
              <div key={"mo-" + v} className="p-col-12 p-md-3 p-xl-3 p-sm-6">
                <div key={"ch1-" + v} className="small-box bg-aqua">
                  <div key={"ch11-" + v} className="icon">
                    {/* <img
                      src={icon}
                      width="60px"
                      style={{ width: "60px", height: "60px" }}
                      className="mx-auto d-block"
                    /> */}
                    <i
                      className={icon + " " + "mx-auto d-block"}
                      style={{
                        width: "60px",
                        height: "60px",
                        color: "#ffffff",
                        fontSize: "3.5em",
                      }}
                    ></i>
                  </div>
                  <Link
                    to={e.feature.protectedUrl}
                    className="small-box-footer"
                  >
                    {e.feature.title}
                  </Link>
                </div>
              </div>
            );
          }
        }
      })
    );
  };

  render() {
    const items = [{ label: "Dashboard", url: "dashboard" }];

    const home = { icon: "pi pi-home", url: "/" };
    return (
      <div
        className="p-grid p-fluid dashboard"
        style={{ position: "relative" }}
      >
        <div
          className={
            this.props.licenseProp !== undefined &&
            this.props.licenseProp.licenseStatus !== 2
              ? "overlay"
              : "d-none"
          }
        ></div>
        <div
          className={
            this.props.licenseProp !== undefined &&
            this.props.licenseProp.licenseStatus !== 2
              ? "modal1"
              : "d-none"
          }
        >
          <p style={{ fontSize: "16px", color: "red", fontWeight: "bold" }}>
            {this.props.licenseProp !== undefined &&
              this.props.licenseProp.licenseMsg}
          </p>
          <Link to="/pricing-plan">
            <button className="btn btn-primary mt-2" style={{ float: "right" }}>
              Pricing & Plan
            </button>
          </Link>
        </div>
        <div className="p-col-12 p-lg-12">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="p-col-12 row align-items-start">
          {this.props.extraProp !== undefined && this.webMenuDynamic()}
          {/* <div className="p-col-12 p-md-3 p-xl-3 p-sm-6">
            <div className="small-box bg-aqua">
              <div className="icon">
                <img
                  src={device}
                  width="60px"
                  style={{ width: "60px", height: "60px" }}
                  className="mx-auto d-block"
                />
              </div>
              <Link to="/device-outline" className="small-box-footer">
                Device Outline
              </Link>
            </div>
          </div>
          <div className="p-col-12 p-md-3 p-xl-3 p-sm-6">
            <div className="small-box bg-aqua">
              <div className="icon">
                <img
                  src={app}
                  width="60px"
                  style={{}}
                  className="mx-auto d-block"
                />
              </div>
              <Link to="/app-management" className="small-box-footer">
                App Management
              </Link>
            </div>
          </div>
          <div className="p-col-12 p-md-3 p-xl-3 p-sm-6">
            <div className="small-box bg-aqua">
              <div className="icon">
                <img
                  src={location}
                  width="60px"
                  style={{}}
                  className="mx-auto d-block"
                />
              </div>
              <Link to="/location" className="small-box-footer">
                Locations
              </Link>
            </div>
          </div>
          <div className="p-col-12 p-md-3 p-xl-3 p-sm-6 mt-3">
            <div className="small-box bg-aqua">
              <div className="icon">
                <img
                  src={push}
                  width="60px"
                  style={{}}
                  className="mx-auto d-block"
                />
              </div>
              <Link to="/content-push" className="small-box-footer">
                Content Push
              </Link>
            </div>
          </div>
          <div className="p-col-12 p-md-3 p-xl-3 p-sm-6 mt-3">
            <div className="small-box bg-aqua">
              <div className="icon">
                <img
                  src={d_info}
                  width="60px"
                  style={{ width: "40px", height: "60px" }}
                  className="mx-auto d-block"
                />
              </div>
              <Link to="/device-info" className="small-box-footer">
                Device Info
              </Link>
            </div>
          </div>
          <div className="p-col-12 p-md-3 p-xl-3 p-sm-6 mt-3">
            <div className="small-box bg-aqua">
              <div className="icon">
                <img
                  src={g_seeting}
                  width="60px"
                  style={{ width: "60px", height: "60px" }}
                  className="mx-auto d-block"
                />
              </div>
              <Link to="/global-setting" className="small-box-footer">
                Global Setting
              </Link>
            </div>
          </div>
          <div className="p-col-12 p-md-3 p-xl-3 p-sm-6 mt-3">
            <div className="small-box bg-aqua">
              <div className="icon align-items-center">
                <img
                  src={reports}
                  width="60px"
                  style={{ width: "60px", height: "60px" }}
                  className="mx-auto d-block"
                />
              </div>
              <Link to="/reports" className="small-box-footer">
                Reports
              </Link>
            </div>
          </div>
          <div className="p-col-12 p-md-3 p-xl-3 p-sm-6 mt-3">
            <div className="small-box bg-aqua">
              <div className="icon align-items-center">
                <img
                  src={reports}
                  width="60px"
                  style={{ width: "60px", height: "60px" }}
                  className="mx-auto d-block"
                />
              </div>
              <Link to="/home-ground" className="small-box-footer">
                Home Ground
              </Link>
            </div>
          </div>
          <div className="p-col-12 p-md-3 p-xl-3 p-sm-6 mt-3">
            <div className="small-box bg-aqua">
              <div className="icon align-items-center">
                <img
                  src={reports}
                  width="60px"
                  style={{ width: "60px", height: "60px" }}
                  className="mx-auto d-block"
                />
              </div>
              <Link to="/remote-cast" className="small-box-footer">
                Remote Cast
              </Link>
            </div>
          </div>
          <div className="p-col-12 p-md-3 p-xl-3 p-sm-6 mt-3">
            <div className="small-box bg-aqua">
              <div className="icon align-items-center">
                <img
                  src={reports}
                  width="60px"
                  style={{ width: "60px", height: "60px" }}
                  className="mx-auto d-block"
                />
              </div>
              <Link to="/browsing-barrier" className="small-box-footer">
                Browsing Barrier
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}
