import React, { Component } from "react";
import { Modal, Alert, Spinner } from "react-bootstrap";
import { Messages } from "primereact/messages";
import ErrorDialog from "../components/ErrorDialog";
import SuccessDialog from "../components/SuccessDialog";
import Loader from "../components/Loader";
import { Dialog } from "primereact/dialog";
import { confirmAlert } from "react-confirm-alert";
import {
  postUploadFileManagementUrl,
  getAppManagemnetUrl,
  errorCompute,
} from "../service/ApiUrls";
import {
  OSTypeEnum,
  AppTypeEnum,
  ModuleNameEnum,
  UploadActionEnum,
} from "../Enums";
import api from "../service/ApiService";

const FormData = require("form-data");

class AddNewApp extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.history);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    // this.showSuccess = this.showSuccess.bind(this);

    this.state = {
      appName: "",
      appId: "",
      versionName: "",
      isSubmitted: false,
      error: false,
      isLoading: false,
      isSuccess: false,
      selectedFile: null,
      isError: false,
      visible: false,
      isErrorsmsg: false,
      errorMessage: "Error Occurs!",
    };
  }

  // showSuccess() {
  //   this.fileInput.value = "";
  //   this.messages.show({
  //     severity: "success",
  //     summary: "New App",
  //     detail: "Added Successfully",
  //     life: 5000,
  //     closable: false,
  //   });
  // }
  handleClose() {
    this.setState({
      show: false,
      appName: "",
      appId: "",
      versionName: "",
      isSubmitted: false,
      error: false,
      isLoading: false,
      selectedFile: null,
      isErrorsmsg: false,
    });
  }

  handleShow() {
    console.log("props in handle show", this.props);
    this.setState({ show: true });
  }
  changeHandler = (e) => {
    if (e.target.files != null) {
      console.log("file", e.target.files[0]);
      this.state.selectedFile = e.target.files[0];
      console.log("current state", this.state);
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  callPostAppManagementApi = async ({
    appName = this.state.appName,
    appId = this.state.appId,
    versionName = this.state.versionName,
    versionCode,
    appType = AppTypeEnum.SYSTEMAPP,
    osType = OSTypeEnum.ANDROID,
    logoTempId,
    profileIds,
    groupIds,
    deviceIds,
    isLatest,
  } = {}) => {
    let data = {
      appName,
      appId,
      versionName,
      versionCode,
      appType,
      osType,
      logoTempId,
      profileIds,
      groupIds,
      deviceIds,
      isLatest,
    };

    return api
      .post(getAppManagemnetUrl + "/add", data)
      .then((res) => {
        console.log("res", res);
        return res.data;
      })
      .catch((err) => this.props.history.push("/login"));
  };

  uploadFileToServer = async (formData) => {
    console.log("formData", formData);
    return (
      api
        .post(postUploadFileManagementUrl, formData)
        .then((res) => {
          console.log("res", res);
          return res.data;
        })
        // .catch((err) => {
        //   console.log("err", err);
        //   return err.data;
        // });
        .catch((err) => errorCompute(err))
    );
  };

  onDialogueHide = () => {
    this.setState({ isLoading: false });
  };
  submitHandler = async (event) => {
    // this.setState({ isLoadding: true, show: false });
    const formData = new FormData();
    event.preventDefault();
    this.setState({ isLoading: true });
    console.log("formData in submit ", formData);

    formData.append("uploadActionType", UploadActionEnum.ONLYUPLOAD);
    formData.append("moduleName", ModuleNameEnum.APP);
    if (
      this.state.selectedFile !== undefined &&
      this.state.selectedFile !== null
    ) {
      formData.append("file", this.state.selectedFile);
      this.setState({ isLoading: true, show: false });
      let {
        data,
        result: { isError, errorMsg, statusCode },
      } = await this.uploadFileToServer(formData);

      if (isError === false) {
        this.setState({ isLoading: true, show: true });
        console.log("In success ", isError, data);
        let response = await this.callPostAppManagementApi({
          logoTempId: data,
        });
        if (response.result.isError === false) {
          this.setState(
            {
              isLoading: false,
              appName: "",
              appId: "",
              versionName: "",
              logoTempId: null,
              show: true,
            },
            () => {
              confirmAlert({
                title: "Success Message",
                message: <p className="mod-sp">Added App Successfully</p>,
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {},
                  },
                ],
              });
            }
          );
          // this.handleClose();
          this.props.callParentApi();
        }
        if (response.result.isError === true) {
          this.setState({
            isLoading: false,
            show: true,
            isErrorsmsg: true,
            appName: null,
            errorMessage: response.result.errorMsg,
          });
        }
      } else if (isError === true) {
        this.setState({
          isLoading: false,
          isErrorsmsg: true,
          appName: null,
          show: true,
          errorMessage: errorMsg,
        });
      }
    } else {
      this.setState({ isLoading: false }, async () => {
        let response = await this.callPostAppManagementApi({
          //logoTempId: data,
        });
        if (response.result.isError === false) {
          this.setState({ isLoading: false }, () => {
            confirmAlert({
              title: "Success Message",
              message: <p className="mod-sp">Added App Successfully</p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {},
                },
              ],
            });
          });
          this.handleClose();
          this.props.callParentApi();
        } else if (response.result.isError === true) {
          this.setState({
            isLoading: false,
            show: true,
            isErrorsmsg: true,
            appName: null,
            errorMessage: response.result.errorMsg,
          });
        }
      });
    }
  };

  render() {
    return (
      <div>
        <button onClick={this.handleShow} className="btn btn-primary">
          Add New
        </button>
        <Dialog
          visible={this.state.isLoading}
          modal={true}
          closable={false}
          showHeader={false}
          style={{ width: "20vw" }}
          onHide={this.onDialogueHide}
        >
          <Loader />
        </Dialog>
        <Dialog
          visible={this.state.isSuccess}
          modal={true}
          style={{ width: "25vw" }}
          closable
          showHeader
          onHide={this.onDialogueHideSuccess}
        >
          <SuccessDialog successMessage="Added App Successfully" />
        </Dialog>
        <Modal size="sm" show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <p style={{ fontSize: "16px", fontWeight: "bold" }}>
              App Manage System
            </p>
          </Modal.Header>
          <form onSubmit={this.submitHandler}>
            {/* {this.state.isLoading && (
              <>
                <div style={{ textAlign: "center", padding: "5px" }}>
                  <Spinner animation="grow" variant="primary" />
                  <Spinner animation="grow" variant="secondary" />
                  <Spinner animation="grow" variant="success" />
                  <Spinner animation="grow" variant="danger" />
                  <Spinner animation="grow" variant="warning" />
                  <Spinner animation="grow" variant="light" />
                </div>
              </>
            )} */}
            <Modal.Body>
              {/* {this.state.isSubmitted && (
                <>
                  <Messages ref={(el) => (this.messages = el)} />
                </>
              )}
              {this.state.error && (
                <Alert variant="danger">
                  <b>Error Occurred</b>
                </Alert>
              )} */}

              <div className="col-md-12">
                <div className="form-group">
                  <div
                    className={
                      this.state.isErrorsmsg === true
                        ? "alert alert-danger alert-dismissible fade show"
                        : "d-none"
                    }
                    role="alert"
                  >
                    {this.state.errorMessage}
                    <button
                      type="button"
                      class="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="name">
                    App Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="appName"
                    name="appName"
                    placeholder="Enter App Name"
                    onChange={this.changeHandler}
                    value={this.state.appName}
                  />
                </div>
                {/* <div className="form-group">
                  <label htmlFor="name">Logo</label>
                  <input
                    type="file"
                    className="form-control"
                    id="file"
                    name="file"
                    placeholder="Enter App Name"
                    onChange={this.changeHandler}
                    ref={(ref) => (this.fileInput = ref)}
                  />
                </div> */}
                <div className="form-group">
                  <label htmlFor="name">App ID</label>
                  <input
                    type="text"
                    className="form-control"
                    id="appId"
                    name="appId"
                    placeholder="Enter App ID"
                    onChange={this.changeHandler}
                    value={this.state.appId}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="name">Version</label>
                  <input
                    type="text"
                    className="form-control"
                    id="versionName"
                    name="versionName"
                    placeholder="Enter Version"
                    onChange={this.changeHandler}
                    value={this.state.versionName}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ float: "left" }}>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    );
  }
}

export default AddNewApp;
