/*global google*/
import React, { Component } from "react";
import { GMap } from "primereact/gmap";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { loadGoogleMaps, removeGoogleMaps } from "../load/GoogleMaps";
import { LOCATIONZOOM } from "../Enums";

var map;

export class AppMap extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    const options =
      this.props.options !== undefined
        ? this.props.options
        : {
            center: { lat: 23.797911, lng: 90.414391 },
            zoom: LOCATIONZOOM.ZOOM,
          };

    this.state = {
      googleMapsReady: false,
      dialogVisible: false,
      markerTitle: "",
      draggableMarker: false,
      overlays: null,
      selectedPosition: null,
      options: options,
      placeName: null,
    };

    this.onMapClick = this.onMapClick.bind(this);
    this.onOverlayClick = this.onOverlayClick.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.onMapReady = this.onMapReady.bind(this);
    this.onHide = this.onHide.bind(this);
    this.addMarker = this.addMarker.bind(this);
  }

  getLatlong = (address, fn) => {
    //console.log("address ", address);
    //var address = document.getElementById('textboxid').value;

    let latitude, longitude, coordinates;
    new google.maps.Geocoder().geocode(
      {
        address: address,
      },
      function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          latitude = results[0].geometry.location.lat();
          longitude = results[0].geometry.location.lng();
          coordinates = { latitude, longitude };
          fn(coordinates);
        }
      }
    );
  };
  initialize = () => {
    let autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete"),
      { types: ["geocode"] }
    );
    google.maps.event.addListener(autocomplete, "place_changed", (e) => {
      var place = autocomplete.getPlace();
      console.log("You selected: " + place.formatted_address);
      this.props.changeCenterSearchText(place.formatted_address);
      //document.getElementById("placeName").value = place.formatted_address;
    });
  };

  componentDidMount() {
    loadGoogleMaps(() => {
      this.setState({ googleMapsReady: true }, () => {
        // map = new google.maps.Map(document.getElementById("googleMaps"), {
        //   zoom: 12,
        //   center: this.props.options.center,
        // });
        map = this.myRef.current.getMap();
        // console.log("in component load ", map);
        let newMarkers = [];
        this.createNewOverlayMarker(this.props.overlays, newMarkers, map);
        this.createCircle(this.props.circles, newMarkers, map);
        map.setCenter(this.props.options.center);
        map.panTo(this.props.options.center);

        //console.log("map", map);

        this.setState({
          overlays: newMarkers,
          options: this.props.options,
          map: map,
        });
        this.initialize();
      });
    });
  }

  componentDidUpdate(prevProps) {
    //console.log("current", this.props, "old", prevProps);
    if (
      prevProps.overlays !== undefined &&
      this.props.overlays !== undefined &&
      prevProps.overlays !== this.props.overlays
    ) {
      //console.log("Checking overlays", this.props.overlays);
      if (this.props.from === "Location-History") {
      } else {
        let newMarkers = [];
        this.createNewOverlayMarker(
          this.props.overlays,
          newMarkers,
          this.state.map
        );
        this.createCircle(this.props.circles, newMarkers, this.state.map);
        // console.log(
        //   "New marker in else",
        //   newMarkers,
        //   "prop options",
        //   this.props.options
        // );
        //console.log("this.state.map before", this.state.map.getCenter().lat());
        this.state.map.setCenter(this.props.options.center);
        //console.log("this.state.map after", this.state.map.getCenter().lat());
        this.state.map.panTo(this.props.options.center);
        this.state.map.setZoom(LOCATIONZOOM.ZOOM);
        this.setState({
          overlays: newMarkers,
          options: this.props.options,
        });
      }
    }
  }

  componentWillUnmount() {
    removeGoogleMaps();
  }

  onMapClick(event) {
    // this.setState({
    //   dialogVisible: true,
    //   selectedPosition: event.latLng,
    // });
  }

  onOverlayClick(event) {
    let isMarker = event.overlay.getTitle !== undefined;
    //console.log("overlay", event.overlay);

    if (isMarker) {
      let title;
      if (
        this.props.from !== undefined &&
        this.props.from === "LocationSearch"
      ) {
        title = event.overlay.getTitle().split(":")[1];
      } else {
        title = event.overlay.getTitle();
      }

      this.infoWindow = this.infoWindow || new google.maps.InfoWindow();
      this.infoWindow.setContent("<div>" + title + "</div>");
      this.infoWindow.open(event.map, event.overlay);
      event.map.setCenter(event.overlay.getPosition());

      if (
        this.props.from !== undefined &&
        this.props.from === "LocationSearch"
      ) {
        if (this.props.dataToSend !== undefined) {
          let res = event.overlay.getTitle().split(":");
          //console.log("splitted value", res);
          this.props.history.push({
            pathname: "/location-history",
            state: { detail: this.props.dataToSend, imei: res[0] },
          });
        }
      }

      // this.growl !== null &&
      //   this.growl.show({
      //     severity: "info",
      //     summary: "Marker Selected",
      //     detail: event.overlay.getPosition() + "",
      //   });
    } else {
      // this.growl !== null &&
      //   this.growl.show({
      //     severity: "info",
      //     summary: "Shape Selected",
      //     detail: "",
      //   });
    }
  }

  handleDragEnd(event) {
    this.growl.show({
      severity: "info",
      summary: "Marker Dragged",
      detail: event.overlay.getTitle(),
    });
  }

  addMarker() {
    let newMarker = new google.maps.Marker({
      position: {
        lat: this.state.selectedPosition.lat(),
        lng: this.state.selectedPosition.lng(),
      },
      title: this.state.markerTitle,
      map: map,
      draggable: this.state.draggableMarker,
    });

    this.setState({
      overlays: [...this.state.overlays, newMarker],
      dialogVisible: false,
      draggableMarker: false,
      markerTitle: "",
    });
  }

  createCircle = (circles, arrayToInsert, map) => {
    circles !== null &&
      circles !== undefined &&
      circles.map((e, i) => {
        let e_1 = {
          ...e,
          fillColor: "#1976D2",
          fillOpacity: 0.35,
          strokeWeight: 3,
          map: map,
        };
        //console.log(e_1);
        return arrayToInsert.push(new google.maps.Circle(e_1));
      });
  };
  createNewOverlayMarker = (currentOverlay, arrayToPush, map) => {
    currentOverlay !== null &&
      currentOverlay !== undefined &&
      currentOverlay.map((e, i) => {
        return arrayToPush.push(new google.maps.Marker({ ...e, map: map }));
      });
  };

  onMapReady(event) {
    //console.log(event.map.Pi.);
    //console.log("onMapReady", event.target);
    // let newMarkers = [];
    // this.createNewOverlayMarker(this.props.overlays, newMarkers);
    // this.createCircle(this.props.circles, newMarkers);
    //console.log("App Map data", newMarkers);
    // this.setState({
    //   options: this.props.options,
    //   overlays: newMarkers,
    // });
  }

  onHide(event) {
    this.setState({ dialogVisible: false });
  }

  render() {
    const footer = (
      <div>
        <Button label="Yes" icon="pi pi-check" onClick={this.addMarker} />
        <Button label="No" icon="pi pi-times" onClick={this.onHide} />
      </div>
    );

    return (
      <div>
        <div className="content-section implementation">
          {/* <Growl
            ref={(el) => {
              this.growl = el;
            }}
          ></Growl> */}

          {this.state.googleMapsReady && (
            <GMap
              ref={this.myRef}
              overlays={this.state.overlays}
              options={this.state.options}
              style={{ width: "100%", minHeight: "320px" }}
              onMapReady={this.onMapReady}
              onMapClick={this.onMapClick}
              onOverlayClick={this.onOverlayClick}
              onOverlayDragEnd={this.handleDragEnd}
            />
          )}

          <Dialog
            header="New Location"
            visible={this.state.dialogVisible}
            width="300px"
            modal={true}
            footer={footer}
            onHide={this.onHide}
          >
            <div className="p-grid p-fluid">
              <div className="p-col-2" style={{ paddingTop: ".75em" }}>
                <label htmlFor="title">Label</label>
              </div>
              <div className="p-col-10">
                <InputText
                  type="text"
                  id="title"
                  value={this.state.markerTitle}
                  onChange={(e) =>
                    this.setState({ markerTitle: e.target.value })
                  }
                />
              </div>

              <div className="p-col-2" style={{ paddingTop: ".75em" }}>
                Lat
              </div>
              <div className="p-col-10">
                <InputText
                  readOnly
                  value={
                    this.state.selectedPosition
                      ? this.state.selectedPosition.lat()
                      : ""
                  }
                />
              </div>

              <div className="p-col-2" style={{ paddingTop: ".75em" }}>
                Lng
              </div>
              <div className="p-col-10">
                <InputText
                  readOnly
                  value={
                    this.state.selectedPosition
                      ? this.state.selectedPosition.lng()
                      : ""
                  }
                />
              </div>

              <div className="p-col-2" style={{ paddingTop: ".75em" }}>
                <label htmlFor="drg">Drag</label>
              </div>
              <div className="p-col-10">
                <Checkbox
                  checked={this.state.draggableMarker}
                  onChange={(event) =>
                    this.setState({ draggableMarker: event.checked })
                  }
                />
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}
export default AppMap;
