import React from "react";

const ErrorDialog = ({ errorMessage = "Oops! Something went wrong!" }) => {
  //console.log("Inside loader", textToShow);
  return (
    <div
      className="text-center"
      style={{ margin: "10px 5px", fontWeight: "bold" }}
    >
      <span
        className="text-danger"
        style={{ paddingLeft: "10px", color: "#1BA14A" }}
      >
        {errorMessage}
      </span>
    </div>
  );
};

export default ErrorDialog;
