import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Dashboard } from "./pages/Dashboard";

export const ProtectedRoute = ({
  component: Component,
  extraProp: extraProp,
  licenseProp: licenseProp,
  ...rest
}) => {
  //console.log("in protected route", Component, extraProp);
  const loggedIn = localStorage.getItem("loggedIn");
  return (
    <Route
      {...rest}
      render={(props) => {
        //console.log("props in protected Route", props);
        if (loggedIn === "true") {
          if (licenseProp !== undefined && licenseProp.licenseStatus !== 2) {
            return (
              <Dashboard
                //{...props}
                extraProp={extraProp}
                licenseProp={licenseProp}
              />
            );
          } else {
            return (
              <Component
                {...props}
                extraProp={extraProp}
                licenseProp={licenseProp}
              />
            );
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};
