import React, { Component } from "react";
import "../assets/style.css";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Tree } from "primereact/tree";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import Assign from "../components/Assign";
import Upload from "../components/Upload";
import SuccessDialog from "../components/SuccessDialog";
import { HeaderContent, ListHeader } from "../components/DataUpdate";
import { Paginator } from "primereact/paginator";

import {
  getContentPushUrl,
  postContentPushUrl,
  getContentPushFolderList,
  baseUrl,
} from "../service/ApiUrls";
import api from "../service/ApiService";
import { ContentPushAction, ActionAssign } from "../Enums";
import { NavLink } from "react-router-dom";
import Loader from "../components/Loader";
import { OverlayPanel } from "primereact/overlaypanel";
import { confirmAlert } from "react-confirm-alert";
const helpIcon = require("../assets/icons/help_icon.png");
const FileDownload = require("js-file-download");
export class ContentPush extends Component {
  constructor() {
    super();
    this.state = {
      viewData: [],
      moveFileData: [],
      visible: false,
      type: "Rename",
      object: null,
      gridView: true,
      filter: null,
      selectedNodeKey: null,
      breadcumLineUp: [],
      grouplist: null,
      isLoading: false,
      textToShow: "Loading...",
      isSuccess: false,
      dfirst: 0,
      drows: 12,
      eventrows: 12,
      currentPage: 1,
    };
    this.onClick = this.onClick.bind(this);
    this.onHide = this.onHide.bind(this);
  }
  componentDidMount() {
    this.webCallRoot();
  }
  onDialogueHideSuccess = () => {
    this.setState({ isSuccess: false });
  };
  renameKeys = (obj, newKeys) => {
    const keyValues = Object.keys(obj).map((key) => {
      let newKey = null;
      if (key === "displayName") {
        newKey = newKeys.displayName;
      } else {
        newKey = key;
      }
      if (key === "children") {
        obj[key] = obj[key].map((obj) => this.renameKeys(obj, newKeys));
      }
      return {
        [newKey]: obj[key],
      };
    });
    return Object.assign({}, ...keyValues);
  };

  convertFolderData = (serverData) => {
    //console.log("serverObject", serverData);
    // let result = null;
    if (serverData instanceof Array) {
      for (let i = 0; i < serverData.length; i++) {
        this.convertFolderData(serverData[i]);
      }
      return serverData;
    } else {
      serverData["label"] = serverData["name"];
      serverData["key"] = serverData["id"];
      serverData["icon"] =
        serverData["contentType"] === 0
          ? "pi pi-fw pi-folder"
          : "pi pi-fw pi-file";
      if (serverData["name"] === "Root") {
        serverData["key"] = "1";
        serverData["id"] = "1";
      }
      if (
        serverData["childList"] instanceof Array &&
        serverData["childList"] !== null &&
        serverData["childList"].length > 0
      ) {
        serverData["childList"].map((e, i) => {
          return (serverData["childList"][i] = this.convertFolderData(e));
          //return result;
          //console.log("result " + i, result);
        });
        //condata.push(serverData);
        serverData["children"] = serverData["childList"];
        return serverData;
      }
      if (
        serverData["childList"] === null ||
        serverData["childList"].length === 0
      ) {
        //console.log("childlist null ", serverData);
        serverData["children"] = serverData["childList"];
        //condata.push(serverData);
        return serverData;
      }
    }
  };

  convertData = (serverData, conData) => {
    let result = null;
    if (serverData instanceof Array) {
      for (let i = 0; i < serverData.length; i++) {
        conData.push(this.convertData(serverData[i], conData));
        // if (result) {
        //   break;
        // }
      }
    } else {
      for (let object in serverData) {
        //console.log(object + ":" + serverData[object]);
        if (object === "name") {
          serverData["label"] = serverData["name"];
          serverData["icon"] =
            serverData["contentType"] === 0
              ? "pi pi-fw pi-folder"
              : "pi pi-fw pi-file";
          // if (serverData["childList"] !== undefined) {
          //   serverData["children"] = serverData["childList"];
          // }
          return serverData;
        }
        if (
          serverData[object] instanceof Object ||
          serverData[object] instanceof Array
        ) {
          result = this.convertData(serverData[object], conData);
          if (result) {
            conData.push(result);
            break;
          }
        }
      }
    }
    return conData;
  };

  customFilter = (theObject, value) => {
    //console.log(theObject, value);
    let result = null;
    if (theObject instanceof Array) {
      for (let i = 0; i < theObject.length; i++) {
        result = this.customFilter(theObject[i], value);
        if (result) {
          break;
        }
      }
    } else {
      for (let prop in theObject) {
        console.log(prop + ": " + theObject[prop]);
        if (prop === "key") {
          if (theObject[prop].toLowerCase().includes(value.toLowerCase())) {
            return theObject;
          }
        }
        if (
          theObject[prop] instanceof Object ||
          theObject[prop] instanceof Array
        ) {
          result = this.customFilter(theObject[prop], value);
          if (result) {
            break;
          }
        }
      }
    }
    return result;
  };

  onClick(name, position, eventobject) {
    console.log(eventobject, "type", this.state.type);
    let state = {
      [`${name}`]: true,
    };

    if (position) {
      state = {
        ...state,
        position,
      };
    }

    this.setState(state);
    if (this.state.type === "Rename" || this.state.type === "newFolder") {
      this.setState({
        ...state,
        value: this.state.type === "newFolder" ? "" : eventobject.name,
        object: eventobject,
      });
    } else {
      this.setState({
        ...state,
        object: eventobject,
      });
    }
  }

  onHide(name) {
    this.setState({
      [`${name}`]: false,
    });
  }
  update = (key, prop, val) => {
    //console.log("key", key, "prop", prop, "val", val);
    let selectedObject = this.state.viewData.find((p) => p.key === key);
    let selectedObjectinMove = this.state.moveFileData.find(
      (p) => p.key === key
    );
    if (selectedObject && selectedObject[prop]) {
      selectedObject[prop] = val;
    }
    if (selectedObjectinMove && selectedObjectinMove[prop]) {
      selectedObjectinMove[prop] = val;
    }
    //console.log("selectedObject after childList update", selectedObject);
  };

  ///All api needs to be called here

  submitHandler = async (e) => {
    //console.log("name", this.state.value, this.state.object);
    // if (this.state.type === "newFolder") {
    //   if (this.state.object === undefined || this.state.object === null) {
    //     this.setState({ object: this.state.viewData[0] });
    //   }
    // }
    const elementsIndex = this.state.object
      ? this.state.viewData.findIndex(
          (element) => element.id === this.state.object.id
        )
      : "";
    const elementsIndexinMove = this.state.object
      ? this.state.moveFileData.findIndex(
          (element) => element.id === this.state.object.id
        )
      : "";

    if (elementsIndex === "" || elementsIndexinMove === "") {
      this.onHide("visible");
      //return;
    }
    let newArray = [...this.state.viewData];
    let newMoveArray = [...this.state.moveFileData];

    if (this.state.type === "newFolder") {
      const folderObject = {
        key: "12",
        id: "21",
        name: this.state.value,
        label: this.state.value,
        contentType: 0,
        uploadDate: this.state.currentdate,
        fileSize: "",
        icon: "pi pi-fw pi-folder",
        childList: [],
      };
      let parentId, mediaPath;
      if (this.state.object === undefined || this.state.object === null) {
        newArray.push(folderObject);
        newMoveArray.push(folderObject);
        parentId = null;
        mediaPath = null;
      } else {
        if (elementsIndex === -1) {
          newArray.push(folderObject);
          if (
            newMoveArray[elementsIndexinMove] !== undefined &&
            newMoveArray[elementsIndexinMove].childList !== undefined &&
            newMoveArray[elementsIndexinMove].childList !== null
          ) {
            newMoveArray[elementsIndexinMove].childList.push(folderObject);
          } else {
            if (newMoveArray[elementsIndexinMove] !== undefined) {
              newMoveArray[elementsIndexinMove].childList = [];
              newMoveArray[elementsIndexinMove].childList.push(folderObject);
            }
          }
          parentId =
            newMoveArray[elementsIndexinMove] !== undefined
              ? newMoveArray[elementsIndexinMove].id
              : null;
          mediaPath =
            newMoveArray[elementsIndexinMove] !== undefined
              ? newMoveArray[elementsIndexinMove].name
              : null;
        } else {
          newArray[elementsIndex].childList.push(folderObject);
          newMoveArray[elementsIndexinMove].childList.push(folderObject);
          parentId = newArray[elementsIndex].id;
          mediaPath = newArray[elementsIndex].name;
        }
      }
      let data = {
        name: this.state.value,
        parentId: parentId,
        contentType: 0,
        updateContentAction: ContentPushAction.RENAME,
        mediaPath: this.state.parentMediaPath,
        parentMediaPath: this.state.parentMediaPath,
      };
      console.log("datatoBesend", data);
      await this.callPostContent(newArray, newMoveArray, data);
    }
    if (this.state.type === "Rename") {
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        name: this.state.value,
      };
      newMoveArray[elementsIndexinMove] = {
        ...newMoveArray[elementsIndexinMove],
        name: this.state.value,
      };
      let data = {
        name: this.state.value,
        contentType: newArray[elementsIndex].contentType,
        mediaPath: newArray[elementsIndex].mediaPath,
        updateContentAction: ContentPushAction.RENAME,
        parentId: newArray[elementsIndex].parentId,
        parentMediaPath: this.state.parentMediaPath,
      };
      console.log("data to be renamed ", data);
      this.callPutContent(this.state.object.id, newArray, newMoveArray, data);
    } else if (this.state.type === "Delete") {
      this.callDeleteContent(this.state.object.id, newArray, newMoveArray);
    } else if (this.state.type === "Move" || this.state.type === "Copy") {
      console.log("selected Node Key", this.state.selectedNodeKey);
      let selectedFolder = this.state.selectedNodeKey;
      if (selectedFolder === undefined || selectedFolder === null) {
        this.onHide("visible");
        return;
      }
      let folderChildren = [];
      if (selectedFolder.childList === null) {
        selectedFolder["childList"] = [];
      }
      folderChildren = selectedFolder.childList;
      folderChildren = folderChildren.concat(this.state.object);
      this.update(selectedFolder.key, "childList", folderChildren);
      let data = {
        name: this.state.object.name,
        contentType: this.state.object.contentType,
        mediaPath: selectedFolder.name === "Root" ? null : selectedFolder.name,
        updateContentAction:
          this.state.type === "Move"
            ? ContentPushAction.MOVE
            : ContentPushAction.COPY,
        parentId: selectedFolder.name === "Root" ? null : selectedFolder.id,
        parentMediaPath: this.state.parentMediaPath,
      };
      if (this.state.type === "Move") {
        let index = newArray.indexOf(this.state.object);
        if (index !== -1) {
          newArray.splice(index, 1);
        }
        index = newMoveArray.indexOf(this.state.object);
        if (index !== -1) {
          newMoveArray.splice(index, 1);
        }
      }
      this.callPutContent(this.state.object.id, newArray, newMoveArray, data);
    }
    this.setState({
      //viewData: newArray,
      //moveFileData: newMoveArray,
      selectedNodeKey: null,
    });
    this.onHide("visible");
  };

  onDoubleClick = async (e) => {
    console.log("e", e, "state object", this.state.object);
    let flag = false;
    this.state.breadcumLineUp.map((v, i) => {
      if (v.name === e.name) {
        flag = true;
      }
    });
    if (flag === false) {
      this.state.breadcumLineUp.push({ ...e, label: e.name });
    }

    if (
      e.childList !== undefined &&
      e.childList !== null &&
      e.childList.length > 0
    ) {
      e.childList.map((v, i) => {
        return (v["parent"] = e);
      });
      console.log(e.childList, "In if mediaPath", e.childList[0].mediaPath);
      this.setState(
        { parentMediaPath: e.childList[0].mediaPath, parentObject: e },
        () => {
          this.callgetContents({ mediaPath: this.state.parentMediaPath });
        }
      );
    } else {
      e.childList = [];
      console.log(e.childList, "In else mediaPath", e.mediaPath);
      this.setState({ parentMediaPath: e.name, parentObject: e }, () => {
        this.callgetContents({ mediaPath: this.state.parentMediaPath });
      });
      //this.setState({ : e.childList });
    }
    console.log(e.childList, "mediaPath", e.mediaPath);

    //
  };

  callgetContents = async ({
    mediaPath,
    first = this.state.dfirst,
    limit = this.state.drows,
    filter,
  } = {}) => {
    this.setState({ isLoading: true }, () => {
      api
        .get(getContentPushUrl, {
          params: { mediaPath: mediaPath, first: first, limit: limit, filter },
        })
        .then((res) => {
          if (res.data.result.isError === false) {
            let convertedObject = this.convertData(res.data.data.content, []);
            //console.log("convrted Object", convertedObject);
            this.setState(
              {
                viewData: convertedObject,
                moveFileData: convertedObject,
                isLoading: false,
                ...res.data.data,
                // isSuccess: true,
              },
              () => {
                this.callGetFolderList();
              }
            );
          } else if (res.data.result.isError === true) {
            this.setState({ isLoading: false }, () => {
              confirmAlert({
                title: "Error Message",
                message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {},
                  },
                ],
              });
            });
          }
        });
    });
  };
  onPageChange = (event) => {
    console.log(event);
    this.setState(
      {
        dfirst: event.first,
        page: event.page,
        eventrows: event.rows,
        rows: event.rows,
        currentPage: event.page + 1,
      },
      () => {
        this.callgetContents({
          first: this.state.page,
          limit: this.state.eventrows,
        });
      }
    );
  };
  callDeleteContent = async (id, newArray, newMoveArray) => {
    this.setState({ isLoading: true }, () => {
      api.delete(getContentPushUrl + "/" + id).then((res) => {
        if (res.data.result.isError === false) {
          let index = newArray.indexOf(this.state.object);
          if (index !== -1) {
            newArray.splice(index, 1);
          }
          index = newMoveArray.indexOf(this.state.object);
          if (index !== -1) {
            newMoveArray.splice(index, 1);
          }
          this.setState(
            {
              viewData: newArray,
              moveFileData: newMoveArray,
              isLoading: false,
              // isSuccess: true,
              // successMessage:
            },
            () => {
              confirmAlert({
                title: "Message",
                message: <p className="mod-sp"> Successfully Deleted </p>,
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {},
                  },
                ],
              });
            }
          );
        } else if (res.data.result.isError === true) {
          this.setState({ isLoading: false }, () => {
            confirmAlert({
              title: "Error Message",
              message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {},
                },
              ],
            });
          });
        }
      });
    });
  };

  callPutContent = async (id, newArray, newMoveArray, data) => {
    this.setState({ isLoading: true }, () => {
      console.log("data to send", data, newArray);
      api.put(getContentPushUrl + "/" + id, data).then((res) => {
        if (res.data.result.isError === false) {
          this.setState(
            {
              viewData: newArray,
              moveFileData: newMoveArray,
              isLoading: false,
              object: null,
              // isSuccess: true,
            },
            () => {
              confirmAlert({
                title: "Message",
                message: (
                  <p className="mod-sp">
                    {" "}
                    Successfully{" "}
                    {this.state.type === "newFolder"
                      ? "New Folder"
                      : this.state.type}{" "}
                    done{" "}
                  </p>
                ),
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {
                      if (data.parentMediaPath !== null) {
                        this.callgetContents({
                          mediaPath: data.parentMediaPath,
                        });
                      } else {
                        this.callgetContents({
                          mediaPath: null,
                        });
                      }
                    },
                  },
                ],
              });
            }
          );
        } else if (res.data.result.isError === true) {
          this.setState({ isLoading: false, object: null }, () => {
            confirmAlert({
              title: "Error Message",
              message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    this.callgetContents({
                      mediaPath: data.parentMediaPath,
                    });
                  },
                },
              ],
            });
          });
        }
      });
    });
  };

  callGetFolderList = async () => {
    api
      .get(getContentPushFolderList)
      .then((res) => {
        if (res.data.result.isError === false) {
          // let convertedObject = this.convertData(res.data.data.content, []);
          // console.log("convrted Object", convertedObject);
          // let fileObject = {
          //   key: 1,
          //   id: 1,
          //   name: "root",
          //   label: "root",
          //   contentType: 0,
          //   children: [],
          //   childList: [],
          //   icon: "pi pi-fw pi-folder",
          //   parent: null,
          //   parentId: null,
          // };
          let convertedObject = this.convertFolderData(res.data.data.content);
          //console.log("final converted FolderList", convertedObject);
          // let serverData = convertedObject;
          // serverData.map((e, i) => {
          //   fileObject.childList.push(e);
          //   fileObject.children.push(e);
          // });

          // console.log("file object", convertedObject);
          this.setState({
            folderList: convertedObject,
            isLoading: false,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  callPostContent = async (newArray, newMoveArray, data) => {
    this.setState({ isLoading: true }, () => {
      api.post(postContentPushUrl, data).then((res) => {
        if (res.data.result.isError === false) {
          this.setState({ isLoading: false }, () => {
            confirmAlert({
              title: "Message",
              message: (
                <p className="mod-sp">
                  {" "}
                  Successfully{" "}
                  {this.state.type === "newFolder"
                    ? "New Folder"
                    : this.state.type}{" "}
                  done{" "}
                </p>
              ),
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    this.callgetContents({ mediaPath: data.mediaPath });
                  },
                },
              ],
            });
          });
        } else if (res.data.result.isError) {
          this.setState({ isLoading: false }, () => {
            confirmAlert({
              title: "Error Message",
              message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    this.callgetContents({ mediaPath: data.mediaPath });
                  },
                },
              ],
            });
          });
        }
      });
    });
  };

  webCallRoot = () => {
    this.callgetContents();
  };

  upFolder = async (e) => {
    console.log("e", e, "parent in upfolder", e.parent);
    if (e.parent === undefined || e.parent === null) {
      this.setState({ breadcumLineUp: [] }, async () => {
        return await this.callgetContents({ mediaPath: null });
      });
    } else {
      let parrentArray = [e.parent];
      this.setState({ viewData: parrentArray });
      await this.callgetContents({ mediaPath: e.parent.mediaPath });
      const index = this.state.breadcumLineUp.indexOf(e.parent);
      if (index > -1) {
        this.state.breadcumLineUp.splice(index, 1);
        if (this.state.breadcumLineUp.length === 0) {
          await this.callgetContents({ mediaPath: null });
          //this.setState({ viewData: this.state.moveFileData });
        }
      }
    }
  };

  breadCumbUp = () => {
    console.log("Hello");
  };

  submitFileHandler = ({ data, result }, files, mediaPath) => {
    console.log("parent component ", data, result, files, mediaPath);
    if (result.isError === true) {
      this.onHide("visible");
      confirmAlert({
        title: "Error Message",
        message: <p className="mod-p"> {result.errorMsg} </p>,
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              this.callgetContents({ mediaPath: mediaPath });
            },
          },
        ],
      });
    } else {
      this.onHide("visible");
      let folderToUpload = null;
      if (this.state.object !== null && this.state.breadcumLineUp.length > 0) {
        if (this.state.object.contentType === 0) {
          folderToUpload = this.state.object;
        }
      }
      if (
        this.state.object === null &&
        this.state.parentObject !== undefined &&
        this.state.parentObject !== null
      ) {
        folderToUpload = this.state.parentObject;
      }
      //console.log("folder to upload", folderToUpload);

      files.forEach(async (f) => {
        let type = 1; // f.file.type.includes("image") ? "image" : 1;
        const fileObject = {
          key: f.file.size + "",
          id: f.file.size + "",
          name: f.file.name,
          label: f.file.name,
          contentType: type,
          uploadDate: this.state.currentdate,
          fileSize: f.file.size,
          icon: "pi pi-fw pi-file",
          parent: folderToUpload,
        };
        //console.log("fileobject", fileObject);
        let data = {
          name: fileObject.name,
          parentId: null,
          contentType: 1,
          updateContentAction: 0,
          mediaPath: null,
        };
        if (folderToUpload !== null) {
          if (
            folderToUpload.childList !== undefined &&
            folderToUpload.childList !== null
          ) {
            folderToUpload.childList.push(fileObject);
          } else {
            folderToUpload.childList = [];
            folderToUpload.childList.push(fileObject);
          }
          data["parentId"] = folderToUpload.id;
          data["mediaPath"] = folderToUpload.name;
        }
        confirmAlert({
          title: "Message",
          message: (
            <p className="mod-sp"> Successfully Uploaded {data.name} </p>
          ),
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.callgetContents({
                  mediaPath: folderToUpload !== null ? data["mediaPath"] : null,
                });
              },
            },
          ],
        });
        //this.state.viewData.push(fileObject);

        //this.state.moveFileData.push(fileObject);

        //await this.callPostContent("", "", data);
      });
    }
  };

  renderFooter(name) {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => this.onHide(name)}
        />
        {this.state.type === "Assign" ? (
          <Button
            label="Update"
            icon="pi pi-check"
            className="p-button-success"
            onClick={this.submitHandler}
          />
        ) : (
          <Button
            label="Ok"
            icon="pi pi-check"
            className="p-button-success"
            onClick={this.submitHandler}
          />
        )}
      </div>
    );
  }
  mediaPathCompute = () => {
    let folderToUpload = null;
    if (this.state.object !== null && this.state.breadcumLineUp.length > 0) {
      if (this.state.object.contentType === 0) {
        folderToUpload = this.state.object;
      }
    }
    if (
      this.state.object === null &&
      this.state.parentObject !== undefined &&
      this.state.parentObject !== null
    ) {
      folderToUpload = this.state.parentObject;
    }
    console.log("folder to upload mediaPath Compute", folderToUpload);
    let data = { name: "Check" };
    if (folderToUpload !== null) {
      if (
        folderToUpload.childList !== undefined &&
        folderToUpload.childList !== null
      ) {
        //folderToUpload.childList.push(fileObject);
      } else {
        folderToUpload.childList = [];
        //folderToUpload.childList.push(fileObject);
      }
      data["parentId"] = folderToUpload.id;
      data["mediaPath"] = folderToUpload.name;
      return data["mediaPath"];
    }
    return null;
  };
  parentIdCompute = () => {
    let folderToUpload = null;
    if (this.state.object !== null && this.state.breadcumLineUp.length > 0) {
      if (this.state.object.contentType === 0) {
        folderToUpload = this.state.object;
      }
    }
    if (
      this.state.object === null &&
      this.state.parentObject !== undefined &&
      this.state.parentObject !== null
    ) {
      folderToUpload = this.state.parentObject;
    }
    console.log("folder to upload parent ID", folderToUpload);
    let data = { name: "Check" };
    if (folderToUpload !== null) {
      if (
        folderToUpload.childList !== undefined &&
        folderToUpload.childList !== null
      ) {
        //folderToUpload.childList.push(fileObject);
      } else {
        folderToUpload.childList = [];
        //folderToUpload.childList.push(fileObject);
      }
      data["parentId"] = folderToUpload.id;
      data["mediaPath"] = folderToUpload.name;
      return data["parentId"];
    }
    return null;
  };

  onDialogueHide = () => {
    this.setState({ isLoading: false });
  };

  renderPopUp() {
    const showBox = (this.state.type === "Rename" ||
      this.state.type === "newFolder") && (
      <div className="form-group" style={{ width: "100%" }}>
        <InputText
          value={this.state.value}
          placeholder="Enter Here"
          onChange={(e) =>
            this.setState({ ...this.state, value: e.target.value })
          }
        />
      </div>
    );
    const showFolderTree = (
      <Tree
        style={{ textAlign: "left" }}
        value={this.state.folderList}
        selectionMode="single"
        selectionKeys={this.state.selectedNodeKey}
        onSelectionChange={(e) => {
          // let newValue = this.state.moveFileData.filter((d) =>
          //   d.key.toLowerCase().includes(e.value.toLowerCase())
          // );
          let newValue = this.customFilter(
            this.state.folderList,
            e.value.toLowerCase()
          );
          this.setState({ selectedNodeKey: newValue });
        }}
      />
    );
    const paragraph = (
      <p style={{ textAlign: "center", color: "#e91224", width: "100%" }}>
        Do you want to Delete the item?
      </p>
    );
    const showcomponent =
      this.state.type === "Delete" ? (
        paragraph
      ) : this.state.type === "Rename" ? (
        showBox
      ) : this.state.type === "Move" || this.state.type === "Copy" ? (
        showFolderTree
      ) : this.state.type === "newFolder" ? (
        showBox
      ) : this.state.type === "newFile" ? (
        <>
          <Upload
            style={{ width: "60%" }}
            handleSubmit={this.submitFileHandler}
            from="contentPush"
            mediaPath={this.mediaPathCompute()}
            parentId={this.parentIdCompute()}
          />
        </>
      ) : this.state.type === "Assign" ? (
        <Assign name="Content Push" actionType={ActionAssign.ASSIGN} />
      ) : this.state.type === "UnAssign" ? (
        <Assign name="Content Push" actionType={ActionAssign.UNASSIGN} />
      ) : (
        ""
      );
    return (
      <div
        className="p-grid"
        style={{ fontSize: "14px", textAlign: "center", padding: "5px" }}
      >
        {showcomponent}
      </div>
    );
  }
  layoutHandler = (value) => {
    //console.log("layout in parent", value);
    this.setState({ gridView: value });
  };
  valueChanged = (value) => {
    //console.log("value in parent", value);
    this.setState(
      { filter: typeof value === "string" && value.length > 0 ? value : null },
      () => {
        this.callgetContents({ filter: this.state.filter });
      }
    );
  };
  formatDate = () => {
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let d = new Date(),
      day = "" + d.getDate(),
      year = d.getFullYear();

    let monthIndex = d.getMonth();
    let monthName = monthNames[monthIndex];
    if (day.length < 2) day = "0" + day;

    return [day, monthName, year].join(" ");
  };
  newFolderHandler = (e) => {
    let date = this.formatDate();
    this.setState({ type: "newFolder", currentdate: date }, () => {
      this.onClick("visible", "", this.state.object);
    });
  };
  newFileHandler = (e) => {
    console.log("New File", e);
    let date = this.formatDate();
    this.setState({ type: "newFile", currentdate: date }, () => {
      this.onClick("visible", "", this.state.object);
    });
  };

  textOfType = (e) => {
    switch (e) {
      case "newFolder":
        return "New Folder";
      case "newFile":
        return "New File";
      default:
        return e;
    }
  };

  render() {
    const filter = this.state.filter;
    const dataToShow = filter
      ? this.state.viewData.filter((d) =>
          d.name.toLowerCase().includes(filter.toLowerCase())
        )
      : this.state.viewData;
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Content Push" },
    ];
    const home = { icon: "pi pi-home", url: "dashboard" };
    const root = { icon: "pi pi-home", url: "content-push" };
    const intViewData = dataToShow;
    const gridView =
      intViewData !== null &&
      intViewData !== undefined &&
      Array.isArray(intViewData) &&
      intViewData.map((e, i) => {
        return (
          <div
            key={i}
            style={{ padding: ".5em", display: "inline-block" }}
            className="p-col-12 p-md-2"
          >
            <div className="small-box bg-drive">
              <div style={{ float: "right" }}>
                <i
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="pi pi-ellipsis-v ab"
                >
                  <span className="sr-only">Toggle Dropdown</span>
                </i>
                <div className="dropdown-menu">
                  {e.contentType === 0 ? (
                    <>
                      {/* <a className="dropdown-item" href="#"> */}
                      <span className="cus-assign">
                        <Assign
                          name={`Assign For Folder ${e.name}`}
                          showIcon={true}
                          fromModule="contentPush"
                          dataToSend={e}
                          actionType={ActionAssign.ASSIGN}
                        />
                      </span>
                      <span className="cus-assign">
                        <Assign
                          name={`Un-assign For File ${e.name}`}
                          showIcon={true}
                          fromModule="contentPush"
                          dataToSend={e}
                          actionType={ActionAssign.UNASSIGN}
                        />
                      </span>

                      {/* </a> */}
                      <div role="separator" className="dropdown-divider"></div>
                      {Array.isArray(this.state.breadcumLineUp) &&
                      this.state.breadcumLineUp !== undefined &&
                      this.state.breadcumLineUp.length > 0 ? (
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            this.upFolder(e);
                          }}
                        >
                          <i className="pi pi-backward icon-p"></i>
                          Back
                        </a>
                      ) : (
                        ""
                      )}

                      <a
                        className="dropdown-item"
                        onClick={() => {
                          this.setState({ type: "Rename" }, () => {
                            this.onClick("visible", "", e);
                          });
                        }}
                      >
                        <i className="pi pi-pencil icon-p"></i>
                        Rename
                      </a>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => {
                          this.setState({ type: "Delete" }, () => {
                            this.onClick("visible", "", e);
                          });
                        }}
                      >
                        <i className="pi pi-trash icon-p"></i>
                        Delete
                      </a>
                    </>
                  ) : (
                    <>
                      {/* <a className="dropdown-item" href="#"> */}
                      {/* <i className="pi pi-user-plus icon-p"></i> */}
                      <span className="cus-assign">
                        <Assign
                          name={`Assign For File ${e.name}`}
                          showIcon={true}
                          fromModule="contentPush"
                          dataToSend={e}
                          actionType={ActionAssign.ASSIGN}
                        />
                      </span>
                      <span className="cus-assign">
                        <Assign
                          name={`Un-assign For File ${e.name}`}
                          showIcon={true}
                          fromModule="contentPush"
                          dataToSend={e}
                          actionType={ActionAssign.UNASSIGN}
                        />
                      </span>

                      {/* </a> */}
                      <div role="separator" className="dropdown-divider"></div>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => {
                          this.setState({ type: "Rename" }, () => {
                            this.onClick("visible", "", e);
                          });
                        }}
                      >
                        <i className="pi pi-pencil icon-p"></i>
                        Rename
                      </a>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => {
                          this.setState({ type: "Delete" }, () => {
                            this.onClick("visible", "", e);
                          });
                        }}
                      >
                        <i className="pi pi-trash icon-p"></i>
                        Delete
                      </a>
                      {Array.isArray(this.state.breadcumLineUp) &&
                      this.state.breadcumLineUp !== undefined &&
                      this.state.breadcumLineUp.length > 0 ? (
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            this.upFolder(e);
                          }}
                        >
                          <i className="pi pi-backward icon-p"></i>
                          Back
                        </a>
                      ) : (
                        ""
                      )}
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => {
                          this.setState({ type: "Move" }, () => {
                            this.onClick("visible", "", e);
                          });
                        }}
                      >
                        <i className="pi pi-external-link icon-p"></i>
                        Move
                      </a>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => {
                          this.setState({ type: "Copy" }, () => {
                            this.onClick("visible", "", e);
                          });
                        }}
                      >
                        <i className="pi pi-pencil icon-p"></i>
                        Copy
                      </a>
                      <NavLink
                        to="#"
                        onClick={(v) => {
                          v.preventDefault();
                          this.setState({ isLoading: true });
                          //this.actionToPerform(rowData, column, "download");
                          let urltoDownload = baseUrl + e.downloadUrl;
                          api
                            .get(urltoDownload, {
                              responseType: "blob",
                            })
                            .then((res) => {
                              this.setState({ isLoading: false }, () => {
                                FileDownload(res.data, `${e.name}`);
                              });
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }}
                        className="dropdown-item"
                      >
                        <i className="pi pi-cloud-download icon-p"></i>
                        Download
                      </NavLink>
                    </>
                  )}
                </div>
              </div>
              <div
                className="m-3 p-3"
                onDoubleClick={() => {
                  this.setState({ object: e }, () => {
                    this.onDoubleClick(this.state.object);
                  });
                }}
              >
                {/* <Link to="/device-outline"> */}
                <img
                  src={
                    `${process.env.PUBLIC_URL}/data/icons/` +
                    (e.contentType === 0 ? "folder" : "file") +
                    ".png"
                  }
                  alt={e.name}
                  width="60px"
                  height="60px"
                  style={{}}
                  className="mx-auto d-block"
                />
                {/* </Link> */}
              </div>
              <div className="small-box-footer2">{e.name}</div>
              {/* <InfiniteScroll
                dataLength={this.state.totalRecords}
                next={this.callgetContents()}
                hasMore={true}
                loader={<h4>Loading...</h4>}
                height={400}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>End</b>
                  </p>
                }
              >
                
              </InfiniteScroll> */}
            </div>
          </div>
        );
      });

    const listView =
      intViewData !== null &&
      intViewData !== undefined &&
      Array.isArray(intViewData) &&
      intViewData.map((e, i) => {
        return (
          <div className="p-col-12" key={i}>
            <div className="props-details">
              <div>
                <div className="p-grid">
                  <div className="p-col-12 effect1">
                    <div
                      className="p-col-6"
                      style={{ display: "inline-block" }}
                    >
                      <div
                        style={{ width: "15%", display: "inline-block" }}
                        onDoubleClick={() => {
                          this.setState({ object: e }, () => {
                            this.onDoubleClick(this.state.object);
                          });
                        }}
                      >
                        <img
                          src={
                            `${process.env.PUBLIC_URL}/data/icons/` +
                            (e.contentType === 0 ? "folder" : "file") +
                            ".png"
                          }
                          alt={e.name}
                          width="30px"
                          height="30px"
                          style={{}}
                          className=""
                        />
                      </div>
                      <div style={{ width: "85%", display: "inline-block" }}>
                        <div className="mx-auto d-block">
                          <span>
                            <b>{e.name}</b>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="p-col-2"
                      style={{ display: "inline-block" }}
                    >
                      <span className="mx-auto d-block">{e.creationDate}</span>
                    </div>
                    <div
                      className="p-col-2"
                      style={{ display: "inline-block" }}
                    >
                      <span className="mx-auto d-block">{e.fileSize}</span>
                    </div>
                    <div
                      className="p-col-2"
                      style={{ display: "inline-block", textAlign: "center" }}
                    >
                      <i
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        className="pi pi-ellipsis-v ab"
                      >
                        <span className="sr-only">Toggle Dropdown</span>
                      </i>
                      <div className="dropdown-menu">
                        {e.contentType === 0 ? (
                          <>
                            {/* <a className="dropdown-item" href="#"> */}
                            {/* <i className="pi pi-user-plus icon-p"></i> */}
                            <span
                              className="cus-assign"
                              style={{ display: "block" }}
                            >
                              <Assign
                                name={`Assign For Folder ${e.name}`}
                                showIcon={true}
                                fromModule="contentPush"
                                dataToSend={e}
                                actionType={ActionAssign.ASSIGN}
                                style={{ display: "block" }}
                              />
                            </span>

                            <span
                              className="cus-assign"
                              style={{ display: "block" }}
                            >
                              <Assign
                                name={`Un-assign For File ${e.name}`}
                                showIcon={true}
                                fromModule="contentPush"
                                dataToSend={e}
                                actionType={ActionAssign.UNASSIGN}
                              />
                            </span>

                            {/* </a> */}
                            <div
                              role="separator"
                              className="dropdown-divider"
                            ></div>
                            {Array.isArray(this.state.breadcumLineUp) &&
                            this.state.breadcumLineUp !== undefined &&
                            this.state.breadcumLineUp.length > 0 ? (
                              <Link
                                className="dropdown-item"
                                onClick={() => {
                                  this.upFolder(e);
                                }}
                              >
                                <i className="pi pi-backward icon-p"></i>
                                Back
                              </Link>
                            ) : (
                              ""
                            )}

                            <Link
                              className="dropdown-item"
                              onClick={() => {
                                this.setState({ type: "Rename" }, () => {
                                  this.onClick("visible", "", e);
                                });
                              }}
                            >
                              <i className="pi pi-pencil icon-p"></i>
                              Rename
                            </Link>
                            <Link
                              className="dropdown-item"
                              onClick={() => {
                                this.setState({ type: "Delete" }, () => {
                                  this.onClick("visible", "", e);
                                });
                              }}
                            >
                              <i className="pi pi-trash icon-p"></i>
                              Delete
                            </Link>
                          </>
                        ) : (
                          <>
                            {/* <a className="dropdown-item" href="#"> */}
                            {/* <i className="pi pi-user-plus icon-p"></i> */}
                            <span style={{ display: "block" }}>
                              <Assign
                                name={`Assign For File ${e.name}`}
                                showIcon={true}
                                fromModule="contentPush"
                                dataToSend={e}
                                actionType={ActionAssign.ASSIGN}
                              />
                            </span>
                            <span>
                              <Assign
                                name={`Un-assign For File ${e.name}`}
                                showIcon={true}
                                fromModule="contentPush"
                                dataToSend={e}
                                actionType={ActionAssign.UNASSIGN}
                              />
                            </span>

                            {/* </a> */}
                            <div
                              role="separator"
                              className="dropdown-divider"
                            ></div>
                            <Link
                              className="dropdown-item"
                              onClick={() => {
                                this.setState({ type: "Rename" }, () => {
                                  this.onClick("visible", "", e);
                                });
                              }}
                            >
                              <i className="pi pi-pencil icon-p"></i>
                              Rename
                            </Link>
                            <Link
                              className="dropdown-item"
                              onClick={() => {
                                this.setState({ type: "Delete" }, () => {
                                  this.onClick("visible", "", e);
                                });
                              }}
                            >
                              <i className="pi pi-trash icon-p"></i>
                              Delete
                            </Link>
                            {Array.isArray(this.state.breadcumLineUp) &&
                            this.state.breadcumLineUp !== undefined &&
                            this.state.breadcumLineUp.length > 0 ? (
                              <Link
                                className="dropdown-item"
                                onClick={() => {
                                  this.upFolder(e);
                                }}
                              >
                                <i className="pi pi-backward icon-p"></i>
                                Back
                              </Link>
                            ) : (
                              ""
                            )}
                            <Link
                              className="dropdown-item"
                              onClick={() => {
                                this.setState({ type: "Move" }, () => {
                                  this.onClick("visible", "", e);
                                });
                              }}
                            >
                              <i className="pi pi-external-link icon-p"></i>
                              Move
                            </Link>
                            <Link
                              className="dropdown-item"
                              onClick={() => {
                                this.setState({ type: "Copy" }, () => {
                                  this.onClick("visible", "", e);
                                });
                              }}
                            >
                              <i className="pi pi-pencil icon-p"></i>
                              Copy
                            </Link>
                            <NavLink
                              to="#"
                              className="dropdown-item"
                              onClick={(v) => {
                                v.preventDefault();
                                this.setState({ isLoading: true });
                                //this.actionToPerform(rowData, column, "download");
                                let urltoDownload = baseUrl + e.downloadUrl;
                                api
                                  .get(urltoDownload, {
                                    responseType: "blob",
                                  })
                                  .then((res) => {
                                    this.setState({ isLoading: false }, () => {
                                      FileDownload(res.data, `${e.name}`);
                                    });
                                    // FileDownload(res.data, `${e.name}`);
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                  });
                              }}
                            >
                              <i className="pi pi-cloud-download icon-p"></i>
                              Download
                            </NavLink>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    const showGridOrList =
      this.state.gridView === true ? (
        gridView
      ) : (
        <>
          <ListHeader /> {listView}
        </>
      );
    // const ff = [{ label: "folder1" }];
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 p-ll">
          <div className="p-col-9 inline-b p-ll">
            <BreadCrumb model={items} home={home} />
          </div>
          <div className="p-col-3 inline-b p-zz">
            <img
              src={helpIcon}
              alt="Help"
              width="20"
              height="20"
              onClick={(e) => this.op.toggle(e)}
              style={{
                marginBottom: "15px",
                float: "right",
                cursor: "pointer",
              }}
            />
          </div>
          <OverlayPanel
            ref={(el) => (this.op = el)}
            id="overlay_panel"
            showCloseIcon={true}
          >
            <div
              className="col"
              style={{
                width: "fit-content",
                maxWidth: "300px",
                padding: "0px",
              }}
            >
              <div className="helptip-title">
                <b>How to : Content Push</b>
              </div>
              <div className="helptip-des">
                Users can maintain different contents through files/folder and
                push those files/folders to groups/devices.
                <br /> <br />
                To view those files, devices must have Commlink File-Manager app
                installed which users can find into Commlink App Store
              </div>
            </div>
          </OverlayPanel>
        </div>

        <div className="p-col-12 bg-col p-lg-12 ">
          <HeaderContent
            layoutHandler={this.layoutHandler}
            indexval={this.state.gridView}
            valueChanged={this.valueChanged}
            newFolderH={this.newFolderHandler}
            newFileH={this.newFileHandler}
          />
          <div style={{ background: "#108f3d", paddingLeft: "15px" }}>
            <BreadCrumb
              model={this.state.breadcumLineUp}
              home={root}
              onClick={this.breadCumbUp}
              style={{ margin: "0px", color: "#000000" }}
            />
          </div>

          <div className="p-col">{showGridOrList}</div>
          <Paginator
            first={this.state.dfirst}
            rows={this.state.eventrows}
            totalRecords={this.state.totalRecords}
            rowsPerPageOptions={[6, 12, 20, 30, 50, 100]}
            onPageChange={this.onPageChange}
          ></Paginator>
        </div>
        <Dialog
          header={this.textOfType(this.state.type)}
          visible={this.state.visible}
          style={{ width: "30vw" }}
          onHide={() => this.onHide("visible")}
          footer={
            this.state.type === "newFile" ? "" : this.renderFooter("visible")
          }
        >
          {this.renderPopUp()}
        </Dialog>
        <Dialog
          visible={this.state.isLoading}
          modal
          style={{ width: "20vw" }}
          closable={false}
          showHeader={false}
          onHide={this.onDialogueHide}
        >
          <Loader textToShow={this.state.textToShow} />
        </Dialog>
        <Dialog
          visible={this.state.isSuccess}
          modal={true}
          style={{ width: "25vw" }}
          closable
          showHeader
          onHide={this.onDialogueHideSuccess}
        >
          <SuccessDialog successMessage="Updated Successfully" />
        </Dialog>
      </div>
    );
  }
}
