import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { AllData } from "../service/AllData";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import ErrorDialog from "../components/ErrorDialog";
import NewWhiteList from "../components/NewWhiteList";
import SuccessDialog from "../components/SuccessDialog";
import { confirmAlert } from "react-confirm-alert";
import Loader from "../components/Loader";
import { OverlayPanel } from "primereact/overlaypanel";
import api from "../service/ApiService";
import { baseUrl } from "../service/ApiUrls";
import { ActionAssign } from "../Enums";
import Assign from "../components/Assign";
import { InputSwitch } from "primereact/inputswitch";
import { cbrowserSettingsUrl } from "../service/ApiUrls";

const helpIcon = require("../assets/icons/help_icon.png");

export class WebsiteListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whitelists: null,
      currentPage: 1,
      displayBasic: false,
      isEdit: false,
      rowData: null,
      custMsg: "",
      dfirst: 0,
      drows: 20,
      enable: false,
      isEnable: false,
      browserSettingsId: "",
    };
    this.alldata = new AllData();
  }

  callGetBrowserSettingsApi = async () => {
    this.setState({ isLoading: true }, () => {
      api.get(cbrowserSettingsUrl).then((res) => {
        console.log(res.data);
        if (res !== undefined && res.data.result.isError === false) {
          this.setState({
            isLoading: false,
            browserSettingsId: res.data.data.id,
            enable: res.data.data.isEnableListing,
          });

          this.callWebListApi();
        } else {
          this.setState({ isLoading: false }, () => {
            confirmAlert({
              title: "Error Message",
              message: <p className="mod-p">{res.data.result.errorMsg}</p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {},
                },
              ],
            });
          });
        }
      });
    });
  };

  callEnableBarrierApi = () => {
    this.setState({ isLoading: true }, () => {
      let data = { isEnableListing: this.state.enable };
      api
        .put(cbrowserSettingsUrl + "/" + this.state.browserSettingsId, data)
        .then((res) => {
          if (res !== undefined && res.data.result.isError === false) {
            this.setState({
              isLoading: false,
            });
          } else {
            this.setState(
              { isLoading: false, enable: !this.state.enable },
              () => {
                confirmAlert({
                  title: "Error Message",
                  message: <p className="mod-p">{res.data.result.errorMsg}</p>,
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => {},
                    },
                  ],
                });
              }
            );
          }
        });
    });
  };

  callWebListApi = ({ first = 0, limit = 10, filter } = {}) => {
    this.setState({ isLoading: true }, () => {
      api
        .get(baseUrl + "/browsing-barrier/website-listing", {
          params: { first, limit, filter },
        })
        .then((res) => {
          console.log(res.data.data);
          if (res.data.result.isError === false) {
            this.setState({
              isLoading: false,
              whitelists: res.data.data.siteList.content,
              ...res.data.data.siteList,
              ...res.data.data,
            });
          }
        });
    });
  };

  onDialogueHide = () => {
    this.setState({ isError: false });
  };
  onDialogueEdit = () => {
    this.setState({
      displayBasic: false,
      rowData: null,
      isEdit: false,
      errorStatus: false,
    });
  };
  custMsgs = (custMsg) => {
    this.setState({ custMsg: custMsg.errorMsg, errorStatus: custMsg.isError });
  };

  onClick(name, isEdit, position) {
    let state = {
      [`${name}`]: true,
      [`${isEdit}`]: true,
    };

    if (position) {
      state = {
        ...state,
        position,
      };
    }

    this.setState(state);
  }

  componentDidMount = () => {
    this.callGetBrowserSettingsApi();
    //this.callWebListApi();
  };

  onHide(name) {
    this.setState({
      [`${name}`]: false,
      isEdit: false,
      rowData: null,
      errorStatus: false,
    });
  }
  renderFooter(name) {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => this.onHide(name)}
          className="p-button-text"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={() => this.onHide(name)}
          autoFocus
        />
      </div>
    );
  }
  componentDidMount() {
    // this.alldata
    //   .getWhiteList()
    //   .then((data) => this.setState({ whitelists: data }));
  }
  onDialogueHideSuccess = () => {
    this.setState({ isSuccess: false });
  };

  onPage = (event) => {
    //console.log("Event", event);

    setTimeout(() => {
      const { first, rows, page } = event;

      this.setState(
        {
          dfirst: first,
          currentPage: page + 1,
          drows: rows,
          page,
        },
        () => {
          this.callWebListApi({
            first: this.state.page,
            limit: this.state.drows,
          });
        }
      );
    }, 500);
  };

  actionTemplate(rowData, column) {
    console.log("rowData", rowData);
    return (
      <div>
        <i
          className="pi pi-pencil icon-p"
          onClick={() => {
            this.setState({
              rowData: rowData,
            });
            this.onClick("displayBasic", "isEdit");

            // this.props.history.push({
            //   // pathname: "/new-role",
            //   state: {
            //     rowData: rowData,
            //     stateData: this.state,
            //     isEdit: true,
            //   },
            // });
          }}
          style={{ fontSize: "1.1em", color: "#000000", paddingRight: "5px" }}
        ></i>
        <i
          className="pi pi-trash icon-p"
          onClick={() => {
            // console.log("onClick", rowData);
            this.setState({ visible: true, rowData: rowData });
          }}
          style={{ fontSize: "1.1em", color: "#000000", paddingLeft: "5px" }}
        ></i>
        <Assign
          name={`Assign for ${rowData.name}`}
          fromModule="website-listing"
          dataToSend={rowData}
          actionType={ActionAssign.ASSIGN}
          // versionCall="version-list"
        />
        <Assign
          name={`Un-Assign for ${rowData.name}`}
          fromModule="website-listing"
          dataToSend={rowData}
          actionType={ActionAssign.UNASSIGN}
          // versionCall="version-list"
        />
      </div>
    );
  }
  onBody = (rowData, column) => {
    const property = column.field;
    const getKeyByValue = (obj, value) =>
      Object.keys(obj).find((key) => obj[key] === value);
    return rowData.hasOwnProperty(property) &&
      property === "isEnableShortcut" &&
      rowData[property] === true
      ? "true"
      : "false";
  };
  rowDelete = (deleteObject) => {
    api
      .delete(baseUrl + "/browsing-barrier/website-listing/" + deleteObject.id)
      .then((res) => {
        if (res.data.result.isError === false) {
          console.log("Error ommit", res.data.result);
          this.setState({ isLoading: false }, () => {
            confirmAlert({
              title: "Success Message",
              message: <p className="mod-sp">Deleted Whitelist Successfully</p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {},
                },
              ],
            });
          });
          this.callWebListApi();
        } else {
          this.setState({ isLoading: false }, () => {
            confirmAlert({
              title: "Error Message",
              message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    // this.webcallGetUSerList();
                  },
                },
              ],
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: " Browsing Barrier", url: "website-listing" },
      { label: "Website Listing" },
    ];
    const enablefooter = (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ isEnable: false, enable: !this.state.enable });
          }}
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-success"
          onClick={(e) => {
            this.setState({ isEnable: false, isLoading: false }, () => {
              this.callEnableBarrierApi();
            });
          }}
        />
      </div>
    );
    const enableDialogue = (
      <Dialog
        header={
          this.state.enable === true
            ? "Enable Browsing Barrier"
            : "Disable Browsing Barrier"
        }
        visible={this.state.isEnable}
        style={{ width: "35vw" }}
        modal={true}
        footer={enablefooter}
        onHide={() => this.setState({ isEnable: false })}
      >
        <p
          style={{
            fontWeight: "bold",
            paddingBottom: "10px",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          <p>
            Do you want to {this.state.enable === true ? "Enable" : "Disable"}{" "}
            Browsing Barrier ?
          </p>
        </p>
      </Dialog>
    );
    const header = (
      <>
        <div id="cus-p" className="row justify-content-start" hidden>
          <div
            className="p-col-12 col-md-4 col-lg-4 c-p"
            style={{ textAlign: "left" }}
          >
            <h2>Enable Browsing Barrier:</h2>
          </div>
          <div
            className="p-col-12 col-md-4 col-lg-4 c-p"
            style={{ textAlign: "left" }}
          >
            <InputSwitch
              checked={this.state.enable}
              onChange={(e) =>
                this.setState({ enable: e.value, isEnable: true })
              }
            />
          </div>
        </div>
        <div id="cus-p" className="row justify-content-start">
          <div
            id="c-p"
            className="p-col-12 col-md-5 col-lg-5 c-p"
            style={{ textAlign: "left" }}
          >
            <h2>Whitelists Lists</h2>
          </div>
          <div
            id="c-p"
            className="p-col-12 col-md-4 col-lg-4 c-p"
            style={{ textAlign: "right" }}
          >
            <div id="search" className="p-inputgroup">
              <InputText
                placeholder="Enter Search Item"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.setState({ textfilter: e.target.value }, () => {
                      this.callWebListApi({ filter: this.state.textfilter });
                    });
                  }
                }}
                onChange={(e) => {
                  this.setState({ textfilter: e.target.value }, () => {
                    // this.callWebListApi({ filter: this.state.textfilter });
                  });
                }}
              />
              <Button
                icon="pi pi-search"
                className="p-button-primary s-icon"
                onClick={(e) => {
                  this.callWebListApi({ filter: this.state.textfilter });
                }}
              />
            </div>
          </div>
          <div
            className="p-col-12 col-md-3 col-lg-3 c-p"
            style={{ textAlign: "right" }}
          >
            <button
              className="btn5"
              type="button"
              onClick={() => this.onClick("displayBasic")}
            >
              + New
            </button>
          </div>
        </div>
      </>
    );
    const footer = (
      <div>
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-success"
          onClick={() => {
            this.setState({ visible: false, isLoading: true });
            this.rowDelete(this.state.rowData);
          }}
        />
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ visible: false });
          }}
        />
      </div>
    );
    const deleteDialogue = (
      <Dialog
        header="Delete Whitelist"
        visible={this.state.visible}
        style={{ width: "35vw" }}
        modal={true}
        footer={footer}
        onHide={() => this.setState({ visible: false })}
      >
        <p
          style={{
            fontWeight: "bold",
            paddingBottom: "10px",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          Do you want to Delete This Whitelist?
        </p>
      </Dialog>
    );
    const home = { icon: "pi pi-home", url: "dashboard" };
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 p-ll">
          <div className="p-col-9 inline-b p-ll">
            <BreadCrumb model={items} home={home} />
          </div>
          {/* <div className="p-col-3 inline-b p-zz">
            <img
              src={helpIcon}
              width="20"
              height="20"
              onClick={(e) => this.op.toggle(e)}
              style={{
                marginBottom: "-10px",
                float: "right",
                cursor: "pointer",
              }}
            />
          </div> */}
          <OverlayPanel
            ref={(el) => (this.op = el)}
            id="overlay_panel"
            showCloseIcon={true}
          >
            <div
              className="col"
              style={{
                width: "fit-content",
                maxWidth: "300px",
                padding: "0px",
              }}
            >
              <div className="helptip-title">
                <b>How to : Device Group</b>
              </div>
              <div className="helptip-des">
                whitelists have option to manage devices by group rather than
                individual. One device belongs to only one group and each group
                must have a device profile. Once devices are bound to a group,
                the device profile of that group are automatically applied on
                those devices.
              </div>
            </div>
          </OverlayPanel>
        </div>
        {deleteDialogue}
        <Dialog
          visible={this.state.isLoading}
          modal={true}
          closable={false}
          showHeader={false}
          style={{ width: "20vw" }}
          onHide={this.onDialogueHide}
        >
          <Loader />
        </Dialog>
        <Dialog
          visible={this.state.isSuccess}
          modal={true}
          style={{ width: "25vw" }}
          closable
          showHeader
          onHide={this.onDialogueHideSuccess}
        >
          <SuccessDialog successMessage="Deleted Group Successfully" />
        </Dialog>
        <div className="p-grid bg-col p-lg-12">
          <div className="p-col">
            <div className="p-grid bg-col p-lg-12">
              {/* <div className="p-col">
                <h2>Whitelists List</h2>
              </div> */}
              <div className="datatable-paginator-demo">
                <DataTable
                  value={this.state.whitelists}
                  style={{ marginBottom: "20px" }}
                  responsive={true}
                  header={header}
                  editMode="row"
                  paginator
                  emptyMessage="No records found"
                  totalRecords={this.state.totalRecords}
                  onPage={this.onPage}
                  first={this.state.dfirst}
                  currentPage={this.state.currentPage}
                  lazy
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  rows={this.state.drows}
                  rowsPerPageOptions={[10, 20, 30, 50, 100]}
                >
                  <Column
                    field="name"
                    style={{ textAlign: "center" }}
                    header="Name"
                  />
                  <Column
                    field="baseURL"
                    header="Base Url"
                    style={{ textAlign: "center", wordBreak: "break-word" }}
                  />
                  <Column
                    field="isEnableShortcut"
                    header="Shortcut"
                    style={{ textAlign: "center", width: "25" }}
                    body={this.onBody}
                  />
                  <Column
                    header="Action"
                    style={{
                      textAlign: "center",
                      color: "#000000",
                      width: "20%",
                    }}
                    body={this.actionTemplate.bind(this)}
                  ></Column>
                </DataTable>
              </div>
            </div>
            <Dialog
              header={this.state.isEdit ? "Edit Website" : "Add New Website"}
              visible={this.state.displayBasic}
              style={{ width: "35vw" }}
              onHide={() => this.onHide("displayBasic")}
            >
              <div
                className={
                  this.state.errorStatus === true
                    ? "alert alert-danger alert-dismissible fade show"
                    : "d-none"
                }
                role="alert"
              >
                {this.state.custMsg}
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <NewWhiteList
                callWebListApi={this.callWebListApi}
                isEdit={this.state.isEdit}
                rowData={this.state.rowData}
                action={this.onDialogueEdit}
                custMsgs={this.custMsgs}
              />
            </Dialog>
            {enableDialogue}
            <Dialog
              visible={this.state.isError}
              modal
              style={{ width: "20vw" }}
              closable
              showHeader
              onHide={this.onDialogueHide}
            >
              <ErrorDialog errorMessage={this.state.errorMessage} />
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}
