import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { AppMap } from "../components/AppMap";
import Timeline from "react-time-line";
import ErrorDialog from "../components/ErrorDialog";
import { Dialog } from "primereact/dialog";
import { getLocationHistory } from "../service/ApiUrls";
import api from "../service/ApiService";
import NewDate from "../components/NewDate";
import { LOCATIONZOOM } from "../Enums";
import Loader from "../components/Loader";
import { confirmAlert } from "react-confirm-alert";
let events = [
  { ts: "2017-09-17T12:22:46.587Z", text: "Gulshan 1, Dhaka, Bangladesh" },
  { ts: "2017-09-17T12:21:46.587Z", text: "Gulshan 2, Dhaka, Bangladesh" },
  { ts: "2017-09-17T12:20:46.587Z", text: "Rampura, Dhaka, Bangladesh" },
];
const search_not = require("../assets/search-not.png");
const FileDownload = require("js-file-download");

export class LocationHistory extends Component {
  constructor(props) {
    super(props);
    //console.log("detail in history ", this.props.history.location.state);
    this.state = {
      overlays: null,
      circles: null,
      ...this.props.history.location.state.detail,
      imei: this.props.history.location.state.imei,
      isError: true,
      errorMsg: "No Location Found",
      errorMessage: "Something went wrong!",
      isLoading: false,
    };
    let toDate = new Date();
    toDate = this.convertDate(toDate).split("/").join("-");
    this.callgetEvents({ start: toDate, end: toDate });
  }

  componentWillMount = () => {
    let toDate = new Date();
    toDate = this.convertDate(toDate).split("/").join("-");
    this.callgetEvents({ start: toDate, end: toDate });
  };

  formatDate = (date, format) => {
    const map = {
      mm: date.getMonth() + 1,
      dd: date.getDate(),
      yy: date.getFullYear().toString().slice(-2),
      yyyy: date.getFullYear(),
    };

    return format.replace(/mm|dd|yyyy/gi, (matched) => map[matched]);
  };

  lastXDate = (days) => {
    let now = new Date();
    let lastXdays = new Date(now.setDate(now.getDate() - days));
    lastXdays = this.formatDate(lastXdays, "dd-mm-yyyy");
    return lastXdays;
  };

  convertDate = (date) => {
    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    var today = new Date(date);
    //console.log(today.toLocaleDateString("en-US", options));

    // console.log(today.toLocaleDateString("en-US"));
    return today.toLocaleDateString("en-GB");
  };

  onDialogueHide = () => {
    this.setState({ isLoading: false });
  };

  callgetEvents = ({ start = "05-06-2020", end = "06-06-2020" } = {}) => {
    this.setState({ isLoading: true }, () => {
      // console.log("imei", this.state.imei);
      api
        .get(getLocationHistory + "/" + this.state.imei, {
          params: { start: start, end: end },
        })
        .then((res) => {
          if (res.data.result.isError === false) {
            this.setState({ ...res.data.data, isLoading: false }, () => {
              events = [];
              let overlays = [];
              this.state.content !== undefined &&
                this.state.content !== null &&
                this.state.content.map((v, i) => {
                  v.locationDetils !== undefined &&
                    v.locationDetils !== null &&
                    v.locationDetils.map((e, i) => {
                      if (
                        e.hasOwnProperty("updateTime") &&
                        e.hasOwnProperty("address")
                      ) {
                        let token = e.updateTime.split(" ");
                        let date = token[0].split("-");
                        // let splittedTime = token[1].split(":");
                        // let first = Number(splittedTime[0]) % 24;
                        // let second = splittedTime[1];
                        // let third = splittedTime[2];
                        //let tokenFor = first + ":" + second + ":" + third;
                        let conv =
                          date[2] +
                          "-" +
                          date[1] +
                          "-" +
                          date[0] +
                          "T" +
                          token[1] +
                          "+0600";
                        events.push({ ts: conv, text: e.address });
                        // console.log("Date Time" + conv);
                      }
                      let currloc = e;
                      let currentlocation = {
                        position: {
                          lat: Number(currloc.latitude + "" + i),
                          lng: Number(currloc.longitude + "" + i),
                        },
                        title: currloc.address,
                      };
                      overlays.push(currentlocation);
                    });
                  this.setState({
                    events: events,
                    overlays: overlays,
                    isError: false,
                  });
                });
            });
            this.getLocationHistoryReportByIMEI({ start: start, end: end });
          } else if (res.data.result.isError) {
            this.setState(
              {
                isLoading: false,
              },
              () => {
                confirmAlert({
                  title: "Error Message",
                  message: (
                    <p className="mod-p"> {res.data.result.errorMsg} </p>
                  ),
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => {},
                    },
                  ],
                });
              }
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  getLocationHistoryReportByIMEI = ({ start, end }) => {
    if (null === end) {
      let toDate = new Date();
      end = toDate = this.convertDate(toDate).split("/").join("-");
    }
    if (null === start) {
      if (typeof end === "string") {
        var dateParts = end.split("-");

        // month is 0-based, that's why we need dataParts[1] - 1
        var dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
        var temp = dateObject.setDate(dateObject.getDate() - 30);
        start = this.convertDate(temp).split("/").join("-");
      } else {
        start = end.setDate(end.getDate() - 30);
      }
    }

    api
      .get(getLocationHistory + "/" + this.state.imei + "/export", {
        responseType: "blob",
        params: { start: start, end: end },
      })
      .then((res) => {
        const fileName = res.headers["content-disposition"].split(
          "filename="
        )[1];
        FileDownload(res.data, fileName);
      })
      .catch((err) => {});
  };

  componentDidMount() {
    // this.callgetEvents();
  }
  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Location", url: "location" },
      { label: "Location Search", url: "location-search" },
      { label: "Device Timeline" },
    ];
    const options = {
      center: { lat: 23.797911, lng: 90.414391 },
      zoom: LOCATIONZOOM.ZOOM,
    };

    const home = { icon: "pi pi-home", url: "/" };
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 ">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>

        <div className="p-col-12" style={{ padding: "5px 20px" }}>
          <div id="cus-padding" className="p-grid bg-col">
            <div className="p-col-12">
              <h2 style={{ paddingLeft: "12px" }}>
                Device Timeline & Location History |
                <span>IMEI : {this.state.imei}</span>
              </h2>
            </div>
            <div className="cus-padding p-col-12 p-md-5 p-xl-5 p-sm-6">
              <div
                className="box"
                style={{
                  border: "1px solid #F5F5F5",
                  borderRadius: "5px",
                  padding: "6px",
                  background: "#F5F5F5",
                  marginTop: "5px",
                }}
              >
                <div
                  className="col-lg-12"
                  style={{
                    marginBottom: "10px",
                    borderBottom: "1px solid #9e9e9e",
                    paddingBottom: "10px",
                  }}
                >
                  <NewDate callgetEvents={this.callgetEvents} />
                </div>
                <div
                  className="col-lg-12"
                  style={{ marginBottom: "10px", background: "F5F5F5" }}
                >
                  {!this.state.isError && this.state.totalRecords !== 0 ? (
                    <Timeline items={events} format="HH:mm" />
                  ) : (
                    // <h1 style={{ textAlign: "center" }}>"No data"</h1>
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="p-col-12 p-md-7 p-sm-6">
              <div className="box" style={{}}>
                <div className="p-col-12">
                  {!this.state.isError && this.state.totalRecords !== 0 ? (
                    <AppMap
                      options={this.state.options}
                      overlays={this.state.overlays}
                      style={{ width: "100%", minHeight: "375px" }}
                    ></AppMap>
                  ) : (
                    <>
                      <img
                        src={search_not}
                        width="180px"
                        style={{}}
                        className="mx-auto d-block"
                      />
                      <h2 style={{ textAlign: "center" }}>
                        {this.state.errorMsg}
                      </h2>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Dialog
            visible={this.state.isLoading}
            modal
            style={{ width: "20vw" }}
            closable={false}
            showHeader={false}
            onHide={this.onDialogueHide}
          >
            <Loader />
          </Dialog>
        </div>
      </div>
    );
  }
}
