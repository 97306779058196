import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import { AllData } from "../service/AllData";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import api from "../service/ApiService";
import { Dialog } from "primereact/dialog";
import ErrorDialog from "../components/ErrorDialog";
import SuccessDialog from "../components/SuccessDialog";
import Loader from "../components/Loader";
import { TreeTable } from "primereact/treetable";
import { Checkbox } from "primereact/checkbox";
import { confirmAlert } from "react-confirm-alert";
import {
  getGroupListUrl,
  getGroupListDevice,
  getDeviceSearchData,
  baseUrl,
} from "../service/ApiUrls";
import { OverlayPanel } from "primereact/overlaypanel";
const helpIcon = require("../assets/icons/help_icon.png");

export class NewRoles extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      nodes: [],
      selectedNodeKeys3: [],
      checked: false,
    };
    this.alldata = new AllData();
    this.rowClassName = this.rowClassName.bind(this);
  }

  rowClassName(node) {
    this.state.nodes.map((e, i) => {
      // console.log(e, e.order);
      if (Number(e.order) % 2 === 0) {
        e["className"] = "even-col";
      } else {
        e["className"] = "odd-col";
      }
      if (e.children) {
        e.children.map((v, j) => {
          v["className"] = e["className"];
        });
      }
    });
  }

  onDialogueHide = () => {
    this.setState({ isError: false });
  };

  convertNodeToFeatureList = () => {
    let featureList = [];

    this.state.nodes.map((e, i) => {
      let row = { ...e.data.dataObject };
      if (e.children === null) {
        row["subFeatureList"] = null;
      } else {
        let subrows = [];
        e.children.map((w, k) => {
          let subrow = { ...w.data.dataObject };
          subrows.push(subrow);
        });
        row["subFeatureList"] = subrows;
      }
      featureList.push(row);
    });
    this.setState({ featureList: featureList });
  };

  convertFeatureListToNode = () => {
    let nodes = [];

    this.state.featureList.map((e, v) => {
      let row = {
        key: e.feature.id,
        id: e.feature.id,
        order: e.order,
        data: { name: e.feature.title, dataObject: { ...e } },
        access: e.isReadable,
        write: e.isWritable,
      };
      if (e.subFeatureList === null) {
        row["children"] = null;
      } else {
        let subRows = [];
        e.subFeatureList.map((w, z) => {
          let subRow = {
            key: w.feature.id,
            id: w.feature.id,
            order: w.order,
            data: { name: w.feature.title, dataObject: { ...w } },
            access: w.isReadable,
            write: w.isWritable,
          };
          subRows.push(subRow);
        });
        row["children"] = subRows;
      }
      nodes.push(row);
    });
    this.setState({ nodes: nodes });
  };

  webcallGetFeatureList = () => {
    this.setState({ isLoading: true }, () => {
      let id =
        this.props.location.state.rowData !== undefined
          ? this.props.location.state.rowData.id
          : null;
      let url =
        this.props.location.state.isEdit === true
          ? baseUrl + "/role-list/" + id
          : baseUrl + "/role-list/add";
      api.get(url).then((res) => {
        if (res.data.result.isError === false) {
          if (res.data.data !== null) {
            this.setState(
              {
                isLoading: false,
                ...res.data.data,
                nodes: res.data.data.featureList,
                fullName: res.data.data.name,
              },
              () => {
                this.convertFeatureListToNode();
              }
            );
          }
        }
      });
    });
  };

  componentDidMount() {
    this.webcallGetFeatureList();
    //this.alldata.getRoleList().then((data) => this.setState({ nodes: data }));
  }
  accessAction(node, column) {
    return (
      <div>
        <Checkbox
          inputId={node.id}
          id={node.key}
          checked={node["access"]}
          onChange={(e) => {
            if (Number(e.target.id) === 0) {
              let val = e.target.checked && e.target.id === node.key;
              //console.log("val", val, e.target.id, "node", node);
              node["access"] = val;
              node["write"] = node["access"] === false ? false : node["write"];
              node["data"]["dataObject"]["isReadable"] = val;
              node["data"]["dataObject"]["isWritable"] = node["write"];
              this.state.nodes.map((v, j) => {
                v["access"] = node["access"];
                v["write"] = node["access"] === false ? false : v["write"];
                v["data"]["dataObject"]["isReadable"] = v["access"];
                v["data"]["dataObject"]["isWritable"] = v["write"];
                if (v.children) {
                  v.children.map((w, k) => {
                    w["access"] = v["access"];
                    w["write"] = v["write"];
                    if (w["data"]["dataObject"]["isReadable"] !== null) {
                      w["data"]["dataObject"]["isReadable"] = v["access"];
                      w["data"]["dataObject"]["isReadable"] = v["write"];
                    }
                  });
                }
              });
            } else {
              let val = e.target.checked && e.target.id === node.key;
              // console.log(
              //   "val",
              //   val,
              //   e.target.id,
              //   "node",
              //   node,
              //   node["data"]["dataObject"]["isReadable"]
              // );
              if (e.target.checked === false) {
                this.state.nodes.map((k, l) => {
                  if (Number(k["id"]) === 0) {
                    k["access"] = false;
                    k["write"] = false;
                    k["data"]["dataObject"]["isReadable"] = false;
                    k["data"]["dataObject"]["isWritable"] = false;
                  }
                });
              }
              node["access"] = val;
              node["write"] = node["access"] === false ? false : node["write"];
              node["data"]["dataObject"]["isReadable"] = val;
              node["data"]["dataObject"]["isWritable"] = node["write"];

              if (node.children) {
                node.children.map((v, i) => {
                  console.log("v", v);
                  v["access"] = node["access"];
                  v["write"] = node["write"];
                  if (v["data"]["dataObject"]["isReadable"] !== null) {
                    v["data"]["dataObject"]["isReadable"] = node["access"];
                    v["data"]["dataObject"]["isWritable"] = node["write"];
                  }
                });
              }
              //this.setState({ checked: val });
            }
            // let upDateNodes = this.state.nodes;
            this.convertNodeToFeatureList();
          }}
        />
      </div>
    );
  }
  writeAction(node, column) {
    return (
      <div>
        <Checkbox
          inputId={node.id}
          id={node.key}
          checked={node["write"]}
          onChange={(e) => {
            if (Number(e.target.id) === 0) {
              let val = e.target.checked && e.target.id === node.key;
              //console.log("val", val, e.target.id);

              node["write"] = val;
              node["access"] =
                node["write"] === false ? node["access"] : node["write"];
              node["data"]["dataObject"]["isWritable"] = val;
              this.state.nodes.map((v, j) => {
                v["write"] = node["write"];
                v["access"] = node["access"];
                v["data"]["dataObject"]["isWritable"] = v["write"];
                v["data"]["dataObject"]["isWritable"] = v["access"];
                if (v.children) {
                  v.children.map((w, k) => {
                    w["write"] = v["write"];
                    w["access"] = v["access"];
                    w["data"]["dataObject"]["isWritable"] = w["write"];
                    w["data"]["dataObject"]["isReadable"] = w["access"];
                  });
                }
              });
            } else {
              let val = e.target.checked && e.target.id === node.key;
              // console.log("val", val, e.target.id);

              if (e.target.checked === false) {
                this.state.nodes.map((k, l) => {
                  if (Number(k["id"]) === 0) {
                    k["access"] = k["access"] === true ? true : false;
                    k["write"] = false;
                    k["data"]["dataObject"]["isReadable"] = false;
                    k["data"]["dataObject"]["isWritable"] = false;
                  }
                });
              }
              node["write"] = val;
              node["access"] =
                node["write"] === false ? node["access"] : node["write"];
              node["data"]["dataObject"]["isWritable"] = val;
              node["data"]["dataObject"]["isReadable"] = node["access"];
              if (node.children) {
                node.children.map((v, i) => {
                  v["write"] = node["write"];
                  v["access"] = node["access"];
                  if (v["data"]["dataObject"]["isWritable"] !== null) {
                    v["data"]["dataObject"]["isWritable"] = node["write"];
                    v["data"]["dataObject"]["isReadable"] = node["access"];
                  }
                });
              }
              //this.setState({ checked: val });
            }
            this.convertNodeToFeatureList();
          }}
        />
      </div>
    );
  }

  submitToWeb = () => {
    this.setState({ isLoading: true }, () => {
      let dataToSend = {
        name: this.state.fullName,
        featureList: this.state.featureList,
        isEditable: this.state.isEditable,
        responsibleFor: this.state.responsibleFor,
      };
      console.log("data to send ", dataToSend);
      if (this.props.location.state.isEdit === false) {
        api.post(baseUrl + "/role-list/add", dataToSend).then((res) => {
          if (res.data.result.isError === false) {
            this.setState({ isLoading: false }, () => {
              this.props.history.push("/role-management");
              confirmAlert({
                title: "Success Message",
                message: <p className="mod-sp">Role Added Successfully</p>,
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {},
                  },
                ],
              });
            });
          } else {
            this.setState({ isLoading: false }, () => {
              confirmAlert({
                title: "Error Message",
                message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {
                      // this.webcallGetUSerList();
                    },
                  },
                ],
              });
            });
          }
        });
      } else {
        api
          .put(baseUrl + "/role-list/" + this.state.id, dataToSend)
          .then((res) => {
            if (res.data.result.isError === false) {
              this.setState({ isLoading: false }, () => {
                confirmAlert({
                  title: "Success Message",
                  message: <p className="mod-sp">Role Updated Successfully</p>,
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => {},
                    },
                  ],
                });
                this.props.history.push("/role-management");
              });
            } else {
              console.log("Error", res.data.result.errorMsg);
              this.setState({ isLoading: false }, () => {
                confirmAlert({
                  title: "Error Message",
                  message: (
                    <p className="mod-p"> {res.data.result.errorMsg} </p>
                  ),
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => {
                        // this.webcallGetUSerList();
                      },
                    },
                  ],
                });
              });
            }
          });
      }
    });
  };

  colorAction(node, column) {
    return (
      <div>
        <Checkbox
          inputId="binary"
          checked={this.state.checked}
          onChange={(e) => this.setState({ checked: e.checked })}
        />
      </div>
    );
  }

  actionTemplate(rowData, column) {
    return (
      <div>
        <i
          className="pi pi-pencil icon-p"
          onClick={() => {
            this.props.history.push({
              pathname: "/new-role",
              state: {
                rowData: rowData,
                stateData: this.state,
                isEdit: true,
              },
            });
          }}
          style={{ fontSize: "1.1em", color: "#000000", paddingRight: "5px" }}
        ></i>
        <i
          className="pi pi-trash icon-p"
          onClick={() => {
            // console.log("onClick", rowData);
            this.setState({ visible: true, rowData: rowData });
          }}
          style={{ fontSize: "1.1em", color: "#000000", paddingLeft: "5px" }}
        ></i>
      </div>
    );
  }
  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Home Ground", url: "home-ground" },
      { label: "Role Management", url: "role-management" },
      {
        label:
          this.props.location.state.isEdit === true ? "Edit Role" : "New Role",
      },
    ];

    const home = { icon: "pi pi-home", url: "dashboard" };
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 p-ll">
          <div className="p-col-9 inline-b p-ll">
            <BreadCrumb model={items} home={home} />
          </div>
          <div className="p-col-3 inline-b p-zz">
            {/* <img
              src={helpIcon}
              width="20"
              height="20"
              onClick={(e) => this.op.toggle(e)}
              style={{
                marginBottom: "-10px",
                float: "right",
                cursor: "pointer",
              }}
            /> */}
          </div>
          <OverlayPanel
            ref={(el) => (this.op = el)}
            id="overlay_panel"
            showCloseIcon={true}
          >
            <div
              className="col"
              style={{
                width: "fit-content",
                maxWidth: "300px",
                padding: "0px",
              }}
            >
              <div className="helptip-title">
                <b>How to : Device Group</b>
              </div>
              <div className="helptip-des">
                roles have option to manage devices by group rather than
                individual. One device belongs to only one group and each group
                must have a device profile. Once devices are bound to a group,
                the device profile of that group are automatically applied on
                those devices.
              </div>
            </div>
          </OverlayPanel>
        </div>
        <Dialog
          visible={this.state.isLoading}
          modal={true}
          closable={false}
          showHeader={false}
          style={{ width: "20vw" }}
          onHide={this.onDialogueHide}
        >
          <Loader />
        </Dialog>
        <Dialog
          visible={this.state.isSuccess}
          modal={true}
          style={{ width: "25vw" }}
          closable
          showHeader
          onHide={this.onDialogueHide}
        >
          <SuccessDialog successMessage="Deleted Group Successfully" />
        </Dialog>
        <div className="p-grid bg-col p-lg-12">
          <div className="p-col-12">
            <div className="p-grid bg-col p-lg-12">
              <div className="p-col-12">
                <h3 className="h-b">Edit Role</h3>
              </div>
              <div className="p-col-12 p-md-6 p-lg-6">
                <div className="form-group">
                  <label htmlFor="roleName">Name</label>

                  <input
                    type="text"
                    className="form-control"
                    id="fullName"
                    onChange={(e) => {
                      this.setState({ fullName: e.target.value });
                    }}
                    placeholder="Enter Role Name"
                    value={this.state.fullName}
                  />
                </div>
              </div>
              <div className="p-col-12 p-md-6 p-lg-6">
                <div className="form-group">
                  <label htmlFor="roleName">Responsible For</label>
                  <select
                    value={this.state.responsibleFor}
                    onChange={(e) => {
                      console.log("select e", e.target.value);
                      this.setState({
                        responsibleFor: Number(e.target.value),
                      });
                    }}
                    className="p-col-12 form-control"
                  >
                    {this.state.responsibleTypeMap !== undefined &&
                      Object.keys(this.state.responsibleTypeMap).map((e, k) => {
                        return (
                          <option key={"re-" + k} value={e}>
                            {this.state.responsibleTypeMap[e]}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="datatable-paginator-demo">
                <TreeTable
                  value={this.state.nodes}
                  rowClassName={this.rowClassName}
                >
                  <Column field="name" header="Features" expander></Column>
                  <Column
                    header="Access"
                    style={{
                      textAlign: "center",
                      color: "#000000",
                      width: "20%",
                    }}
                    body={this.accessAction.bind(this)}
                  ></Column>
                  <Column
                    header="Write"
                    style={{
                      textAlign: "center",
                      color: "#000000",
                      width: "20%",
                    }}
                    body={this.writeAction.bind(this)}
                  ></Column>
                </TreeTable>
              </div>
              <div className="p-col-12 mt-4" style={{ textAlign: "center" }}>
                <button
                  className="btn btn-primary mr-3"
                  onClick={() => {
                    this.submitToWeb();
                  }}
                >
                  Submit
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    this.props.history.push("/role-management");
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
            <Dialog
              visible={this.state.isError}
              modal
              style={{ width: "20vw" }}
              closable
              showHeader
              onHide={this.onDialogueHide}
            >
              <ErrorDialog errorMessage={this.state.errorMessage} />
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}
