import React, { Component, Suspense, lazy } from "react";
import classNames from "classnames";
import { AppTopbar } from "./AppTopbar";
// import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppProfile } from "./AppProfile";
import { Route, Switch } from "react-router-dom";
import Spinner from "./components/Spinner";

import { Dashboard } from "./pages/Dashboard";
import { GlobalSetting } from "./pages/GlobalSetting";
import { CreateNewTheme } from "./components/CreateNewTheme";
// import { AppMap } from "./components/AppMap";
import { NewDeviceProfile } from "./components/NewDeviceProfile";
import { Enroll } from "./pages/Enroll";
import { QrRegistration } from "./pages/QrRegistration";
// import ChangePassword from "./pages/ChangePassword";
import { ImeiUpload } from "./pages/ImeiUpload";
import { DeviceOutline } from "./pages/DeviceOutline";
import { Location } from "./pages/Location";
import { LocationSetting } from "./pages/LocationSetting";
import { LocationHistory } from "./pages/LocationHistory";
import { LocationSearch } from "./pages/LocationSearch";
import { LocationTraking } from "./pages/LocationTracking";
import { LocationTracking2 } from "./pages/LocationTracking2";
import { Geofence } from "./pages/Geofence";
import { DeviceGroup } from "./pages/DeviceGroup";
import PushMessageHistory from "./pages/PushMessageHistory";
import PushMessage from "./pages/PushMessage";
import { HomeGround } from "./pages/HomeGround";
import { UserManagement } from "./pages/UserManagement";
import { RoleManagement } from "./pages/RoleManagement";
import { SetupAndroidEnterprise } from "./pages/SetupAndroidEnterprise";
import { RemoteCast } from "./pages/RemoteCast";
import { RemoteConnect } from "./pages/RemoteConnect";
import { BrowsingBarrier } from "./pages/BrowsingBarrier";
import { BrowserSetting } from "./pages/BrowserSetting";
import { WebsiteListing } from "./pages/WebsiteListing";
import Organization from "./pages/Organization";
import { NewRoles } from "./pages/NewRoles";
import NewUsers from "./pages/NewUsers";
import FactoryReset from "./pages/FactoryReset";
import ClearCache from "./pages/ClearCache";
import Lock from "./pages/Lock";
import DeactiveLauncher from "./pages/DeactiveLauncher";
import ClearAppData from "./pages/ClearAppData";
import ClearFiles from "./pages/ClearFiles";
import DeviceTheme from "./pages/DeviceTheme";
import { Purchase } from "./pages/Purchase";
import { MyPlan } from "./pages/MyPlan";
import { FactoryResetProtection } from "./pages/FactoryResetProtection";
import { DeviceProfile } from "./pages/DeviceProfile";
import { EnrollmentHistory } from "./pages/EnrollmentHistory";
import { CreateDeviceGroup } from "./pages/CreateDeviceGroup";
import { EnrollmentSettings } from "./pages/EnrollmentSettings";
import { EntManage } from "./pages/EntManage";
import { DeviceInfo } from "./pages/DeviceInfo";
import { ViewDeviceInfo } from "./pages/ViewDeviceInfo";
import { ContentPush } from "./pages/ContentPush";
import { AppManagement } from "./pages/AppManagement";
import { Reports } from "./pages/Reports";
import AddNewGeofence from "./pages/AddNewGeofence";
import RedirectionEmail from "./pages/RedirectionEmail";
import { PricingPlans } from "./pages/PricingPlans";
import { RemoteControl } from "./pages/RemoteControl";
// import Profile from "./pages/Profile";
import { AssignData } from "./components/AssignData";
// // import LandingPage from "./components/LandingPage/LandingPage";
import "../src/assets/style.css";
import "../src/assets/nova/theme.css";
// import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./layout/layout.scss";
import "./App.scss";
import { ProtectedRoute } from "./ProtectedRoute";
import RemoteVideo from "./pages/RemoteVideo";
import { AppIconSizeEnum } from "./Enums";
import api from "./service/ApiService";
import { baseUrl } from "./service/ApiUrls";
const logo = require("./assets/logo-white.png");

// const AppTopbar = React.lazy(() => import("./AppTopbar"));
// const AppMenu = React.lazy(() => import("./AppMenu"));

const LandingPage = React.lazy(() =>
  import("./components/LandingPage/LandingPage")
);
const AboutUs = React.lazy(() => import("./components/LandingPage/AboutUs"));
const Features = React.lazy(() => import("./components/LandingPage/Features"));
const ContactUs = React.lazy(() =>
  import("./components/LandingPage/ContactUs")
);
const WhyMobionizer = React.lazy(() =>
  import("./components/LandingPage/WhyMobionizer")
);
const PrivacyPolicy = React.lazy(() =>
  import("./components/LandingPage/PrivacyPolicy")
);

const Profile = React.lazy(() => import("./pages/Profile"));
const ChangePassword = React.lazy(() => import("./pages/ChangePassword"));

class SidebarMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layoutMode: "static",
      layoutColorMode: "dark",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      webcallOnce: true,
      menu: [],
    };

    this.onWrapperClick = this.onWrapperClick.bind(this);
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.onSidebarClick = this.onSidebarClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    //this.createMenu();
    this.helpingMenu = [
      {
        label: "Dashboard",
        icon: "pi pi-fw pi-home",
        to: "/dashboard",
      },
      {
        label: "Enroll",
        icon: "pi pi-fw pi-plus",
        to: "/enroll",
      },
      {
        label: "Device Outline",
        icon: "pi pi-fw pi-desktop",
        to: "/device-outline",
      },
      {
        label: "App Management",
        icon: "pi pi-fw pi-sitemap",
        to: "/app-management",
      },
      {
        label: "Device Info",
        icon: "pi pi-fw pi-info-circle",
        to: "/device-info",
      },
      {
        label: "Location",
        icon: "pi pi-fw pi-map-marker",
        to: "/location",
      },
      {
        label: "Content Push",
        icon: "pi pi-fw pi-window-maximize",
        to: "/content-push",
      },
      {
        label: "Global Setting",
        icon: "pi pi-fw pi-globe",
        to: "/global-settings",
      },
      {
        label: "Reports",
        icon: "pi pi-fw pi-chart-line",
        to: "/reports",
      },
      {
        label: "Home Ground",
        icon: "pi pi-fw pi-palette",
        to: "/home-ground",
      },
      {
        label: "Remote Cast",
        icon: "pi pi-fw pi-camera",
        to: "/remote-cast",
      },
      {
        label: "Browsing Barrier",
        icon: "pi pi-fw pi-check-circle",
        to: "/website-listing",
      },
      {
        label: "Pricing & Plans",
        icon: "pi pi-fw pi-dollar",
        to: "/pricing-plan",
      },
      {
        label: "Remote Control",
        icon: "pi pi-fw pi-external-link",
        to: "/remote-control",
      },
    ];
    //this.webcallProfile();
  }

  findObject = (v) => {
    return this.helpingMenu.find((e) => {
      return e.to === v;
    });
  };

  onWrapperClick(event) {
    if (!this.menuClick) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }

    this.menuClick = false;
  }

  onToggleMenu(event) {
    this.menuClick = true;

    if (this.isDesktop()) {
      if (this.state.layoutMode === "overlay") {
        this.setState({
          overlayMenuActive: !this.state.overlayMenuActive,
        });
      } else if (this.state.layoutMode === "static") {
        this.setState({
          staticMenuInactive: !this.state.staticMenuInactive,
        });
      }
    } else {
      const mobileMenuActive = this.state.mobileMenuActive;
      this.setState({
        mobileMenuActive: !mobileMenuActive,
      });
    }

    event.preventDefault();
  }

  onSidebarClick(event) {
    this.menuClick = true;
  }

  onMenuItemClick(event) {
    if (!event.item.items) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }
  }

  findSubFeatureList = (v) => {
    let list = null;
    this.state.allowedFeatureList !== null &&
      this.state.allowedFeatureList !== undefined &&
      this.state.allowedFeatureList.map((e, x) => {
        //console.log("before if ", e.feature.protectedUrl, e.subFeatureList);
        if (e.feature.protectedUrl === v) {
          // console.log("Inside if ", e.feature.protectedUrl, e.subFeatureList);
          list = e.subFeatureList;
        }
      });
    return list;
  };

  webcallProfile = () => {
    api
      .get(baseUrl + "/user-list/my-profile")
      .then((res) => {
        if (res.data.result.isError === false) {
          this.setState({ ...res.data.data, webcallOnce: false }, () => {
            //this.createMenu();
            this.menu = [];

            this.state.allowedFeatureList !== null &&
              this.state.allowedFeatureList.map((e, v) => {
                if (e.feature.category === 0) {
                  let helpingMenu = this.findObject(e.feature.protectedUrl);

                  //console.log("helping menu ", helpingMenu);
                  let icon =
                    helpingMenu !== undefined
                      ? helpingMenu.icon
                      : "pi pi-fw pi-external-link";
                  let oneMenu = {
                    label: e.feature.title,
                    icon: icon,
                    to: e.feature.protectedUrl,
                  };
                  this.menu.push(oneMenu);
                }
              });
            this.setState({ menu: this.menu }, () => {
              localStorage.setItem("fullState", JSON.stringify(this.state));
            });
            //console.log("menu", this.state.menu);
          });
        }
      })
      .catch((err) => {});
  };

  addClass(element, className) {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  }

  removeClass(element, className) {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  componentDidMount() {
    const loggedIn = localStorage.getItem("loggedIn");
    let menu = localStorage.getItem("fullState");

    if (loggedIn === "true" && menu === null) {
      this.webcallProfile();
    } else {
      this.setState({ ...JSON.parse(menu) });
    }
  }
  componentDidUpdate() {
    if (this.state.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  }

  render() {
    const wrapperClass = classNames("layout-wrapper", {
      "layout-overlay": this.state.layoutMode === "overlay",
      "layout-static": this.state.layoutMode === "static",
      "layout-static-sidebar-inactive":
        this.state.staticMenuInactive && this.state.layoutMode === "static",
      "layout-overlay-sidebar-active":
        this.state.overlayMenuActive && this.state.layoutMode === "overlay",
      "layout-mobile-sidebar-active": this.state.mobileMenuActive,
    });

    const sidebarClassName = classNames("layout-sidebar", {
      "layout-sidebar-dark": this.state.layoutColorMode === "dark",
      "layout-sidebar-light": this.state.layoutColorMode === "light",
    });
    const loggedIn = localStorage.getItem("loggedIn");
    const switchRoute = (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route path="/" exact component={LandingPage} />
          <Route path="/login" exact component={LandingPage} />
          <Route path="/about-us" exact component={AboutUs} />
          <Route path="/features" exact component={Features} />
          <Route path="/why-mobionizer" exact component={WhyMobionizer} />
          <Route path="/contact-us" exact component={ContactUs} />
          <Route path="/privacypolicy" exact component={PrivacyPolicy} />
          <ProtectedRoute
            path="/factory-callback"
            exact
            component={RedirectionEmail}
          />
          <ProtectedRoute
            path="/dashboard"
            extraProp={this.state.allowedFeatureList}
            licenseProp={this.state.myLicenseStatus}
            exact
            component={Dashboard}
          />
          <ProtectedRoute
            path="/enroll"
            extraProp={
              this.findSubFeatureList("/enroll") !== null
                ? this.findSubFeatureList("/enroll")
                : null
            }
            component={Enroll}
          />
          <ProtectedRoute
            path="/enrollment"
            component={EnrollmentSettings}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/qr-registration"
            component={QrRegistration}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/imei-upload"
            component={ImeiUpload}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/device-outline"
            extraProp={
              this.findSubFeatureList("/device-outline") !== null
                ? this.findSubFeatureList("/device-outline")
                : null
            }
            component={DeviceOutline}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute path="/device-group" component={DeviceGroup} />
          <ProtectedRoute
            path="/device-profile-list"
            component={DeviceProfile}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/create_device_group"
            component={CreateDeviceGroup}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/device-theme"
            component={DeviceTheme}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/new-theme"
            component={CreateNewTheme}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/device-profile"
            component={NewDeviceProfile}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/app-management"
            component={AppManagement}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/assign"
            component={AssignData}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/entmanage"
            component={EntManage}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/device-info"
            component={DeviceInfo}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/view-device-info"
            component={ViewDeviceInfo}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/remote-view"
            component={RemoteVideo}
            licenseProp={this.state.myLicenseStatus}
          />

          {/* <ProtectedRoute path="/g-map" component={AppMap} /> */}
          <ProtectedRoute
            path="/location"
            extraProp={
              this.findSubFeatureList("/location") !== null
                ? this.findSubFeatureList("/location")
                : null
            }
            licenseProp={this.state.myLicenseStatus}
            component={Location}
          />
          <ProtectedRoute
            path="/location-setting"
            licenseProp={this.state.myLicenseStatus}
            component={LocationSetting}
          />
          <ProtectedRoute path="/location-search" component={LocationSearch} />
          <ProtectedRoute
            path="/location-traking2"
            component={LocationTraking}
          />
          <ProtectedRoute
            path="/location-traking"
            component={LocationTracking2}
          />
          <ProtectedRoute
            path="/location-history"
            licenseProp={this.state.myLicenseStatus}
            component={LocationHistory}
          />
          <ProtectedRoute
            path="/geofence"
            component={Geofence}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/content-push"
            component={ContentPush}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/home-ground"
            extraProp={
              this.findSubFeatureList("/home-ground") !== null
                ? this.findSubFeatureList("/home-ground")
                : null
            }
            component={HomeGround}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/user-management"
            licenseProp={this.state.myLicenseStatus}
            component={UserManagement}
          />
          <ProtectedRoute
            path="/role-management"
            licenseProp={this.state.myLicenseStatus}
            component={RoleManagement}
          />
          <ProtectedRoute
            path="/setup-android-enterprise"
            licenseProp={this.state.myLicenseStatus}
            component={SetupAndroidEnterprise}
          />
          <ProtectedRoute
            path="/new-role"
            component={NewRoles}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/new-user"
            component={NewUsers}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/organization"
            component={Organization}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/remote-cast"
            component={RemoteCast}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/remote-connect"
            component={RemoteConnect}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/browser-setting"
            component={BrowserSetting}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/website-listing"
            component={WebsiteListing}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/clear-cache"
            component={ClearCache}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/browsing-barrier"
            component={BrowsingBarrier}
          />
          <ProtectedRoute path="/profile" component={Profile} />
          <ProtectedRoute
            path="/change-password"
            component={ChangePassword}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/add-new-geofence"
            component={AddNewGeofence}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/global-settings"
            extraProp={
              this.findSubFeatureList("/global-settings") !== null
                ? this.findSubFeatureList("/global-settings")
                : null
            }
            component={GlobalSetting}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/reports"
            component={Reports}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/push-message"
            component={PushMessage}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/push-message-history"
            component={PushMessageHistory}
          />
          <ProtectedRoute
            path="/factory-reset"
            component={FactoryReset}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/lock"
            component={Lock}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/deactive-launcher"
            component={DeactiveLauncher}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/clear-launcher-data"
            component={ClearAppData}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/clear-files"
            component={ClearFiles}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/factory-reset-protection"
            component={FactoryResetProtection}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute
            path="/enrollment-history"
            component={EnrollmentHistory}
            licenseProp={this.state.myLicenseStatus}
          />
          <ProtectedRoute path="/pricing-plan" component={PricingPlans} />
          <ProtectedRoute path="/purchase" component={Purchase} />
          <ProtectedRoute path="/my-plan" component={MyPlan} />
          <ProtectedRoute path="/remote-control" component={RemoteControl} />
        </Switch>
      </Suspense>
    );
    const layoutMain = (
      <div className={loggedIn === "true" ? "layout-main" : ""}>
        {switchRoute}
      </div>
    );

    const applayoutVisible = loggedIn === "true" && (
      <>
        <AppTopbar
          extraProps={
            this.state.allowedFeatureList !== undefined
              ? this.state.allowedFeatureList
              : undefined
          }
          onToggleMenu={this.onToggleMenu}
        />
        <div
          ref={(el) => (this.sidebar = el)}
          className={sidebarClassName}
          onClick={this.onSidebarClick}
        >
          <div
            className={
              this.state.myLicenseStatus !== undefined &&
              this.state.myLicenseStatus.licenseStatus !== 2
                ? "overlay1"
                : "d-none"
            }
          ></div>
          <div className="layout-logo" style={{ marginBottom: "30px" }}>
            <img alt="Logo" src={logo} />
          </div>
          {/* <AppProfile /> */}
          <AppMenu
            // licenseProp={this.state.myLicenseStatus}
            model={this.state.menu}
            onMenuItemClick={this.onMenuItemClick}
          />
        </div>
      </>
    );
    return (
      <div className={wrapperClass} onClick={this.onWrapperClick}>
        {applayoutVisible}
        {layoutMain}
        {/* <AppFooter /> */}
        <div className="layout-mask"></div>
      </div>
    );
  }
}

export default SidebarMenu;
