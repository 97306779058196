import React from "react";
import { Spinner } from "react-bootstrap";
const Loader = ({ textToShow = "Loading...." }) => {
  //console.log("Inside loader", textToShow);
  let varients = [
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "dark",
  ];
  let spinnerList = varients.map((e, i) => {
    return (
      <Spinner
        key={i}
        animation="grow"
        style={{ width: "1.5em", height: "1.5em", margin: "3px" }}
        variant={e}
      />
    );
  });
  return (
    <div className="text-center" style={{ margin: "20px 5px" }}>
      {/* <div className="spinner-border spinner-border-sm text-info" role="status">
        <span className="sr-only">{textToShow}</span>
      </div> */}
      {spinnerList}
      <span
        className="text-success"
        style={{ paddingLeft: "10px", color: "#1BA14A" }}
      >
        {/* {textToShow} */}
      </span>
    </div>
  );
};

export default Loader;
