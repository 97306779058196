import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Link } from "react-router-dom";

const group = require("../assets/location-setting.png");
const theme = require("../assets/geofence.png");
const search = require("../assets/device-group.png");

export class BrowsingBarrier extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Browsing Barrier" },
    ];

    const home = { icon: "pi pi-home", url: "dashboard" };
    return (
      <div className="p-grid p-fluid dashboard">
        <div className="p-col-12 p-lg-12">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="p-col-12 p-md-3 p-xl-3 p-sm-6">
          <div className="small-box bg-aqua">
            <div className="icon">
              <img
                src={group}
                width="60px"
                style={{ width: "60px", height: "60px" }}
                className="mx-auto d-block"
                alt=""
              />
            </div>
            <Link to="/browser-setting" className="small-box-footer">
              Browser Setting
            </Link>
          </div>
        </div>
        <div className="p-col-12 p-md-3 p-xl-3 p-sm-6">
          <div className="small-box bg-aqua">
            <div className="icon">
              <img
                src={search}
                width="60px"
                style={{ width: "60px", height: "60px" }}
                className="mx-auto d-block"
                alt=""
              />
            </div>
            <Link to="/website-listing" className="small-box-footer">
              Website Listing
            </Link>
          </div>
        </div>
        <div className="p-col-12 p-md-3 p-xl-3 p-sm-6">
          <div className="small-box bg-aqua">
            <div className="icon">
              <img
                src={theme}
                width="60px"
                style={{ width: "60px", height: "60px" }}
                className="mx-auto d-block"
                alt=""
              />
            </div>
            <Link to="/clear-cache" className="small-box-footer">
              Clear Cache
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
