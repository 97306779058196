import React, { Component } from "react";

import { AllData } from "../service/AllData";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import api from "../service/ApiService";
import { Dialog } from "primereact/dialog";
import SuccessDialog from "../components/SuccessDialog";
import Loader from "../components/Loader";
import { Prompt } from "react-router-dom";
import html2canvas from "html2canvas";
import { RemoteCastSessionStatus } from "../Enums";
import {
  connect,
  startRTC,
  offer,
  disconnect,
  stop,
  makeSettings,
  isCanvasBlank,
  hide,
} from "./videoCall3";
import { ToggleButton } from "primereact/togglebutton";
import { getGroupListUrl, baseUrl } from "../service/ApiUrls";
import { OverlayPanel } from "primereact/overlaypanel";
const helpIcon = require("../assets/icons/help_icon.png");
const screen = require("../assets/screenshot.svg");
var RecordRTC = require("recordrtc");
let recorder;
var gSettings;
function downloadBase64File(contentType, base64Data, fileName) {
  let linkSource = base64Data; //`data:${contentType};base64,${base64Data}`;
  //console.log("linksource", linkSource);
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}
let meesage;
let selectedObjects = [
  { name: "enableVoiceCall", value: false },
  { name: "enableFileShare", value: false },
  { name: "allowToStopCast", value: false },
];
let settings = {};
selectedObjects.map((ev, i) => {
  settings[ev.name] = ev.value;
});
//console.log(settings, makeSettings({ ...settings }));
gSettings = makeSettings({ ...settings });
//gSettings = selectedObjects;
export class RemoteConnect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: null,
      options: [],
      selectedObjects: [],
      nodes: null,
      startplay: true,
      startrecord: true,
      valueToShow: "Connecting...",
      isLeave: false,
      lastLocation: null,
      confirmedNavigation: false,
      rowData: props.location.state.rowData,
      apiData: {
        apiData: props.location.state.apiData,
        imei: props.location.state.rowData.imei1,
        androidId: props.location.state.rowData.androidId,
      },
    };
    this.alldata = new AllData();
    this.onOptionChange = this.onOptionChange.bind(this);
    // var imei = document.createElement("input");
    // imei.setAttribute("type", "hidden");
    // imei.setAttribute("id", "imei");
    // imei.setAttribute("name", "imei");
    // imei.setAttribute("value", this.state.apiData);
  }
  showModal = (location) =>
    this.setState({
      isLeave: true,
      lastLocation: location,
    });
  closeModal = (callback) =>
    this.setState(
      {
        isLeave: false,
      },
      callback
    );
  handleBlockedNavigation = (nextLocation) => {
    const { confirmedNavigation } = this.state;
    if (!confirmedNavigation) {
      this.showModal(nextLocation);
      return false;
    }
    return true;
  };
  handleConfirmNavigation = () =>
    this.closeModal(() => {
      const { history } = this.props;
      const { lastLocation } = this.state;
      if (lastLocation) {
        this.setState(
          {
            confirmedNavigation: true,
          },
          () => {
            let dataID = document.getElementById("dataID");
            console.log(dataID);
            let dataToSend = {};
            if (
              dataID !== undefined &&
              dataID !== null &&
              dataID.value !== "null"
            ) {
              dataToSend["id"] = dataID.value;
              dataToSend["status"] = RemoteCastSessionStatus.COMPLETED;
              console.log("dataToSend", dataToSend);
              // api
              //   .put(
              //     baseUrl +
              //       "/remote-cast/" +
              //       this.state.apiData.imei +
              //       "/session/" +
              //       dataID.value,
              //     dataToSend
              //   )
              //   .then((res) => {
              //     console.log("posting data with result", res.data);

              //   })
              //   .catch((err) => {
              //     console.log("error in posting data", err);
              //   });
            }
            disconnect();
            history.push(lastLocation);
            //
          }
        );
      }
    });
  crossHandler = (idx) => {
    console.log("Delete " + idx);
    const canvas = document.getElementById("canvas" + idx);
    canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
    if (isCanvasBlank(canvas)) {
      hide(document.querySelectorAll("#down" + idx));
      hide(document.getElementById("cross" + idx));
    }
  };
  downloadHandler = (idx) => {
    console.log("Download handaler" + idx);
    let canvas = document.getElementById("canvas" + idx);
    let base64URL = canvas.toDataURL();
    downloadBase64File("image/png", base64URL, "scr-image-" + new Date());
  };
  componentDidMount = () => {
    connect(this.state.apiData.apiData);
    //connect("mdm");
  };
  componentDidUpdate() {
    window.onbeforeunload = function () {
      return "Data will be lost if you leave the page, are you sure?";
    };
  }
  // componentWillMount = () => {
  //   window.history.pushState(
  //     { name: "browserBack" },
  //     "on browser back click",
  //     window.location.href
  //   );
  //   // window.history.pushState(
  //   //   { name: "browserBack" },
  //   //   "on browser back click",
  //   //   window.location.href
  //   // );
  // };
  // pushState = () => {
  //   window.addEventListener(
  //     "popstate",
  //     (event) => {
  //       if (event.state) {
  //         console.log("Helo");
  //         //do your code here
  //         alert("Popup");
  //       }
  //     },
  //     false
  //   );
  // };
  test = () => {
    let object = document.getElementById("remoteView");
    let canvas = document.getElementById("canvas"); // declare a canvas element in your html
    let ctx = canvas.getContext("2d");
    let videos = object;
    let w, h;

    const v = videos;
    if (!v.src) {
    } // no video here
    try {
      w = v.videoWidth;
      h = v.videoHeight;
      canvas.width = w;
      canvas.height = h;
      ctx.fillRect(0, 0, w, h);
      ctx.drawImage(v, 0, 0, w, h);
      v.style.backgroundImage = `url(${canvas.toDataURL()})`; // here is the magic
      v.style.backgroundSize = "cover";
      // ctx.clearRect(0, 0, w, h); // clean the canvas
    } catch (e) {
      console.log(e);
    }
  };

  recording = () => {
    let object = document.getElementById("remoteView");
    console.log(object);
    // Set the source of one <video> element to be a stream from another.
    var stream = object.captureStream
      ? object.captureStream()
      : object.mozCaptureStream();
    console.log("stream", stream);
    recorder = RecordRTC(stream, {
      type: "video",
      mimeType: "video/webm",
    });
    recorder.startRecording();

    // const sleep = (m) => new Promise((r) => setTimeout(r, m));
    // await sleep(15000);
  };

  stopRecording = (recorder) => {
    recorder.stopRecording(function () {
      let blob = recorder.getBlob();
      RecordRTC.invokeSaveAsDialog(blob, "video.webm");
    });
  };

  onOptionChange = (e) => {
    // console.log(e);
    // let selectedOptions = [...this.state.options];
    // if (e.checked) {
    //   selectedOptions.push(e.value);
    //   switch (e.target.name) {
    //     case "enableVoiceCall":
    //       selectedObjects[0].value = true;
    //       break;
    //     case "enableFileShare":
    //       selectedObjects[1].value = true;
    //       break;
    //     case "allowToStopCast":
    //       selectedObjects[2].value = true;
    //       break;
    //   }
    // } else {
    //   selectedOptions.splice(selectedOptions.indexOf(e.value), 1);
    //   switch (e.target.name) {
    //     case "enableVoiceCall":
    //       selectedObjects[0].value = false;
    //       break;
    //     case "enableFileShare":
    //       selectedObjects[1].value = false;
    //       break;
    //     case "allowToStopCast":
    //       selectedObjects[2].value = false;
    //       break;
    //   }
    //   //selectedObjects.splice(selectedObjects.indexOf(e.target.name), 1);
    // }
    // let settings = {};
    // selectedObjects.map((ev, i) => {
    //   settings[ev.name] = ev.value;
    // });
    // console.log(settings, makeSettings({ ...settings }));
    // gSettings = makeSettings({ ...settings });
    // this.setState(
    //   { options: selectedOptions, selectedObjects: selectedObjects },
    //   () => {
    //     // let settings = {};
    //     // this.state.options.map((e, i) => {
    //     //   settings[e.name] = e.value;
    //     // });
    //     // makeSettings({ ...settings });
    //   }
    // );
  };
  onDialogueHide = () => {
    this.setState({ isLeave: false });
  };

  onDialogueHideSuccess = () => {
    this.setState({ isSuccess: false });
  };

  canvasCreation = (idx) => {
    return (
      <div id="download" className="p-col-12 p-md-3 p-zz inline-b">
        <canvas
          id={"canvas" + idx}
          // src={screen}
          style={{
            // minHeight: "450px",
            // padding: "10px",
            background: "gray",
            padding: "3px",
            width: "100%",
          }}
          className="img-thumbnail screenshots"
        />

        <i
          id={"cross" + idx}
          style={{ visibility: "hidden" }}
          className="pi pi-times c-icon"
          onClick={() => {
            this.crossHandler(idx);
          }}
        ></i>

        <div
          id={"down" + idx}
          style={{
            position: "relative",
            width: "95%",
            left: "1px",
            visibility: "hidden",
          }}
        >
          <button
            onClick={() => {
              this.downloadHandler(idx);
            }}
            className="c-btn"
          >
            Download
          </button>
        </div>
      </div>
    );
  };

  canvasGroup = () => {
    let group = [];
    for (let index = 0; index < 4; index++) {
      group.push(this.canvasCreation(index));
    }
    return group;
  };

  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Remote Cast", url: "remote-cast" },
      { label: "IMEI" },
    ];
    const { when } = this.props;
    const footer = (
      <div style={{ textAlign: "center" }}>
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-success"
          style={{ marginRight: "20px", padding: "2px 10px" }}
          onClick={() => {
            this.handleConfirmNavigation();
          }}
        />
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-danger"
          style={{ padding: "2px 10px" }}
          onClick={() => {
            this.setState({ isLeave: false });
          }}
        />
      </div>
    );

    const home = { icon: "pi pi-home", url: "dashboard" };
    return (
      <div className="p-col-12 p-grid">
        <input
          type="hidden"
          id="hiddendisabled"
          value={
            document.getElementById("hiddendisabled") !== undefined &&
            document.getElementById("hiddendisabled") !== null &&
            document.getElementById("hiddendisabled").value === "false"
              ? "false"
              : "true"
          }
        />
        {/* <Prompt
          message={(location, action) => {
            if (action === "POP") {
              console.log("Backing up...");
            }

            return location.pathname.startsWith("/app")
              ? true
              : `Are you sure you want to go to ${location.pathname}?`;
          }}
        /> */}
        <div className="p-col-12 p-lg-12 p-ll">
          <div className="p-col-9 inline-b p-ll">
            <BreadCrumb model={items} home={home} />
          </div>
          <div className="p-col-3 inline-b p-zz">
            <img
              src={helpIcon}
              width="20"
              height="20"
              onClick={(e) => this.op.toggle(e)}
              style={{
                marginBottom: "-10px",
                float: "right",
                cursor: "pointer",
              }}
            />
          </div>
          <OverlayPanel
            ref={(el) => (this.op = el)}
            id="overlay_panel"
            showCloseIcon={true}
          >
            <div
              className="col"
              style={{
                width: "fit-content",
                maxWidth: "300px",
                padding: "0px",
              }}
            >
              <div className="helptip-title">
                <b>How to : Remote Cast</b>
              </div>
              <div className="helptip-des">
                Users can mirror the device screen using Commlink Remote Cast
                feature. To be able to use this feature, "Remote-Cast" app needs
                to be installed into devices.
              </div>
            </div>
          </OverlayPanel>
        </div>
        <Dialog
          visible={this.state.isLoading}
          modal={true}
          closable={false}
          showHeader={false}
          style={{ width: "20vw" }}
          onHide={() => {
            this.onDialogueHide();
          }}
        >
          <Loader />
        </Dialog>
        <Dialog
          visible={this.state.isSuccess}
          modal={true}
          style={{ width: "25vw" }}
          closable
          showHeader
          onHide={() => {
            this.onDialogueHideSuccess();
          }}
        >
          <SuccessDialog successMessage="Deleted Group Successfully" />
        </Dialog>
        <div id="masterDiv" className="p-col-12 row align-items-start">
          <div className="col-4 bg-col p-l">
            <div className="p-col-12 bg-col">
              <p className="mb-3">
                <b>Session Description</b>
              </p>
              <div className="p-col-12 pb-0">
                <p className="mb-1">
                  <b>Device IMEI:</b> {this.state.rowData.imei1}
                </p>
                <p>
                  <b>Model:</b> {this.state.rowData.model}
                </p>
              </div>
              <div className="p-col-12 pt-0">
                <div className="p-field-checkbox">
                  <input
                    type="checkbox"
                    id="enableVoiceCall"
                    name="enableVoiceCall"
                    value="Allow Voice Call"
                    onChange={() => {
                      this.onOptionChange();
                    }}
                  />
                  <label htmlFor="enableVoiceCall">Allow Voice Call</label>
                </div>
                <div className="p-field-checkbox" style={{ display: "none" }}>
                  <input
                    type="checkbox"
                    id="enableFileShare"
                    name="enableFileShare"
                    value="Enable File Sharing"
                    onChange={() => {
                      this.onOptionChange();
                    }}
                  />
                  <label htmlFor="enableFileShare">Enable File Sharing</label>
                </div>
                <div className="p-field-checkbox">
                  <input
                    type="checkbox"
                    id="allowToStopCast"
                    name="allowToStopCast"
                    value="Allow Users to Stop Cast"
                    onChange={() => {
                      this.onOptionChange();
                    }}
                  />
                  <label htmlFor="allowToStopCast">
                    Allow User to Stop Cast
                  </label>
                </div>
              </div>
              <div className="p-col-12">
                <input
                  type="hidden"
                  id="imei"
                  name="imei"
                  value={JSON.stringify(this.state.apiData)}
                />
                <button
                  id="startButton"
                  className="btn disabled"
                  value={this.state.valueToShow}
                  disabled
                  // onClick={(e) => {
                  //   //if (this.state.startplay === false) {
                  //   console.log("trying to connect....");
                  //   connect("nazmun");
                  //   offer("omi");
                  //   // }
                  // }}
                  onClick={(e) => {
                    console.log(
                      "onClick trying to connect....",
                      e.target.value
                    );
                    let value;
                    if (e.target.value === "Start") {
                      // setTimeout(function () {
                      //   startRTC();
                      // }, 5000);
                      setTimeout(function () {
                        offer("e0db12dc91359d73", gSettings);
                        // offer("bd41fe4ec9a9bc08", gSettings);
                      }, 5000);
                      document.getElementById("startButton").value = "Stop";
                      document.getElementById("startButton").innerHTML = "Stop";
                      value = "Stop";

                      console.log(
                        document.getElementsByClassName("p-button-label")
                          .innerHTML
                      );
                      document
                        .getElementById("recording")
                        .classList.add("p-highlight");
                      document.getElementsByClassName(
                        "p-button-label"
                      ).innerHTML = "Record";
                      // setTimeout(function () {
                      //   offer("nazmun");
                      // }, 5000);
                      // setTimeout(function () {
                      //   offer("omi");
                      // }, 1000);
                    }
                    if (e.target.value === "Stop") {
                      stop();
                      if (document.getElementById("remoteView").srcObject) {
                        document.getElementById("remoteView").srcObject = null;
                      } else {
                        document.getElementById("remoteView").src = null;
                      }
                      document.getElementById("startButton").value = "Start";
                      document.getElementById("startButton").innerHTML =
                        "Start";
                      value = "Start";

                      console.log(
                        document.getElementsByClassName("p-button-label")
                          .innerHTML
                      );
                      document
                        .getElementById("recording")
                        .classList.add("p-highlight");
                      document.getElementsByClassName(
                        "p-button-label"
                      ).innerHTML = "Record";

                      //closeConnection();
                    }
                    this.setState({
                      startplay: e.target.value === "Start" ? false : true,
                      valueToShow: value,
                    });
                  }}
                  // onLabel="Start"
                  // offLabel="Stop"
                  // onIcon="pi pi-play "
                  // iconPos="right"
                  // offIcon="pi pi-power-off"
                  style={{
                    width: "100%",
                    // display: "inline-block",
                  }}
                >
                  {this.state.valueToShow}
                </button>
              </div>
              <div
                id="segmentToShow"
                className="p-col-12"
                style={{ visibility: "hidden" }}
              >
                <div className="p-col-12 p-md-6 p-lg-6 inline-b pl-0">
                  <button
                    value="Screenshot"
                    className="btn btn-primary disabled"
                    // label="Screenshot"
                    disabled
                    id="screenshot"
                    // icon="pi pi-image"
                    style={{
                      width: "100%",
                      backgroundColor: "#008CBA",
                      borderRadius: "15px",
                      marginTop: "-20px",
                      fontSize: "14px",
                    }}
                    onClick={() => {
                      this.test();
                    }}
                  >
                    Screenshot
                  </button>
                </div>
                <div className="p-col-12 p-md-6 p-lg-6 inline-b pr-0">
                  <ToggleButton
                    id="recording"
                    disabled={
                      document.getElementById("hiddendisabled") !== null &&
                      document.getElementById("hiddendisabled").value === "true"
                    }
                    checked={this.state.startrecord}
                    onChange={(e) => {
                      this.setState({ startrecord: e.value }, () => {
                        console.log("checking e", e.value);
                        if (this.state.startrecord === false) {
                          this.recording();
                          document.getElementById("hiddendisabled").value =
                            "false";
                        }
                        if (this.state.startrecord === true) {
                          this.stopRecording(recorder);
                          document.getElementById("hiddendisabled").value =
                            "false";
                        }
                      });
                    }}
                    onLabel="Record"
                    offLabel="Recording"
                    onIcon="pi pi-circle-on"
                    iconPos="right"
                    offIcon="pi pi-circle-on"
                    style={{
                      width: "100%",
                      display: "inline-block",
                      padding: "1px 0px",
                    }}
                  />
                </div>
              </div>

              {this.state.options.includes("Allow Voice Call") ? (
                <div className="p-col-12 inline-b">
                  {/* <Button
                    className="inline-b btn-main"
                    label="Voice Call"
                    icon="pi pi-volume-up"
                    style={{ width: "100%" }}
                  /> */}
                </div>
              ) : (
                ""
              )}
              {this.state.options.includes("Enable File Sharing")
                ? ""
                : // <div className="p-col-12 inline-b">
                  //   <p className="cus-p2">
                  //     File Explorer
                  //     <i
                  //       className="pi pi-refresh"
                  //       style={{ fontSize: "1em", paddingLeft: "10px" }}
                  //     ></i>
                  //   </p>

                  //   <Tree value={this.state.nodes} />
                  // </div>
                  ""}
            </div>
          </div>
          <div className="col-8 p-r">
            <div className="p-col-12 bg-col">
              <div className="p-col-12">
                <ol className="">
                  <li>Select Your Preference</li>
                  <li>Click "Start Button to initiate casting</li>
                  <li>
                    Open "Remote Cast" app on device manually if casting does
                    not start. Then click "Start" button again
                  </li>
                </ol>
              </div>
              <div className="col-12">
                <div
                  className="row align-items-center c-row"
                  style={{
                    // minHeight: "450px",
                    padding: "10px",
                    background: "gray",
                    height: "auto",
                    maxHeight: "500px",
                  }}
                >
                  <div
                    className="col-12"
                    style={{ paddingLeft: "0px", paddingRight: "0px" }}
                  >
                    <div className="text-center">
                      <video
                        id="remoteView"
                        // width="640"
                        height="480"
                        autoPlay
                        style={{
                          display: "inline",
                          width: "100%",
                          height: "auto",
                          maxHeight: "480px",
                        }}
                        className="text-center"
                      ></video>
                      {/* <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div> */}
                      {/* {this.state.startplay === true ? (
                        
                      ) : (
                        <p
                          style={{
                            color: " #ffffff",
                            fontWeight: "bold",
                            paddingTop: "10px",
                          }}
                        >
                          Waiting
                        </p>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
              <div id="c-grid" className="p-col-12 p-ll">
                {this.canvasGroup()}
              </div>
            </div>
          </div>
        </div>
        <Prompt when={when} message={this.handleBlockedNavigation} />
        <Dialog
          visible={this.state.isLeave}
          modal
          style={{ width: "25vw" }}
          closable
          showHeader
          footer={footer}
          onHide={this.onDialogueHide}
        >
          <p className="w-p">Are you sure you want to Leave this Page ?</p>
        </Dialog>
      </div>
    );
  }
}
