import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Link } from "react-router-dom";

const group = require("../assets/location-setting.png");
const theme = require("../assets/geofence.png");
const search = require("../assets/device-group.png");

export class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  webMenuDynamic = () => {
    console.log("calling", "webMenuDynamic");
    let imagemap = {
      "/location-setting": group,
      "/location-search": search,
      "/geofence": theme,
    };
    return (
      this.props.extraProp !== null &&
      this.props.extraProp !== undefined &&
      this.props.extraProp.map((e, v) => {
        if (e.feature.category === 0) {
          if (e.feature.title !== "Dashboard") {
            let helpingMenu = imagemap[e.feature.protectedUrl];
            let icon =
              helpingMenu !== undefined ? helpingMenu : "pi pi-fw pi-dollar";
            return (
              <div key={"mo-" + v} className="p-col-12 p-md-3 p-xl-3 p-sm-6">
                <div key={"ch1-" + v} className="small-box bg-aqua">
                  <div key={"ch11-" + v} className="icon">
                    <img
                      src={icon}
                      width="60px"
                      style={{ width: "60px", height: "60px" }}
                      className="mx-auto d-block"
                    />
                    {/* <i
                      className={icon + " " + "mx-auto d-block"}
                      style={{
                        width: "60px",
                        height: "60px",
                        color: "#ffffff",
                        fontSize: "3.5em",
                      }}
                    ></i> */}
                  </div>
                  <Link
                    to={e.feature.protectedUrl}
                    className="small-box-footer"
                  >
                    {e.feature.title}
                  </Link>
                </div>
              </div>
            );
          }
        }
      })
    );
  };

  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Location" },
    ];

    const home = { icon: "pi pi-home", url: "dashboard" };
    return (
      <div className="p-grid p-fluid dashboard">
        <div className="p-col-12 p-lg-12">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        {this.props.extraProp !== undefined && this.webMenuDynamic()}
        {/* <div className="p-col-12 p-md-3 p-xl-3 p-sm-6">
          <div className="small-box bg-aqua">
            <div className="icon">
              <img
                src={group}
                width="60px"
                style={{ width: "60px", height: "60px" }}
                className="mx-auto d-block"
                alt=""
              />
            </div>
            <Link to="/location-setting" className="small-box-footer">
              Location Setting
            </Link>
          </div>
        </div>
        <div className="p-col-3 p-md-3 p-xl-3">
          <div className="small-box bg-aqua">
            <div className="icon">
              <img
                src={profile}
                width="60px"
                style={{ width: "60px", height: "60px" }}
                className="mx-auto d-block"
                alt=""
              />
            </div>
            <Link to="/location-history" className="small-box-footer">
              History
            </Link>
          </div>
        </div>
        <div className="p-col-12 p-md-3 p-xl-3 p-sm-6">
          <div className="small-box bg-aqua">
            <div className="icon">
              <img
                src={search}
                width="60px"
                style={{ width: "60px", height: "60px" }}
                className="mx-auto d-block"
                alt=""
              />
            </div>
            <Link to="/location-search" className="small-box-footer">
              Location Search
            </Link>
          </div>
        </div>
        <div className="p-col-12 p-md-3 p-xl-3 p-sm-6">
          <div className="small-box bg-aqua">
            <div className="icon">
              <img
                src={theme}
                width="60px"
                style={{ width: "60px", height: "60px" }}
                className="mx-auto d-block"
                alt=""
              />
            </div>
            <Link to="/geofence" className="small-box-footer">
              Geofence
            </Link>
          </div>
        </div> */}
      </div>
    );
  }
}
