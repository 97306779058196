import React, { Component } from "react";
import { Checkbox } from "primereact/checkbox";
import api from "../service/ApiService";
import { baseUrl, errorCompute } from "../service/ApiUrls";
import ThemeUploader from "./ThemeUploader";
import { ModuleNameEnum, UploadActionEnum } from "../Enums";
import { confirmAlert } from "react-confirm-alert";
import Loader from "../components/Loader";
import { Dialog } from "primereact/dialog";
const userImg = require("../assets/logodummy.png");
class NewWhiteList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      custMsg: null,
      checked: false,
      // baseURL: null,
      // name: null,
      ...this.props?.rowData,
      isLoading: false,
      isEnableShortcut: false,
      displayBasic: this.props.displayBasic,
    };
    this._handleLogoChange = this._handleLogoChange.bind(this);
    // this.upPictureToServer = this.upPictureToServer.bind(this);
  }
  changeHandler = (e) => {
    console.log("e", e.target);
    this.setState({ [e.target.id]: e.target.value });
  };
  _handleLogoChange = (type) => async (e) => {
    e.preventDefault();
    document.getElementById("logoCross").style.display = "block";
    this._logoChange(e);
  };
  onDialogueHide = () => {
    this.setState({ isLoading: false });
  };
  webPhotoupload = ({ file } = {}) => {
    const formData = new FormData();
    formData.append("uploadActionType", UploadActionEnum.IMAGEUPLOAD);
    formData.append("moduleName", ModuleNameEnum.WEBLISTING);
    formData.append("file", file);

    api.post(baseUrl + "/uploads", formData).then((res) => {
      if (res.data.result.isError === false) {
        this.setState({
          ...res.data.data,
          logoTempId: res.data.data.fileTempId,
          siteLogoBase64: res.data.data.imageBase64,
          //"data:image/png;base64," + res.data.data.imageBase64,
        });
      } else {
        confirmAlert({
          title: "Error Message",
          message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                // this.webcallGetUSerList();
              },
            },
          ],
        });
      }
    });
  };

  callGetApiOFWHITELISTWithID = (id) => {
    api.get(baseUrl + "/browsing-barrier/website-listing/" + id).then((res) => {
      if (res.data.result.isError === false) {
        this.setState({ ...res.data.data });
      } else {
        confirmAlert({
          title: "Error Message",
          message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                // this.webcallGetUSerList();
              },
            },
          ],
        });
      }
    });
  };

  resetLockFile = () => {
    this.setState({
      lockFile: null,
      logofileNameToShow: undefined,
      androidProperties: {
        ...this.state.androidProperties,
        lockLogobase64: null,
        lockLogoTempId: null,
      },
    });
  };
  _logoChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      let result = reader.result;
      if (result.substring(0, 22).includes("jpeg"))
        result = result.substring(23);
      else result = result.substring(22);

      this.setState(
        {
          lockFile: file,
          logofileNameToShow: file.name,
          lockLogobase64: result,
        },
        () => {
          if (
            this.state.lockFile !== undefined &&
            this.state.lockFile !== null
          ) {
            console.log("lock");
            this.webPhotoupload({ file: this.state.lockFile });
          }
        }
      );
    };
    reader.readAsDataURL(file);
  };
  callAddApi = ({
    name = "apple",
    baseURL = "www.apple.com",
    isEnableShortcut = false,
    logoTempId = null,
    siteLogoBase64 = null,
    isEdit = this.props.isEdit,
    id = this.props.rowData !== null ? this.props.rowData.id : null,
  } = {}) => {
    this.setState({ isLoading: true }, () => {
      let dataToAdd = {
        name: name,
        baseURL: baseURL,
        isEnableShortcut: isEnableShortcut,
        logoTempId: logoTempId,
        //siteLogoBase64: siteLogoBase64,
        isWhite: true,
      };

      let url = isEdit
        ? baseUrl + "/browsing-barrier/website-listing/" + id
        : baseUrl + "/browsing-barrier/website-listing/add";
      if (isEdit === false) {
        api
          .post(url, dataToAdd)
          .then((res) => {
            if (res.data.result.isError === false) {
              this.setState({ isLoading: false }, () => {
                this.props.action();
                confirmAlert({
                  title: "Success Message",
                  message: <p className="mod-sp">Website Added Successfully</p>,
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => {
                        this.props.callWebListApi();
                      },
                    },
                  ],
                });
              });
            } else if (res.data.result.isError === true) {
              this.setState({ isLoading: false }, () => {
                this.props.action();
                confirmAlert({
                  title: "Error Message",
                  message: (
                    <p className="mod-p">
                      {res.data.result.errorMsg !== null
                        ? res.data.result.errorMsg
                        : "Error occurs"}
                    </p>
                  ),
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => {
                        this.props.callWebListApi();
                      },
                    },
                  ],
                });
                // this.props.action();
                // this.setState({ custMsg: res.data.result }, () => {
                //   this.props.custMsgs(this.state.custMsg);
                // });
              });
            }
          })
          .catch((err) => {
            errorCompute(err);
          });
      } else {
        api
          .put(url, dataToAdd)
          .then((res) => {
            if (res.data.result.isError === false) {
              this.setState({ isLoading: false }, () => {
                this.props.action();
                confirmAlert({
                  title: "Success Message",
                  message: (
                    <p className="mod-sp">Website Updated Successfully</p>
                  ),
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => {
                        this.props.callWebListApi();
                      },
                    },
                  ],
                });
                this.props.action();
              });
            } else {
              this.setState({ isLoading: false }, () => {
                this.setState({ custMsg: res.data.result }, () => {
                  this.props.custMsgs(this.state.custMsg);
                });
              });
            }
          })
          .catch((err) => {
            errorCompute(err);
          });
      }
    });
  };

  // componentDidMount = () => {
  //   if (this.props.rowData !== null) {
  //     this.callGetApiOFWHITELISTWithID(this.props.rowData.id);
  //   }
  // };

  render() {
    console.log("this state", this.state);
    let lockLogobase64 =
      this.state.siteLogoBase64 === undefined ||
      this.state.siteLogoBase64 === null
        ? userImg
        : this.state.siteLogoBase64;

    let $lockLogoView = null;
    // $imagePreview = (imagePreviewUrl)
    $lockLogoView = (
      <img
        src={
          lockLogobase64 !== userImg && lockLogobase64 !== null
            ? `data:image/jpeg;base64,${lockLogobase64}`
            : userImg
        }
        className="mx-auto d-block"
        width="150"
        height="150"
        alt="User Image"
        style={{
          borderRadius: "100px",
          border: "1px solid #ffffff",
        }}
      />
    );
    let { backgroudWallpaperbase64 } =
      this.state.androidProperties !== undefined &&
      this.state.androidProperties;
    let $imgPath = null;
    if (backgroudWallpaperbase64 === null) {
      $imgPath = this.state.bgImage;
    }
    if (backgroudWallpaperbase64) {
      $imgPath = backgroudWallpaperbase64;
    }
    return (
      <div className="p-col-12 p-lg-12 ">
        <div id="custom-div" className="row align-items-start bg-col">
          {/* <div className="p-col-12 p-lg-4 p-md-4">
            <div className="p-col-12">
              <div className="text-center mt-3">
                {$lockLogoView}
                <div className="form-group p-col-12 mb-0">
                  <ThemeUploader
                    // name="Lock Screen Logo"
                    id="lock"
                    cross="logoCross"
                    handleLock={() => this._handleLogoChange("lock")}
                    brandfileNameToShow={this.state.logofileNameToShow}
                    parentCall={() => {
                      this.resetLockFile();
                    }}
                  />
                </div>
              </div>
            </div>
          </div> */}
          <div className="p-col-12 p-lg-12 p-md-12">
            <div className="p-col-12">
              <div
                className="form-group p-col-12 p-lg-12 p-md-12"
                style={{ display: "inline-block" }}
              >
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Enter Name"
                  value={this.state.name}
                  onChange={this.changeHandler}
                />
              </div>
              <div
                className="form-group p-col-12 p-lg-12 p-md-12"
                style={{ display: "inline-block" }}
              >
                <label htmlFor="baseurl">Base url</label>
                <input
                  type="text"
                  className="form-control"
                  id="baseURL"
                  onChange={this.changeHandler}
                  value={this.state.baseURL}
                  placeholder="http://www.example.com"
                />
              </div>
              <div
                className="form-group p-col-12 p-lg-12 p-md-12"
                style={{ display: "inline-block" }}
              >
                <div className="p-field-checkbox">
                  <Checkbox
                    inputId="binary"
                    checked={this.state.isEnableShortcut}
                    onChange={(e) =>
                      this.setState({ isEnableShortcut: e.checked })
                    }
                  />
                  <label htmlFor="binary">Enable Shortcut</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-col-12 mt-4" style={{ textAlign: "center" }}>
          <button
            className="btn btn-primary mr-3"
            onClick={(e) => {
              console.log(e);
              this.callAddApi({
                name: this.state.name,
                baseURL: this.state.baseURL,
                isEnableShortcut: this.state.isEnableShortcut,
                logoTempId: this.state.logoTempId,
                // siteLogoBase64: this.state.siteLogoBase64,
              });
            }}
          >
            Submit
          </button>
          <button className="btn btn-danger" onClick={this.props.action}>
            Cancel
          </button>
        </div>
        <Dialog
          visible={this.state.isLoading}
          modal={true}
          closable={false}
          showHeader={false}
          style={{ width: "20vw" }}
          onHide={this.onDialogueHide}
        >
          <Loader />
        </Dialog>
      </div>
    );
  }
}

export default NewWhiteList;
