import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import axios from "axios";
import { Checkbox } from "primereact/checkbox";
import api from "../service/ApiService";
import { getLocationSettings, putLocationSetting } from "../service/ApiUrls";
import Loader from "../components/Loader";
import SuccessDialog from "../components/SuccessDialog";
import { Dialog } from "primereact/dialog";
import { confirmAlert } from "react-confirm-alert";
import { Chart } from "primereact/chart";
import { ProgressBar } from "primereact/progressbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBatteryFull } from "@fortawesome/free-solid-svg-icons";
import { faBatteryQuarter } from "@fortawesome/free-solid-svg-icons";
import { faBatteryHalf } from "@fortawesome/free-solid-svg-icons";
import { faBatteryThreeQuarters } from "@fortawesome/free-solid-svg-icons";
import { faHandPeace } from "@fortawesome/free-solid-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faHeartBroken } from "@fortawesome/free-solid-svg-icons";

export class LocationSetting extends Component {
  constructor() {
    super();
    this.state = {
      qrlists: [],
      isLoading: false,
      isSuccess: false,
      lose: false,
    };
    this.basicData = {
      labels: [
        "Location",
        "Chrome",
        "Firefox",
        "Linkedin",
        "Facebook",
        "Twitter",
        "Youtube",
      ],
      datasets: [
        {
          label: "Mobile Data Uses Save",
          backgroundColor: "#42A5F5",
          data: [65, 59, 80, 81, 56, 55, 40],
        },
        {
          label: "Battery Life Save",
          backgroundColor: "#FFA726",
          data: [28, 48, 40, 19, 86, 27, 90],
        },
      ],
    };
    this.options = this.getLightTheme();
  }
  getLightTheme() {
    let basicOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            color: "#495057",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
      },
    };
    return {
      basicOptions,
    };
  }
  callgetSettings = () => {
    api
      .get(getLocationSettings)
      .then((res) => {
        this.setState({
          qrlists: { ...res.data.data.locationSettings },
          id: res.data.data.id,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  componentDidMount() {
    this.callgetSettings();
    // axios
    //   .get(`${process.env.PUBLIC_URL}/data/location-setting.json`)
    //   .then((res) => {
    //     this.setState({
    //       qrlists: res.data.data,
    //     });
    //   });
  }
  handleCheckbox = (e) => {
    console.log("e", e);
  };
  submitHandler = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
  };
  onDialogueHide = () => {
    this.setState({ isLoading: false, isSuccess: false });
  };
  saveData = () => {
    this.setState({ isLoading: true });
    api
      .put(putLocationSetting + "/" + this.state.id, {
        id: this.state.id,
        locationSettings: this.state.qrlists,
      })
      .then((res) => {
        if (res.data.result.isError === false) {
          this.setState({ isLoading: false }, () => {
            confirmAlert({
              title: "Success Message",
              message: (
                <p className="mod-sp">Location Setting Update Successfully</p>
              ),
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {},
                },
              ],
            });
          });
          this.callgetSettings();
        } else if (res.data.result.isError === false) {
          this.setState(
            {
              isLoading: false,
            },
            () => {
              confirmAlert({
                title: "Error Message",
                message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {},
                  },
                ],
              });
            }
          );
        }
        //   this.setState({ isLoading: false });
        // this.callgetSettings(this.setState({ isSuccess: true }));
      });
  };

  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Location", url: "location" },
      { label: "Location Setting" },
    ];
    const home = { icon: "pi pi-home", url: "dashboard" };
    const intqrLists =
      this.state.qrlists !== undefined && this.state.qrlists[0];
    // const qrlist = intqrLists.map((ev, i) => {
    // console.log("qrcode", qrcode);
    //   return (
    //     <>
    //       <div key={i + ""}>
    //         <div className="custom-control custom-checkbox" key={i + "" + i}>
    //           <Checkbox
    //             key={i + "" + i + "" + i}
    //             className="custom-control-input"
    //             id={ev.id + ""}
    //             inputId={i + ""}
    //             value={ev}
    //             name={ev.name}
    //             onChange={this.handleCheckbox}
    //             checked={this.state.qrlists.some((obj) => obj.id === ev.id)}
    //           />
    //           <label
    //             key={i + "" + i + " " + i}
    //             className="custom-control-label"
    //             htmlFor={ev.id}
    //           >
    //             {ev.name}
    //           </label>
    //         </div>
    //       </div>
    //     </>
    //   );
    // });
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 ">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="p-grid bg-col p-lg-12">
          <div className="p-col">
            <h2 style={{ paddingLeft: "12px" }}>Location Setting</h2>
          </div>
          <div className="p-col" style={{ paddingRight: "20px" }}>
            <div className="box">
              <button type="submit" className="btn2" onClick={this.saveData}>
                Save
              </button>
            </div>
          </div>
          <div className="p-col-12" style={{ padding: "5px 20px" }}>
            <Dialog
              visible={this.state.isLoading}
              modal={true}
              closable={false}
              showHeader={false}
              style={{ width: "20vw" }}
              onHide={this.onDialogueHide}
            >
              <Loader textToShow="Saving Location Setting..." />
            </Dialog>
            <Dialog
              visible={this.state.isSuccess}
              modal={true}
              style={{ width: "25vw" }}
              closable
              showHeader
              onHide={this.onDialogueHide}
            >
              <SuccessDialog successMessage="Saved Location Settings Successfully" />
            </Dialog>
            <div style={{ background: "#f1f3f1", padding: "7px 10px" }}>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="1"
                  onChange={(e) => {
                    this.setState({
                      qrlists: {
                        ...this.state.qrlists,
                        enableLocationTracking: e.target.checked,
                      },
                    });
                  }}
                  checked={this.state.qrlists.enableLocationTracking}
                />
                <label className="custom-control-label" htmlFor="1">
                  Enable Location Tracking
                </label>
              </div>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="2"
                  onChange={(e) => {
                    this.setState({
                      qrlists: {
                        ...this.state.qrlists,
                        forceGPSOn: e.target.checked,
                      },
                    });
                  }}
                  checked={this.state.qrlists.forceGPSOn}
                />
                <label className="custom-control-label" htmlFor="2">
                  Force GPS On
                </label>
              </div>
              <div className="custom-control custom-checkbox">
                {/* <input
                  type="checkbox"
                  className="custom-control-input"
                  id="3"
                /> */}
                {/* <label className="custom-control-label" htmlFor="3"> */}
                <div className="form-group row justify-content-start">
                  <span style={{ paddingLeft: "15px", paddingRight: "5px" }}>
                    Location Tracker Interval:
                  </span>
                  <input
                    type="text"
                    className="form-control-plaintext"
                    id="staticEmail"
                    value={this.state.qrlists.locationTrackerIntervalInSecond}
                    onChange={(e) => {
                      this.setState({
                        qrlists: {
                          ...this.state.qrlists,
                          locationTrackerIntervalInSecond: Number(
                            e.target.value
                          ),
                        },
                      });
                    }}
                    style={{
                      borderBottom: "1px solid #000000",
                      padding: "0px",
                      width: "60px",
                      height: "18px",
                      marginRight: "5px",
                    }}
                  />
                  Seconds
                </div>
                {/* </label> */}
              </div>
            </div>
          </div>
        </div>
        {/* <div
          className="p-grid bg-col p-lg-12"
          style={{ marginTop: "15px", width: "100%" }}
        >
          <div className="p-grid bg-col p-lg-6">
            <div className="card" style={{ width: "100%", margin: "10px" }}>
              <h5
                style={{
                  borderBottom: "1px dashed",
                  paddingBottom: "10px",
                  fontWeight: "bold",
                }}
              >
                <FontAwesomeIcon icon={faGlobe} /> Mobile Data Use
                (Approximately)
              </h5>
              <div className="p-grid bg-col p-lg-12">
                <p
                  className={this.state.lose === true ? "lose" : "win"}
                  style={{
                    textAlign: "center",
                    fontSize: "13px",
                  }}
                >
                  {this.state.lose === true ? (
                    <>
                      <FontAwesomeIcon icon={faHeartBroken} /> Sorry ! your data
                      will use
                      <span style={{ fontWeight: "bold" }}> 12.3%</span> more
                      monthly
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faHandPeace} /> Congratulation!
                      your data will use
                      <span style={{ fontWeight: "bold" }}> 12.3%</span> less
                      monthly
                    </>
                  )}
                </p>
                <div className="p-lg-4" style={{ borderRight: "1px dashed" }}>
                  <span
                    style={{
                      color: "red",
                      fontSize: "11px",
                      fontWeight: "bold",
                    }}
                  >
                    Data Will Use
                  </span>
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "red",
                      fontSize: "20px",
                      marginBottom: "0px",
                    }}
                  >
                    25.24 MB <br />
                    <span style={{ color: "#404040", fontSize: "14px" }}>
                      Daily
                    </span>
                  </p>
                </div>
                <div className="p-lg-4" style={{ borderRight: "1px dashed" }}>
                  <span
                    className={this.state.lose === true ? "lose" : "win"}
                    style={{
                      fontSize: "11px",
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.lose === true
                      ? "Data Will Lose"
                      : "Data Will Save"}
                  </span>
                  <p
                    className={this.state.lose === true ? "lose" : "win"}
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      marginBottom: "0px",
                    }}
                  >
                    09.10 MB <br />
                    <span style={{ color: "#404040", fontSize: "14px" }}>
                      Daily
                    </span>
                  </p>
                </div>
                <div className="p-lg-4">
                  <span
                    className={this.state.lose === true ? "lose" : "gain"}
                    style={{
                      fontSize: "11px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    {this.state.lose === true ? "Total Lose" : "Total Save"}
                  </span>
                  <p
                    className={this.state.lose === true ? "lose" : "gain"}
                    style={{
                      fontWeight: "bold",
                      fontSize: "22px",
                      marginBottom: "0px",
                    }}
                  >
                    <FontAwesomeIcon icon={faGlobe} /> 12.3% <br />
                    <span style={{ color: "#404040", fontSize: "14px" }}>
                      Monthly
                    </span>
                  </p>
                </div>
                <p style={{ marginTop: "10px", fontSize: "12px" }}>
                  *** Data use Calculated based on{" "}
                  <span style={{ fontWeight: "bold" }}>4G Network</span> .
                </p>
                <p style={{ fontSize: "12px" }}>
                  *** Data use Calculated based on{" "}
                  <span style={{ fontWeight: "bold" }}>
                    Live Tracking Interval
                  </span>
                  .
                </p>
                <p style={{ fontSize: "12px" }}>
                  *** Data use Calculated based{" "}
                  <span style={{ fontWeight: "bold" }}>300 Sec</span> Live
                  Tracking Interval.
                </p>
              </div>
            </div>
          </div>
          <div className="p-grid bg-col p-lg-6">
            <div className="card" style={{ width: "100%", margin: "10px" }}>
              <h5
                style={{
                  borderBottom: "1px dashed",
                  paddingBottom: "10px",
                  fontWeight: "bold",
                }}
              >
                <FontAwesomeIcon icon={faBatteryFull} /> Battery will Use
                (Approximately)
              </h5>
              <div className="p-grid bg-col p-lg-12">
                <p
                  className={this.state.lose === true ? "lose" : "win"}
                  style={{
                    textAlign: "center",
                    fontSize: "13px",
                  }}
                >
                  {this.state.lose === true ? (
                    <>
                      <FontAwesomeIcon icon={faHeartBroken} /> Sorry ! your
                      battery will use
                      <span style={{ fontWeight: "bold" }}> 15.3%</span> more
                      monthly
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faHandPeace} /> Congratulation!
                      your battery will use
                      <span style={{ fontWeight: "bold" }}> 15.3%</span> less
                      monthly
                    </>
                  )}
                </p>
                <div className="p-lg-4" style={{ borderRight: "1px dashed" }}>
                  <span
                    style={{
                      color: "red",
                      fontSize: "11px",
                      fontWeight: "bold",
                    }}
                  >
                    Battery Will Use
                  </span>
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "red",
                      fontSize: "22px",
                      marginBottom: "0px",
                    }}
                  >
                    {this.state.lose === true ? (
                      <FontAwesomeIcon icon={faBatteryHalf} />
                    ) : (
                      <FontAwesomeIcon icon={faBatteryQuarter} />
                    )}{" "}
                    33.3% <br />
                    <span style={{ color: "#404040", fontSize: "14px" }}>
                      Daily
                    </span>
                  </p>
                </div>
                <div className="p-lg-4" style={{ borderRight: "1px dashed" }}>
                  <span
                    className={this.state.lose === true ? "lose" : "win"}
                    style={{
                      fontSize: "11px",
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.lose === true
                      ? " Battery Will Lose"
                      : " Battery Will Save"}
                  </span>
                  <p
                    className={this.state.lose === true ? "lose" : "win"}
                    style={{
                      fontWeight: "bold",
                      fontSize: "22px",
                      marginBottom: "0px",
                    }}
                  >
                    {this.state.lose === true ? (
                      <FontAwesomeIcon icon={faBatteryFull} />
                    ) : (
                      <FontAwesomeIcon icon={faBatteryHalf} />
                    )}
                    13.3% <br />
                    <span style={{ color: "#404040", fontSize: "14px" }}>
                      Daily
                    </span>
                  </p>
                </div>
                <div className="p-lg-4">
                  <span
                    className={this.state.lose === true ? "lose" : "gain"}
                    style={{
                      fontSize: "11px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    {this.state.lose === true ? " Total Lose" : " Total Save"}
                  </span>
                  <p
                    className={this.state.lose === true ? "lose" : "gain"}
                    style={{
                      fontWeight: "bold",
                      fontSize: "22px",
                      marginBottom: "0px",
                    }}
                  >
                    {this.state.lose === true ? (
                      <FontAwesomeIcon icon={faBatteryQuarter} />
                    ) : (
                      <FontAwesomeIcon icon={faBatteryThreeQuarters} />
                    )}
                    15.3% <br />
                    <span style={{ color: "#404040", fontSize: "14px" }}>
                      Monthly
                    </span>
                  </p>
                </div>
                <p style={{ marginTop: "10px", fontSize: "12px" }}>
                  *** Battery use Calculated based on{" "}
                  <span style={{ fontWeight: "bold" }}>4000mAh</span> Battery.
                </p>
                <p style={{ fontSize: "12px" }}>
                  *** Battery use Calculated based on{" "}
                  <span style={{ fontWeight: "bold" }}>
                    Live Tracking Interval
                  </span>{" "}
                  .
                </p>
                <p style={{ fontSize: "12px" }}>
                  *** Battery use Calculated based{" "}
                  <span style={{ fontWeight: "bold" }}>300 Sec</span> Live
                  Tracking Interval.
                </p>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="p-grid bg-col p-lg-12" style={{marginTop:"15px"}}>
        <div className="card" style={{width:"100%"}}>
                <h5>Mobile Data and Battery Savings</h5>
                <Chart type="bar" data={this.basicData} options={this.options.basicOptions} />
        </div>
        </div> */}
      </div>
    );
  }
}
