import React, { Component } from "react";
import axios from "axios";
import { Modal, Alert, Spinner } from "react-bootstrap";
import { Messages } from "primereact/messages";
import { ServicesofAxios } from "../webservices/servicesofaxios";
import { RadioButton } from "primereact/radiobutton";
import api from "../service/ApiService";
import { getDeviceSearchData } from "../service/ApiUrls";
import { Dialog } from "primereact/dialog";
import ErrorDialog from "../components/ErrorDialog";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";

class DeleteProfile extends Component {
  constructor(props) {
    super(props);
    console.log(props.rowData);
    //console.log("calling constuctor");
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.showSuccess = this.showSuccess.bind(this);

    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.rowData.id !== prevProps.rowData.id) {
      //this.fetchData(this.props.userID);
      //console.log("current object id ", this.props.rowData.id);
    }
  }

  showSuccess() {
    this.messages.show({
      severity: "success",
      summary: "Geofence",
      detail: "Added Successfully",
      life: 5000,
      closable: false,
    });
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({
      show: true,
      rowId: this.props.rowData.id,
      profileList: this.props.searchData,
      isLoading: false,
      isSubmitted: false,
    });
  }

  submitHandler = (e) => {
    e.preventDefault();
    this.setState({
      isLoading: true,
      isSubmitted: false,
    });
    const paramdata = {};
  };
  footer(name) {
    return (
      <div>
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-success"
          onClick={() => {
            this.setState({ confirm: false });
            // this.rowDelete(this.state.rowData);
          }}
        />
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ confirm: false });
          }}
        />
      </div>
    );
  }
  render() {
    const profileList =
      this.state.profileList !== undefined &&
      this.state.profileList.map((e, i) => {
        //console.log("e", e);
        return (
          <div
            className=""
            style={{ display: "flex", padding: "5px", marginLeft: "12px" }}
            key={i}
          >
            <RadioButton
              inputId={i + ""}
              name={e.name}
              value={e}
              onChange={(obj) => {
                this.setState({ profile: obj.value });
              }}
              checked={
                this.state.profile !== undefined &&
                this.state.profile.id === e.id
              }
            />
            <label htmlFor="profile" className="p-radiobutton-label">
              {e.name}
            </label>
          </div>
        );
      });

    let varients = [
      "primary",
      "secondary",
      "success",
      "danger",
      "warning",
      "light",
    ];
    let spinnerList = varients.map((e, i) => {
      return <Spinner key={i} animation="grow" variant={e} />;
    });

    return (
      <>
        <a
          className="custom-a"
          onClick={() => {
            this.setState({
              confirm: true,
              rowId: this.props.rowData.id,
              profileList: this.props.searchData,
            });
          }}
        >
          <Tooltip
            target=".pi-trash"
            position="top"
            mouseTrack
            mouseTracktop={20}
          />
          <i
            className="pi pi-trash"
            data-pr-tooltip="Delete"
            style={{ fontSize: "1.1em", color: "#000000", paddingRight: "5px" }}
          ></i>
        </a>
        <Dialog
          header="Delete Device Profile"
          visible={this.state.confirm}
          closable
          showHeader
          style={{ width: "35vw" }}
          modal={true}
          // footer={this.footer}
          onHide={() => this.setState({ confirm: false })}
        >
          <p
            style={{
              fontSize: "12px",
              textAlign: "justify",
            }}
          >
            Every device must have a profile. Hence user needs to select another
            profile to replace deleted profile for devices which are under that
            deleted profile.
          </p>
          <div class="col align-self-center">
            <div className="p-col-12 pop-box">
              <div
                className="form-inline d-flex justify-content-center md-form form-sm mt-0 b-input"
                style={{ padding: "3px 0px" }}
              >
                <input
                  className="form-control form-control-sm mr-2 b-n"
                  type="text"
                  placeholder="Enter Search Terms"
                  aria-label="Search"
                  style={{ width: "90%" }}
                  onClick={() => {}}
                />
                <i className="pi pi-search" aria-hidden="true"></i>
              </div>
              <div class="row align-items-start">
                <div
                  className="p-col-12"
                  style={{ display: "inline-block", padding: "5px" }}
                >
                  {profileList}
                </div>
              </div>
            </div>
          </div>

          <div
            className="p-col-12"
            style={{
              display: "inline-block",
              padding: "5px",
              textAlign: "center",
              marginTop: "15px",
            }}
          >
            <Button
              label="Yes"
              icon="pi pi-check"
              style={{ margin: "0px 5px" }}
              className="p-button-success"
              onClick={(e) => {
                this.handleClose();
                this.props.parentDelete(
                  this.props.rowData,
                  "",
                  this.state.profile
                );
                this.setState({ confirm: false });
              }}
            />
            <Button
              label="No"
              icon="pi pi-times"
              style={{ margin: "0px 5px" }}
              className="p-button-danger"
              onClick={() => {
                this.setState({ confirm: false });
              }}
            />
          </div>
        </Dialog>
        {/* <Modal size="sm" show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              Deleting the profile: {this.props.rowData.name} ?
            </Modal.Title>
          </Modal.Header>
          <form onSubmit={this.submitHandler}>
            {this.state.isLoading && (
              <>
                <div style={{ textAlign: "center", padding: "5px" }}>
                  {spinnerList}
                </div>
              </>
            )}
            <Modal.Body>
              {this.state.isSubmitted && (
                <>
                  <Messages ref={(el) => (this.messages = el)} />
                </>
              )}
              {this.state.error && (
                <Alert variant="danger">
                  <b>Error Occurred</b>
                </Alert>
              )}
              <>
                <div className="col-12 p-z">
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "justify",
                    }}
                  >
                    Every device must have a profile. Hence user needs to select
                    another profile to replace deleted profile for devices which
                    are under that deleted profile.
                  </p>
                </div>
                <div
                  className="p-col-12"
                  style={{
                    border: "1px solid #c2c2c2",
                    borderRadius: "10px",
                    padding: "0px",
                  }}
                >
                  <div
                    className="p-col-12"
                    style={{ display: "inline-block", padding: "5px" }}
                  >
                    {profileList}
                  </div>
                </div>
              </>
            </Modal.Body>
            <Modal.Footer style={{ display: "block", textAlign: "center" }}>
              <button
                type="submit"
                className="btn btn-primary"
                style={{ background: "#166A8F" }}
                onClick={(e) => {
                  this.handleClose();
                  this.props.parentDelete(
                    this.props.rowData,
                    "",
                    this.state.profile
                  );
                }}
              >
                Submit
              </button>
            </Modal.Footer>
          </form>
        </Modal> */}
      </>
    );
  }
}

export default DeleteProfile;
