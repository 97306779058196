import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { ServicesofAxios } from "../webservices/servicesofaxios";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import { GlobalSettingsType } from "../Enums";
import AssignInDiv from "../components/AssignInDiv";

class ClearCache extends Component {
  constructor(props) {
    super(props);
    this.webservice = new ServicesofAxios();

    this.state = {};
  }

  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: " Browser Barrier", url: "browsing-barrier" },
      { label: "Clear Cache" },
    ];
    const home = { icon: "pi pi-home", url: "dashboard" };

    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 ">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="p-col-12 row align-items-start">
          <div className="col-12 bg-col2">
            <div className="col-12 p-left">
              <p>
                <b>Clear Cache</b>
              </p>
              <p>
                It will wipe data from devices corresponding to selected
                profiles / groups / devices.
              </p>
            </div>
            <div className="row align-items-start p-left">
              <div className="col-12  p-r">
                <p
                  className="col-12 mt-3"
                  style={{ borderTop: "1px solid #d8d5d5", paddingTop: "10px" }}
                >
                  <b>Choose Recipients</b>
                </p>
                <AssignInDiv
                  dataToSend={{
                    id: GlobalSettingsType.FACTORYRESET,
                    ...this.state,
                  }}
                  type={GlobalSettingsType.FACTORYRESET}
                  fromModule="global"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClearCache;
