import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export const baseURL = window.baseURL;
// const baseURL = "https://www.mobionizer.com:8443/mdm";
// const baseURL = "https://mdmdev.commlinkinfotech.com:8443/mdm";
// const baseURL = "https://mdm.commlinkinfotech.com:8443/mdmtest";
// const baseURL = "https://mdm.commlinkinfotech.com:8443/dev";
// const baseURL = "http://localhost:8080";
export const redirectTograbEmail =
  window.location.protocol +
  "//" +
  window.location.host +
  "/factory-callback?email=x@gmail.com";
const loginURL = "/oauth/token";
const enrollMentUrl = "/enrollment-config-list";
const getEnrollmentAddUrl = enrollMentUrl + "/add";
const postEnrollmentAddUrl = enrollMentUrl + "/add";
const groupListUrl = "/group-list";
const profileListUrl = "/profile-list";
const appManagementUrl = "/app-management";
const appManagementVersionListUrl = appManagementUrl + "/version-list";
const uploadFileManagementUrl = "/uploads";
const userProfileUrl = "/user-list/my-profile";
const themeUrl = "/theme-list";
const addTheme = themeUrl + "/add";
const getProfileList = "/profile-list";
const addProfileList = getProfileList + "/add";

const locationGeofence = "/locations/geofences";
const addLocationGeofence = locationGeofence + "/add";
const locationSettings = "/locations/settings";
const locationhistory = "/locations/history";

const reportVersionWiseDevice = "/reports/version-wise-devices";
const reportOsWiseDevice = "/reports/os-wise-devices";
const reportGeoFenceBreach = "/reports/geofence-breach";
const reportAvailableStorage = "/reports/available-storage";
const reportAvailableAppStorage = "/reports/available-app-storage";
const reportAvailableContentStorgae = "/reports/available-content-storage";
const reportMonthlyDataUsage = "/reports/monthly-data-usage";
const reportLastConnectedDevices = "/reports/last-connected-devices";
const reportStatusWiseDevices = "/reports/status-wise-devices";

const devices = "/devices";
const deviceSerachData = devices + "/search-data";

const groupListAdd = groupListUrl + "/add";
const groupListDevices = groupListUrl + devices;
const groupListDevicesAssign = groupListUrl + "/assign";
const browserSettingsUrl = "/browsing-barrier/browser-settings";

//Exports
export const baseUrl = baseURL;
export const getEnrollMentUrl = baseURL + enrollMentUrl;
export const getLoginUrl = baseURL + loginURL;

export const getAddEnrollMentUrl = baseURL + getEnrollmentAddUrl;
export const postAddEnrollMentUrl = baseURL + postEnrollmentAddUrl;
export const getAppManagemnetUrl = baseURL + appManagementUrl;
export const getAppManagementVersionListUrl =
  baseURL + appManagementVersionListUrl;
export const postUploadFileManagementUrl = baseURL + uploadFileManagementUrl;
export const getUserProfileUrl = baseURL + userProfileUrl;
export const getThemeListUrl = baseURL + themeUrl;
export const postAddThemeUrl = baseURL + addTheme;
export const putAddThemeUrl = baseURL + themeUrl;
export const deleteThemeUrl = baseURL + themeUrl;

export const getProfileListUrl = baseURL + getProfileList;
export const postAddProfileUrl = baseURL + addProfileList;
export const putAddProfileUrl = baseURL + getProfileList;
export const deleteProfileUrl = baseURL + getProfileList;

export const getLocationGeofenceUrl = baseURL + locationGeofence;
export const postaddLocationGeofence = baseURL + addLocationGeofence;
export const deleteLocationGeofence = baseURL + locationGeofence;
export const postputLocationGeofence = baseURL + locationGeofence;

export const getLocationSettings = baseURL + locationSettings;
export const putLocationSetting = baseURL + locationSettings;
export const getLocationHistory = baseURL + locationhistory;

export const getVersionReport = baseURL + reportVersionWiseDevice;
export const getOSWiseReport = baseURL + reportOsWiseDevice;
export const getGeoFenceBreachReport = baseURL + reportGeoFenceBreach;
export const getAvailableStorageReport = baseURL + reportAvailableStorage;
export const getAvailableAppStorageReport = baseURL + reportAvailableAppStorage;
export const getAvailableContentStorgaeReport =
  baseURL + reportAvailableContentStorgae;
export const getMonthlyDataUsageReport = baseURL + reportMonthlyDataUsage;
export const getLastConnectedDevicesReport =
  baseURL + reportLastConnectedDevices;
export const getStatusWiseDevicesReport = baseURL + reportStatusWiseDevices;

export const getDevicesUrl = baseURL + devices;
export const getDeviceSearchData = baseURL + deviceSerachData;

export const getGroupListUrl = baseURL + groupListUrl;
export const postGroupListAdd = baseURL + groupListAdd;
export const getGroupListDevice = baseURL + groupListDevices;
export const putGroupList = baseURL + groupListUrl;
export const getGroupById = baseURL + groupListUrl;
export const deleteGroupList = baseURL + groupListUrl;

export const getContentPushUrl = baseUrl + "/content-push";
export const postContentPushUrl = baseUrl + "/content-push/add";
export const getContentPushFolderList = baseUrl + "/content-push/folders-list";
export const getGroupListAssign = baseUrl + groupListDevicesAssign;

export const getStatusWiseDevices = baseUrl + "/export/status-wise-devices";
export const getCreationDateWiseDevoices =
  baseUrl + "/export/creationdate-wise-devices";
export const getGeofenceAlertsReport =
  baseUrl + "/export/updationdate-wise-geofencealerts";
export const getConnectiondateWiseDevices =
  baseUrl + "/export/connectiondate-wise-devices";
export const getOsVersionWiseDevices =
  baseUrl + "/export/os-version-wise-devices";
export const getOsWiseDevices = baseUrl + "/export/os-wise-devices";
export const getDataUsageWiseDevices =
  baseUrl + "/export/data-usage-wise-devices";
export const cbrowserSettingsUrl = baseURL + browserSettingsUrl;

export function errorCompute(error) {
  let errorMessage = "";
  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    // console.log(error.response.data);
    // console.log(error.response.status);
    // console.log(error.response.headers);
    switch (error.response.status) {
      case 500:
        errorMessage = "Internal Server Error.";
        break;
      case 503:
        errorMessage = "Service unavailable.";
        //tests["Service unavailable."] = statMessages === 503;
        break;
      case 502:
        errorMessage = "502 bad gateway.";
        //tests["502 bad gateway."] = statMessages === 502;
        break;
      case 599:
        errorMessage = "Network connect timeout error.";
        // tests["Network connect timeout error."] = statMessages === 599;
        break;
      case 408:
        errorMessage = "Request timedout.";
        // tests["Request timedout."] = statMessages === 408;
        break;
      case 400:
        errorMessage = error.response.data.error_description;
        break;
      default:
        errorMessage = "Unhandlled error code.";
        break;
      //tests["Sorry, we are out of" + responseCode.code];
    }

    return confirmAlert({
      title: "Error Message",
      message: <p className="mod-p"> {errorMessage} </p>,
      buttons: [
        {
          label: "Ok",
          onClick: () => {},
        },
      ],
    });
  } else if (error.request) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     * of http.ClientRequest in Node.js
     */
    console.log(error.request);
    return confirmAlert({
      title: "Error Message",
      message: <p className="mod-p"> {error.request} </p>,
      buttons: [
        {
          label: "Ok",
          onClick: () => {},
        },
      ],
    });
  } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    return confirmAlert({
      title: "Error Message",
      message: <p className="mod-p"> {error.message} </p>,
      buttons: [
        {
          label: "Ok",
          onClick: () => {},
        },
      ],
    });
  }
}
