import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { ServicesofAxios } from "../webservices/servicesofaxios";
import { GlobalSettingsType } from "../Enums";
import AssignInDiv from "../components/AssignInDiv";

class ClearFiles extends Component {
  constructor(props) {
    super(props);
    this.webservice = new ServicesofAxios();

    this.state = { showError: false, clearFileSD: false, clearOwnFile: false };
  }
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  callParent = () => {
    this.setState({ showError: true });
  };
  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Global Settings", url: "global-settings" },
      { label: "Clear Files" },
    ];
    const home = { icon: "pi pi-home", url: "dashboard" };

    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 ">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="p-col-12 row align-items-start">
          <div className="col-12 bg-col2">
            <div className="col-12 p-left">
              <p>
                <b>Clear Files</b>
              </p>
              <p>
                Clear “Own Files” or “All Files From SD Card” of devices
                corresponding to selected profiles / groups / devices.
              </p>
            </div>
            <div className="row align-items-start p-left">
              <div className="col-12">
                <label htmlFor="messageTitle">
                  <b>Clear Files Options</b>
                </label>
                <div className="form-group mt-2">
                  {this.state.showError ? (
                    <p style={{ color: "red", fontWeight: "bold" }}>
                      *** Select Clear Files Options
                    </p>
                  ) : (
                    ""
                  )}
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="clearOwnFile"
                      id="clearOwnFile"
                      value="option1"
                      onChange={(e) => {
                        this.setState({ clearOwnFile: e.target.checked });
                      }}
                    />
                    <label className="form-check-label" htmlFor="clearOwnFile">
                      Clear Own Files
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="clearFileSD"
                      name="clearFileSD"
                      value="option2"
                      onChange={(e) => {
                        this.setState({ clearFileSD: e.target.checked });
                      }}
                    />
                    <label className="form-check-label" htmlFor="clearFileSD">
                      Clear All Files from SD Card
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-start p-left">
              <div className="col-12  p-r">
                <p
                  className="col-12 mt-3"
                  style={{ borderTop: "1px solid #d8d5d5", paddingTop: "10px" }}
                >
                  <b>Choose Recipients</b>
                </p>
                <AssignInDiv
                  dataToSend={{
                    id: GlobalSettingsType.CLEARFILE,
                    ...this.state,
                  }}
                  callParent={this.callParent}
                  type={GlobalSettingsType.CLEARFILE}
                  fromModule="global"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClearFiles;
