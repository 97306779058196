import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { GlobalSettingsType } from "../Enums";
import AssignInDiv from "../components/AssignInDiv";
import { Link } from "react-router-dom";
import { InputTextarea } from "primereact/inputtextarea";
import { confirmAlert } from "react-confirm-alert";
class PushMessage extends Component {
  constructor(props) {
    super(props);
    this.state = { showError: false, messageTitle: "", msgBody: "", length: 0 };
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  callParent = () => {
    this.setState({ showError: true });
  };
  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Global Settings", url: "global-settings" },
      { label: "Push Message" },
    ];
    const home = { icon: "pi pi-home", url: "dashboard" };

    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 ">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="p-col-12 row align-items-start">
          <div className="col-12 bg-col2">
            <div className="row justify-content-between">
              <div className="col-4" style={{ paddingTop: "15px" }}>
                <h2 style={{ paddingLeft: "15px" }}>Push Message</h2>
              </div>
              <div className="col-4">
                <Link
                  to={{
                    pathname: "/push-message-history",
                  }}
                >
                  <button
                    className="btn btn-primary cus-btn"
                    style={{ float: "right" }}
                  >
                    Archive
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-12 p-left">
              <p>
                Send a push notification to selected profiles / groups /
                devices. It will be shown into <b>“Admin Messages”</b> on
                devices.
              </p>
            </div>
            <div className="row align-items-start p-left">
              <div className="col-12 mb-2">
                {this.state.showError ? (
                  <p style={{ color: "red", fontWeight: "bold" }}>
                    ***Input fields can not be empty
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="messageTitle">Message Title</label>
                  <input
                    type="text"
                    className="form-control"
                    id="messageTitle"
                    name="messageTitle"
                    placeholder="Enter Message Title"
                    onChange={this.changeHandler}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="msgBody">
                    Message Body (*Maximum character limit 1000)
                  </label>
                  <InputTextarea
                    id="msgBody"
                    name="msgBody"
                    // onChange={(e) => this.setState({ value1: e.target.value })}
                    onChange={(e) => {
                      // this.changeHandler();
                      this.setState(
                        {
                          length: e.target.value.length,
                          msgBody: e.target.value,
                        },
                        () => {
                          if (this.state.length > 1000) {
                            confirmAlert({
                              title: "Error Message",
                              message: (
                                <p className="mod-p">
                                  Maximum character limit 1000
                                </p>
                              ),
                              buttons: [
                                {
                                  label: "Ok",
                                  onClick: () => {},
                                },
                              ],
                            });
                          }
                        }
                      );
                    }}
                    placeholder="Write here.."
                    rows={4}
                    cols={30}
                    style={{ width: "100%" }}
                    maxLength={1000}
                  />
                  {/* <input
                    type="text"
                    className="form-control"
                    id="msgBody"
                    name="msgBody"
                    placeholder="Enter Message Body"
                    onChange={this.changeHandler}
                  /> */}
                </div>
              </div>
            </div>
            <div className="row align-items-start mt-4 p-left">
              <div className="col-12  p-r">
                <p
                  className="col-12 mt-3"
                  style={{ borderTop: "1px solid #d8d5d5", paddingTop: "10px" }}
                >
                  <b>Choose Recipients</b>
                </p>
                <AssignInDiv
                  dataToSend={{
                    id: GlobalSettingsType.PUSHMESSAGE,
                    ...this.state,
                  }}
                  callParent={this.callParent}
                  type={GlobalSettingsType.PUSHMESSAGE}
                  fromModule="global"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PushMessage;
