import React, { Component } from "react";
import { Modal, Alert, Spinner } from "react-bootstrap";
import api from "../service/ApiService";
import { Link } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";
import {
  getDeviceSearchData,
  getCreationDateWiseDevoices,
  getGeofenceAlertsReport,
  geoAlertOrCreationDevice,
  baseUrl,
  getStatusWiseDevices,
  getConnectiondateWiseDevices,
  getOsVersionWiseDevices,
  getOsWiseDevices,
  getDataUsageWiseDevices,
} from "../service/ApiUrls";
import DatePicker from "react-datepicker";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { ACTIVEINACTIVESTATUS } from "../Enums";
const FileDownload = require("js-file-download");

class ReportDownload extends Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    let currentDate = new Date();
    this.state = {
      startDate: currentDate,
      endDate: currentDate,
      first: 0,
      rows: 10,
      criteria: 4,
      groupcities: [],
      profilecities: [],
      isLoading: false,
      errorMessage: "Something went wrong!",
      isSuccess: false,
      activeValue: ACTIVEINACTIVESTATUS.ACTIVE,
      ostype: 0,
      osversion: 7,
      usagerange: 0,
    };
  }

  onChanges = (e) => {
    this.setState({
      [e.target.name]: Number(e.target.value),
    });
  };
  statusChange = (e) => {
    this.setState({
      activeValue: Number(e.target.value),
    });
  };
  actionType = () => {
    try {
      switch (this.props.actionType !== undefined && this.props.actionType) {
        case 0:
          return "assign";
        case 2:
          return "unassign";
        case 1:
          return "uninstall";
      }
    } catch (error) {
      return "assign";
    }
  };
  dateConvert = (date) => {
    // Days you want to subtract

    // var last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    var fulldate =
      String("00" + day).slice(-2) +
      "-" +
      String("00" + month).slice(-2) +
      "-" +
      year;
    console.log(fulldate);
    return fulldate;
  };

  callgetProfileListGroup = (urlpart, { profileId } = {}) => {
    api
      .get(
        baseUrl +
          "/" +
          urlpart +
          "/" +
          (this.props.versionCall !== undefined
            ? this.props.versionCall + "/"
            : "") +
          this.props.dataToSend.id +
          "/" +
          this.actionType(),
        {
          params: { profileId: profileId, criteria: 1 },
        }
      )
      .then((res) => {
        if (res.data.data !== null) {
          this.setState({ groups: res.data.data.groups.content }, () => {
            let x = [];
            this.state.groups.map((e, i) => {
              if (e.isSelected) x.push(e);
            });
            this.setState({ groupcities: x });
          });
        }
      })
      .catch((err) => console.log(err));
  };

  callgetProfileListDevice = (
    urlpart,
    { profileId, imei, groupId, first, limit } = {}
  ) => {
    api
      .get(
        baseUrl +
          "/" +
          urlpart +
          "/" +
          (this.props.versionCall !== undefined
            ? this.props.versionCall + "/"
            : "") +
          this.props.dataToSend.id +
          "/" +
          this.actionType(),
        {
          params: {
            profileId: profileId,
            imei: imei,
            groupId: groupId,
            criteria: 2,
            first,
            limit,
          },
        }
      )
      .then((res) => {
        if (res.data.data !== null) {
          this.setState(
            {
              devices: res.data.data.devices.content,
              totalRecords: res.data.data.devices.totalRecords,
            },
            () => {
              let x = [];
              this.state.devices.map((e, i) => {
                if (e.isSelected) x.push(e);
              });
              this.setState({ devicecities: x });
            }
          );
        }
      })
      .catch((err) => console.log(err));
  };

  callDeviceSearchData = () => {
    api
      .get(getDeviceSearchData)
      .then((res) => {
        if (res.data.result.isError === false) {
          //console.log("Inside false");
          this.setState(
            {
              profileList: res.data.data.profileList,
              groupList: res.data.data.groupList,
            },
            () => {
              // this.setState({ selectedProfile: this.state.profileList[0] });
            }
          );
        }
      })
      .catch((err) => {
        localStorage.setItem("loggedIn", false);
        // window.location = process.env.PUBLIC_URL + "/login";
      });
  };

  callOsVersionWiseDevices = ({ active, profileid, groupid }) => {
    api
      .get(getOsVersionWiseDevices, {
        responseType: "blob",
        params: {
          version: active,
          profileid: profileid,
          groupid: groupid,
        },
      })
      .then((res) => {
        const fileName = res.headers["content-disposition"].split(
          "filename="
        )[1];
        FileDownload(res.data, fileName);
      })
      .catch((err) => {});
  };
  callOsWiseDevices = ({ active, profileid, groupid }) => {
    api
      .get(getOsWiseDevices, {
        responseType: "blob",
        params: {
          os: active,
          profileid: profileid,
          groupid: groupid,
        },
      })
      .then((res) => {
        const fileName = res.headers["content-disposition"].split(
          "filename="
        )[1];
        FileDownload(res.data, fileName);
      })
      .catch((err) => {});
  };
  callStatusWiseDeviceReport = ({ active, profileid, groupid }) => {
    api
      .get(getStatusWiseDevices, {
        responseType: "blob",
        params: {
          status: active,
          profileid: profileid,
          groupid: groupid,
        },
      })
      .then((res) => {
        const fileName = res.headers["content-disposition"].split(
          "filename="
        )[1];
        FileDownload(res.data, fileName);
      })
      .catch((err) => {});
  };
  callDataUsageWiseDevices = ({ active, profileid, groupid }) => {
    api
      .get(getDataUsageWiseDevices, {
        responseType: "blob",
        params: {
          usagerange: active,
          profileid: profileid,
          groupid: groupid,
        },
      })
      .then((res) => {
        const fileName = res.headers["content-disposition"].split(
          "filename="
        )[1];
        FileDownload(res.data, fileName);
      })
      .catch((err) => {});
  };
  callConnectiondateWiseDevices = ({
    profileid,
    groupid,
    start = this.dateConvert(this.state.startDate),
    end = this.dateConvert(this.state.endDate),
  }) => {
    api
      .get(getConnectiondateWiseDevices, {
        responseType: "blob",
        params: {
          start: start,
          end: end,
          profileid: profileid,
          groupid: groupid,
        },
      })
      .then((res) => {
        const fileName = res.headers["content-disposition"].split(
          "filename="
        )[1];
        FileDownload(res.data, fileName);
      })
      .catch((err) => {});
  };
  callCrationDateWiseDevices = ({
    geoAlertOrCreationDevice,
    start = this.dateConvert(this.state.startDate),
    end = this.dateConvert(this.state.endDate),
    profileid,
    groupid,
  }) => {
    api
      .get(
        geoAlertOrCreationDevice === true
          ? getCreationDateWiseDevoices
          : getGeofenceAlertsReport,
        {
          responseType: "blob",
          params: {
            start: start,
            end: end,
            profileid: profileid,
            groupid: groupid,
          },
        }
      )
      .then((res) => {
        const fileName = res.headers["content-disposition"].split(
          "filename="
        )[1];
        FileDownload(res.data, fileName);
      })
      .catch((err) => {});
  };
  startChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  endChange = (date) => {
    this.setState({
      endDate: date,
    });
  };
  componentDidMount() {}

  onPageChange(event) {
    this.setState({
      first: event.first,
      rows: event.rows,
    });
  }
  cusPopup = (val) => () => {
    //alert("Hello");
    switch (val) {
      case 0:
        this.callgetThemeListProfile(this.urlPartFinding());
        break;
      case 1:
        this.callgetProfileListGroup(this.urlPartFinding());
        break;
      case 2:
        break;
      default:
        break;
    }
  };

  handleClose() {
    this.setState({
      show: false,
      groupcities: [],
      profilecities: [],
      opt: this.dropOption !== undefined && this.dropOption[0],
    });
  }

  urlPartFinding = () => {
    switch (this.props.fromModule) {
      case "profile":
        return "profile-list";
      case "appManagement":
        return "app-management";
      case "contentPush":
        return "content-push";
      case "geo":
        return "locations/geofences";
      case "theme":
        return "theme-list";
      default:
        return "profile-list";
    }
  };

  handleShow() {
    this.setState({ show: true }, () => {
      this.callDeviceSearchData();
    });
  }

  render() {
    const profileListDropDown = this.state.profileList;
    let profileOptions =
      profileListDropDown !== undefined &&
      profileListDropDown.length > 0 &&
      profileListDropDown.map((e, i) => {
        //console.log("profile", e);
        return (
          <option key={i} value={e.id}>
            {e.name}
          </option>
        );
      });
    const groupListDropDown = this.state.groupList;
    let groupOptions =
      groupListDropDown !== undefined &&
      groupListDropDown.length > 0 &&
      groupListDropDown.map((e, i) => {
        //console.log("profile", e);
        return (
          <option key={i} value={e.id}>
            {e.name}
          </option>
        );
      });

    const profileSelect = (
      <>
        <label style={{ fontWeight: "700" }}>Profile</label>
        <select
          className="form-control"
          // id="exampleFormControlSelect1"
          onChange={(e) => {
            //console.log("e in profile ", e.target.value);
            let profileObject = this.state.profileList.find((obj) => {
              //console.log("object", obj.id);
              return obj.id === e.target.value;
            });
            //console.log("profile object", profileObject);
            this.setState(
              {
                ...this.state,
                selectedProfile:
                  profileObject !== undefined ? profileObject : null,
              },
              () => {
                // if (this.state.selectedProfile !== undefined) {
                //   this.callgetProfileListDevice(this.urlPartFinding(), {
                //     profileId:
                //       this.state.selectedProfile !== null
                //         ? this.state.selectedProfile.id
                //         : null,
                //   });
                // }
              }
            );
          }}
          id="selectedProfile"
          name="selectedProfile"
          value={
            this.state.selectedProfile !== null &&
            this.state.selectedProfile !== undefined
              ? this.state.selectedProfile.id
              : null
          }
        >
          <option>Select Profile</option>
          {profileOptions}
        </select>
      </>
    );

    const groupSelect = (
      <>
        <label style={{ fontWeight: "700" }}>Group</label>
        <select
          className="form-control"
          onChange={(e) => {
            let groupobject = this.state.groupList.find((obj) => {
              return obj.id === e.target.value;
            });
            this.setState(
              {
                ...this.state,
                selectedGroup: groupobject !== undefined ? groupobject : null,
              },
              () => {
                // if (this.state.selectedGroup !== undefined) {
                //   this.callgetProfileListDevice(this.urlPartFinding(), {
                //     groupId:
                //       this.state.selectedGroup !== null
                //         ? this.state.selectedGroup.id
                //         : null,
                //   });
                // }
              }
            );
          }}
          id="selectedGroup"
          name="selectedGroup"
          value={
            this.state.selectedGroup !== undefined &&
            this.state.selectedGroup !== null
              ? this.state.selectedGroup.id
              : null
          }
        >
          <option>Select Group</option>
          {groupOptions}
        </select>
      </>
    );

    const deviceSearch = (
      <>
        <div className="row justify-content-center col-12">
          <div
            className="col-3 p-m"
            style={{ marginBottom: "0px", display: "inline-block" }}
          >
            <div className="form-group">{profileSelect}</div>
          </div>
          <div
            className="col-3 p-m"
            style={{ marginBottom: "0px", display: "inline-block" }}
          >
            <div className="form-group">{groupSelect}</div>
          </div>
          <div
            className="col-3 p-m"
            style={{
              marginBottom: "0px",
              display:
                this.props.moduleName === "DeviceActive"
                  ? "inline-block"
                  : this.props.moduleName === "OSCount"
                  ? "inline-block"
                  : this.props.moduleName === "OSVersion"
                  ? "inline-block"
                  : this.props.moduleName === "DataUsage"
                  ? "inline-block"
                  : "none",
            }}
          >
            {this.props.moduleName === "DeviceActive" ? (
              <div className="form-group">
                <label style={{ fontWeight: "700" }}>Status</label>
                <select
                  className="form-control"
                  id="status"
                  name="status"
                  onChange={this.statusChange}
                  value={this.state.activeValue}
                >
                  <option value="">Select Status</option>
                  <option value={ACTIVEINACTIVESTATUS.ACTIVE}>Active</option>
                  <option value={ACTIVEINACTIVESTATUS.INACTIVE}>
                    Inactive
                  </option>
                </select>
              </div>
            ) : this.props.moduleName === "OSCount" ? (
              <div className="form-group">
                <label style={{ fontWeight: "700" }}>OS Type</label>
                <select
                  className="form-control"
                  id="ostype"
                  name="ostype"
                  onChange={this.onChanges}
                  value={this.state.ostype}
                >
                  <option value="">Select OS Type</option>
                  <option value={0}>Android</option>
                  <option value={1}>ISO</option>
                </select>
              </div>
            ) : this.props.moduleName === "OSVersion" ? (
              <div className="form-group">
                <label style={{ fontWeight: "700" }}>OS Version</label>
                <select
                  className="form-control"
                  id="osversion"
                  name="osversion"
                  onChange={this.onChanges}
                  value={this.state.osversion}
                >
                  <option value="">Select OS Version</option>
                  <option value={7}>Android 7</option>
                  <option value={8}>Android 8</option>
                  <option value={9}>Android 9</option>
                  <option value={10}>Android 10</option>
                  <option value={11}>Android 11</option>
                  <option value={12}>Android 12</option>
                </select>
              </div>
            ) : this.props.moduleName === "DataUsage" ? (
              <div className="form-group">
                <label style={{ fontWeight: "700" }}>Data Usages</label>
                <select
                  className="form-control"
                  id="usagerange"
                  name="usagerange"
                  onChange={this.onChanges}
                  value={this.state.usagerange}
                >
                  <option value="">Select Data Usages</option>
                  <option value={0}>0-100</option>
                  <option value={1}>101-300</option>
                  <option value={2}>301-600</option>
                  <option value={3}>600+</option>
                </select>
              </div>
            ) : (
              ""
            )}
          </div>
          {/* <div
          style={{
            display:
              this.props.moduleName === "DeviceActive" ? "none" : "inline-block",
          }}
        > */}
          <div
            className="col-3 p-m"
            style={{
              marginBottom: "0px",
              display:
                this.props.moduleName === "DeviceActive"
                  ? "none"
                  : this.props.moduleName === "OSCount"
                  ? "none"
                  : this.props.moduleName === "OSVersion"
                  ? "none"
                  : this.props.moduleName === "DataUsage"
                  ? "none"
                  : "inline-block",
            }}
          >
            <div className="form-group">
              <label style={{ fontWeight: "700" }}>Start Date</label>
              <DatePicker
                selected={this.state.startDate}
                onChange={this.startChange}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                isClearable
                className="date-class p-col-12 p-md-12 p-xl-12"
                // selectsStart={this.state.startDate}
                dateFormat="dd-MM-yyyy"
                placeholderText="Select Start Date"
              />
            </div>
          </div>
          <div
            className="col-3 p-m"
            style={{
              marginBottom: "0px",
              display:
                this.props.moduleName === "DeviceActive"
                  ? "none"
                  : this.props.moduleName === "OSCount"
                  ? "none"
                  : this.props.moduleName === "OSVersion"
                  ? "none"
                  : this.props.moduleName === "DataUsage"
                  ? "none"
                  : "inline-block",
            }}
          >
            <div className="form-group">
              <label style={{ fontWeight: "700" }}>End Date</label>
              <DatePicker
                selected={this.state.endDate}
                onChange={this.endChange}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                minDate={this.state.startDate}
                isClearable
                className="date-class p-col-12 p-md-12 p-xl-12"
                // selectsEnd={this.state.endDate}
                dateFormat="dd-MM-yyyy"
                placeholderText=" Select End Date"
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-center col-12">
          <div
            className="col-3 p-m"
            style={{
              marginTop: "10px",
              display: "inline-block",
              textAlign: "center",
            }}
          >
            <button
              className="btn btn-primary5 export"
              style={{ textAlign: "center", display: "inline-block" }}
              data-pr-tooltip="Export"
              onClick={() => {
                //
                if (this.props.moduleName === "DeviceActive") {
                  this.callStatusWiseDeviceReport({
                    active: this.state.activeValue,
                    profileid:
                      this.state.selectedProfile !== undefined &&
                      this.state.selectedProfile !== null
                        ? this.state.selectedProfile.id
                        : null,
                    groupid:
                      this.state.selectedGroups !== undefined &&
                      this.state.selectedGroups !== null
                        ? this.state.selectedGroups.id
                        : null,
                  });
                }
                if (this.props.moduleName === "GeoFence") {
                  this.callCrationDateWiseDevices({
                    geoAlertOrCreationDevice: false,
                    profileid:
                      this.state.selectedProfile !== undefined &&
                      this.state.selectedProfile !== null
                        ? this.state.selectedProfile.id
                        : null,
                    groupid:
                      this.state.selectedGroups !== undefined &&
                      this.state.selectedGroups !== null
                        ? this.state.selectedGroups.id
                        : null,
                  });
                }
                if (this.props.moduleName === "ConnectedDevices") {
                  this.callConnectiondateWiseDevices({
                    profileid:
                      this.state.selectedProfile !== undefined &&
                      this.state.selectedProfile !== null
                        ? this.state.selectedProfile.id
                        : null,
                    groupid:
                      this.state.selectedGroups !== undefined &&
                      this.state.selectedGroups !== null
                        ? this.state.selectedGroups.id
                        : null,
                  });
                }
                if (this.props.moduleName === "OSCount") {
                  this.callOsWiseDevices({
                    active: this.state.ostype,
                    profileid:
                      this.state.selectedProfile !== undefined &&
                      this.state.selectedProfile !== null
                        ? this.state.selectedProfile.id
                        : null,
                    groupid:
                      this.state.selectedGroups !== undefined &&
                      this.state.selectedGroups !== null
                        ? this.state.selectedGroups.id
                        : null,
                  });
                }
                if (this.props.moduleName === "OSVersion") {
                  this.callOsVersionWiseDevices({
                    active: this.state.osversion,
                    profileid:
                      this.state.selectedProfile !== undefined &&
                      this.state.selectedProfile !== null
                        ? this.state.selectedProfile.id
                        : null,
                    groupid:
                      this.state.selectedGroups !== undefined &&
                      this.state.selectedGroups !== null
                        ? this.state.selectedGroups.id
                        : null,
                  });
                }
                if (this.props.moduleName === "DataUsage") {
                  this.callDataUsageWiseDevices({
                    active: this.state.usagerange,
                    profileid:
                      this.state.selectedProfile !== undefined &&
                      this.state.selectedProfile !== null
                        ? this.state.selectedProfile.id
                        : null,
                    groupid:
                      this.state.selectedGroups !== undefined &&
                      this.state.selectedGroups !== null
                        ? this.state.selectedGroups.id
                        : null,
                  });
                }
              }}
            >
              <FaCloudDownloadAlt className="export" data-pr-tooltip="Export" />
            </button>
          </div>
        </div>
      </>
    );
    const showModal = (
      <Modal size="lg" show={this.state.show} onHide={this.handleClose}>
        <Modal.Header className="mb-0" closeButton>
          <p className="mb-0">
            <b>{this.props.NameToShow}</b>
          </p>
        </Modal.Header>
        <Modal.Body>
          <>
            <div
              className="row justify-content-center"
              style={{ padding: "20px 10px" }}
            >
              {deviceSearch}
            </div>
          </>
        </Modal.Body>
      </Modal>
    );

    const partModel = (
      <Link to="#" onClick={this.handleShow}>
        <Tooltip
          target=".export"
          position="top"
          mouseTrack
          mouseTrackTop={15}
        />
        <span
          className="export"
          style={{ textAlign: "right", width: "100%" }}
          data-pr-tooltip="Export"
        >
          <FaCloudDownloadAlt
            style={{
              float: "right",
              color: "#159743",
              fontWeight: "bold",
              fontSize: "1.3em",
            }}
          />
        </span>
      </Link>
    );

    return (
      <>
        {partModel}
        {showModal}
      </>
    );
  }
}

export default ReportDownload;
