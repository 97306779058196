import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import api from "../service/ApiService";
import {
  getProfileListUrl,
  deleteProfileUrl,
  getDeviceSearchData,
} from "../service/ApiUrls";
import { OSTypeEnum, ActionAssign, ActionCopyOREdit } from "../Enums";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import Loader from "../components/Loader";
import Assign from "../components/Assign";
import DeleteProfile from "../components/DeleteProfile";
import { OverlayPanel } from "primereact/overlaypanel";
import { Tooltip } from "primereact/tooltip";
import { confirmAlert } from "react-confirm-alert";
const helpIcon = require("../assets/icons/help_icon.png");

export class DeviceProfile extends Component {
  constructor() {
    super();
    this.state = {
      profile: null,
      editing: false,
      visible: false,
      currentPage: 1,
      drows: 20,
      dfirst: 0,
    };
    // this.alldata = new AllData();
    this.editorForRowEditing = this.editorForRowEditing.bind(this);
    this.onRowEditInit = this.onRowEditInit.bind(this);
  }

  callgetProfileList = async ({
    first = 0,
    limit = 900,
    filter = null,
  } = {}) => {
    return api
      .get(getProfileListUrl, {
        params: {
          first,
          limit,
          filter,
        },
      })
      .then((res) => {
        const {
          data,
          result: { isError, errMsg, statusCode },
        } = res.data;
        //console.log(data, "isError", isError, "error msg", errMsg);
        //this.setState({ systemApps: data.content, isLoading: false });
        if (isError === false)
          return { data, result: { isError, errMsg, statusCode } };
        // else return { result: { isError, errMsg, statusCode } };
      })
      .catch((err) => {
        console.log("err", err);
        //this.props.history.push("/login");
      });
  };

  callDeviceSearchData = () => {
    api
      .get(getDeviceSearchData)
      .then((res) => {
        if (res.data.result.isError === false) {
          //console.log("Inside false");
          this.setState({ profileList: res.data.data.profileList });
        }
      })
      .catch((err) => {
        localStorage.setItem("loggedIn", false);
        window.location = process.env.PUBLIC_URL + "/login";
      });
  };

  callProfileList = async ({ filter = null } = {}) => {
    this.setState({ visible: true }, async () => {
      let response = await this.callgetProfileList({ filter: filter });
      if (
        response !== undefined &&
        response.data !== undefined &&
        response.data !== null
      ) {
        this.setState({ ...response.data, visible: false }, () => {
          this.callDeviceSearchData();
        });
      }
    });
  };

  componentDidMount() {
    this.callProfileList();
    // this.callDeviceSearchData();
  }
  onRowEditInit(event) {
    console.log("from init", event);
    // this.clonedCars[event.data.vin] = { ...event.data };
  }

  rowMyEdit(rowData1, column) {
    console.log("from rowMyEdit", column);
  }
  onEditorValueChangeForRowEditing(props, value) {
    let updatedCars = [...props.value];
    updatedCars[props.rowIndex][props.field] = value;
    this.setState({ cars2: updatedCars });
    //console.log(value);
  }

  editorForRowEditing(props, field) {
    return (
      <InputText
        type="text"
        value={props.rowData[field]}
        onChange={(e) =>
          this.onEditorValueChangeForRowEditing(props, e.target.value)
        }
      />
    );
  }
  rowDelete = (rowData, column, replaceProfileId) => {
    this.setState(
      { visible: true, textInLoader: "Deleteing Profile ...." },
      () => {
        // alert("Do you want to delete the row");
        console.log("replaceProfileId ", replaceProfileId);
        var array = [...this.state.content]; // make a separate copy of the array
        var index = array.indexOf(rowData);
        if (index !== -1) {
          try {
            api
              .delete(deleteProfileUrl + "/" + rowData.id, {
                params: { replaceid: replaceProfileId.id },
              })
              .then((res) => {
                if (res.data.result.isError === false) {
                  this.setState({ content: array, visible: false }, () => {
                    confirmAlert({
                      title: "Success Message",
                      message: (
                        <p className="mod-sp">Deleted Profile Successfully</p>
                      ),
                      buttons: [
                        {
                          label: "Ok",
                          onClick: () => {
                            array.splice(index, 1);
                            this.callDeviceSearchData();
                          },
                        },
                      ],
                    });
                  });
                } else if (res.data.result.isError === true) {
                  this.setState({ visible: false }, () => {
                    confirmAlert({
                      title: "Error Message",
                      message: (
                        <p className="mod-p"> {res.data.result.errorMsg} </p>
                      ),
                      buttons: [
                        {
                          label: "Ok",
                          onClick: () => {
                            this.callDeviceSearchData();
                          },
                        },
                      ],
                    });
                  });
                  //window.location = process.env.PUBLIC_URL + "/device-profile-list";
                }
              });
          } catch (err) {
            this.setState({ visible: false });
            window.location = process.env.PUBLIC_URL + "/device-profile-list";
          }
        }
      }
    );
  };

  rowEdit(rowData, column) {}

  onRowEditSave(event) {
    console.log("from save", event);
  }
  onDialogueHide = () => {
    this.setState({ visible: false });
  };

  onRowEditCancel(event) {
    console.log("from row edit cancel ", event);
  }

  actionTemplate(rowData, column) {
    return (
      <div>
        <a>
          <Tooltip
            target=".pi-pencil"
            position="top"
            mouseTrack
            mouseTracktop={20}
          />
          <i
            className="pi pi-pencil"
            data-pr-tooltip="Edit"
            style={{
              fontSize: "1.1em",
              color: "#000000",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
            onClick={() =>
              // console.log("onClick", rowData);
              this.props.history.push({
                pathname: "/device-profile",
                state: { e: rowData, from: ActionCopyOREdit.EDIT },
              })
            }
          ></i>
        </a>
        <a>
          <Tooltip
            target=".pi-copy"
            position="top"
            mouseTrack
            mouseTracktop={20}
          />
          <i
            className="pi pi-copy"
            data-pr-tooltip="Copy"
            style={{
              fontSize: "1.1em",
              color: "#000000",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
            onClick={() => {
              this.props.history.push({
                pathname: "/device-profile",
                state: { e: rowData, from: ActionCopyOREdit.COPY },
              });
            }}
          ></i>
        </a>
        <DeleteProfile
          rowData={rowData}
          searchData={this.state.profileList}
          parentDelete={this.rowDelete}
        />
        <Assign
          showIcon={true}
          name={"Device Profile Assign: " + rowData.name}
          dataToSend={rowData}
          fromModule="profile"
          actionType={ActionAssign.ASSIGN}
        />
        {/* <a href="">
          <i className="pi pi-user-plus edit_icon"></i>
        </a> */}
      </div>
    );
  }

  onBody(rowData, column) {
    const property = column.field;
    const getKeyByValue = (obj, value) =>
      Object.keys(obj).find((key) => obj[key] === value);
    return rowData.hasOwnProperty(property) && property === "osType"
      ? getKeyByValue(OSTypeEnum, rowData[property])
      : rowData[property];
  }

  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Device Outline", url: "device-outline" },
      { label: "Device Profile List" },
    ];

    const header = (
      <div className="row justify-content-end">
        <div className="p-col-12 p-md-4 p-lg-4">
          <h2 style={{ textAlign: "left", paddingTop: "10px" }}>
            Device Profile List
          </h2>
        </div>
        <div className="p-col-12 p-md-4 p-lg-4">
          <div id="search" className="p-inputgroup">
            <InputText
              placeholder="Enter Search Item"
              onChange={(e) => {
                this.setState({ filterData: e.target.value }, () => {
                  if (this.state.filterData.length === 0) {
                    this.callProfileList();
                  }
                });
              }}
              onKeyDown={(e) => {
                if (e.keyCode == 13) {
                  this.callProfileList({ filter: e.target.value });
                }
              }}
            />
            <Button
              icon="pi pi-search"
              className="p-button-primary s-icon s-btn"
              onClick={() => {
                this.callProfileList({ filter: this.state.filterData });
              }}
            />
          </div>
        </div>
        <div className="p-col-12 p-md-4 p-lg-4" style={{ textAlign: "right" }}>
          <Link to="/device-profile">
            <button
              className="btn btn-primary"
              // style={{ float: "right", marginLeft: "20px" }}
            >
              + Add New
            </button>
          </Link>
        </div>
      </div>
    );
    const home = { icon: "pi pi-home", url: "dashboard" };
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 p-ll">
          <Dialog
            visible={this.state.visible}
            modal={true}
            style={{ width: "25vw" }}
            closable={false}
            showHeader={false}
            onHide={this.onDialogueHide}
          >
            <Loader textToShow={this.state.textInLoader} />
          </Dialog>
          <div className="p-col-9 inline-b p-ll">
            <BreadCrumb model={items} home={home} />
          </div>
          <div className="p-col-3 inline-b p-zz">
            <img
              src={helpIcon}
              width="20"
              height="20"
              onClick={(e) => this.op.toggle(e)}
              style={{
                marginBottom: "-10px",
                float: "right",
                cursor: "pointer",
              }}
            />
          </div>
          <OverlayPanel
            ref={(el) => (this.op = el)}
            id="overlay_panel"
            showCloseIcon={true}
          >
            <div
              className="col"
              style={{
                width: "fit-content",
                maxWidth: "300px",
                padding: "0px",
              }}
            >
              <div className="helptip-title">
                <b>How to : Device Profile</b>
              </div>
              <div className="helptip-des">
                A device profile represents a set of policies about how a device
                can be managed. Every device or group must have a device
                profile. At-first a user needs to create a profile consisting a
                list of applications, a specific theme(optional) and a set of
                settings for example: network settings, display settings,
                bluetooth settings etc. After creation, the user can apply the
                profile on multiple devices at the same time and start
                monitoring and managing devices.
              </div>
            </div>
          </OverlayPanel>
        </div>
        <div className="p-grid bg-col p-lg-12">
          <div className="datatable-paginator-demo">
            <DataTable
              ref={(el) => (this.dt = el)}
              value={this.state.content}
              style={{ marginBottom: "20px" }}
              header={header}
              responsive={true}
              globalFilter={this.state.globalFilter}
              emptyMessage="No records found"
              paginator
              lazy
              onPage={(event) => {
                //console.log("Event", event);

                setTimeout(() => {
                  const { first, rows, page } = event;

                  this.setState(
                    {
                      dfirst: first,
                      page,
                      drows: rows,
                      currentPage: page + 1,
                    },
                    async () => {
                      let response = await this.callgetProfileList({
                        first: event.page,
                        limit: this.state.drows,
                      });
                      this.setState({ ...response.data });
                    }
                  );
                }, 500);
              }}
              first={this.state.dfirst}
              currentPage={this.state.currentPage}
              totalRecords={this.state.totalRecords}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              rows={this.state.drows}
              rowsPerPageOptions={[10, 20, 30, 50, 100]}
            >
              <Column
                field="profile_name"
                header="Profile Name"
                style={{ display: "none" }}
              />
              <Column field="name" header="Name" />

              <Column
                field="osType"
                header="Os Type"
                body={this.onBody}
                style={{ textAlign: "center" }}
              />
              <Column
                field="countAssignedDevice"
                header="Assigned Devices"
                style={{ textAlign: "center" }}
              />
              <Column
                field="countAssignedGroup"
                header="Assigned Groups"
                style={{ textAlign: "center" }}
              />
              <Column
                field="countAllowedApp"
                header="Allowed Apps"
                style={{ textAlign: "center" }}
              />
              <Column
                header="Action"
                body={this.actionTemplate.bind(this)}
                style={{ textAlign: "center" }}
              />
            </DataTable>
          </div>
        </div>
      </div>
    );
  }
}
