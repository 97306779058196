import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Link } from "react-router-dom";
import api from "../service/ApiService";
import { getUserProfileUrl } from "../service/ApiUrls";
const push_message = require("../assets/push-message.png");
const factory_reset = require("../assets/factory-reset.png");
const factory_reset2 = require("../assets/factory-reset.png");
const lock = require("../assets/lock.png");
const deactive_luncher = require("../assets/deactive-luncher.png");
const clear_launcher_data = require("../assets/clear-app.png");
const clear_file = require("../assets/clear-file.png");
const search = require("../assets/device-group.png");
export class GlobalSetting extends Component {
  constructor(props) {
    super(props);
  }

  webcallForProfile = async () => {
    try {
      let {
        data: {
          data,
          result: { isError, errorMsg, statusCode },
        },
      } = await api.get(getUserProfileUrl);
      if (isError === false) this.setState({ ...data });
      else this.props.history.replace("/login");
      //console.log("res ", res.data);
    } catch (err) {
      localStorage.setItem("loggedIn", false);
      // window.location = process.env.PUBLIC_URL + "/login";
    }
  };
  componentDidMount() {
    // this.webcallForProfile();
  }

  webMenuDynamic = () => {
    console.log("calling", "webMenuDynamic");
    let imageMap = {
      "/push-message": push_message,
      "/factory-reset": factory_reset2,
      "/lock": lock,
      "/deactive-launcher": deactive_luncher,
      "/clear-launcher-data": clear_launcher_data,
      "/clear-files": clear_file,
      "/factory-reset-protection": factory_reset,
    };
    return (
      this.props.extraProp !== null &&
      this.props.extraProp !== undefined &&
      this.props.extraProp.map((e, v) => {
        if (e.feature.category === 0) {
          if (e.feature.title !== "Dashboard") {
            let helpingMenu = imageMap[e.feature.protectedUrl];
            let icon =
              helpingMenu !== undefined ? helpingMenu : "pi pi-fw pi-dollar";
            return (
              <div key={"mo-" + v} className="p-col-12 p-md-3 p-xl-3 p-sm-6">
                <div key={"ch1-" + v} className="small-box bg-aqua">
                  <div key={"ch11-" + v} className="icon">
                    <img
                      src={icon}
                      width="60px"
                      style={{ width: "60px", height: "60px" }}
                      className="mx-auto d-block"
                    />
                    {/* <i
                      className={icon + " " + "mx-auto d-block"}
                      style={{
                        width: "60px",
                        height: "60px",
                        color: "#ffffff",
                        fontSize: "3.5em",
                      }}
                    ></i> */}
                  </div>
                  <Link
                    to={e.feature.protectedUrl}
                    className="small-box-footer"
                  >
                    {e.feature.title}
                  </Link>
                </div>
              </div>
            );
          }
        }
      })
    );
  };

  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Global Settings" },
    ];

    const home = { icon: "pi pi-home", url: "dashboard" };
    return (
      <div className="p-grid p-fluid dashboard">
        <div className="p-col-12 p-lg-12">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        {this.props.extraProp !== undefined && this.webMenuDynamic()}
        {/* <div className="p-col-12 row align-items-start">
          <div className="p-col-12 p-md-3 p-xl-3 p-sm-6">
            <div className="small-box bg-aqua">
              <div className="icon">
                <img
                  alt={"push"}
                  src={push_message}
                  style={{ width: "60px", height: "60px" }}
                  className="mx-auto d-block"
                />
              </div>
              <Link to="push-message" className="small-box-footer">
                Push Message
              </Link>
            </div>
          </div>
          <div className="p-col-12 p-md-3 p-xl-3 p-sm-6">
            <div className="small-box bg-aqua">
              <div className="icon">
                <img
                  alt="facory"
                  src={factory_reset}
                  style={{ width: "60px", height: "60px" }}
                  className="mx-auto d-block"
                />
              </div>
              <Link to="factory-reset" className="small-box-footer">
                Factory Reset
              </Link>
            </div>
          </div>
          <div className="p-col-12 p-md-3 p-xl-3 p-sm-6">
            <div className="small-box bg-aqua">
              <div className="icon">
                <img
                  alt="lock"
                  src={lock}
                  style={{ width: "60px", height: "60px" }}
                  className="mx-auto d-block"
                />
              </div>
              <Link to="lock" className="small-box-footer">
                Lock
              </Link>
            </div>
          </div>
          <div className="p-col-12 p-md-3 p-xl-3 p-sm-6">
            <div className="small-box bg-aqua">
              <div className="icon">
                <img
                  alt="deactiate"
                  src={deactive_luncher}
                  style={{ width: "60px", height: "60px" }}
                  className="mx-auto d-block"
                />
              </div>
              <Link to="deactive-launcher" className="small-box-footer">
                Deactive Launcher
              </Link>
            </div>
          </div>
          <div className="p-col-12 p-md-3 p-xl-3 p-sm-6">
            <div className="small-box bg-aqua">
              <div className="icon">
                <img
                  alt="clearApp"
                  src={clear_app}
                  style={{ width: "60px", height: "60px" }}
                  className="mx-auto d-block"
                />
              </div>
              <Link to="clear-app-data" className="small-box-footer">
               Clear Launcher Data
              </Link>
            </div>
          </div>
          <div className="p-col-12 p-md-3 p-xl-3 p-sm-6">
            <div className="small-box bg-aqua">
              <div className="icon">
                <img
                  alt="clearfile"
                  src={clear_file}
                  style={{ width: "60px", height: "60px" }}
                  className="mx-auto d-block"
                />
              </div>
              <Link to="clear-files" className="small-box-footer">
                Clear Files
              </Link>
            </div>
          </div>
          <div className="p-col-12 p-md-3 p-xl-3 p-sm-6">
            <div className="small-box bg-aqua">
              <div className="icon">
                <img
                  alt="clearfile"
                  src={search}
                  style={{ width: "60px", height: "60px" }}
                  className="mx-auto d-block"
                />
              </div>
              <Link to="factory-reset-protection" className="small-box-footer">
                Factory Reset Protection
              </Link>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}
