import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { AllData } from "../service/AllData";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import api from "../service/ApiService";
import { Dialog } from "primereact/dialog";
import {
  getDevicesUrl,
  errorCompute,
  baseUrl,
  getDeviceSearchData,
} from "../service/ApiUrls";
import ErrorDialog from "../components/ErrorDialog";
import SuccessDialog from "../components/SuccessDialog";
import Loader from "../components/Loader";
import { getGroupListUrl } from "../service/ApiUrls";
import { OverlayPanel } from "primereact/overlaypanel";
const helpIcon = require("../assets/icons/help_icon.png");

export class RemoteCast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cast: null,
      selectedProfile: null,
      selectedGroup: null,
      currentPage: 1,
      dfirst: 0,
      drows: 20,
    };
    this.profiles = [
      { name: "Profile 1", code: "PF1" },
      { name: "Profile 2", code: "PF2" },
      { name: "Profile 3", code: "PF3" },
    ];
    this.groups = [
      { name: "Group 1", code: "GP1" },
      { name: "Group 2", code: "GP2" },
      { name: "Group 3", code: "GP3" },
    ];

    this.onProfileChange = this.onProfileChange.bind(this);
    this.onGroupChange = this.onGroupChange.bind(this);
    this.alldata = new AllData();
  }
  onProfileChange = (e) => {
    if (e.value !== "Select Profile") {
      this.setState({ selectedProfile: e.value });
    } else {
      this.setState({ selectedProfile: null });
    }
  };
  onGroupChange = (e) => {
    if (e.value !== "Select Group") {
      this.setState({ selectedGroup: e.value });
    } else {
      this.setState({ selectedGroup: null });
    }
  };
  onDialogueHide = () => {
    this.setState({ isError: false });
  };

  callgetDevices = ({
    first = 0,
    limit = 10,
    profileId = null,
    imei = null,
    groupId = null,
  } = {}) => {
    api
      .get(getDevicesUrl, {
        params: {
          first: first,
          limit: limit,
          profileId: profileId,
          imei: imei,
          groupId: groupId,
        },
      })
      .then((res) => {
        this.setState({
          cast: res.data.data.content,
          ...res.data.data,
        });
      })
      .catch((err) => {
        errorCompute(err);
        // localStorage.setItem("loggedIn", false);
        // window.location = process.env.PUBLIC_URL + "/login";
      });
  };

  callDeviceSearchData = () => {
    api
      .get(getDeviceSearchData)
      .then((res) => {
        if (res.data.result.isError === false) {
          console.log("Inside false");
          this.setState(
            {
              profileList: res.data.data.profileList,
              groupList: res.data.data.groupList,
            },
            () => {
              this.callgetDevices();
            }
          );
        } else if (res.data.result.isError === true) {
          this.setState({
            isError: true,
            errorMessage: res.data.result.errorMsg,
          });
        }
      })
      .catch((err) => {
        // localStorage.setItem("loggedIn", false);
        // window.location = process.env.PUBLIC_URL + "/login";
      });
  };

  componentDidMount() {
    //this.alldata.getRemote().then((data) => this.setState({ cast: data }));
    // this.callgetDevices();
    this.callDeviceSearchData();
  }
  onDialogueHideSuccess = () => {
    this.setState({ isSuccess: false });
  };
  /* Row Editing */

  rowDelete = (rowData, column) => {
    api
      .delete(
        getGroupListUrl +
          "/" +
          rowData.id +
          "?preserve=" +
          this.state.prechecked
      )
      .then((res) => {
        if (res.data.result.isError === false) {
          this.setState({ isSuccess: true, isLoading: false });
          window.location = process.env.PUBLIC_URL + "/device-group";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onPage = (event) => {
    //console.log("Event", event);

    setTimeout(() => {
      const { first, rows, page } = event;

      this.setState(
        {
          dfirst: first,
          currentPage: page + 1,
          drows: rows,
          page,
        },
        () => {
          this.callgetDevices({
            first: this.state.page,
            limit: this.state.drows,
          });
        }
      );
    }, 500);
  };

  actionTemplate(rowData, column) {
    return (
      <div>
        <button
          className="btn btn-outline-primary btn-main1"
          onClick={() => {
            api
              .post(baseUrl + "/remote-cast/" + rowData.imei1)
              .then((res) => {
                if (res.data.result.isError === false) {
                  this.props.history.push({
                    pathname: "/remote-connect",
                    state: {
                      rowData: rowData,
                      stateData: this.state,
                      isEdit: true,
                      apiData: res.data.data,
                    },
                  });
                } else {
                  console.log("can not call the post method");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }}
        >
          Connect
        </button>
      </div>
    );
  }
  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Remote Cast" },
    ];

    let profileArr = [{ name: "Select Profile", value: "Select Profile" }];
    let groupArr = [{ name: "Select Group", value: "Select Group" }];
    profileArr =
      this.state.profileList !== undefined &&
      profileArr.concat(this.state.profileList);

    groupArr =
      this.state.groupList !== undefined &&
      groupArr.concat(this.state.groupList);
    {
      //console.log(profileArr);
    }
    const header = (
      <div id="cus-p" className="row justify-content-start">
        <div id="c-p" className="p-col-12 col-md-3 col-lg-3">
          <Dropdown
            value={this.state.selectedProfile}
            options={profileArr}
            onChange={this.onProfileChange}
            optionLabel="name"
            placeholder="Select Profile"
          />
        </div>
        <div id="c-p" className="p-col-12 col-md-3 col-lg-3">
          <Dropdown
            value={this.state.selectedGroup}
            options={groupArr}
            onChange={this.onGroupChange}
            optionLabel="name"
            placeholder="Select Group"
          />
        </div>
        <div id="c-p" className="p-col-12 col-md-3 col-lg-3">
          <input
            type="text"
            className="form-control"
            id="IMEI"
            onChange={(e) => this.setState({ imei: e.target.value })}
            placeholder="IMEI"
            // value={this.state.IMEI}
            onKeyDown={(e) =>
              // this.setState({ globalFilter: e.target.value })
              {
                console.log("e", e.key);
                if (e.key === "Enter") {
                  this.callgetDevices({
                    profileId:
                      this.state.selectedProfile !== null
                        ? this.state.selectedProfile.id
                        : null,
                    groupId:
                      this.state.selectedGroup !== null
                        ? this.state.selectedGroup.id
                        : null,
                    imei: this.state.imei,
                  });
                } else {
                  if (this.state.imei === undefined || this.state.imei === "") {
                    this.callgetDevices({
                      profileId:
                        this.state.selectedProfile !== null
                          ? this.state.selectedProfile.id
                          : null,
                      groupId:
                        this.state.selectedGroup !== null
                          ? this.state.selectedGroup.id
                          : null,
                    });
                  }
                }
              }
            }
          />
        </div>
        <div className="p-col">
          <button
            className="btn btn-primary btn-suc cus-pad"
            onClick={(e) => {
              this.callgetDevices({
                profileId:
                  this.state.selectedProfile !== null
                    ? this.state.selectedProfile.id
                    : null,
                groupId:
                  this.state.selectedGroup !== null
                    ? this.state.selectedGroup.id
                    : null,
                imei: this.state.imei,
              });
            }}
          >
            Search
          </button>
        </div>
      </div>
    );
    const footer = (
      <div>
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-success"
          onClick={() => {
            this.setState({ visible: false, isLoading: true });
            this.rowDelete(this.state.rowData);
          }}
        />
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ visible: false });
          }}
        />
      </div>
    );
    const deleteDialogue = (
      <Dialog
        header="Delete Device Group"
        visible={this.state.visible}
        style={{ width: "35vw" }}
        modal={true}
        footer={footer}
        onHide={() => this.setState({ visible: false })}
      >
        <p
          style={{
            fontWeight: "bold",
            paddingBottom: "10px",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          Do you want to Delete This Remotecast?
        </p>
      </Dialog>
    );
    const home = { icon: "pi pi-home", url: "dashboard" };
    const paginatorLeft = <Button icon="pi pi-refresh" />;
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 p-ll">
          <div className="p-col-9 inline-b p-ll">
            <BreadCrumb model={items} home={home} />
          </div>
          {/* <div className="p-col-3 inline-b p-zz">
            <img
              src={helpIcon}
              width="20"
              height="20"
              onClick={(e) => this.op.toggle(e)}
              style={{
                marginBottom: "-10px",
                float: "right",
                cursor: "pointer",
              }}
            />
          </div> */}
          <OverlayPanel
            ref={(el) => (this.op = el)}
            id="overlay_panel"
            showCloseIcon={true}
          >
            <div
              className="col"
              style={{
                width: "fit-content",
                maxWidth: "300px",
                padding: "0px",
              }}
            >
              <div className="helptip-title">
                <b>How to : Device Group</b>
              </div>
              <div className="helptip-des">
                roles have option to manage devices by group rather than
                individual. One device belongs to only one group and each group
                must have a device profile. Once devices are bound to a group,
                the device profile of that group are automatically applied on
                those devices.
              </div>
            </div>
          </OverlayPanel>
        </div>
        {deleteDialogue}
        <Dialog
          visible={this.state.isLoading}
          modal={true}
          closable={false}
          showHeader={false}
          style={{ width: "20vw" }}
          onHide={this.onDialogueHide}
        >
          <Loader />
        </Dialog>
        <Dialog
          visible={this.state.isSuccess}
          modal={true}
          style={{ width: "25vw" }}
          closable
          showHeader
          onHide={this.onDialogueHideSuccess}
        >
          <SuccessDialog successMessage="Deleted Group Successfully" />
        </Dialog>
        <div className="p-grid bg-col p-lg-12">
          <div className="p-col">
            <div className="p-grid bg-col p-lg-12">
              <div className="p-col">
                <p>
                  Users can mirror the device screen using Commlink Remote Cast
                  feature. To be able to use this feature, "Remote-Cast" app
                  needs to be installed into devices.
                </p>
              </div>
              <div className="datatable-paginator-demo">
                <DataTable
                  value={this.state.cast}
                  style={{ marginBottom: "20px" }}
                  emptyMessage="No records found"
                  responsive={true}
                  header={header}
                  paginator
                  totalRecords={this.state.totalRecords}
                  onPage={this.onPage}
                  first={this.state.dfirst}
                  currentPage={this.state.currentPage}
                  lazy
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  rows={this.state.drows}
                  rowsPerPageOptions={[10, 20, 30, 50, 100]}
                >
                  <Column field="imei1" header="IMEI" />
                  <Column
                    field="model"
                    header="Model"
                    style={{ textAlign: "center" }}
                  />
                  <Column
                    field="osTypeSt"
                    header="OS"
                    style={{ textAlign: "center" }}
                  />
                  <Column
                    header="Action"
                    style={{
                      textAlign: "center",
                      color: "#000000",
                      width: "20%",
                    }}
                    body={this.actionTemplate.bind(this)}
                  ></Column>
                </DataTable>
              </div>
            </div>
            <Dialog
              visible={this.state.isError}
              modal
              style={{ width: "20vw" }}
              closable
              showHeader
              onHide={this.onDialogueHide}
            >
              <ErrorDialog errorMessage={this.state.errorMessage} />
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}
