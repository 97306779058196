import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import api from "../service/ApiService";
import axios from "axios";
import {
  getVersionReport,
  getOSWiseReport,
  getGeoFenceBreachReport,
  getAvailableStorageReport,
  getAvailableAppStorageReport,
  getAvailableContentStorgaeReport,
  getMonthlyDataUsageReport,
  getLastConnectedDevicesReport,
  getStatusWiseDevicesReport,
} from "../service/ApiUrls";
import { Chart } from "primereact/chart";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { Tooltip } from "primereact/tooltip";
import ReportDownload from "../components/ReportDownload";
let versionReport,
  osReport,
  geoFenceReport,
  avilableStorageReport,
  avilableAppStorageReport,
  availableContentStorageReport,
  monthlyDataUsageReport,
  lastConnectedDevicesReport,
  statusWiseDevicesReport;
export class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  webcallForProfile = () => {
    // console.log("list of Axios", listofAxios);

    axios
      .all([
        api.get(getVersionReport),
        api.get(getOSWiseReport),
        api.get(getGeoFenceBreachReport),

        api.get(getAvailableStorageReport),
        api.get(getAvailableAppStorageReport),
        api.get(getAvailableContentStorgaeReport),

        api.get(getMonthlyDataUsageReport),
        api.get(getLastConnectedDevicesReport),
        api.get(getStatusWiseDevicesReport),
      ])
      .then(
        axios.spread((...responses) => {
          versionReport = responses[0].data.data;
          osReport = responses[1].data.data;
          geoFenceReport = responses[2].data.data;

          avilableStorageReport = responses[3].data.data;
          avilableAppStorageReport = responses[4].data.data;
          availableContentStorageReport = responses[5].data.data;

          monthlyDataUsageReport = responses[6].data.data;
          lastConnectedDevicesReport = responses[7].data.data;
          statusWiseDevicesReport = responses[8].data.data;

          this.setState({
            versionReport: versionReport,
            osReport: osReport,
            geoFenceReport: geoFenceReport,
            avilableStorageReport: avilableStorageReport,
            avilableAppStorageReport: avilableAppStorageReport,
            availableContentStorageReport: availableContentStorageReport,
            monthlyDataUsageReport: monthlyDataUsageReport,
            lastConnectedDevicesReport: lastConnectedDevicesReport,
            statusWiseDevicesReport: statusWiseDevicesReport,
          });
        })
      )
      .catch((err) => {
        // localStorage.setItem("loggedIn", false);
        // window.location = process.env.PUBLIC_URL + "/login";
      });
  };
  componentDidMount() {
    this.webcallForProfile();
  }

  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Reports" },
    ];
    const home = { icon: "pi pi-home", url: "dashboard" };
    let x = [];
    let mapData =
      osReport !== undefined &&
      osReport.labels !== undefined &&
      osReport.labels.forEach((e) => {
        //console.log("e", e);
        x.push(osReport.map[e]);
      });
    //console.log("mapData", x);
    const osType = osReport !== undefined && {
      labels: osReport.labels,
      datasets: [
        {
          data: x,
          backgroundColor: ["#2DBE60", "#25282B"],
        },
      ],
    };
    //console.log("osType", osType);

    x = [];
    mapData =
      versionReport !== undefined &&
      versionReport.labels !== undefined &&
      versionReport.labels.forEach((e) => {
        //console.log("e", e);
        x.push(versionReport.map[e]);
      });

    const osVersion = this.state.versionReport !== undefined && {
      labels: versionReport.labels,
      datasets: [
        {
          data: x,
          backgroundColor: [
            "#FB4E4E",
            "#2D9CDB",
            "#166A8F",
            "#168f4c",
            "#e09606",
            "#a08dff",
            "#301b99",
          ],
        },
      ],
    };

    x = [];
    mapData =
      statusWiseDevicesReport !== undefined &&
      statusWiseDevicesReport.labels !== undefined &&
      statusWiseDevicesReport.labels.forEach((e) => {
        // console.log("e", e);
        x.push(statusWiseDevicesReport.map[e]);
      });
    const activeDeactive = statusWiseDevicesReport !== undefined && {
      labels: statusWiseDevicesReport.labels,
      datasets: [
        {
          data: x,
          backgroundColor: ["#2DBE60", "#FB4E4E"],
        },
      ],
    };
    x = [];
    mapData =
      avilableStorageReport !== undefined &&
      avilableStorageReport.labels !== undefined &&
      avilableStorageReport.labels.forEach((e) => {
        //console.log("e", e);
        x.push(avilableStorageReport.map[e]);
      });
    const aStorage = avilableStorageReport !== undefined && {
      labels: avilableStorageReport.labels,
      datasets: [
        {
          data: x,
          backgroundColor: ["#2DBE60", "#FB4E4E"],
        },
      ],
    };
    x = [];
    mapData =
      avilableAppStorageReport !== undefined &&
      avilableAppStorageReport.labels !== undefined &&
      avilableAppStorageReport.labels.forEach((e) => {
        //console.log("e", e);
        x.push(avilableAppStorageReport.map[e]);
      });
    const appStorage = avilableAppStorageReport !== undefined && {
      labels: avilableAppStorageReport.labels,
      datasets: [
        {
          data: x,
          backgroundColor: ["#2DBE60", "#FB4E4E"],
        },
      ],
    };

    x = [];
    mapData =
      availableContentStorageReport !== undefined &&
      availableContentStorageReport.labels !== undefined &&
      availableContentStorageReport.labels.forEach((e) => {
        //console.log("e", e);
        x.push(availableContentStorageReport.map[e]);
      });
    const contentStorage = availableContentStorageReport !== undefined && {
      labels: availableContentStorageReport.labels,
      datasets: [
        {
          data: x,
          backgroundColor: ["#2DBE60", "#FB4E4E"],
        },
      ],
    };
    x = [];
    mapData =
      lastConnectedDevicesReport !== undefined &&
      lastConnectedDevicesReport.labels !== undefined &&
      lastConnectedDevicesReport.labels.forEach((e) => {
        //console.log("e", e);
        x.push(lastConnectedDevicesReport.map[e]);
      });
    const conDevice = lastConnectedDevicesReport !== undefined && {
      labels: lastConnectedDevicesReport.labels,
      datasets: [
        {
          label: "Connected Devices",
          backgroundColor: "#2DBE60",
          data: x,
        },
      ],
    };

    x = [];
    mapData =
      monthlyDataUsageReport !== undefined &&
      monthlyDataUsageReport.labels !== undefined &&
      monthlyDataUsageReport.labels.forEach((e) => {
        //console.log("e", e);
        x.push(monthlyDataUsageReport.map[e]);
      });
    const dataUses = monthlyDataUsageReport !== undefined && {
      labels: monthlyDataUsageReport.labels,
      datasets: [
        {
          label: "Data Uses",
          backgroundColor: "#166A8F",
          data: x,
        },
      ],
    };
    x = [];
    mapData =
      geoFenceReport !== undefined &&
      geoFenceReport.labels !== undefined &&
      geoFenceReport.labels.forEach((e) => {
        //console.log("e", e);
        x.push(geoFenceReport.map[e]);
      });
    const geofence = geoFenceReport !== undefined && {
      labels: geoFenceReport.labels,
      datasets: [
        {
          label: "Geofence",
          backgroundColor: "#2DBE60",
          data: x,
        },
      ],
    };
    return (
      <div className="p-grid p-fluid dashboard">
        <div className="p-col-12 p-lg-12">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="col-12 row align-items-start">
          <div className="p-col-12 p-md-4 p-xl-4 p-sm-6">
            <div className="col-12 bg-col2">
              <Tooltip
                target=".export11"
                position="top"
                mouseTrack
                mouseTrackTop={15}
              />
              <ReportDownload
                NameToShow="OS Wise Device Count"
                moduleName="OSCount"
              />
              <h6 className="chart-title">OS Wise Device Count</h6>
              <Chart type="doughnut" data={osType} />
            </div>
          </div>
          <div className="p-col-12 p-md-4 p-xl-4 p-sm-6">
            <div className="col-12 bg-col2">
              <Tooltip
                target=".export12"
                position="top"
                mouseTrack
                mouseTrackTop={15}
              />
              <ReportDownload
                NameToShow="Android OS Version Count"
                moduleName="OSVersion"
              />
              <h6 className="chart-title">Android OS Version Count</h6>
              <Chart type="doughnut" data={osVersion} />
            </div>
          </div>
          <div className="p-col-12 p-md-4 p-xl-4 p-sm-6">
            <div className="col-12 bg-col2">
              <Tooltip
                target=".export1"
                position="top"
                mouseTrack
                mouseTrackTop={15}
              />
              <ReportDownload
                NameToShow="Active Vs Inactive Devices Report"
                moduleName="DeviceActive"
              />

              <h6 className="chart-title">Active Vs Inactive Devices</h6>
              <Chart type="doughnut" data={activeDeactive} />
            </div>
          </div>
          <div className="p-col-12 p-md-4 p-xl-4 p-sm-6">
            <div className="col-12 bg-col2">
              <ReportDownload
                NameToShow="Geo-fence Breach Report"
                moduleName="GeoFence"
              />
              <h6 className="chart-title">Geo-fence Breach</h6>
              <Chart type="bar" data={geofence} />
            </div>
          </div>
          <div className="p-col-12 p-md-4 p-xl-4 p-sm-6">
            <div className="col-12 bg-col2">
              <Tooltip
                target=".export13"
                position="top"
                mouseTrack
                mouseTrackTop={15}
              />
              <ReportDownload
                NameToShow="Last Connected Devices"
                moduleName="ConnectedDevices"
              />
              <h6 className="chart-title">Last Connected Devices</h6>
              <Chart type="bar" data={conDevice} />
            </div>
          </div>
          <div className="p-col-12 p-md-4 p-xl-4 p-sm-6">
            <div className="col-12 bg-col2">
              <Tooltip
                target=".export13"
                position="top"
                mouseTrack
                mouseTrackTop={15}
              />
              <ReportDownload
                NameToShow="Monthly Date Usage (MB)"
                moduleName="DataUsage"
              />
              <h6 className="chart-title">Monthly Data Usage (MB)</h6>
              <Chart type="bar" data={dataUses} />
            </div>
          </div>
          <div className="p-col-12 p-md-4 p-xl-4 p-sm-6 mb-5">
            <div className="col-12 bg-col2">
              <h6 className="chart-title">Available Storage (MB)</h6>
              <Chart type="doughnut" data={aStorage} />
            </div>
          </div>
          <div className="p-col-12 p-md-4 p-xl-4 p-sm-6 mb-5">
            <div className="col-12 bg-col2">
              <h6 className="chart-title">Enterprise App Storage (MB)</h6>
              <Chart type="doughnut" data={appStorage} />
            </div>
          </div>
          <div className="p-col-12 p-md-4 p-xl-4 p-sm-6 mb-5">
            <div className="col-12 bg-col2">
              <h6 className="chart-title">Content Center Storage (MB)</h6>
              <Chart type="doughnut" data={contentStorage} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
