import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import Loader from "../components/Loader";
import { Dialog } from "primereact/dialog";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { baseUrl } from "../service/ApiUrls";
import { confirmAlert } from "react-confirm-alert";
import api from "../service/ApiService";
export class FactoryResetProtection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      visible: false,
      deletePopup: false,
      emmActive: false,
      totalRecords: 10,
    };
  }

  callGetApiforEMMActive = () => {
    api.get(baseUrl + "/ae-frp-emails").then((res) => {
      if (res.data.result.isError === false) {
        if (res.data.data.aestatus === true) {
          this.setState({
            emmActive: true,
            ...res.data.data,
          });
        }
      }
    });
  };

  calldeleteApiforemail = (rowData) => {
    api
      .delete(baseUrl + "/ae-frp-emails/" + rowData.id)
      .then((res) => {
        if (res.data.result.isError === false) {
          this.setState({ isLoading: false }, () => {
            confirmAlert({
              title: "Success Message",
              message: <p className="mod-sp">Delete Account Successfully</p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {},
                },
              ],
            });
            this.callGetApiforEMMActive();
          });
        } else {
          this.setState({ isLoading: false }, () => {
            confirmAlert({
              title: "Error Message",
              message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    // this.webcallGetUSerList();
                  },
                },
              ],
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount = () => {
    //get api
    this.callGetApiforEMMActive();
  };

  onDialogueHide = () => {
    this.setState({ isLoading: false, isSuccess: false, deviceActive: false });
  };
  actionTemplate(rowData, column) {
    return (
      <div>
        <i
          className="pi pi-trash icon-p"
          onClick={() => {
            this.setState({
              deletePopup: true,
              rowData: rowData,
            });
          }}
          // onClick={() => {
          //   console.log("onClick", rowData);
          //   this.calldeleteApiforemail(rowData.id);
          // }}
          style={{ fontSize: "1.1em", color: "#000000", paddingLeft: "5px" }}
        ></i>
      </div>
    );
  }
  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Global Settings", url: "global-settings" },
      { label: "Factory Reset Protection" },
    ];

    const home = { icon: "pi pi-home", url: "dashboard" };
    const header = (
      <div id="cus-p" className="row justify-content-end">
        <div className="p-col-12 p-md-3 p-lg-3">
          <button
            className="btn btn-primary2"
            style={{ float: "right" }}
            onClick={() => {
              this.setState({ visible: true });
            }}
          >
            + Add New
          </button>
        </div>
      </div>
    );
    const footer = (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ visible: false });
          }}
        />

        <Button
          label="Proceed"
          icon="pi pi-check"
          className="p-button-success"
          onClick={() => {
            this.setState({ visible: false, isLoading: true }, () => {
              window.location.replace(baseUrl + "/oauth2/authorization/google");
            });
          }}
        />
      </div>
    );
    const alertBox = (
      <Dialog
        header="Factory Reset Protection"
        visible={this.state.visible}
        style={{ width: "35vw" }}
        modal={true}
        footer={footer}
        onHide={() => this.setState({ visible: false })}
      >
        <p style={{ textAlign: "center" }}>
          <p
            style={{
              paddingBottom: "10px",
              paddingTop: "10px",
            }}
          >
            We want to redicrect you to the google account to get the factory
            reset protection email.
          </p>
        </p>
      </Dialog>
    );
    const footerDelete = (
      <div>
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-success"
          onClick={() => {
            this.setState({ deletePopup: false, isLoading: true });
            this.calldeleteApiforemail(this.state.rowData);
          }}
        />
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ deletePopup: false });
          }}
        />
      </div>
    );
    const deleteDialogue = (
      <Dialog
        header="Delete Account"
        visible={this.state.deletePopup}
        style={{ width: "35vw" }}
        modal={true}
        footer={footerDelete}
        onHide={() => this.setState({ deletePopup: false })}
      >
        <p
          style={{
            fontWeight: "bold",
            paddingBottom: "10px",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          Do you want to Delete This Account?
        </p>
      </Dialog>
    );
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 ">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="p-grid bg-col p-lg-12">
          <div className="p-col-12">
            <h2 style={{ paddingLeft: "12px" }}>Factory Reset Protection</h2>
          </div>
          <div
            className={this.state.emmActive ? "p-col-12 d-none" : "p-col-12"}
          >
            {this.state.emmActive ? (
              <p>
                When the device is factory reset, it will require one of these
                below accounts to log in with the Google account email and
                password to unlock the device. If no admins are specified, the
                device won't provide factory reset protection.
              </p>
            ) : (
              <>
                {" "}
                <p style={{ paddingLeft: "12px", marginBottom: "20px" }}>
                  Factory Reset Protection (FRP) is a feature of EMM. At first
                  you need to setup{" "}
                  <Link
                    style={{ textDecoration: "underline" }}
                    to="/setup-android-enterprise"
                  >
                    Android Enterprise
                  </Link>
                </p>
              </>
            )}
          </div>
          <div
            className={this.state.emmActive ? "p-col-12" : "p-col-12 d-none"}
          >
            <p style={{ paddingLeft: "12px" }}>
              When the device is factory reset, it will require one of these
              below accounts to log in with the Google account email and
              password to unlock the device. If no admins are specified, the
              device won't provide factory reset protection.
              <br />
              <br />
              <ul>
                <li>Add Gmail Accounts</li>
                <li>During Reset, sign-in with that account to proceed</li>
              </ul>
            </p>
            <div
              className={
                this.state.emails !== undefined && this.state.emails.length > 0
                  ? "p-col-12 d-none"
                  : "p-col-12"
              }
              style={{ textAlign: "center" }}
            >
              <button
                className="btn btn-primary2"
                onClick={() => {
                  this.setState({ visible: true });
                }}
              >
                + Add Gmail Account
              </button>
            </div>
            <div className="p-col-12" style={{ paddingLeft: "12px" }}>
              <div className="datatable-paginator-demo">
                {this.state.emails !== undefined &&
                this.state.emails.length > 0 ? (
                  <DataTable
                    value={this.state.emails}
                    style={{ marginBottom: "20px" }}
                    responsive={true}
                    header={header}
                    //paginator
                    totalRecords={this.state.totalRecords}
                    //onPage={this.onPage}
                    first={this.state.first}
                    lazy
                    emptyMessage="No records found"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    rows={10}
                    //rowsPerPageOptions={[1, 2, 5, 10, 20]}
                  >
                    <Column field="email" header="Email Address" />
                    <Column field="updatedate" header="Activation Date" />
                    <Column
                      header="Action"
                      style={{
                        textAlign: "center",
                        color: "#000000",
                        width: "10%",
                      }}
                      body={this.actionTemplate.bind(this)}
                    ></Column>
                  </DataTable>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {alertBox}
          {deleteDialogue}
          <Dialog
            visible={this.state.isLoading}
            modal={true}
            closable={false}
            showHeader={false}
            style={{ width: "20vw" }}
            onHide={this.onDialogueHide}
          >
            <Loader textToShow="Saving Location Setting..." />
          </Dialog>
        </div>
      </div>
    );
  }
}
