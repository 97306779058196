import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import LocalStorageService from "./service/localStorageService";
import api from "./service/ApiService";
import { getUserProfileUrl, baseUrl } from "./service/ApiUrls";
import { confirmAlert } from "react-confirm-alert";
const userImg = require("./assets/user_profile.png");

export class AppTopbar extends Component {
  static defaultProps = {
    onToggleMenu: null,
  };

  static propTypes = {
    onToggleMenu: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    //console.log(this.props.extraProps);
    //this.state = { extraProps: { ...this.props.extraProps } };
    this.state = {
      imagePreviewUrl: userImg,
      defaultImg: userImg,
      serverImg: null,
    };
  }
  webcallForProfile = async () => {
    this.setState({ isLoading: true }, async () => {
      try {
        let {
          data: {
            data,
            result: { isError, errorMsg },
          },
        } = await api.get(getUserProfileUrl);
        if (isError === false)
          this.setState({
            ...data,
            imagePreviewUrl:
              "data:image/jpeg;base64," + data.profilePictureBase64St,
            serverImg: data.profilePictureBase64St,
            isLoading: false,
          });
        else if (isError === true) {
          this.setState({ isLoading: false }, () => {
            confirmAlert({
              title: "Error Message",
              message: <p className="mod-p"> {errorMsg} </p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {},
                },
              ],
            });
          });
        }
        //console.log("res ", res.data);
      } catch (err) {
        this.setState({ isLoading: false });
        // localStorage.setItem("loggedIn", false);
        // window.location = process.env.PUBLIC_URL + "/login";
      }
    });
  };
  componentDidMount() {
    this.webcallForProfile();
  }
  makeDynamicLink = () => {
    let urlToicon = {
      "/profile": "pi pi-fw pi-user",
      "/change-password": "pi pi-fw pi-lock",
      "/pricing-plan": "pi pi-fw pi-dollar",
      "/my-plan": "pi pi-fw pi-briefcase",
    };
    return (
      this.props.extraProps !== null &&
      this.props.extraProps !== undefined &&
      this.props.extraProps.map((e, v) => {
        if (e.feature.category === 1) {
          return (
            <Link
              key={"link-" + v}
              className="dropdown-item"
              to={{
                pathname: e?.feature?.protectedUrl,
                search: "",
                hash: "",
                state: {},
              }}
            >
              <i
                className={urlToicon[e.feature.protectedUrl]}
                style={{ fontSize: "1em", marginRight: "5px" }}
              />
              {e.feature.title}
            </Link>
          );
        }
      })
    );
  };

  render() {
    const { imagePreviewUrl, defaultImg, serverImg } = this.state;
    return (
      <>
        <div className="layout-topbar clearfix">
          <button
            className="p-link layout-menu-button"
            onClick={this.props.onToggleMenu}
          >
            <span className="pi pi-bars" />
          </button>
          <div className="layout-topbar-icons">
            <span>{this.state.username}</span>
            <button
              className="p-link"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="layout-topbar-item-text">User</span>
              {/* <span className="layout-topbar-icon pi pi-user" /> */}
              <img
                className="rounded-circle"
                src={serverImg !== null ? imagePreviewUrl : defaultImg}
                alt="User"
                width="30"
                height="30"
                style={{ border: "1px solid #fff" }}
              />
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {this.props.extraProps !== undefined && this.makeDynamicLink()}
              <a
                className="dropdown-item"
                href="#"
                onClick={() => {
                  localStorage.setItem("loggedIn", false);
                  LocalStorageService.clearToken();
                  localStorage.removeItem("fullState");
                  //this.props.history.push(`${process.env.PUBLIC_URL}/login`);
                  window.location = `${process.env.PUBLIC_URL}/login`;
                }}
              >
                <i
                  className="pi pi-fw pi-power-off"
                  style={{ fontSize: "1em", marginRight: "5px" }}
                />
                Logout
              </a>
            </div>
          </div>
        </div>
        <div className="layout-topbar2">
          <div className="p-col-12 p-lg-12"></div>
        </div>
      </>
    );
  }
}
