import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { AppIconSizeEnum } from "../Enums";
import api from "../service/ApiService";
import { baseUrl, errorCompute } from "../service/ApiUrls";
import { confirmAlert } from "react-confirm-alert";
import Loader from "../components/Loader";
import { Dialog } from "primereact/dialog";

export class Purchase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      price: 120,
      isLoading: false,
      total: 0,
      devices: null,
      ...props.location.state.licenceToPurchase,
    };
  }
  changeHandler = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  onDialogueHide = () => {
    this.setState({ isLoading: false });
  };

  componentDidMount() {
    this.webcallGetApi();
  }

  webcallGetApi = () => {
    this.setState({ isLoading: true }, () => {
      api
        .get(baseUrl + "/license/get-organization-license")
        .then((res) => {
          if (res.data.result.isError === false) {
            if (res.data.data !== null) {
              this.setState({
                isLoading: false,
                ...res.data.data,
                ...res.data.data.licenseDetail,
                ...res.data.data.contact,
              });
            } else {
              this.setState({ isLoading: false });
            }
          } else {
            this.setState({ isLoading: false }, () => {
              confirmAlert({
                title: "Error Message",
                message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {
                      // this.webcallGetUSerList();
                    },
                  },
                ],
              });
            });
          }
        })
        .catch((err) => {
          errorCompute(err);
        });
    });
  };

  webcallPostRequest = () => {
    this.setState({ isLoading: true }, () => {
      let dataToSend = {
        licenseId: this.props.location.state.licenceToPurchase.id,
        contact: {
          address1: this.state.address1,
          address2: this.state.address2,
          city: this.state.city,
          state: this.state.state,
          postalCode: this.state.postalCode,
          country: this.state.country,
          email: this.state.email,
          mobileNO: this.state.mobileNO,
        },
        maxDeviceCount: this.state.maxDeviceCount,
      };
      console.log("datatosend", dataToSend);
      api
        .post(baseUrl + "/license/add-organization-license", dataToSend)
        .then((res) => {
          if (res.data.result.isError === false) {
            this.setState({ isLoading: false }, () => {
              this.props.history.push("/my-plan");
            });
          } else if (res.data.result.isError === true) {
            this.setState({ isLoading: false }, () => {
              confirmAlert({
                title: "Error Message",
                message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {
                      // this.webcallGetUSerList();
                    },
                  },
                ],
              });
            });
          }
        })
        .catch((err) => {
          errorCompute(err);
        });
    });
  };

  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Pricing & Plans", url: "pricing-plan" },
      { label: "Purchase" },
    ];
    const home = { icon: "pi pi-home", url: "dashboard" };
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 ">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="p-grid bg-col p-col-12 p-lg-12 price justify-content-md-center">
          <h5 className="mt-2" style={{ fontWeight: "bold" }}>
            Selected Plan:{" "}
            {this.props.location.state.licenceToPurchase.type !== undefined &&
              this.props.location.state.licenceToPurchase.type}
          </h5>
          <h4 style={{ width: "100%" }}>Calcualte Price:</h4>
          <div className="p-col-12 p-md-2 p-lg-2">
            <div className="form-group">
              <label htmlFor="devices">Devices</label>
              <input
                type="number"
                className="form-control"
                id="maxDeviceCount"
                onChange={this.changeHandler}
                placeholder="Devices Count"
                value={this.state.maxDeviceCount}
              />
            </div>
          </div>
          <div
            className="p-col-12 p-md-1 p-lg-1"
            style={{
              marginTop: "37px",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            *
          </div>
          <div className="p-col-12 p-md-2 p-lg-2">
            <div className="form-group">
              <label htmlFor="prices">Prices</label>
              <input
                type="number"
                className="form-control"
                id="prices"
                onChange={this.changeHandler}
                placeholder="Enter Prices"
                value={this.props.location.state.licenceToPurchase.price}
                disabled
              />
            </div>
          </div>
          <div
            className="p-col-12 p-md-1 p-lg-1"
            style={{
              marginTop: "37px",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            =
          </div>
          <div className="p-col-12 p-md-2 p-lg-2">
            <label htmlFor="total">Total Amount</label>
            <div className="input-group mb-2">
              <input
                type="number"
                className="form-control"
                id="total"
                onChange={this.changeHandler}
                placeholder="Total"
                value={
                  this.state.maxDeviceCount *
                  this.props.location.state.licenceToPurchase.price
                }
                disabled
              />
              <div className="input-group-prepend">
                <div className="input-group-text">{this.state.priceUnit}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-grid bg-col p-col-12 p-lg-12 price">
          <h4 style={{ width: "100%" }}>Contact Information:</h4>
          <div className="p-col-12 p-md-6 p-lg-6">
            <div className="form-group">
              <label htmlFor="address1">Address 1</label>
              <textarea
                className="form-control"
                onChange={this.changeHandler}
                id="address1"
                placeholder="Enter Address 1"
                rows="3"
                value={this.state.address1}
              ></textarea>
            </div>
          </div>
          <div className="p-col-12 p-md-6 p-lg-6">
            <div className="form-group">
              <label htmlFor="address2">Address 2</label>
              <textarea
                className="form-control"
                onChange={this.changeHandler}
                id="address2"
                placeholder="Enter Address 2"
                rows="4"
                value={this.state.address2}
              ></textarea>
            </div>
          </div>
          <div className="p-col-12 p-md-4 p-lg-4">
            <div className="form-group">
              <label htmlFor="city">City</label>
              <input
                type="text"
                className="form-control"
                id="city"
                onChange={this.changeHandler}
                placeholder="Enter City"
                value={this.state.city}
              />
            </div>
          </div>
          <div className="p-col-12 p-md-4 p-lg-4">
            <div className="form-group">
              <label htmlFor="state">State</label>
              <input
                type="text"
                className="form-control"
                id="state"
                onChange={this.changeHandler}
                placeholder="Enter State"
                value={this.state.state}
              />
            </div>
          </div>
          <div className="p-col-12 p-md-4 p-lg-4">
            <div className="form-group">
              <label htmlFor="city">Postal Code</label>
              <input
                type="text"
                className="form-control"
                id="postalCode"
                onChange={this.changeHandler}
                placeholder="Enter Postal Code"
                value={this.state.postalCode}
              />
            </div>
          </div>
          <div className="p-col-12 p-md-4 p-lg-4">
            <div className="form-group">
              <label for="country">Country</label>
              <select className="form-control" id="country" disabled>
                <option>Bangladesh</option>
              </select>
            </div>
          </div>
          <div className="p-col-12 p-md-4 p-lg-4">
            <div className="form-group">
              <label htmlFor="city">Contact Email</label>
              <input
                type="text"
                className="form-control"
                id="email"
                onChange={this.changeHandler}
                placeholder="Enter Email Address"
                value={this.state.email}
              />
            </div>
          </div>
          <div className="p-col-12 p-md-4 p-lg-4">
            <div className="form-group">
              <label htmlFor="city">Contact Mobile No</label>
              <input
                type="text"
                className="form-control"
                id="mobileNO"
                onChange={this.changeHandler}
                placeholder="Enter Mobile Number"
                value={this.state.mobileNO}
              />
            </div>
          </div>
          <div className="p-col-12 p-md-12 p-lg-12 mt-2">
            <div className="form-group" style={{ textAlign: "right" }}>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  this.webcallPostRequest();
                }}
              >
                Submit
              </button>
            </div>
          </div>
          <Dialog
            visible={this.state.isLoading}
            modal={true}
            closable={false}
            showHeader={false}
            style={{ width: "20vw" }}
            onHide={this.onDialogueHide}
          >
            <Loader />
          </Dialog>
        </div>
      </div>
    );
  }
}
