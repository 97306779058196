import React, { Component } from "react";
import { Modal, Alert, Spinner } from "react-bootstrap";
import { Messages } from "primereact/messages";
import { Dropdown } from "primereact/dropdown";
// import { Checkbox } from "primereact/checkbox";
import api from "../service/ApiService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Link } from "react-router-dom";
// import ErrorDialog from "../components/ErrorDialog";
import SuccessDialog from "../components/SuccessDialog";
import Loader from "../components/Loader";
import { Tooltip } from "primereact/tooltip";
import { confirmAlert } from "react-confirm-alert";
import { getDeviceSearchData, baseUrl } from "../service/ApiUrls";

class Assign extends Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.showSuccess = this.showSuccess.bind(this);
    //this.webservice = new ServicesofAxios();

    this.dropOption = [
      {
        name: "Group " + (props.fromModule === "profile" ? "" : " & Profile"),
        code: "GP",
      },
      { name: "Device", code: "DV" },
    ];
    this.state = {
      opt:
        this.props.fromModule === "profile"
          ? this.dropOption[1]
          : this.dropOption[0],
      first: 0,
      first1: 0,
      rows: 10,
      rows1: 10,
      criteria: 4,
      groupcities: [],
      profilecities: [],
      isLoading: false,
      errorMessage: "Something went wrong!",
      isSuccess: false,
      dfirst: 0,
      currentPage: 1,
      drows: 20,
      pfirst: 0,
      prows: 10,
      gfirst: 0,
      grows: 10,
    };

    if (props.fromModule === "theme") {
      this.dropOption = [
        {
          name: "Profile",
          code: "GP",
        },
      ];
    }
    this.dropOptionChange = this.dropOptionChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
  }
  onDialogueHide = () => {
    this.setState({ isLoading: false });
  };
  onDialogueHideSuccess = () => {
    this.setState({ isSuccess: false });
  };

  actionType = () => {
    try {
      switch (this.props.actionType !== undefined && this.props.actionType) {
        case 0:
          return "assign";
        case 2:
          return "unassign";
        case 1:
          return "uninstall";
      }
    } catch (error) {
      return "assign";
    }
  };

  callgetProfileListGroup = (
    urlpart,
    { profileId, first = 0, limit = 10 } = {}
  ) => {
    api
      .get(
        baseUrl +
          "/" +
          urlpart +
          "/" +
          (this.props.versionCall !== undefined
            ? this.props.versionCall + "/"
            : "") +
          this.props.dataToSend.id +
          "/" +
          this.actionType(),
        {
          params: {
            profileId: profileId,
            criteria: 1,
            first: first,
            limit: limit,
          },
        }
      )
      .then((res) => {
        if (res.data.data !== null) {
          this.setState(
            {
              groups: res.data.data.groups.content,
              ...res.data.data.groups,
            },
            () => {
              let x = [];
              this.state.groups.map((e, i) => {
                if (e.isSelected) x.push(e);
              });
              this.setState({ groupcities: x });
            }
          );
        }
      })
      .catch((err) => console.log(err));
  };

  callgetProfileListDevice = (
    urlpart,
    { profileId, imei, groupId, first = 0, limit = 10 } = {}
  ) => {
    api
      .get(
        baseUrl +
          "/" +
          urlpart +
          "/" +
          (this.props.versionCall !== undefined
            ? this.props.versionCall + "/"
            : "") +
          this.props.dataToSend.id +
          "/" +
          this.actionType(),
        {
          params: {
            profileId: profileId,
            imei: imei,
            groupId: groupId,
            criteria: 2,
            first,
            limit,
          },
        }
      )
      .then((res) => {
        if (res.data.data !== null) {
          this.setState(
            {
              devices: res.data.data.devices.content,
              totalRecords: res.data.data.devices.totalRecords,
              ...res.data.data.devices,
            },
            () => {
              let x = [];
              this.state.devices.map((e, i) => {
                if (e.isSelected) x.push(e);
              });
              this.setState({ devicecities: x });
            }
          );
        }
      })
      .catch((err) => console.log(err));
  };

  callgetThemeListProfile = (
    urlpart,
    { profileId, groupId, first = 0, limit = 10 } = {}
  ) => {
    api
      .get(
        baseUrl +
          "/" +
          urlpart +
          "/" +
          (this.props.versionCall !== undefined
            ? this.props.versionCall + "/"
            : "") +
          this.props.dataToSend.id +
          "/" +
          this.actionType(),
        {
          params: {
            profileId: profileId,
            groupId: groupId,
            criteria: 0,
            first: first,
            limit: limit,
          },
        }
      )
      .then((res) => {
        if (res.data.data !== null) {
          this.setState(
            {
              profiles: res.data.data.profiles.content,
              totalRecords1: res.data.data.profiles.totalRecords,
              ...res.data.data.profiles,
            },
            () => {
              let x = [];
              this.state.profiles.map((e, i) => {
                if (e.isSelected) x.push(e);
              });
              this.setState({ profilecities: x });
            }
          );
        }
      })
      .catch((err) => console.log(err));
  };

  callDeviceSearchData = () => {
    api
      .get(getDeviceSearchData)
      .then((res) => {
        if (res.data.result.isError === false) {
          //console.log("Inside false");
          this.setState(
            {
              profileList: res.data.data.profileList,
              groupList: res.data.data.groupList,
            },
            () => {
              if (this.props.fromModule === "profile") {
                this.callgetProfileListDevice(this.urlPartFinding());
              } else if (this.props.fromModule !== "theme") {
                this.callgetThemeListProfile(this.urlPartFinding());
                //this.callgetProfileListDevice(this.urlPartFinding());
                //this.callgetProfileListGroup(this.urlPartFinding());
              } else {
                this.callgetThemeListProfile(this.urlPartFinding());
              }
              // this.setState({ selectedProfile: this.state.profileList[0] });
            }
          );
        }
      })
      .catch((err) => {
        localStorage.setItem("loggedIn", false);
        // window.location = process.env.PUBLIC_URL + "/login";
      });
  };

  componentDidMount() {}

  dropOptionChange(e) {
    this.setState({ opt: e.value }, () => {
      if (this.state.opt.code === "DV") {
        this.callgetProfileListDevice(this.urlPartFinding());
      } else {
        if (this.state.opt.name === "Profile") {
          this.callgetThemeListProfile(this.urlPartFinding());
        } else {
          this.callgetProfileListGroup(this.urlPartFinding());
        }
      }
    });
  }
  onPageChange(event) {
    this.setState({
      first: event.first,
      rows: event.rows,
    });
  }
  cusPopup = (val) => () => {
    //alert("Hello");
    switch (val) {
      case 0:
        this.callgetThemeListProfile(this.urlPartFinding());
        break;
      case 1:
        this.callgetProfileListGroup(this.urlPartFinding());
        break;
      case 2:
        break;
      default:
        break;
    }
  };
  showSuccess() {
    this.messages.show({
      severity: "success",
      summary: "Geofence",
      detail: "Added Successfully",
      life: 5000,
      closable: false,
    });
  }
  handleClose() {
    this.setState({
      show: false,
      groupcities: [],
      profilecities: [],
      opt: this.dropOption[0],
    });
  }

  urlPartFinding = () => {
    switch (this.props.fromModule) {
      case "profile":
        return "profile-list";
      case "appManagement":
        return "app-management";
      case "contentPush":
        return "content-push";
      case "geo":
        return "locations/geofences";
      case "theme":
        return "theme-list";
      case "website-listing":
        return "browsing-barrier/website-listing";
      default:
        return "profile-list";
    }
  };

  handleShow() {
    this.setState({ show: true }, () => {
      this.callDeviceSearchData();
    });
  }

  submitHandler = (e) => {
    e.preventDefault();
    this.setState({
      isLoading: false,
      isSubmitted: false,
    });
  };

  onBody = (property) => (rowData, column) => {
    return rowData[column.field][property];
  };

  iconToShow = () => {
    try {
      switch (this.props.actionType !== undefined && this.props.actionType) {
        case 1:
          return (
            <>
              <Tooltip
                target=".vUninstall"
                position="top"
                mouseTrack
                mouseTrackTop={20}
              />
              <i
                className="pi pi-window-minimize vUninstall"
                data-pr-tooltip="Version Uninstall"
                style={{ fontSize: "1em" }}
              ></i>
              {/* <img
                className="vUninstall p-ml-2"
                src={vUninstall}
                width="15px"
                data-pr-tooltip="Version Uninstall"
                alt="Uninstall"
              /> */}
            </>
          );
        case 0:
          if (
            this.props.versionCall !== undefined &&
            this.props.versionCall === "version-list"
          ) {
            return (
              <>
                <Tooltip
                  target=".vAssign"
                  position="top"
                  mouseTrack
                  mouseTrackTop={20}
                />
                <i
                  className="pi pi-sign-in vAssign"
                  data-pr-tooltip="Version Assign"
                  style={{ fontSize: "1em" }}
                ></i>
                {/* <img
                  className="vAssign"
                  src={vAssign}
                  width="15px"
                  data-pr-tooltip="Assign"
                  alt="Version Assign"
                /> */}
              </>
            );
          } else {
            return (
              <>
                <Tooltip
                  target=".pi-sign-in"
                  position="top"
                  mouseTrack
                  mouseTrackTop={20}
                />
                <i
                  className="pi pi-sign-in"
                  data-pr-tooltip="Assign"
                  style={{ fontSize: "1em" }}
                ></i>
              </>
            );
          }
        case 2:
          if (
            this.props.versionCall !== undefined &&
            this.props.versionCall === "version-list"
          ) {
            return (
              <>
                <Tooltip
                  target=".pi-sign-out"
                  position="top"
                  mouseTrack
                  mouseTrackTop={20}
                />
                <i
                  className="pi pi-sign-out"
                  data-pr-tooltip="Un-Assign"
                  style={{ fontSize: "1em" }}
                ></i>
                {/* <img
                  className="vUnAssign"
                  src={vUnAssign}
                  width="15px"
                  data-pr-tooltip="Un-Assign"
                  alt="Version Un-Assign"
                /> */}
              </>
            );
          } else {
            if (this.props.fromModule === "appManagement") {
              return (
                <>
                  <button
                    type="button"
                    className="btn btn-primary cus-btn p-21"
                  >
                    <Tooltip
                      target=".Un-Assign"
                      position="top"
                      mouseTrack
                      mouseTrackTop={20}
                    />
                    <i
                      className="pi pi-sign-out Un-Assign"
                      data-pr-tooltip="Un-Assign"
                      style={{ fontSize: "1em" }}
                    ></i>
                    {/* <img
                      src={unassignIcon}
                      width="20px"
                      data-pr-tooltip="Un-Assign"
                      className="Un-Assign"
                      alt="Un-Assign"
                    /> */}
                  </button>{" "}
                </>
              );
            } else {
              return (
                <>
                  <Tooltip
                    target=".vUnAssign"
                    position="top"
                    mouseTrack
                    mouseTrackTop={20}
                  />
                  <i
                    className="pi pi-sign-out vUnAssign"
                    data-pr-tooltip="Un-Assign"
                    alt="Version Un-Assign"
                    style={{ fontSize: "1em" }}
                  ></i>
                  {/* <img
                    className="vUnAssign p-ml-2"
                    src={vUnAssign}
                    width="15px"
                    data-pr-tooltip="Un-Assign"
                    alt="Version Un-Assign"
                  /> */}
                </>
              );
            }
          }

        default:
          return <>Assign</>;
      }
    } catch (error) {
      return "";
    }
  };

  successfullMessage = () => {
    try {
      switch (this.props.actionType !== undefined && this.props.actionType) {
        case 1:
          return "Uninstalled Successfully";
        case 2:
          return "Un-Assigned Successfully";
        default:
          return "Assigned Successfully";
      }
    } catch (error) {
      return "Some Error Ocuured";
    }
  };

  render() {
    const groupList = (
      <div className="datatable-paginator-demo">
        <DataTable
          value={this.state.groups !== undefined ? this.state.groups : null}
          style={{ marginBottom: "20px" }}
          responsive={true}
          globalFilter={this.state.globalFilter}
          //footer={this.displaySelection(this.state.selectedCars3)}
          selection={this.state.groupcities}
          onSelectionChange={(e) => {
            let checkedGroups = [];
            let ids = e.value.map((v, i) =>
              checkedGroups.push(v.assignedObject.id)
            );
            this.setState({
              criteria: 1,
              groupcities: e.value,
              checkedGroups: checkedGroups,
            });
          }}
          //paginatorLeft={paginatorLeft}
          paginator
          totalRecords={this.state.totalRecords}
          onPage={(event) => {
            console.log("Event", event);
            setTimeout(() => {
              const { first, rows, page } = event;

              this.setState(
                {
                  gfirst: first,
                  grows: rows,
                  page,
                  currentPage: page + 1,
                },
                () => {}
              );
            }, 500);
          }}
          first={this.state.gfirst}
          currentPage={this.state.currentPage}
          lazy
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          rows={this.state.grows}
          rowsPerPageOptions={[10, 20, 30, 50, 100]}
        >
          <Column selectionMode="multiple" style={{ width: "3em" }} />
          <Column
            field={"assignedObject"}
            body={this.onBody("name")}
            header="Group Name"
            style={{}}
          />
          <Column
            field={"assignedObject"}
            body={this.onBody("profileName")}
            header="Profile Name"
            style={{}}
          />
        </DataTable>
      </div>
    );

    const deviceList = (
      <div className="datatable-paginator-demo">
        <DataTable
          value={this.state.devices !== undefined ? this.state.devices : null}
          style={{ marginBottom: "20px" }}
          responsive={true}
          //footer={this.displaySelection(this.state.selectedCars3)}
          selection={this.state.selectedCars3}
          onSelectionChange={(e) => {
            let selectedDevices = [];
            let ids = e.value.map((v, i) =>
              selectedDevices.push(v.assignedObject.id)
            );
            this.setState({
              criteria: 2,
              selectedCars3: e.value,
              checkedDevices: selectedDevices,
            });
          }}
          //paginatorLeft={paginatorLeft}
          paginator={true}
          lazy
          onPage={(event) => {
            console.log("Event", event);
            setTimeout(() => {
              const { first, rows, page } = event;

              this.setState(
                {
                  dfirst: first,
                  drows: rows,
                  page,
                  currentPage: page + 1,
                },
                () => {
                  this.callgetProfileListDevice(this.urlPartFinding(), {
                    first: page,
                    limit: rows,
                  });
                }
              );
            }, 500);
          }}
          totalRecords={this.state.totalRecords}
          first={this.state.dfirst}
          currentPage={this.state.currentPage}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          rows={this.state.drows}
          rowsPerPageOptions={[10, 20, 30, 50, 100]}
          // rowsPerPageOptions={[5, 10, 20]}
        >
          <Column selectionMode="multiple" style={{ width: "3em" }} />
          <Column
            field={"assignedObject"}
            body={this.onBody("imei1")}
            header="IMEI"
            style={{ width: "30%" }}
          />
          <Column
            field={"assignedObject"}
            body={this.onBody("groupName")}
            header="Group"
            style={{ width: "30%" }}
          />
          <Column
            field={"assignedObject"}
            body={this.onBody("profileName")}
            header="Profile"
            style={{ width: "30%" }}
          />
        </DataTable>
      </div>
    );

    const profilesList = (
      <div className="datatable-paginator-demo">
        <DataTable
          value={this.state.profiles !== undefined ? this.state.profiles : null}
          style={{ marginBottom: "20px" }}
          responsive={true}
          globalFilter={this.state.globalFilter}
          //footer={this.displaySelection(this.state.selectedCars3)}
          selection={this.state.profilecities}
          onSelectionChange={(e) => {
            let checkedProfiles = [];
            this.state.checkedProfiles != undefined &&
              this.state.checkedProfiles.map((cp) => {
                checkedProfiles.push(cp);
              });
            let ids = e.value.map((v, i) =>
              checkedProfiles.push(v.assignedObject.id)
            );
            console.log("ids", ids);
            console.log("checkedGroups", checkedProfiles);
            console.log("this.state.profilecities", this.state.profilecities);
            this.setState({
              criteria: 0,
              profilecities: e.value,
              checkedProfiles: checkedProfiles,
            });
          }}
          paginator
          totalRecords={this.state.totalRecords1}
          onPage={(event) => {
            console.log("Event", event);
            setTimeout(() => {
              const { first, rows, page } = event;

              this.setState(
                {
                  first1: first,
                  rows1: rows,
                  page,
                  currentPage: page + 1,
                },
                () => {
                  this.callgetThemeListProfile(this.urlPartFinding(), {
                    first: page,
                    limit: this.state.rows1,
                  });
                }
              );
            }, 500);
          }}
          first={this.state.first1}
          currentPage={this.state.currentPage}
          lazy
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          rows={this.state.rows1}
          rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
        >
          <Column selectionMode="multiple" style={{ width: "3em" }} />
          <Column
            field={"assignedObject"}
            body={this.onBody("name")}
            header="Profile Name"
            style={{}}
          />
          <Column
            field={"assignedObject"}
            body={this.onBody("groupName")}
            header="OS Type"
            style={{}}
          />
        </DataTable>
      </div>
    );

    const profileListDropDown = this.state.profileList;
    let profileOptions =
      profileListDropDown !== undefined &&
      profileListDropDown.length > 0 &&
      profileListDropDown.map((e, i) => {
        //console.log("profile", e);
        return (
          <option key={i} value={e.id}>
            {e.name}
          </option>
        );
      });
    const groupListDropDown = this.state.groupList;
    let groupOptions =
      groupListDropDown !== undefined &&
      groupListDropDown.length > 0 &&
      groupListDropDown.map((e, i) => {
        //console.log("profile", e);
        return (
          <option key={i} value={e.id}>
            {e.name}
          </option>
        );
      });

    let varients = [
      "primary",
      "secondary",
      "success",
      "danger",
      "warning",
      "light",
    ];
    let spinnerList = varients.map((e, i) => {
      return <Spinner key={i} animation="grow" variant={e} />;
    });
    const groupProfile = (
      <div
        className="p-col-12 cus-nav"
        style={{ paddingRight: "0px", paddingLeft: "0px" }}
      >
        <ul
          className="nav nav-pills center justify-content-md-center mb-2"
          id="pills-tab"
          role="tablist"
          style={{
            borderBottom: "1px solid gray",
            paddingBottom: "10px",
          }}
        >
          {this.props.fromModule === "profile" ? (
            ""
          ) : (
            <li className="nav-item">
              <a
                className="nav-link active"
                id="all-apps"
                data-toggle="pill"
                href="#pills-all-apps"
                role="tab"
                aria-controls="pills-all-apps"
                aria-selected="false"
                onClick={this.cusPopup(0)}
              >
                Profile
              </a>
            </li>
          )}
          {this.props.fromModule === "theme" ||
          this.props.fromModule === "appManagement" ||
          this.props.fromModule === "website-listing" ? (
            ""
          ) : (
            <li className="nav-item">
              <a
                className={
                  this.props.fromModule === "profile"
                    ? "nav-link active"
                    : "nav-link"
                }
                // className="nav-link"
                id="enabled-app"
                data-toggle="pill"
                href="#pills-enabled"
                role="tab"
                aria-controls="pills-enabled"
                aria-selected="true"
                onClick={this.cusPopup(1)}
              >
                Group
              </a>
            </li>
          )}
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className={
              this.props.fromModule === "profile"
                ? "tab-pane fade"
                : "tab-pane fade show active"
            }
            id="pills-all-apps"
            role="tabpanel"
            aria-labelledby="all-apps"
          >
            <div className="row align-items-start">
              <div className="col">{profilesList}</div>
            </div>
          </div>

          <div
            className={
              this.props.fromModule !== "theme" &&
              this.props.fromModule === "profile"
                ? "tab-pane fade show active"
                : "tab-pane fade"
            }
            id="pills-enabled"
            role="tabpanel"
            aria-labelledby="enabled-app"
          >
            <div className="row align-items-start">
              <div className="col">{groupList}</div>
            </div>
          </div>
        </div>
      </div>
    );
    const device = (
      <div
        className="p-col-12 cus-nav"
        style={{ paddingRight: "0px", paddingLeft: "0px" }}
      >
        <ul
          className="nav nav-pills center justify-content-md-center mb-2"
          id="pills-tab"
          role="tablist"
          style={{
            borderBottom: "1px solid gray",
            paddingBottom: "10px",
          }}
        >
          <li className="nav-item">
            <a
              className="nav-link active"
              id="enabled-device"
              data-toggle="pill"
              href="#pills-device"
              role="tab"
              aria-controls="enabled-device"
              aria-selected="true"
            >
              Device
            </a>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane show active"
            id="pills-device"
            role="tabpanel"
            aria-labelledby="enabled-device"
          >
            <div className="row align-items-start">
              <div className="col-12">{deviceList}</div>
            </div>
          </div>
        </div>
      </div>
    );

    const profileSelect = (
      <>
        {" "}
        <select
          className="form-control"
          // id="exampleFormControlSelect1"
          onChange={(e) => {
            //console.log("e in profile ", e.target.value);

            let profileObject = this.state.profileList.find((obj) => {
              //console.log("object", obj.id);
              return obj.id === e.target.value;
            });
            //console.log("profile object", profileObject);
            this.setState(
              {
                ...this.state,
                selectedProfile:
                  profileObject !== undefined ? profileObject : null,
              },
              () => {
                if (this.state.selectedProfile !== undefined) {
                  this.callgetProfileListDevice(this.urlPartFinding(), {
                    profileId:
                      this.state.selectedProfile !== null
                        ? this.state.selectedProfile.id
                        : null,
                  });
                  // if (this.props.fromModule === "profile") {
                  //   this.callgetProfileListDevice("profile-list", {
                  //     profileId:
                  //       this.state.selectedProfile !== null
                  //         ? this.state.selectedProfile.id
                  //         : null,
                  //   });
                  // } else if (this.props.fromModule === "theme") {
                  //   this.callgetProfileListDevice("theme-list", {
                  //     profileId:
                  //       this.state.selectedProfile !== null
                  //         ? this.state.selectedProfile.id
                  //         : null,
                  //   });
                  // } else if (this.props.fromModule === "geo") {
                  //   this.callgetProfileListDevice("locations/geofences", {
                  //     profileId:
                  //       this.state.selectedProfile !== null
                  //         ? this.state.selectedProfile.id
                  //         : null,
                  //   });
                  // } else if (this.props.fromModule === "contentPush") {
                  //   this.callgetProfileListDevice("content-push", {
                  //     profileId:
                  //       this.state.selectedProfile !== null
                  //         ? this.state.selectedProfile.id
                  //         : null,
                  //   });
                  // } else if (this.props.fromModule === "appManagement") {
                  //   this.callgetProfileListDevice("app-management", {
                  //     profileId:
                  //       this.state.selectedProfile !== null
                  //         ? this.state.selectedProfile.id
                  //         : null,
                  //   });
                  // }
                }
              }
            );
          }}
          id="selectedProfile"
          name="selectedProfile"
          value={
            this.state.selectedProfile !== null &&
            this.state.selectedProfile !== undefined
              ? this.state.selectedProfile.id
              : null
          }
        >
          <option>Select Profile</option>
          {profileOptions}
        </select>
      </>
    );

    const groupSelect = (
      <>
        <select
          className="form-control"
          onChange={(e) => {
            let groupobject = this.state.groupList.find((obj) => {
              return obj.id === e.target.value;
            });
            this.setState(
              {
                ...this.state,
                selectedGroup: groupobject !== undefined ? groupobject : null,
              },
              () => {
                if (this.state.selectedGroup !== undefined) {
                  this.callgetProfileListDevice(this.urlPartFinding(), {
                    groupId:
                      this.state.selectedGroup !== null
                        ? this.state.selectedGroup.id
                        : null,
                  });
                  // if (this.props.fromModule === "profile") {
                  //   this.callgetProfileListDevice("profile-list", {
                  //     groupId:
                  //       this.state.selectedGroup !== null
                  //         ? this.state.selectedGroup.id
                  //         : null,
                  //   });
                  // } else if (this.props.fromModule === "theme") {
                  //   this.callgetProfileListDevice("theme-list", {
                  //     groupId:
                  //       this.state.selectedGroup !== null
                  //         ? this.state.selectedGroup.id
                  //         : null,
                  //   });
                  // } else if (this.props.fromModule === "geo") {
                  //   this.callgetProfileListDevice("locations/geofences", {
                  //     groupId:
                  //       this.state.selectedGroup !== null
                  //         ? this.state.selectedGroup.id
                  //         : null,
                  //   });
                  // } else if (this.props.fromModule === "contentPush") {
                  //   this.callgetProfileListDevice("content-push", {
                  //     groupId:
                  //       this.state.selectedGroup !== null
                  //         ? this.state.selectedGroup.id
                  //         : null,
                  //   });
                  // } else if (this.props.fromModule === "appManagement") {
                  //   this.callgetProfileListDevice("app-management", {
                  //     groupId:
                  //       this.state.selectedGroup !== null
                  //         ? this.state.selectedGroup.id
                  //         : null,
                  //   });
                  // }
                }
              }
            );
          }}
          id="selectedGroup"
          name="selectedGroup"
          value={
            this.state.selectedGroup !== undefined &&
            this.state.selectedGroup !== null
              ? this.state.selectedGroup.id
              : null
          }
        >
          <option>Select Group</option>
          {groupOptions}
        </select>
      </>
    );

    let component = null;
    switch (this.state.opt !== null && this.state.opt.code) {
      case "GP":
        component = groupProfile;
        break;
      case "DV":
        component = device;
        break;
      default:
        component = groupProfile;
        break;
    }
    const deviceSearch = (
      <>
        <div
          className="col-3 p-m"
          style={{ marginBottom: "0px", display: "inline-block" }}
        >
          <div className="form-group">{profileSelect}</div>
        </div>
        <div
          className="col-3 p-m"
          style={{ marginBottom: "0px", display: "inline-block" }}
        >
          <div className="form-group">{groupSelect}</div>
        </div>
        <div
          className="col-3 p-m"
          style={{ marginBottom: "0px", display: "inline-block" }}
        >
          <div className="form-group">
            <div
              className="form-inline d-flex justify-content-center md-form form-sm mt-0 b-input"
              style={{ padding: "2px" }}
            >
              <input
                className="form-control form-control-sm mr-2 b-n"
                type="text"
                placeholder="Enter IMEI"
                aria-label="Search"
                style={{ width: "80%" }}
                onChange={(e) => {
                  this.setState({
                    imei: e.target.value,
                  });
                }}
                onKeyDown={(e) => {
                  //console.log("inOnkeyDown", e);
                  if (e.key === "Enter") {
                    console.log(
                      "object",
                      this.state.imei,
                      this.state.selectedProfile,
                      this.state.selectedGroup
                    );
                    if (this.state.selectedProfile !== undefined) {
                      if (this.props.fromModule === "profile") {
                        this.callgetProfileListDevice("profile-list", {
                          profileId: this.state.selectedProfile.id,
                          imei: this.state.imei,
                          groupId: this.state.selectedGroup.id,
                        });
                      } else if (this.props.fromModule === "theme") {
                        this.callgetProfileListDevice("theme-list", {
                          profileId: this.state.selectedProfile.id,
                          imei: this.state.imei,
                          groupId: this.state.selectedGroup.id,
                        });
                      } else if (this.props.fromModule === "geo") {
                        this.callgetProfileListDevice("locations/geofences", {
                          profileId: this.state.selectedProfile.id,
                          imei: this.state.imei,
                          groupId: this.state.selectedGroup.id,
                        });
                      } else if (this.props.fromModule === "contentPush") {
                        this.callgetProfileListDevice("content-push", {
                          profileId: this.state.selectedProfile.id,
                          imei: this.state.imei,
                          groupId: this.state.selectedGroup.id,
                        });
                      } else if (this.props.fromModule === "appManagement") {
                        this.callgetProfileListDevice("app-management", {
                          profileId: this.state.selectedProfile.id,
                          imei: this.state.imei,
                          groupId: this.state.selectedGroup.id,
                        });
                      }
                    }
                  }
                }}
                value={this.state.imei !== undefined ? this.state.imei : ""}
              />
              <i
                className="pi pi-search"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  if (this.state.selectedProfile !== undefined) {
                    if (this.props.fromModule === "profile") {
                      this.callgetProfileListDevice("profile-list", {
                        profileId: this.state.selectedProfile.id,
                        imei: this.state.imei,
                        groupId: this.state.selectedGroup.id,
                      });
                    } else if (this.props.fromModule === "theme") {
                      this.callgetProfileListDevice("theme-list", {
                        profileId: this.state.selectedProfile.id,
                        imei: this.state.imei,
                        groupId: this.state.selectedGroup.id,
                      });
                    } else if (this.props.fromModule === "geo") {
                      this.callgetProfileListDevice("locations/geofences", {
                        profileId: this.state.selectedProfile.id,
                        imei: this.state.imei,
                        groupId: this.state.selectedGroup.id,
                      });
                    } else if (this.props.fromModule === "contentPush") {
                      this.callgetProfileListDevice("content-push", {
                        profileId: this.state.selectedProfile.id,
                        imei: this.state.imei,
                        groupId: this.state.selectedGroup.id,
                      });
                    } else if (this.props.fromModule === "appManagement") {
                      this.callgetProfileListDevice("app-management", {
                        profileId: this.state.selectedProfile.id,
                        imei: this.state.imei,
                        groupId: this.state.selectedGroup.id,
                      });
                    }
                  }
                }}
              ></i>
            </div>
          </div>
        </div>
      </>
    );
    // const defaultSearch = (
    //   <div
    //     className="col-3 p-m"
    //     style={{ marginBottom: "0px", display: "inline-block" }}
    //   >
    //     <div className="form-group">
    //       <div
    //         className="form-inline d-flex justify-content-center md-form form-sm mt-0 b-input"
    //         style={{ padding: "2px" }}
    //       >
    //         <input
    //           className="form-control form-control-sm mr-2 b-n"
    //           type="text"
    //           placeholder="Search"
    //           aria-label="Search"
    //           style={{ width: "80%" }}
    //           onChange={(e) => {
    //             console.log("this e", e.target.value);
    //             this.setState({ globalFilter: e.target.value });
    //           }}
    //         />
    //         <i className="pi pi-search" aria-hidden="true"></i>
    //       </div>
    //     </div>
    //   </div>
    // );
    let searchCom = null;
    switch (this.state.opt !== null && this.state.opt.code) {
      case "GP":
        searchCom = "";
        break;
      case "DV":
        searchCom = deviceSearch;
        break;
      default:
        searchCom = "";
        break;
    }
    const showModal = (
      <Modal size="lg" show={this.state.show} onHide={this.handleClose}>
        <Modal.Header className="mb-0" closeButton>
          <p className="mb-0">
            <b>
              {this.props.name !== undefined ? this.props.name : "App Name"}
            </b>
            {this.props.discussionView !== undefined && (
              <span style={{ display: "block", padding: "5px 0px" }}>
                App Description
              </span>
            )}
          </p>
        </Modal.Header>
        {/* <form onSubmit={this.submitHandler}> */}
        {this.state.isLoading && (
          <>
            <div style={{ textAlign: "center", padding: "5px" }}>
              {spinnerList}
            </div>
          </>
        )}
        <Modal.Body>
          {this.state.isSubmitted && (
            <>
              <Messages ref={(el) => (this.messages = el)} />
            </>
          )}
          {this.state.error && (
            <Alert variant="danger">
              <b>Error Occurred</b>
            </Alert>
          )}
          <>
            {this.props.fromModule === "theme" ||
            this.props.fromModule === "appManagement" ||
            this.props.fromModule === "website-listing" ? (
              ""
            ) : (
              <div className="row align-items-start">
                <div className="col-12">
                  <label>
                    <b>Choose Recipients</b>
                  </label>
                </div>
                <div className="col-3 p-m" style={{ display: "inline-block" }}>
                  <div className="form-group" style={{ marginBottom: "0px" }}>
                    <Dropdown
                      value={this.state.opt}
                      options={this.dropOption}
                      className="custom-d"
                      onChange={this.dropOptionChange}
                      style={{
                        width: "100%",
                        padding: "1px",
                        border: "1px solid #ced4da",
                        borderRadius: "0.25em",
                      }}
                      optionLabel="name"
                      placeholder="Select Option"
                    />
                  </div>
                </div>
                {searchCom}
              </div>
            )}
            {component}
          </>
        </Modal.Body>
        <Modal.Footer style={{ display: "block", textAlign: "center" }}>
          <button
            // type="submit"
            className="btn btn-primary"
            onClick={(e) => {
              if (
                this.state.selectedCars3 !== undefined
                  ? this.state.selectedCars3.lenght === 0 &&
                    this.state.groupcities.length === 0 &&
                    this.state.profilecities.length === 0
                  : this.state.groupcities.length === 0 &&
                    this.state.profilecities.length === 0
              ) {
                this.setState(
                  { isLoading: false, show: false, isSubmitted: false },
                  () => {
                    confirmAlert({
                      title: "Error Message",
                      message: (
                        <p className="mod-p">
                          Please Select Profile / Group / Devices
                        </p>
                      ),
                      buttons: [
                        {
                          label: "Ok",
                          onClick: () => {
                            this.setState({ show: true });
                          },
                        },
                      ],
                    });
                  }
                );
              } else {
                this.setState({ isLoading: true, show: false }, () => {
                  let data = {
                    criteria: this.state.criteria,
                    data: this.props.dataToSend.id,
                    devices: this.state.checkedDevices,
                    groups: this.state.checkedGroups,
                    profiles: this.state.checkedProfiles,
                  };
                  let urlToSend;
                  if (this.props.fromModule === "profile") {
                    urlToSend = `${baseUrl}/profile-list/${
                      this.props.dataToSend.id
                    }/${this.actionType()}`;
                  } else if (this.props.fromModule === "theme") {
                    urlToSend = `${baseUrl}/theme-list/${
                      this.props.dataToSend.id
                    }/${this.actionType()}`;
                  }
                  if (this.props.fromModule === "appManagement") {
                    urlToSend = `${baseUrl}/app-management/${
                      this.props.versionCall !== undefined
                        ? "version-list/"
                        : ""
                    }${this.props.dataToSend.id}/${this.actionType()}`;
                  }
                  if (this.props.fromModule === "contentPush") {
                    urlToSend = `${baseUrl}/content-push/${
                      this.props.dataToSend.id
                    }/${this.actionType()}`;
                  }
                  if (this.props.fromModule === "geo") {
                    urlToSend = `${baseUrl}/locations/geofences/${
                      this.props.dataToSend.id
                    }/${this.actionType()}`;
                  }
                  if (this.props.fromModule === "website-listing") {
                    urlToSend = `${baseUrl}/browsing-barrier/website-listing/${
                      this.props.dataToSend.id
                    }/${this.actionType()}?criteria=0`;
                  }
                  console.log(
                    "fromModule",
                    this.props.fromModule,
                    "urlToSend ",
                    urlToSend,
                    " data ",
                    data
                  );
                  api
                    .post(urlToSend, data)
                    .then((res) => {
                      if (res.data.result.isError === false) {
                        this.setState(
                          {
                            isLoading: false,
                            show: false,
                            isSubmitted: true,
                          },
                          () => {
                            confirmAlert({
                              title: "Success Message",
                              message: (
                                <p className="mod-sp">
                                  {this.successfullMessage()}
                                </p>
                              ),
                              buttons: [
                                {
                                  label: "Ok",
                                  onClick: () => {},
                                },
                              ],
                            });
                          }
                        );
                      } else {
                        this.setState(
                          {
                            isLoading: false,
                            show: false,
                            isSubmitted: true,
                          },
                          () => {
                            confirmAlert({
                              title: "Error Message",
                              message: <p className="mod-p">Error Occurs</p>,
                              buttons: [
                                {
                                  label: "Ok",
                                  onClick: () => {},
                                },
                              ],
                            });
                          }
                        );
                      }
                    })
                    .catch((err) => console.log(err));
                });
              }
            }}
          >
            {this.props.actionType === 0
              ? "Assign"
              : this.props.actionType === 2
              ? "Un-Assign"
              : "Uninstall"}
          </button>
        </Modal.Footer>
        {/* </form> */}
      </Modal>
    );

    // const showDiv = (
    //   <form onSubmit={this.submitHandler}>
    //     <div className="row align-items-start col-12 p-z">
    //       <div className="col-12">
    //         <div
    //           className="col-3  p-m"
    //           style={{ display: "inline-block", padding: "5px" }}
    //         >
    //           <div className="form-group">
    //             <Dropdown
    //               value={this.state.opt}
    //               options={this.dropOption}
    //               onChange={this.dropOptionChange}
    //               style={{ width: "100%", padding: "2px" }}
    //               optionLabel="name"
    //               placeholder="Select Option"
    //             />
    //           </div>
    //         </div>
    //       </div>
    //       <div className="row align-items-start col-12"></div>
    //       <div className="col-12">{component}</div>
    //       <div className="col-12" style={{ textAlign: "center" }}>
    //         <button type="submit" className="btn btn-primary mt-3">
    //           Submit
    //         </button>
    //       </div>
    //     </div>
    //   </form>
    // );

    const partModel = (
      <Link className="custom-a" onClick={this.handleShow}>
        {this.props.showIcon ? (
          this.props.fromModule === "contentPush" ? (
            <>
              {/* <i className="pi pi-sign-in" style={{ fontSize: "1em" }}></i> */}
              <label style={{ paddingLeft: "20px", textAlign: "center" }}>
                {this.props.actionType === 2 ? (
                  <Link className="icon-a">
                    <i
                      className="pi pi-sign-out"
                      style={{ fontSize: "1em", paddingRight: "6px" }}
                    ></i>
                    Un-Assign
                  </Link>
                ) : (
                  <Link className="icon-a">
                    <i
                      className="pi pi-sign-in"
                      style={{ fontSize: "1em", paddingRight: "6px" }}
                    ></i>
                    Assign
                  </Link>
                )}
              </label>
            </>
          ) : (
            <>
              <Tooltip
                target=".pi-sign-in"
                position="top"
                mouseTrack
                mouseTracktop={20}
              />
              <i
                className="pi pi-sign-in"
                data-pr-tooltip="Assign"
                style={{ fontSize: "1em" }}
              ></i>
            </>
          )
        ) : (
          this.iconToShow()
        )}
      </Link>
    );

    return (
      <>
        <Dialog
          visible={this.state.isLoading}
          modal={true}
          closable={false}
          showHeader={false}
          style={{ width: "20vw" }}
          onHide={this.onDialogueHide}
        >
          <Loader />
        </Dialog>
        <Dialog
          visible={this.state.isSuccess}
          modal={true}
          style={{ width: "25vw" }}
          closable
          showHeader
          onHide={this.onDialogueHideSuccess}
        >
          <SuccessDialog successMessage="Assigned Successfully" />
        </Dialog>
        {partModel}
        {showModal}
      </>
    );
  }
}

export default Assign;
