import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { AppMap } from "../components/AppMap";
import {
  getLocationHistory,
  getDeviceSearchData,
  errorCompute,
} from "../service/ApiUrls";
import api from "../service/ApiService";
import { LOCATIONZOOM } from "../Enums";
import Loader from "../components/Loader";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";

export class LocationSearch extends Component {
  constructor() {
    super();
    this.state = {
      overlays: null,
      deviceInfo: [],
      currentPage: 1,
      drows: 20,
      dfirst: 0,
    };
  }
  onDialogueHide = () => {
    this.setState({ isLoading: false });
  };

  callLocationHistory = async () => {
    let response = await this.callgetHistoryLocation();
  };

  callgetHistoryLocation = async ({
    imei = null,
    profileId,
    groupId,
    first = 0,
    limit = 20,
  } = {}) => {
    this.setState({ isLoading: this.state.flag }, () => {
      let params = {
        imei: imei,
        profileId: profileId,
        groupId: groupId,
        first: first,
        limit: limit,
      };
      api
        .get(getLocationHistory, { params: params })
        .then((res) => {
          if (res.data.result.isError === false) {
            if (res.data.data.totalRecords === 0) {
              this.setState({ noData: true, isLoading: false });
            } else {
              let contents = res.data.data.content;
              this.state.deviceInfo = contents;

              let overLayArr = [];
              contents !== undefined &&
                contents !== null &&
                contents.map((e, i) => {
                  let currloc = e.currentLocation;
                  let currentlocation = {
                    position: {
                      lat: Number(currloc.latitude),
                      lng: Number(currloc.longitude),
                    },
                    title: e.imei1 + ":" + currloc.address,
                  };
                  overLayArr.push(currentlocation);
                });
              let options;
              if (
                contents !== null &&
                contents[0] !== null &&
                contents[0].currentLocation !== null
              ) {
                let currloc = contents[0].currentLocation;
                options = {
                  center: {
                    lat: Number(currloc.latitude),
                    lng: Number(currloc.longitude),
                  },
                  zoom: LOCATIONZOOM.ZOOM,
                };
              } else {
                let currloc = { latitude: 23.8103, longitude: 90.4125 };
                options = {
                  center: {
                    lat: Number(currloc.latitude),
                    lng: Number(currloc.longitude),
                  },
                  zoom: LOCATIONZOOM.ZOOM,
                };
              }
              this.setState({
                ...res.data.data,
                overlays: overLayArr,
                options: options,
                isLoading: false,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err.error);
        });
    });
  };

  callDeviceSearchData = () => {
    api
      .get(getDeviceSearchData)
      .then((res) => {
        if (res.data.result.isError === false) {
          //console.log("Inside false");
          this.setState(
            {
              profileList: res.data.data.profileList,
              groupList: res.data.data.groupList,
              flag: true,
            },
            () => {
              this.callgetHistoryLocation();
            }
          );
        }
      })
      .catch((err) => {
        //console.log(err);
        errorCompute(err);
        //localStorage.setItem("loggedIn", false);
        // window.location = process.env.PUBLIC_URL + "/login";
      });
  };
  actionTemplate(rowData, column) {
    return (
      <div>
        <Tooltip
          target=".pi-eye"
          position="top"
          mouseTrack
          mouseTracktop={20}
        />
        <i
          className="pi pi-eye d-inline-block mr-2"
          style={{ cursor: "pointer" }}
          data-pr-tooltip="View Timeline"
          aria-hidden="true"
          onClick={() => {
            //console.log(this.state);

            this.props.history.push({
              pathname: "/location-history",
              state: { detail: this.state, imei: rowData.imei1 },
            });
          }}
        ></i>
        <Tooltip
          target=".pi-map-marker"
          position="top"
          mouseTrack
          mouseTracktop={20}
        />
        <i
          className="pi pi-map-marker d-inline-block mr-2"
          style={{ cursor: "pointer" }}
          data-pr-tooltip="Live Tracking"
          aria-hidden="true"
          onClick={() => {
            //console.log(this.state);

            this.props.history.push({
              pathname: "/location-traking",
              state: {
                detail: this.state,
                imei: rowData.imei1,
                id: rowData.id,
                address: rowData.currentLocation.address,
                lat: rowData.currentLocation.latitude,
                long: rowData.currentLocation.longitude,
                options: {
                  lat: rowData.currentLocation.latitude,
                  lng: rowData.currentLocation.longitude,
                },
              },
            });
          }}
        ></i>
      </div>
    );
  }
  componentDidMount() {
    this.callDeviceSearchData();
  }

  onPage = (event) => {
    //console.log("Event", event);
    this.callgetHistoryLocation({ first: event.page, limit: this.state.drows });
    setTimeout(() => {
      const { first, rows, page } = event;

      this.setState({
        dfirst: first,
        page,
        drows: rows,
        currentPage: page + 1,
      });
    }, 500);
  };
  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Location", url: "location" },
      { label: "Location Search" },
    ];
    const options = {
      center: { lat: 23.797911, lng: 90.414391 },
      zoom: LOCATIONZOOM.ZOOM,
    };
    const events = [
      { ts: "2017-09-17T12:22:46.587Z", text: "Gulshan 1, Dhaka, Bangladesh" },
      { ts: "2017-09-17T12:21:46.587Z", text: "Gulshan 2, Dhaka, Bangladesh" },
      { ts: "2017-09-17T12:20:46.587Z", text: "Rampura, Dhaka, Bangladesh" },
    ];
    const home = { icon: "pi pi-home", url: "dashboard" };
    const groupList = this.state.groupList;
    const profileList = this.state.profileList;
    let groupOptions =
      groupList !== undefined &&
      groupList.length > 0 &&
      groupList.map((e, i) => {
        //console.log("group", e);
        return (
          <option key={i} value={e.id}>
            {e.name}
          </option>
        );
      });

    let profileOptions =
      profileList !== undefined &&
      profileList.length > 0 &&
      profileList.map((e, i) => {
        //console.log("profile", e);
        return (
          <option key={i} value={e.id}>
            {e.name}
          </option>
        );
      });

    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 ">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>

        <div
          id="custom-div"
          className="p-col-12"
          style={{ padding: "5px 20px" }}
        >
          <div className="p-grid bg-col">
            <div className="p-col-12 p-md-4 p-lg-4 p-sm-12">
              <div className="box" style={{}}>
                <div className="p-col-12">
                  <h2 style={{ paddingLeft: "12px" }}>Search</h2>
                </div>
                <div
                  className="p-col-12 p-md-12 col-lg-12"
                  style={{
                    paddingBottom: "10px",
                    marginTop: "5px",
                  }}
                >
                  <div className="input-group border1">
                    <input
                      type="text"
                      placeholder="Enter IMEI"
                      className="form-control bg-none border-0"
                      style={{ padding: "1.375rem 0.75rem" }}
                      onChange={(e) => {
                        this.setState({
                          imei: e.target.value,
                          // profileId:
                          //   this.state.selectedProfile !== undefined &&
                          //   this.state.selectedProfile !== null
                          //     ? this.state.selectedProfile.id
                          //     : null,
                          // groupId:
                          //   this.state.selectedGroup !== undefined &&
                          //   this.state.selectedGroup !== null
                          //     ? this.state.selectedGroup.id
                          //     : null,
                        });
                      }}
                      // value={this.state.imei}
                      onKeyDown={(e) =>
                        // this.setState({ globalFilter: e.target.value })
                        {
                          console.log("e", e.key);
                          if (e.key === "Enter") {
                            this.callgetDevices({
                              profileId:
                                this.state.selectedProfile !== null
                                  ? this.state.selectedProfile.id
                                  : null,
                              groupId:
                                this.state.selectedGroup !== null
                                  ? this.state.selectedGroup.id
                                  : null,
                              imei: this.state.imei,
                            });
                          } else {
                            if (
                              this.state.imei === undefined ||
                              this.state.imei === ""
                            ) {
                              this.callgetHistoryLocation({
                                profileId:
                                  this.state.selectedProfile !== undefined &&
                                  this.state.selectedProfile !== null
                                    ? this.state.selectedProfile.id
                                    : null,
                                groupId:
                                  this.state.selectedGroup !== undefined &&
                                  this.state.selectedGroup !== null
                                    ? this.state.selectedGroup.id
                                    : null,
                              });
                            }
                          }
                        }
                      }
                    />
                    <div className="input-group-append border-0">
                      <button
                        id="button-addon3"
                        type="button"
                        className="btn btn-link text-success"
                      >
                        <i className="pi pi-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="p-col-12 p-md-6 col-lg-6"
                  style={{ marginBottom: "10px", display: "inline-block" }}
                >
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={(e) => {
                        //console.log("e in profile ", e.target);
                        let profileObject = profileList.find((obj) => {
                          // console.log("object", obj.id);
                          return obj.id === e.target.value;
                        });
                        //console.log("profile object", profileObject);
                        this.setState({
                          ...this.state,
                          selectedProfile: profileObject,
                        });
                      }}
                      id="selectedProfile"
                      name="selectedProfile"
                      value={
                        this.state.selectedProfile !== null &&
                        this.state.selectedProfile !== undefined
                          ? this.state.selectedProfile.id
                          : ""
                      }
                    >
                      <option>Select Profile</option>
                      {profileOptions}
                    </select>
                  </div>
                </div>
                <div
                  className="p-col-12 p-md-6 col-lg-6"
                  style={{ marginBottom: "10px", display: "inline-block" }}
                >
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={(e) => {
                        let groupobject = groupList.find((obj) => {
                          return obj.id === e.target.value;
                        });
                        this.setState({
                          ...this.state,
                          selectedGroup: groupobject,
                        });
                      }}
                      id="selectedGroup"
                      name="selectedGroup"
                      value={
                        this.state.selectedGroup !== undefined &&
                        this.state.selectedGroup !== null
                          ? this.state.selectedGroup.id
                          : ""
                      }
                    >
                      <option>Select Group</option>
                      {groupOptions}
                    </select>
                  </div>
                </div>
                <button
                  className="btn2"
                  style={{ float: "left", marginLeft: "15px" }}
                  onClick={() => {
                    this.setState({ flag: true });
                    this.callgetHistoryLocation({
                      imei: this.state.imei,
                      profileId:
                        this.state.selectedProfile !== undefined &&
                        this.state.selectedProfile !== null
                          ? this.state.selectedProfile.id
                          : null,
                      groupId:
                        this.state.selectedGroup !== undefined &&
                        this.state.selectedGroup !== null
                          ? this.state.selectedGroup.id
                          : null,
                    });
                  }}
                >
                  Search
                </button>
              </div>
            </div>
            <div className="p-col-12 p-md-8 p-xl-8 p-sm-12">
              <div className="box" style={{}}>
                <div className="p-col-12">
                  <h2 style={{ paddingLeft: "12px" }}>Current Location</h2>
                </div>
                <div className="p-col-12">
                  {!this.state.noData ? (
                    this.state.options !== null &&
                    this.state.options !== undefined && (
                      <AppMap
                        options={
                          this.state.options !== null &&
                          this.state.options !== undefined &&
                          this.state.options
                        }
                        overlays={
                          this.state.overlays !== null &&
                          this.state.overlays !== undefined &&
                          this.state.overlays
                        }
                        from="LocationSearch"
                        dataToSend={this.state}
                        style={{ width: "100%", minHeight: "320px" }}
                        history={this.props.history}
                      ></AppMap>
                    )
                  ) : (
                    <>
                      <AppMap
                        options={options}
                        style={{ width: "100%", minHeight: "320px" }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="card">
              <DataTable
                value={this.state.deviceInfo}
                style={{ marginBottom: "20px" }}
                responsive={true}
                emptyMessage="No records found"
                paginator
                lazy
                onPage={this.onPage}
                // onPage={(event) => {
                //   setTimeout(() => {
                //     const { first, rows, page } = event;
                //     this.setState(
                //       {
                //         dfirst: first,
                //         page,
                //         drows: rows,
                //         currentPage: page + 1,
                //       },
                //       async () => {
                //         // let response = await this.callgetHistoryLocation({
                //         //   first: event.page,
                //         //   limit: this.state.drows,
                //         // });
                //         // this.setState({ ...response });
                //         this.setState({ flag: false });
                //         await this.callLocationHistory({
                //           first: event.page,
                //           limit: this.state.drows,
                //         });
                //       }
                //     );
                //   }, 500);
                // }}
                first={this.state.dfirst}
                currentPage={this.state.currentPage}
                totalRecords={this.state.totalRecords}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                rows={this.state.drows}
                rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
              >
                <Column field="deviceName" header="Device Name"></Column>
                <Column field="imei1" header="IMEI1"></Column>
                <Column
                  field="currentLocation.address"
                  header="Current Location"
                ></Column>
                <Column
                  field="currentLocation.updateTime"
                  header="Update Time"
                ></Column>
                <Column
                  header="Action"
                  body={this.actionTemplate.bind(this)}
                  style={{ textAlign: "center" }}
                />
              </DataTable>
            </div>
            <Dialog
              visible={this.state.isLoading}
              modal={true}
              closable={false}
              showHeader={false}
              style={{ width: "20vw" }}
              onHide={this.onDialogueHide}
            >
              <Loader />
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}
