import React from "react";
const ImgUpload = ({ onChange, src }) => (
  <label htmlFor="photo-upload" className="custom-file-upload fas">
    <div id="user" className="img-wrap img-upload">
      <img htmlFor="photo-upload" src={src} />
    </div>
    <input
      id="photo-upload"
      className="custom-file-input"
      type="file"
      onChange={onChange}
      style={{ display: "none" }}
    />
  </label>
);
export default ImgUpload;
