import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AllData } from "../service/AllData";
import { Link } from "react-router-dom";
import Assign from "../components/Assign";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Loader from "../components/Loader";
import SuccessDialog from "../components/SuccessDialog";
import { Dialog } from "primereact/dialog";
import {
  getLocationGeofenceUrl,
  deleteLocationGeofence,
} from "../service/ApiUrls";

import api from "../service/ApiService";
import { ActionAssign } from "../Enums";
import { confirmAlert } from "react-confirm-alert";

export class Geofence extends Component {
  constructor() {
    super();
    this.state = {
      geofence: null,
      editing: false,
      currentPage: 1,
      drows: 20,
      dfirst: 0,
    };
    this.alldata = new AllData();
    this.editorForRowEditing = this.editorForRowEditing.bind(this);
  }
  componentDidMount() {
    // this.alldata
    //   .getGeofence()
    //   .then((data) => this.setState({ geofence: data }));
    this.callgetGeoFenceApi().then(({ data, result }) => {
      this.setState({ geofence: data.content, ...data });
    });
  }
  onDialogueHide = () => {
    this.setState({ isLoading: false });
  };
  onDialogueHideSuccess = () => {
    this.setState({ isSuccess: false });
  };
  callgetGeoFenceApi = async ({ first = 0, limit = 10, filter } = {}) => {
    return api
      .get(getLocationGeofenceUrl, {
        params: {
          first,
          limit,
          filter,
        },
      })
      .then((res) => {
        console.log("res", res);
        return res.data;
      })
      .catch((err) => {
        return err.data;
      });
  };

  rowEdit(rowData, column) {
    console.log("from rowMyEdit", rowData);
  }
  onEditorValueChangeForRowEditing(props, value) {
    let updateGeofence = [...props.value];
    updateGeofence[props.rowIndex][props.field] = value;
    this.setState({ geofence2: updateGeofence });
    //console.log(value);
  }

  editorForRowEditing(props, field) {
    return (
      <InputText
        type="text"
        value={props.rowData[field]}
        onChange={(e) =>
          this.onEditorValueChangeForRowEditing(props, e.target.value)
        }
      />
    );
  }
  rowDelete(rowData, column) {
    // alert("Do you want to delete the row");

    api
      .delete(deleteLocationGeofence + "/" + rowData.id)
      .then((res) => {
        console.log(res);
        if (res.data.result.isError === false) {
          this.setState({ isLoading: false }, () => {
            confirmAlert({
              title: "Success Message",
              message: <p className="mod-sp">Deleted Geofence Successfully</p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {},
                },
              ],
            });
          });
          var array = [...this.state.geofence]; // make a separate copy of the array
          var index = array.indexOf(rowData);
          if (index !== -1) {
            array.splice(index, 1);
            this.setState(
              { geofence: array }
              //console.log("new state", this.state.geofence)
            );
          }
        } else if (res.data.result.isError === true) {
          this.setState(
            {
              isLoading: false,
            },
            () => {
              confirmAlert({
                title: "Error Message",
                message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {},
                  },
                ],
              });
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onBody(rowData, column) {
    const property = column.field;
    const getKeyByValue = (obj, value) =>
      Object.keys(obj).find((key) => obj[key] === value);
    return rowData.hasOwnProperty(property) &&
      typeof rowData[property] === "boolean"
      ? rowData[property].toString()
      : rowData[property];
  }

  onRowEditSave(event) {}

  onRowEditCancel(event) {}
  actionTemplate(rowData, column) {
    return (
      <div>
        <Assign
          showIcon={true}
          name={"GeoFence Assign"}
          dataToSend={rowData}
          actionType={0}
          fromModule="geo"
        />
        <Link
          to={{
            pathname: "/add-new-geofence",
            state: {
              dataCopy: rowData,
              isEdit: true,
            },
          }}
        >
          <i
            className="pi pi-pencil"
            style={{ fontSize: "1em", color: "#000000" }}
          ></i>
        </Link>

        <Assign
          showIcon={false}
          actionType={ActionAssign.UNASSIGN}
          name={"GeoFence Un-Assign"}
          dataToSend={rowData}
          fromModule="geo"
        />
        <a href="#">
          <i
            className="pi pi-trash"
            onClick={() => {
              this.setState({
                visible: true,
                rowData: rowData,
              });
            }}
            style={{ fontSize: "1em", color: "#000000" }}
          ></i>
        </a>
      </div>
    );
  }
  assignTemplate = (tag) => (rowData, column) => {
    if (tag === 0) {
      return (
        <div>
          <Assign
            showIcon={true}
            name={"GeoFence Assign"}
            dataToSend={rowData}
            fromModule="geo"
          />
        </div>
      );
    } else if (tag === 1) {
      return (
        <div>
          <Link
            to={{
              pathname: "/add-new-geofence",
              state: {
                dataCopy: rowData,
                isEdit: true,
              },
            }}
          >
            <i
              className="pi pi-pencil"
              style={{ fontSize: "1em", color: "#000000" }}
            ></i>
          </Link>
        </div>
      );
    } else if (tag === 2) {
      return (
        <div>
          <Assign
            showIcon={false}
            actionType={ActionAssign.UNASSIGN}
            name={"GeoFence Un-Assign"}
            dataToSend={rowData}
            fromModule="geo"
          />
        </div>
      );
    }
  };
  onPage = (event) => {
    //console.log("Event", event);
    this.callgetGeoFenceApi({
      first: event.page,
      limit: this.state.drows,
    }).then(({ data, result }) => {
      this.setState({ geofence: data.content, ...data }, () => {
        setTimeout(() => {
          const { first, rows, page } = event;
          this.setState({
            dfirst: first,
            page,
            drows: rows,
            currentPage: page + 1,
          });
        }, 500);
      });
    });
  };
  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Location", url: "location" },
      { label: "Geofence" },
    ];

    const home = { icon: "pi pi-home", url: "dashboard" };
    const footer = (
      <div>
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-success"
          onClick={() => {
            this.setState({ visible: false, isLoading: true });
            this.rowDelete(this.state.rowData);
          }}
        />
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ visible: false });
          }}
        />
      </div>
    );
    const deleteDialogue = (
      <Dialog
        header="Delete Geofence"
        visible={this.state.visible}
        style={{ width: "35vw" }}
        modal={true}
        footer={footer}
        onHide={() => this.setState({ visible: false })}
      >
        <p
          style={{
            fontWeight: "bold",
            paddingBottom: "10px",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          Do you want to Delete this GeoFence?
        </p>
      </Dialog>
    );

    const header = (
      <div className="p-col-12 p-md-4 p-lg-4 inline-b">
        <div id="search" className="p-inputgroup">
          <InputText
            placeholder="Enter Search Item"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.setState({ textfilter: e.target.value }, () => {
                  this.callgetGeoFenceApi({
                    filter: this.state.textfilter,
                  }).then(({ data, result }) => {
                    this.setState({ geofence: data.content, ...data });
                  });
                });
              }
            }}
            onChange={(e) => {
              this.setState({ textfilter: e.target.value }, () => {
                if (
                  this.state.textfilter.length === 0 ||
                  this.state.textfilter === ""
                ) {
                  this.callgetGeoFenceApi().then(({ data, result }) => {
                    this.setState({ geofence: data.content, ...data });
                  });
                }
                // this.callWebListApi({ filter: this.state.textfilter });
              });
            }}
          />
          <Button
            icon="pi pi-search"
            className="p-button-primary s-icon"
            onClick={(e) => {
              this.callgetGeoFenceApi({ filter: this.state.textfilter }).then(
                ({ data, result }) => {
                  this.setState({ geofence: data.content, ...data });
                }
              );
            }}
          />
        </div>
      </div>
    );

    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 ">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
          {deleteDialogue}
          <Dialog
            visible={this.state.isLoading}
            modal={true}
            closable={false}
            showHeader={false}
            style={{ width: "20vw" }}
            onHide={this.onDialogueHide}
          >
            <Loader />
          </Dialog>
          <Dialog
            visible={this.state.isSuccess}
            modal={true}
            style={{ width: "25vw" }}
            closable
            showHeader
            onHide={this.onDialogueHideSuccess}
          >
            <SuccessDialog successMessage="Deleted Geofence Successfully" />
          </Dialog>
        </div>
        <div className="p-grid bg-col p-lg-12">
          <div className="p-col">
            <h2>Geofence</h2>
          </div>
          {header}
          <div className="p-col">
            <div className="box">
              <Link to="/add-new-geofence">
                <button className="btn2">+ Add New</button>
              </Link>
            </div>
          </div>
          <div className="datatable-paginator-demo">
            <DataTable
              value={this.state.geofence}
              style={{ marginBottom: "20px" }}
              // header={header}
              responsive={true}
              globalFilter={this.state.globalFilter}
              //editMode="row"
              onRowEditInit={this.onRowEditInit}
              onRowEditSave={this.onRowEditSave}
              onRowEditCancel={this.onRowEditCancel}
              totalRecords={this.state.totalRecords}
              paginator
              onPage={this.onPage}
              emptyMessage="No records found"
              lazy
              first={this.state.dfirst}
              currentPage={this.state.currentPage}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              rows={this.state.drows}
              rowsPerPageOptions={[10, 20, 30, 50, 100]}
            >
              <Column
                field="name"
                header="Name"
                //editor={(props) => this.editorForRowEditing(props, "name")}
              />
              <Column
                field="latitude"
                header="Latitude"
                style={{ textAlign: "center", wordBreak: "break-all" }}

                //editor={(props) => this.editorForRowEditing(props, "lat")}
              />
              <Column
                field="longitude"
                header="Longitude"
                style={{ textAlign: "center", wordBreak: "break-all" }}
                //editor={(props) => this.editorForRowEditing(props, "long")}
              />
              <Column
                field="searchRadiusInKM"
                header="Radius(KM)"
                //editor={(props) => this.editorForRowEditing(props, "radius")}
                style={{ textAlign: "center" }}
              />
              <Column
                field="phoneNumber"
                header="Phone"
                style={{ textAlign: "center", wordBreak: "break-all" }}
                //body={this.onBody}
              />
              <Column
                field="emailAddress"
                header="Email"
                style={{
                  width: "12%",
                  textAlign: "center",
                  wordBreak: "break-all",
                }}
                //body={this.onBody}
              />
              {/* emailAddress */}
              {/* <Column
                field="entryAlertEnabled"
                header="Entry Alert"
                style={{ textAlign: "center" }}
                body={this.onBody}
              /> */}
              <Column
                field="exitAlertEnabled"
                header="Exit Alert"
                style={{ textAlign: "center" }}
                body={this.onBody}
              />
              {/* <Column
                field="entryAlertMessage"
                header="Entry Alert Msg"
                style={{ textAlign: "center" }}
                //body={this.onBody}
              />
              <Column
                field="exitAlertMessage"
                header="Exit Alert Msg"
                style={{ textAlign: "center" }}
                //body={this.onBody}
              /> */}
              {/* entryAlertMessage","exitAlertMessage","entryAlertMessage",phoneNumber */}
              {/* <Column
                header="Assign"
                body={this.assignTemplate(0)}
                style={{ textAlign: "center", width: "70px" }}
              />
              <Column
                header="Un-Assign"
                body={this.assignTemplate(2)}
                style={{ textAlign: "center", width: "70px" }}
              />
              <Column
                header="Edit"
                //rowEditor={true}
                body={this.assignTemplate(1)}
                style={{ width: "50px", textAlign: "center" }}
              /> */}
              <Column
                header="Actions"
                body={this.actionTemplate.bind(this)}
                style={{ textAlign: "center", width: "10em" }}
              />
            </DataTable>
          </div>
        </div>
      </div>
    );
  }
}
