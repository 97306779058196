import React, { Component } from "react";
import SidebarMenu from "./SidebarMenu";

class App extends Component {
  render() {
    return (
      <div>
        <SidebarMenu />
      </div>
    );
  }
}

export default App;
