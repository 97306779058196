import "react-dropzone-uploader/dist/styles.css";
import React from "react";
import Dropzone from "react-dropzone-uploader";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import Loader from "./Loader";
import {
  postUploadFileManagementUrl,
  baseUrl,
  errorCompute,
} from "../service/ApiUrls";
import { ModuleNameEnum, UploadActionEnum } from "../Enums";
import api from "../service/ApiService";
import LocalStorageService from "../service/localStorageService";
const qr = require("../assets/fileupload.png");

let callapiforupload = (obj, props) => {
  console.log(obj);
  const formData = new FormData();
  if (obj.from === "entApp") {
    formData.append("file", obj.file);
    formData.append("uploadActionType", UploadActionEnum.APKPARSE);
    formData.append("moduleName", ModuleNameEnum.APP);
  }
};

const Upload = (props) => {
  // specify upload params and url for your files
  //console.log("props", props);
  const [show, setshow] = useState(false);
  const [textToShow, settextToShow] = useState("Loading....");
  const [fromEntApp, setfromEntApp] = useState(
    props.from !== undefined && props.from === "entApp"
  );
  const [fromContentPush, setfromContentPush] = useState(
    props.from !== undefined && props.from === "contentPush"
  );
  const [fromimeiUpload, setfromimeiUpload] = useState(
    props.from !== undefined && props.from === "imeiUpload"
  );
  const [isEdit, setIsEdit] = useState(
    props.ObjectToShow !== undefined ? true : false
  );
  const [fullObject, setFullObject] = useState(props.ObjectToShow);
  // console.log("fullObject ", fullObject);
  // console.log("isEdit", isEdit);
  const getUploadParams = async ({ file, meta }) => {
    let urlTosend,
      headers = { Authorization: null };
    if (fromEntApp) {
      const formData = new FormData();
      let fields = {
        uploadActionType: UploadActionEnum.APKPARSE,
        moduleName: ModuleNameEnum.APP,
      };
      formData.append("file", file);
      console.log(formData);
      const localStorageService = LocalStorageService.getService();
      const token = localStorageService.getAccessToken();
      if (token) {
        headers = { Authorization: "Bearer " + token };
      }
      urlTosend = {
        url: postUploadFileManagementUrl,
        formData,
        fields,
        headers,
      };
    } else if (fromimeiUpload) {
      const formData = new FormData();
      let fields = {
        uploadActionType: UploadActionEnum.ONLYUPLOAD,
        moduleName: ModuleNameEnum.ENROLLMENT,
      };
      if (props.mediaPath !== null) {
        fields["mediaPath"] = props.mediaPath;
      }
      if (props.parentId !== null) {
        fields["parentId"] = props.parentId;
      }
      formData.append("file", file);
      const localStorageService = LocalStorageService.getService();
      const token = localStorageService.getAccessToken();
      if (token) {
        headers = { Authorization: "Bearer " + token };
      }
      urlTosend = {
        url: postUploadFileManagementUrl,
        formData,
        fields,
        headers,
      };
    } else if (fromContentPush) {
      const formData = new FormData();
      let fields = {
        uploadActionType: UploadActionEnum.ONLYUPLOAD,
        moduleName: ModuleNameEnum.CONTENT,
      };
      if (props.mediaPath !== null) {
        fields["mediaPath"] = props.mediaPath;
      }
      if (props.parentId !== null) {
        fields["parentId"] = props.parentId;
      }
      formData.append("file", file);
      const localStorageService = LocalStorageService.getService();
      const token = localStorageService.getAccessToken();
      if (token) {
        headers = { Authorization: "Bearer " + token };
      }
      urlTosend = {
        url: postUploadFileManagementUrl,
        formData,
        fields,
        headers,
      };
    }
    // else {
    //   urlTosend = { url: "https://httpbin.org/post" };
    // }

    return { ...urlTosend };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file, xhr }, status) => {
    console.log("handle change ", status, meta, file, xhr);

    if (status === "error_upload" || status === "exception_upload") {
      console.log("Error in access token");
      //callapiforupload({ from: "entApp", file: file });
    }

    if (status !== "done" && status !== "removed") {
      settextToShow(status);
      //setshow(true);
    } else if (status === "removed") {
      //setshow(false);
      if (fromEntApp) {
        props.handleSubmit({ result: null });
        //setfromEntApp(fromEntApp);
      }
    } else if (status === "done") {
      //setshow(false);
      if (fromEntApp) {
        console.log({ ...JSON.parse(xhr.response) });
        //setshow(false);
        props.handleSubmit({ ...JSON.parse(xhr.response) });
        setfromEntApp(fromEntApp);
        // const formData = new FormData();
        // formData.append("file", file);
        // formData.append("uploadActionType", UploadActionEnum.APKPARSE);
        // formData.append("moduleName", ModuleNameEnum.APP);
        // api
        //   .post(baseUrl + "/uploads", formData)
        //   .then((res) => {
        //     //setshow(false);
        //
        //   })
        //   .catch((err) => {
        //     // errorCompute(err);
        //     console.log("Error Message", err);
        //     //setshow(false);
        //   });
        // .catch((err) => {
        //   console.log(err);
        //   //setshow(false);
        // });
      }
      if (fromimeiUpload) {
        // const formData = new FormData();
        // formData.append("file", file);
        // formData.append("uploadActionType", UploadActionEnum.ONLYUPLOAD);
        // formData.append("moduleName", ModuleNameEnum.ENROLLMENT);
        // if (props.mediaPath !== null) {
        //   //fields["mediaPath"] = props.mediaPath;
        //   formData.append("mediaPath", props.mediaPath);
        // }
        // if (props.parentId !== null) {
        //   //fields["parentId"] = props.parentId;
        //   formData.append("parentId", props.parentId);
        // }
        // api
        //   .post(baseUrl + "/uploads", formData)
        //   .then((res) => {
        //     props.handleSubmit(res.data);
        //     //setfromEntApp(fromEntApp);
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
        props.handleSubmit({ ...JSON.parse(xhr.response) });
      }
      if (fromContentPush) {
        //setshow(true);
        // console.log("JSON Response", JSON.parse(xhr.response));
        let files = [];
        files.push({ file: file });
        let parseddata = { ...JSON.parse(xhr.response) };
        console.log("parsedata", parseddata);
        props.handleSubmit(parseddata, files, props.mediaPath);
        // const formData = new FormData();
        // formData.append("file", file);
        // formData.append("uploadActionType", UploadActionEnum.ONLYUPLOAD);
        // formData.append("moduleName", ModuleNameEnum.CONTENT);
        // if (props.mediaPath !== null) {
        //   //fields["mediaPath"] = props.mediaPath;
        //   formData.append("mediaPath", props.mediaPath);
        // }
        // if (props.parentId !== null) {
        //   //fields["parentId"] = props.parentId;
        //   formData.append("parentId", props.parentId);
        // }
        // api
        //   .post(baseUrl + "/uploads", formData)
        //   .then((res) => {
        //     //setshow(false);
        //     //props.handleSubmit(res.data);

        //     //setfromEntApp(fromEntApp);
        //   })
        //   .catch((err) => {
        //     // errorCompute(err);
        //     console.log("Error Message", err);
        //     //setshow(false);
        //   });
        // .catch((err) => {
        //   console.log(err);
        //   //setshow(false);
        // });
      }
    }
  };

  const onHideCall = () => {
    setshow(false);
  };

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    console.log("In child files", files, "all Files", allFiles);
    if (props.handleSubmit) {
      props.handleSubmit(files, allFiles);
    } else {
      allFiles.forEach((f) => f.remove());
    }
  };
  const getUploadParamss = ({ meta }) => {
    return { url: "https://www.mobionizer.com:8443/mdm/uploads" };
  };
  let dropZoneProps = {
    getUploadParams: getUploadParams,
    onChangeStatus: handleChangeStatus,
  };

  if (fromEntApp) {
    dropZoneProps.maxSizeBytes = 20 * 1024 * 1024 * 1024;
    dropZoneProps.maxFiles = 1;
    dropZoneProps.inputContent = (files, extra) =>
      (extra.reject ? "Apk files only" : "Drag Files") && (
        <img src={qr} alt="Upload" width="300px" />
      );
    dropZoneProps.accept = ".apk";
    dropZoneProps.styles = {
      dropzoneReject: { borderColor: "red", backgroundColor: "#DAA" },
      inputLabel: (files, extra) => (extra.reject ? { color: "red" } : {}),
    };
  } else if (fromimeiUpload) {
    dropZoneProps.maxFiles = 1;
    dropZoneProps.accept = ".xls,.xlsx";
    // dropZoneProps.onSubmit = ;
    dropZoneProps.inputContent = <img src={qr} alt="Upload" width="300px" />;
  } else {
    dropZoneProps.accept =
      ".webm,.jpg,.png,.mp4,.xlsx,.doc,.xls,.ppt,.ppt,.dotx,.docx,.apk,.exe,.mp3,.pdf,.zip,.gif,.jpeg,.psd,.wmv,.avi,.mov";
    dropZoneProps.maxSizeBytes = 20 * 1024 * 1024 * 1024;
    dropZoneProps.maxFiles = 1;
    dropZoneProps.multiple = false;
    //dropZoneProps.onSubmit = handleSubmit;
    dropZoneProps.maxFiles = 1;
    dropZoneProps.inputContent = <img src={qr} alt="Upload" width="300px" />;
  }

  //console.log("fromEntApp ", fromEntApp, "dropzone props", dropZoneProps);

  return (
    <>
      <Dialog
        visible={show}
        modal={true}
        style={{ width: "20vw" }}
        closable={false}
        showHeader={false}
        onHide={onHideCall}
      >
        <Loader textToShow={textToShow} />
      </Dialog>
      <Dropzone {...dropZoneProps} />
    </>
  );
};

export default Upload;
