import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Link } from "react-router-dom";
import api from "../service/ApiService";
import { getThemeListUrl, deleteThemeUrl } from "../service/ApiUrls";
import { Dialog } from "primereact/dialog";
import Loader from "../components/Loader";
import { Button } from "primereact/button";
import "../assets/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Assign from "../components/Assign";
import { OverlayPanel } from "primereact/overlaypanel";
import { ActionAssign } from "../Enums";
import { confirmAlert } from "react-confirm-alert";
import { Tooltip } from "primereact/tooltip";
import { Paginator } from "primereact/paginator";
const helpIcon = require("../assets/icons/help_icon.png");
const google = require("../assets/google.png");
const chrome = require("../assets/chrome.png");
const firefox = require("../assets/firefox.png");
const gmap = require("../assets/gmap.png");
const yahoo = require("../assets/yahoo.png");
const gmail = require("../assets/gmail.png");
const fmanager = require("../assets/fmanager.png");
const customapp = require("../assets/customapp.png");
const dlogo = require("../assets/dummy_logo.png");
const defaultbg = require("../assets/lockscreen.png");
class DeviceTheme extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dfirst: 0,
      currentPage: 1,
      rows: 6,
      first: 0,
      eventrows: 6,
    };
  }

  webCallGetTheme = ({ first = 0, limit = 6, filter = "" } = {}) => {
    this.setState({ visible: true }, () => {
      api
        .get(getThemeListUrl, {
          params: {
            first: first,
            limit: limit,
            filter: filter,
          },
        })
        .then((res) => {
          if (res.data.result.isError === false) {
            this.setState({
              ...res.data.data,
              visible: false,
              totalRecords: res.data.data.totalRecords,
            });
          } else if (res.data.result.isError === true) {
            this.setState({ visible: false });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
  onPageChange = (event) => {
    console.log(event);
    this.setState(
      {
        dfirst: event.first,
        page: event.page,
        eventrows: event.rows,
        rows: event.rows,
        currentPage: event.page + 1,
      },
      () => {
        this.webCallGetTheme({
          first: this.state.page,
          limit: this.state.eventrows,
        });
      }
    );
  };
  // webCallGetThemeList = async ({
  //   first = 0,
  //   limit = 10,
  //   filter = "RRR",
  // } = {}) => {
  //   try {
  //     this.setState({ visible: true, textInLoader: "Loading ...." });
  //     let {
  //       data: {
  //         data,
  //         result: { isError, errorMsg },
  //       },
  //     } = await api.get(getThemeListUrl);
  //     //console.log("response", response.data);
  //     if (isError === false) {
  //       this.setState({ ...data, visible: false });
  //     } else console.log("Reason", errorMsg);
  //   } catch (err) {
  //     console.log("error", err);
  //     this.setState({ visible: false });
  //     //localStorage.setItem("loggedIn", false);
  //     // window.location = process.env.PUBLIC_URL + "/login";
  //   }
  // };
  componentDidMount = () => {
    this.webCallGetTheme();
  };

  onDialogueHide = () => {
    this.setState({ visible: false });
  };

  deleteTheme = (e) => {
    console.log("in delete ", e);
    this.setState({ visible: true, textInLoader: "Deleting ..." });
    api
      .delete(deleteThemeUrl + "/" + e.id)
      .then((res) => {
        if (res.data.result.isError === false) {
          this.setState({ visible: false }, () => {
            confirmAlert({
              title: "Success Message",
              message: <p className="mod-sp">Deleted Theme Successfully</p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    this.webCallGetTheme();
                  },
                },
              ],
            });
          });
          //Error Dialgoue
        } else {
        }
      })
      .catch((err) => this.setState({ visible: false }));
  };

  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Device Outline", url: "device-outline" },
      { label: "Device Theme" },
    ];
    const home = { icon: "pi pi-home", url: "dashboard" };
    const footer = (
      <div>
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-success"
          onClick={(e) => {
            this.setState({ confirm: false, visible: true }, (e) => {
              this.deleteTheme(this.state.deleteObject);
            });
          }}
        />
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ confirm: false });
          }}
        />
      </div>
    );
    const deleteDialogue = (
      <Dialog
        header="Delete Theme"
        visible={this.state.confirm}
        style={{ width: "30vw" }}
        modal={true}
        footer={footer}
        onHide={() => this.setState({ confirm: false })}
      >
        <p
          style={{
            textAlign: "center",
            fontWeight: "bold",
            paddingBottom: "10px",
            marginBottom: "10px",
          }}
        >
          Do you Want to Delete this Theme?
        </p>
      </Dialog>
    );
    let theme_app =
      this.state.content !== undefined &&
      this.state.content.map((e, i) => {
        let picture =
          e.androidProperties === null ||
          e.androidProperties.backgroudWallpaperbase64 === undefined ||
          e.androidProperties.backgroudWallpaperbase64 === null
            ? { defaultbg }
            : `data:image/jpeg;base64,${e.androidProperties.backgroudWallpaperbase64}`;
        //console.log("i", i, e.androidProperties.topBarFontColorHexCode);
        let topBar;
        if (e.androidProperties !== null && e.androidProperties !== undefined) {
          if (
            e.androidProperties.topBarColorHexCode !== null &&
            e.androidProperties.topBarColorHexCode !== undefined
          ) {
            if (e.androidProperties.topBarColorHexCode.startsWith("#")) {
              topBar = e.androidProperties.topBarColorHexCode;
            } else {
              topBar = "#" + e.androidProperties.topBarColorHexCode;
            }
          }
        }
        let topBarFontColor;
        if (e.androidProperties !== null && e.androidProperties !== undefined) {
          if (
            e.androidProperties.topBarFontColorHexCode !== null &&
            e.androidProperties.topBarFontColorHexCode !== undefined
          ) {
            if (e.androidProperties.topBarFontColorHexCode.startsWith("#")) {
              topBarFontColor = e.androidProperties.topBarFontColorHexCode;
            } else {
              topBarFontColor =
                "#" + e.androidProperties.topBarFontColorHexCode;
            }
          }
        }

        // "#" + e.androidProperties.topBarFontColorHexCode;
        return (
          <>
            <div
              key={i}
              className="p-col-6 p-md-4 p-lg-2 p-sm-6"
              style={{ display: "inline-block" }}
            >
              <div key={i} className="phonebg">
                <div className="row justify-content-center">
                  <div
                    className="col-12"
                    style={{
                      background: "#d8d5d5",
                      textAlign: "center",
                      maxWidth: "75%",
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                    }}
                  >
                    <Tooltip
                      target=".Edit"
                      position="top"
                      mouseTrack
                      mouseTrackTop={20}
                    />
                    <Link to={{ pathname: "/new-theme", state: { e: e } }}>
                      <i
                        className="pi pi-pencil edit_icon Edit"
                        data-pr-tooltip="Edit"
                      ></i>
                    </Link>
                    <Tooltip
                      target=".Delete"
                      position="top"
                      mouseTrack
                      mouseTrackTop={20}
                    />
                    <i
                      className="pi pi-trash Delete edit_icon"
                      data-pr-tooltip="Delete"
                      style={{ cursor: "pointer" }}
                      onClick={(ev) => {
                        this.setState({ confirm: true, deleteObject: e });
                        // this.deleteTheme(e)
                      }}
                    ></i>
                    <Assign
                      showIcon={true}
                      actionType={ActionAssign.ASSIGN}
                      name={"Profile Assign"}
                      dataToSend={e}
                      fromModule="theme"
                    />
                    <Assign
                      showIcon={false}
                      actionType={ActionAssign.UNASSIGN}
                      name={"Profile Un-Assign"}
                      dataToSend={e}
                      fromModule="theme"
                    />
                  </div>
                </div>
                <div className="cus-row">
                  <div className="smartphone-theme">
                    <div
                      className="content"
                      style={{
                        backgroundImage: "url(" + picture + ")",
                      }}
                    >
                      <div
                        className="theme-headerbg"
                        style={{
                          backgroundColor: topBar,
                        }}
                      >
                        <span
                          className="device-name"
                          style={{
                            color: topBarFontColor,
                          }}
                        >
                          <img
                            // src={`data:image/jpeg;base64,${e.androidProperties.brandLogobase64}`}
                            src={
                              e.androidProperties === null ||
                              e.androidProperties.brandLogobase64 ===
                                undefined ||
                              e.androidProperties.brandLogobase64 === null
                                ? dlogo
                                : `data:image/jpeg;base64,${e.androidProperties.brandLogobase64}`
                            }
                            width="15px"
                            style={{ paddingRight: "5px" }}
                            alt=""
                          />
                          {e.brandName}
                        </span>
                        <div
                          style={{
                            display: "inline-block",
                            padding: "10px 3px",
                            marginTop: "5px",
                          }}
                        >
                          <img src={chrome} className="theme-icon" alt="" />
                          <img src={firefox} className="theme-icon" alt="" />
                          <img src={gmail} className="theme-icon" alt="" />
                          <img src={yahoo} className="theme-icon" alt="" />
                        </div>
                        <div
                          style={{
                            display: "inline-block",
                            padding: "3px 3px",
                          }}
                        >
                          <img src={google} className="theme-icon" alt="" />
                          <img src={gmap} className="theme-icon" alt="" />
                          <img src={customapp} className="theme-icon" alt="" />
                          <img src={fmanager} className="theme-icon" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <span className="theme_title">{e.name}</span>
              </div>
            </div>
          </>
        );
      });

    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12">
          <Dialog
            visible={this.state.visible}
            modal={true}
            style={{ width: "20vw" }}
            closable={false}
            showHeader={false}
            onHide={this.onDialogueHide}
          >
            <Loader textToShow={this.state.textInLoader} />
          </Dialog>
          {deleteDialogue}
          <div className="p-col-9 inline-b p-ll">
            <BreadCrumb model={items} home={home} />
          </div>
          <div className="p-col-3 inline-b p-zz">
            <img
              src={helpIcon}
              width="20"
              height="20"
              onClick={(e) => this.op.toggle(e)}
              alt="Help"
              style={{
                marginBottom: "-10px",
                float: "right",
                cursor: "pointer",
              }}
            />
          </div>
          <OverlayPanel
            ref={(el) => (this.op = el)}
            id="overlay_panel"
            showCloseIcon={true}
          >
            <div
              className="col"
              style={{
                width: "fit-content",
                maxWidth: "300px",
                padding: "0px",
              }}
            >
              <div className="helptip-title">
                <b>How to : Device Theme</b>
              </div>
              <div className="helptip-des">
                Device theme defines type of style that is applied on devices
                via their profile. It represents organization branding, logo,
                icon style of apps, background wall-paper etc.
              </div>
            </div>
          </OverlayPanel>
        </div>
        <div className="p-col-12">
          <div className="card">
            <div className="p-col-12 p-lg-12 ">
              <div className="p-col-6" style={{ display: "inline-block" }}>
                <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                  {" "}
                  Available Themes
                </p>
              </div>
              <div className="p-col-2" style={{ display: "inline-block" }}>
                <Link to="/new-theme" style={{ textDecoration: "underline" }}>
                  + Create New Theme
                </Link>
              </div>
              <div className="p-col-4" style={{ display: "inline-block" }}>
                <div className="form-inline d-flex justify-content-center md-form form-sm mt-0 b-input">
                  <input
                    className="form-control form-control-sm mr-2 b-n"
                    type="text"
                    placeholder="Enter Theme name"
                    aria-label="Search"
                    style={{ width: "85%" }}
                    onChange={(e) => {
                      //   if (
                      //     this.state.content !== null &&
                      //     this.state.content !== undefined
                      //   ) {
                      //     let oldData = [...this.state.content];
                      //     console.log("e.target", e.target.value);
                      //     this.state.content.map((v, i) => {
                      //       if (
                      //         e.target.value !== "" &&
                      //         e.target.value
                      //           .toLowerCase()
                      //           .match(v.name.toLowerCase())
                      //       ) {
                      //         this.setState({
                      //           content: [v],
                      //           oldData: oldData,
                      //         });
                      //       }
                      //     });
                      //     if (e.target.value === "") {
                      //       this.setState({
                      //         content: this.state.oldData,
                      //       });
                      //     }
                      //   }
                      // }}

                      this.setState({ textFilter: e.target.value }, () => {
                        if (this.state.textFilter === "") {
                          this.webCallGetTheme({ filter: "" });
                        }
                      });
                    }}
                    onKeyDown={(e) =>
                      // this.setState({ globalFilter: e.target.value })
                      {
                        console.log("e", e.key);
                        if (e.key === "Enter") {
                          this.webCallGetTheme({
                            filter: this.state.textFilter,
                          });
                        } else {
                          if (this.state.textFilter === "") {
                            this.webCallGetTheme({
                              filter: "",
                            });
                          }
                        }
                      }
                    }
                  />
                  <i
                    className="pi pi-search"
                    aria-hidden="true"
                    onClick={() => {
                      if (this.state.textFilter !== "") {
                        this.webCallGetTheme({
                          filter: this.state.textFilter,
                        });
                      } else {
                        this.webCallGetTheme({
                          filter: "",
                        });
                      }
                    }}
                  ></i>
                </div>
              </div>
            </div>
            <div
              className="p-col-12 p-lg-12 "
              style={
                {
                  //   height: "340px",
                  //   overflowY: "auto",
                  //   overflowX: "hidden",
                }
              }
            >
              {theme_app}
              <Paginator
                first={this.state.dfirst}
                rows={this.state.eventrows}
                totalRecords={this.state.totalRecords}
                rowsPerPageOptions={[6, 12, 18, 50, 100]}
                onPageChange={this.onPageChange}
                style={{ marginTop: "10px" }}
              ></Paginator>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DeviceTheme;
