import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { AppMap2 } from "../components/AppMap2";

import { Dialog } from "primereact/dialog";
import { baseUrl, getLocationHistory, errorCompute } from "../service/ApiUrls";
import api from "../service/ApiService";
import { LOCATIONZOOM } from "../Enums";
import Loader from "../components/Loader";
import { confirmAlert } from "react-confirm-alert";
let events = [
  { ts: "2017-09-17T12:22:46.587Z", text: "Gulshan 1, Dhaka, Bangladesh" },
  { ts: "2017-09-17T12:21:46.587Z", text: "Gulshan 2, Dhaka, Bangladesh" },
  { ts: "2017-09-17T12:20:46.587Z", text: "Rampura, Dhaka, Bangladesh" },
];
const search_not = require("../assets/search-not.png");

export class LocationTraking extends Component {
  constructor(props) {
    super(props);
    //console.log("detail in history ", this.props.history.location.state);
    this.state = {
      overlays: null,
      circles: null,
      ...this.props.history.location.state.detail,
      imei: this.props.history.location.state.imei,
      id: this.props.history.location.state.id,
      isError: true,
      errorMsg: "No Location Found",
      errorMessage: "Something went wrong!",
      isLoading: false,
    };
    let toDate = new Date();
    toDate = this.convertDate(toDate).split("/").join("-");
    this.callgetEvents({ start: toDate, end: toDate });
  }

  componentWillMount = () => {
    let toDate = new Date();
    toDate = this.convertDate(toDate).split("/").join("-");
    this.callgetEvents({ start: toDate, end: toDate });
  };

  formatDate = (date, format) => {
    const map = {
      mm: date.getMonth() + 1,
      dd: date.getDate(),
      yy: date.getFullYear().toString().slice(-2),
      yyyy: date.getFullYear(),
    };

    return format.replace(/mm|dd|yyyy/gi, (matched) => map[matched]);
  };

  lastXDate = (days) => {
    let now = new Date();
    let lastXdays = new Date(now.setDate(now.getDate() - days));
    lastXdays = this.formatDate(lastXdays, "dd-mm-yyyy");
    return lastXdays;
  };

  convertDate = (date) => {
    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    var today = new Date(date);
    //console.log(today.toLocaleDateString("en-US", options));

    // console.log(today.toLocaleDateString("en-US"));
    return today.toLocaleDateString("en-GB");
  };

  onDialogueHide = () => {
    this.setState({ isLoading: false });
  };

  callgetEvents = ({ start = "05-12-2021", end = "08-12-2021" } = {}) => {
    this.setState({ isLoading: true }, () => {
      // console.log("imei", this.state.imei);
      api
        .get(getLocationHistory + "/" + this.state.imei, {
          params: { start: start, end: end },
        })
        .then((res) => {
          if (res.data.result.isError === false) {
            this.setState({ ...res.data.data, isLoading: false }, () => {
              events = [];
              let overlays = [];
              this.state.content !== undefined &&
                this.state.content !== null &&
                this.state.content.map((v, i) => {
                  v.locationDetils !== undefined &&
                    v.locationDetils !== null &&
                    v.locationDetils.map((e, i) => {
                      if (
                        e.hasOwnProperty("updateTime") &&
                        e.hasOwnProperty("address")
                      ) {
                        let token = e.updateTime.split(" ");
                        let date = token[0].split("-");
                        // let splittedTime = token[1].split(":");
                        // let first = Number(splittedTime[0]) % 24;
                        // let second = splittedTime[1];
                        // let third = splittedTime[2];
                        //let tokenFor = first + ":" + second + ":" + third;
                        let conv =
                          date[2] +
                          "-" +
                          date[1] +
                          "-" +
                          date[0] +
                          "T" +
                          token[1] +
                          "+0600";
                        events.push({ ts: conv, text: e.address });
                        // console.log("Date Time" + conv);
                      }
                      let currloc = e;
                      let currentlocation = {
                        position: {
                          lat: Number(currloc.latitude + "" + i),
                          lng: Number(currloc.longitude + "" + i),
                        },
                        title: currloc.address,
                      };
                      overlays.push(currentlocation);
                    });
                  this.setState({
                    events: events,
                    overlays: overlays,
                    isError: false,
                  });
                });
            });
          } else if (res.data.result.isError) {
            this.setState(
              {
                isLoading: false,
              },
              () => {
                confirmAlert({
                  title: "Error Message",
                  message: (
                    <p className="mod-p"> {res.data.result.errorMsg} </p>
                  ),
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => {},
                    },
                  ],
                });
              }
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  callLiveTracking = () => {
    api
      .get(baseUrl + "/devices/" + this.state.id + "/latest-location")
      .then((res) => {
        if (res.data.result.isError === false) {
          console.log("Inside false", res.data);
          this.setState(
            {
              lastLocation: res.data.data.locationDetils,
            },
            () => {
              //   this.callgetHistoryLocation();
            }
          );
        }
      })
      .catch((err) => {
        //console.log(err);
        errorCompute(err);
        //localStorage.setItem("loggedIn", false);
        // window.location = process.env.PUBLIC_URL + "/login";
      });
  };

  componentDidMount() {
    this.callLiveTracking();
    this.callgetEvents();
  }
  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Location", url: "location" },
      { label: "Location Search", url: "location-search" },
      { label: "Live Location" },
    ];
    const options = {
      center: { lat: 23.797911, lng: 90.414391 },
      zoom: LOCATIONZOOM.ZOOM,
    };

    const home = { icon: "pi pi-home", url: "/" };
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 ">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>

        <div className="p-col-12" style={{ padding: "5px 20px" }}>
          <div id="cus-padding" className="p-grid bg-col">
            <div className="p-col-12">
              <h2 style={{ paddingLeft: "12px" }}>
                Device Live Location History |
                <span>IMEI : {this.state.imei}</span>
              </h2>
            </div>
            <div className="cus-padding p-col-12 p-md-4 p-xl-4 p-sm-6">
              <div
                className="box"
                style={{
                  border: "1px solid #F5F5F5",
                  borderRadius: "5px",
                  padding: "6px",
                  background: "#F5F5F5",
                  marginTop: "5px",
                }}
              >
                <div
                  className="col-lg-12"
                  style={{ marginBottom: "10px", background: "F5F5F5" }}
                >
                  {this.state.lastLocation !== undefined &&
                  this.state.lastLocation.address !== undefined
                    ? this.state.lastLocation.address
                    : ""}
                  {this.state.lastLocation !== undefined &&
                    this.state.lastLocation.map((item, i) => {
                      return (
                        <>
                          <p className="mb-1" key={i}>
                            <b>Current Location</b>
                            <br />
                            {item.address}
                          </p>
                          <p className="mb-1" key={i}>
                            <b>Current Latitude</b>
                            <br />
                            {item.latitude}
                          </p>
                          <p className="mb-1" key={i}>
                            <b>Current Longitude</b>
                            <br />
                            {item.longitude}
                          </p>
                          <p className="mb-1" key={i}>
                            <b>Latest Update Date & Time</b>
                            <br />
                            {item.updateTime}
                          </p>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="p-col-12 p-md-8 p-sm-6">
              <div className="box" style={{}}>
                <div className="p-col-12">
                  {!this.state.isError && this.state.totalRecords !== 0 ? (
                    <AppMap2
                      options={this.state.options}
                      stateID={this.state.id}
                      overlays={this.state.overlays}
                      style={{ width: "100%", minHeight: "375px" }}
                    ></AppMap2>
                  ) : (
                    <>
                      <img
                        src={search_not}
                        width="180px"
                        style={{}}
                        className="mx-auto d-block"
                      />
                      <h2 style={{ textAlign: "center" }}>
                        {this.state.errorMsg}
                      </h2>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Dialog
            visible={this.state.isLoading}
            modal
            style={{ width: "20vw" }}
            closable={false}
            showHeader={false}
            onHide={this.onDialogueHide}
          >
            <Loader />
          </Dialog>
        </div>
      </div>
    );
  }
}
