import React, { Component } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
class NewDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
    };
  }

  startChange = (date) => {
    this.setState(
      {
        startDate: date,
      },
      () => {
        console.log("Dates", this.state);
        if (this.state.endDate !== null && this.state.startDate !== null) {
          let et = this.convertDate(this.state.endDate).split("/").join("-");
          let st = this.convertDate(this.state.startDate).split("/").join("-");
          console.log(et, st);
          this.props.callgetEvents({ start: st, end: et });
        }
      }
    );
  };
  endChange = (date) => {
    this.setState(
      {
        endDate: date,
      },
      () => {
        console.log("Dates", this.state);
        if (this.state.endDate !== null && this.state.startDate !== null) {
          let et = this.convertDate(this.state.endDate).split("/").join("-");
          let st = this.convertDate(this.state.startDate).split("/").join("-");
          console.log(et, st);
          this.props.callgetEvents({ start: st, end: et });
        }
      }
    );
  };

  convertDate = (date) => {
    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    var today = new Date(date);
    //console.log(today.toLocaleDateString("en-US", options));

    console.log(today.toLocaleDateString("en-US"));
    return today.toLocaleDateString("en-GB");
  };

  render() {
    return (
      <>
        <div className="col-12 p-md-6 p-xl-6 p-sm-12 inline-b">
          <label>Start Date</label>
          <DatePicker
            selected={this.state.startDate}
            onChange={this.startChange}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            isClearable
            className="date-class p-col-12 p-md-12 p-xl-12"
            // selectsStart={this.state.startDate}
            dateFormat="dd-MM-yyyy"
            placeholderText="Select Start Date"
          />
        </div>

        <div className="col-12 p-md-6 p-xl-6 p-sm-12 inline-b">
          <label>End Date</label>
          <DatePicker
            selected={this.state.endDate}
            onChange={this.endChange}
            endDate={this.state.endDate}
            minDate={this.state.startDate}
            isClearable
            className="date-class p-col-12 p-md-12 p-xl-12"
            // selectsEnd={this.state.endDate}
            dateFormat="dd-MM-yyyy"
            placeholderText="Select End Date"
          />
        </div>
      </>
    );
  }
}

export default NewDate;
