import React, { Component } from "react";
import { Modal, Alert, Spinner } from "react-bootstrap";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import api from "../service/ApiService";
import {
  getGroupListUrl,
  getGroupListDevice,
  getDeviceSearchData,
  postGroupListAdd,
} from "../service/ApiUrls";
class AddNewDevice extends Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    // this.webservice = new ServicesofAxios();

    this.state = { currentPage: 1 };
    //this.alldata = new AllData();
  }
  componentDidMount() {
    //  this.setState({ group: });
  }
  callGroupListDevices({
    profileId = this.state.selectedProfile !== null &&
    this.state.selectedProfile !== undefined
      ? this.state.selectedProfile.id
      : null,
    filter,
    first = 0,
    limit = 1,
  } = {}) {
    api
      .get(getGroupListDevice, {
        params: { profileId: profileId, imei: filter, first, limit },
      })
      .then((res) => {
        if (res.data.result.isError === false) {
          this.setState({
            listofDevices: res.data.data.content,
            totalRecords: res.data.data.totalRecords,
          });
        } else if (res.data.result.isError) {
          this.setState({
            isLoading: true,
            errorMessage: res.data.result.errorMsg,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  actionTemplate(rowData, column) {
    return (
      <div>
        <a href="#">
          <i
            className="pi pi-minus-circle"
            style={{ fontSize: "1.3em", color: "red" }}
          ></i>
        </a>
      </div>
    );
  }
  handleClose() {
    this.setState({ show: false, selectedCars3: null });
  }

  handleShow() {
    this.setState({ show: true }, () => {
      this.callGroupListDevices({
        first: 0,
        limit: 5,
      });
    });
  }

  submitHandler = (e) => {
    e.preventDefault();
  };

  render() {
    const paginatorLeft = <Button icon="pi pi-refresh" />;
    const profileList = this.props.profileList;
    let profileOptions =
      profileList !== undefined &&
      profileList.length > 0 &&
      profileList.map((e, i) => {
        //console.log("profile", e);
        return (
          <option key={i} value={e.id}>
            {e.name}
          </option>
        );
      });
    const header = (
      <div id="cus-p" className="row justify-content-end">
        <div className="p-col-12 p-md-2 p-lg-3">
          <div className="form-group">
            <select
              className="form-control"
              // id="exampleFormControlSelect1"
              onChange={(e) => {
                console.log("e in profile ", e.target);
                let profileObject = profileList.find((obj) => {
                  //console.log("object", obj.id);
                  return obj.id === e.target.value;
                });
                //console.log("profile object", profileObject);
                this.setState(
                  {
                    ...this.state,
                    selectedProfile: profileObject,
                  },
                  () => {
                    this.callGroupListDevices({
                      profileId:
                        this.state.selectedProfile !== null &&
                        this.state.selectedProfile !== undefined
                          ? this.state.selectedProfile.id
                          : null,
                    });
                  }
                );
              }}
              id="selectedProfile"
              name="selectedProfile"
              value={
                this.state.selectedProfile !== null &&
                this.state.selectedProfile !== undefined
                  ? this.state.selectedProfile.id
                  : ""
              }
            >
              <option value={null}>Select Profile</option>
              {profileOptions}
            </select>
          </div>
        </div>
        <div className="p-col-12 p-md-4 p-lg-4">
          <div
            className="form-inline d-flex justify-content-center md-form form-sm mt-0 b-input"
            style={{ padding: "3px 0px" }}
          >
            <InputText
              className="form-control form-control-sm mr-2 b-n"
              type="search"
              style={{ width: "300px", marginTop: "5px" }}
              onKeyDown={(e) => {
                if (e.keyCode == 13) {
                  this.callGroupListDevices({ filter: e.target.value });
                }
              }}
              placeholder="Enter Search Item"
              // style={{ width: "80%" }}
              aria-label="Search"
            />
            <i className="pi pi-search" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    );
    return (
      <>
        <Button
          type="button"
          label=" + Add New Device"
          className="p-button-rounded p-button-success"
          style={{
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
          onClick={this.handleShow}
        />

        {/* <a href="#" className="custom-a" onClick={this.handleShow}>
          Assign
        </a> */}
        <Modal size="lg" show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              Add New Device{" "}
              <p
                style={{
                  textAlign: "left",
                  color: "#000",
                  fontSize: "14px",
                  marginBottom: "0px",
                }}
              >
                User can select devices which are not into any group.
              </p>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="col-12">
              {header}
              <DataTable
                value={
                  this.state.listofDevices !== null &&
                  this.state.listofDevices !== undefined
                    ? this.state.listofDevices
                    : this.props.listofDevices
                }
                style={{ marginBottom: "20px" }}
                //responsive={true}
                selection={this.state.selectedCars3}
                lazy={true}
                paginator={true}
                onPage={(event) => {
                  //console.log("Event", event);
                  this.callGroupListDevices({
                    first: event.first,
                    limit: event.rows,
                  });
                  setTimeout(() => {
                    const { first, rows, page } = event;

                    this.setState({
                      first,
                      currentPage: page + 1,
                    });
                  }, 500);
                }}
                first={this.state.first}
                totalRecords={this.state.totalRecords}
                //paginatorLeft={paginatorLeft}

                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                currentPage={this.state.currentPage}
                //rows={this.state.rows}
                emptyMessage="No records found"
                rowsPerPageOptions={[5, 10, 20]}
                onSelectionChange={(e) =>
                  this.setState({ selectedCars3: e.value })
                }
              >
                <Column selectionMode="multiple" style={{ width: "3em" }} />
                {/* <Column field="deviceName" header="Device Name" /> */}
                <Column
                  field="imei1"
                  header="IMEI1"
                  style={{ width: "11em" }}
                />
                <Column
                  field="imei2"
                  header="IMEI2"
                  style={{ width: "11em" }}
                />
                <Column
                  field="osVersion"
                  header="OS Version"
                  style={{ wordWrap: "break-word" }}
                />
                <Column field="model" header="Model" />

                {/* <Column
                  header="Action"
                  body={this.actionTemplate.bind(this)}
                  style={{ textAlign: "center", width: "8em" }}
                /> */}
              </DataTable>
            </div>
          </Modal.Body>
          <Modal.Footer style={{ display: "block", textAlign: "center" }}>
            <Button
              type="button"
              label=" + Add"
              className="p-button-rounded p-button-success"
              style={{
                paddingLeft: "15px",
                paddingRight: "15px",
              }}
              onClick={(e) => {
                this.handleClose();
                this.props.sendSelections(this.state.selectedCars3);
              }}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default AddNewDevice;
