import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { AppMap } from "../components/AppMap";
import { Dialog } from "primereact/dialog";
import Loader from "../components/Loader";
import ErrorDialog from "../components/ErrorDialog";
import SuccessDialog from "../components/SuccessDialog";
import {
  postaddLocationGeofence,
  postputLocationGeofence,
  baseUrl,
  errorCompute,
} from "../service/ApiUrls";
import api from "../service/ApiService";
import { LOCATIONZOOM } from "../Enums";
import { confirmAlert } from "react-confirm-alert";
let isEditOn;
class AddNewGeofence extends Component {
  constructor(props) {
    super(props);
    //this.webservice = new ServicesofAxios();
    isEditOn =
      this.props.history.location.state !== undefined &&
      this.props.history.location.state.dataCopy !== undefined;
    const options =
      this.props.history.location.state !== undefined &&
      this.props.history.location.state.dataCopy !== undefined
        ? {
            center: {
              lat: parseFloat(
                this.props.history.location.state.dataCopy.latitude
              ),
              lng: parseFloat(
                this.props.history.location.state.dataCopy.longitude
              ),
            },
            zoom: LOCATIONZOOM.ZOOM,
          }
        : {
            center: { lat: 23.797911, lng: 90.414391 },
            zoom: LOCATIONZOOM.ZOOM,
          };
    this.state = {
      id: null,
      name: "",
      latitude: 0.0,
      longitude: 0.0,
      searchRadiusInKM: 0,
      phoneNumber: "",
      emailAddress: "",
      entryAlertEnabled: true,
      entryAlertMessage: "",
      exitAlertEnabled: true,
      exitAlertMessage: "",
      centerLocation: "",
      isSubmitted: false,
      error: false,
      isLoading: false,
      isSuccess: false,
      options: options,
      errorMessage: "Something went wrong!",
    };

    this.child = React.createRef();
  }

  callGetApi = () => {
    api
      .get(
        baseUrl +
          "/locations/geofences/" +
          this.props.history.location.state.dataCopy.id
      )
      .then((res) => {
        if (res.data.result.isError === false) {
          this.setState({ ...res.data.data }, () => {
            this.setContents();
            this.callSetFunc("centerLocation", this.state.centerLocation);
          });
        } else {
        }
      })
      .catch((err) => errorCompute(err));
  };

  callSetFunc = (target, value) => {
    console.log("target value ", target, " only value", value);
    if (
      target === "searchRadiusInKM" ||
      target === "latitude" ||
      target === "longitude"
    ) {
      this.setContents();
    } else {
      this.child.current.getLatlong(value, (coordinates) => {
        this.setState(
          {
            latitude: coordinates.latitude,
            longitude: coordinates.longitude,
            options: {
              center: { lat: coordinates.latitude, lng: coordinates.longitude },
              zoom: LOCATIONZOOM.ZOOM,
            },
          },
          () => {
            if (
              target === "latitude" ||
              target === "longitude" ||
              target === "centerLocation" ||
              target === "searchRadiusInKM"
            ) {
              this.setContents();
            }
          }
        );
      });
    }
  };

  changeCenterSearchText = (val) => {
    console.log("entered value : ", val);
    this.setState({ centerLocation: val }, () => {
      this.callSetFunc("centerLocation", this.state.centerLocation);
    });
  };

  changeHandler = (e) => {
    let {
      target: { name, value },
    } = e;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (
          name === "latitude" ||
          name === "longitude" ||
          name === "centerLocation" ||
          name === "searchRadiusInKM"
        ) {
          this.callSetFunc(name, value);
        }
      }
    );
  };

  submitHandler = (e) => {
    e.preventDefault();
    this.setState({
      isLoading: true,
      isSubmitted: false,
    });
    const paramdata = {
      name: this.state.name,
      lat: this.state.lat,
      long: this.state.long,
      radius: this.state.radius,
    };
  };

  setContents = () => {
    let options = {
      center: {
        lat: parseFloat(this.state.latitude),
        lng: parseFloat(this.state.longitude),
      },
      zoom: LOCATIONZOOM.ZOOM,
    };
    let overlay = [
      {
        position: {
          lat: parseFloat(this.state.latitude),
          lng: parseFloat(this.state.longitude),
        },
        title: "",
      },
    ];

    let circleCenter = [
      {
        center: {
          lat: parseFloat(this.state.latitude),
          lng: parseFloat(this.state.longitude),
        },
        radius: parseFloat(this.state.searchRadiusInKM) * 1000,
      },
    ];

    console.log("Overlay", overlay, "circles", circleCenter);

    this.setState({
      overlays: overlay,
      circles: circleCenter,
      options: options,
    });
  };

  componentDidMount() {
    if (
      this.props.history.location.state !== undefined &&
      this.props.history.location.state.dataCopy !== undefined
    ) {
      this.callGetApi();
    }
  }

  addGeoFence = (isEdit) => () => {
    this.setState({ isLoading: true });
    let url = isEdit
      ? postputLocationGeofence + "/" + this.state.id
      : postaddLocationGeofence;
    console.log("url", url);
    if (!isEdit) {
      api.post(url, this.state).then((res) => {
        if (res.data.result.isError === false) {
          confirmAlert({
            title: "Success Message",
            message: <p className="mod-sp">Added Geofence Successfully</p>,
            buttons: [
              {
                label: "Ok",
                onClick: () => {},
              },
            ],
          });
          this.props.history.push("/geofence");
          // this.setState({ isSuccess: true, isLoading: false });
        } else if (res.data.result.isError === true) {
          this.setState({ isLoading: false }, () => {
            confirmAlert({
              title: "Error Message",
              message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {},
                },
              ],
            });
          });
        }
      });
    } else {
      api.put(url, this.state).then((res) => {
        if (res.data.result.isError === false) {
          confirmAlert({
            title: "Success Message",
            message: <p className="mod-sp">Updated Geofence Successfully</p>,
            buttons: [
              {
                label: "Ok",
                onClick: () => {},
              },
            ],
          });
          this.props.history.push("/geofence");
        } else if (res.data.result.isError === true) {
          this.setState({ isLoading: false }, () => {
            confirmAlert({
              title: "Error Message",
              message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {},
                },
              ],
            });
          });
        }

        // this.setState({ isSuccess: true, isLoading: false });
      });
    }
  };

  radioButtonChange = (entry, enable) => () => {
    console.log("entry ", entry, "enable", enable);
    if (entry === 1) {
      if (enable === 1) {
        this.setState({ entryAlertEnabled: true });
      } else {
        this.setState({ entryAlertEnabled: false });
      }
    } else {
      if (enable === 1) {
        this.setState({ exitAlertEnabled: true });
      } else {
        this.setState({ exitAlertEnabled: false });
      }
    }
  };

  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Location", url: "location" },
      { label: "Geofence", url: "geofence" },
      { label: (isEditOn ? "Edit " : "Add ") + "Geofence" },
    ];

    //console.log("options", options);
    const events = [
      { ts: "2017-09-17T12:22:46.587Z", text: "Gulshan 1, Dhaka, Bangladesh" },
      { ts: "2017-09-17T12:21:46.587Z", text: "Gulshan 2, Dhaka, Bangladesh" },
      { ts: "2017-09-17T12:20:46.587Z", text: "Rampura, Dhaka, Bangladesh" },
    ];
    const home = { icon: "pi pi-home", url: "dashboard" };
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 ">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="p-col-12 row align-items-start">
          <Dialog
            visible={this.state.isLoading}
            modal={true}
            closable={false}
            showHeader={false}
            style={{ width: "20vw" }}
            onHide={this.onDialogueHide}
          >
            <Loader />
          </Dialog>
          <Dialog
            visible={this.state.isSuccess}
            modal={true}
            style={{ width: "15vw" }}
            closable={this.state.closable}
            showHeader={this.state.showHeader}
            onHide={this.onDialogueHide}
          >
            <SuccessDialog successMessage="Saved Geofence Successfully" />
          </Dialog>
          <Dialog
            visible={this.state.isError}
            modal={true}
            style={{ width: "15vw" }}
            closable={this.state.closable}
            showHeader={this.state.showHeader}
            onHide={this.onDialogueHide}
          >
            <ErrorDialog errorMessage={this.state.errorMessage} />
          </Dialog>
          <div className="col-4 p-r">
            <div className="col-12 bg-col2">
              <p className="mb-3">
                <b>{isEditOn ? "Edit " : "Add "} Geofences</b>
              </p>
              <div className="form-group">
                <label htmlFor="name">Location Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Enter Name"
                  onChange={this.changeHandler}
                  value={this.state.name}
                />
              </div>
              <div className="form-group">
                <label style={{ paddingRight: "15px" }}>Geo Entry Alert</label>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="alertentry"
                    id="entryEnable"
                    value="entryEnable"
                    onChange={this.radioButtonChange(1, 1)}
                    checked={this.state.entryAlertEnabled === true}
                  />
                  <label className="form-check-label" htmlFor="entryEnable">
                    Enable
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="alertentry"
                    id="entryDisable"
                    value="entryDisable"
                    onChange={this.radioButtonChange(1, 0)}
                    checked={this.state.entryAlertEnabled === false}
                  />
                  <label className="form-check-label" htmlFor="entryDisable">
                    Disable
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label style={{ paddingRight: "15px" }}>Geo Exit Alert</label>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exitentry"
                    id="exitEnable"
                    value="exitEnable"
                    onChange={this.radioButtonChange(0, 1)}
                    checked={this.state.exitAlertEnabled === true}
                  />
                  <label className="form-check-label" htmlFor="exitEnable">
                    Enable
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exitentry"
                    id="exitDisable"
                    value="exitDisable"
                    onChange={this.radioButtonChange(0, 0)}
                    checked={this.state.exitAlertEnabled === false}
                  />
                  <label className="form-check-label" htmlFor="exitDisable">
                    Disable
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="nemail">Notification Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="emailAddress"
                  name="emailAddress"
                  placeholder="Enter Email"
                  onChange={this.changeHandler}
                  value={this.state.emailAddress}
                />
              </div>
              <div className="form-group">
                <label htmlFor="nphone">Notification Phone</label>
                <input
                  type="text"
                  className="form-control"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="Enter Phone"
                  onChange={this.changeHandler}
                  value={this.state.phoneNumber}
                />
              </div>
              <div className="form-group">
                <label htmlFor="alertmsg">Entry Alert Message</label>
                <input
                  type="text"
                  className="form-control"
                  id="entryAlertMessage"
                  name="entryAlertMessage"
                  placeholder="Enter Message"
                  onChange={this.changeHandler}
                  value={
                    this.state.entryAlertMessage !== null
                      ? this.state.entryAlertMessage
                      : ""
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="alertmsg">Exit Alert Message</label>
                <input
                  type="text"
                  className="form-control"
                  id="exitAlertMessage"
                  name="exitAlertMessage"
                  placeholder="Enter Message"
                  onChange={this.changeHandler}
                  value={
                    this.state.exitAlertMessage !== null
                      ? this.state.exitAlertMessage
                      : ""
                  }
                />
              </div>
              <div className="form-group">
                <button
                  className="btn9"
                  onClick={this.addGeoFence(
                    this.props.history.location.state !== undefined &&
                      this.props.history.location.state.dataCopy !== undefined
                  )}
                >
                  {isEditOn ? "Update " : "+ Add "} Geofence
                </button>
              </div>
            </div>
          </div>
          <div className="col-8 p-l">
            <div className="col-12 bg-col2">
              <div className="form-group">
                <label htmlFor="centerLocation">
                  Search For Center Location
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="autocomplete"
                  name="centerLocation"
                  placeholder="Enter Here"
                  onChange={this.changeHandler}
                  value={this.state.centerLocation}
                />
              </div>
              <div className="row align-items-start">
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="lat">Lattitude</label>
                    <input
                      type="text"
                      className="form-control"
                      id="lat"
                      name="latitude"
                      placeholder="Enter Here"
                      onChange={this.changeHandler}
                      value={this.state.latitude}
                      disabled
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="long">Longitude</label>
                    <input
                      type="text"
                      className="form-control"
                      id="long"
                      name="longitude"
                      placeholder="Enter Here"
                      onChange={this.changeHandler}
                      value={this.state.longitude}
                      disabled
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="radious">Radius(KM)</label>
                    <input
                      type="text"
                      className="form-control"
                      id="radius"
                      name="searchRadiusInKM"
                      placeholder="Enter Here"
                      onChange={this.changeHandler}
                      value={this.state.searchRadiusInKM}
                    />
                  </div>
                </div>
                <div className="col-12 mt-4">
                  {true && (
                    <AppMap
                      options={this.state.options}
                      overlays={this.state.overlays}
                      circles={this.state.circles}
                      changeCenterSearchText={this.changeCenterSearchText}
                      style={{ width: "100%", minHeight: "340px" }}
                      ref={this.child}
                    ></AppMap>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddNewGeofence;
