import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/js/bootstrap.bundle.min";
import App from "./App";
//import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

ReactDOM.render(
  <>
    <Router>
      <ScrollToTop>
        <App></App>
      </ScrollToTop>
    </Router>
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
