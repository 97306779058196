import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { BreadCrumb } from "primereact/breadcrumb";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AllData } from "../service/AllData";
import EntPopupForm from "../components/EntPopupForm";
import {
  getAppManagementVersionListUrl,
  baseUrl,
  getAppManagemnetUrl,
} from "../service/ApiUrls";
import { ActionType, DeleteType, ActionAssign } from "../Enums";
import { Button } from "primereact/button";
import api from "../service/ApiService";
import Loader from "../components/Loader";
import { Dialog } from "primereact/dialog";
import SuccessDialog from "../components/SuccessDialog";
import Assign from "../components/Assign";
import { Tooltip } from "primereact/tooltip";
import { confirmAlert } from "react-confirm-alert";
const uninstallDeleteIcon = require("../assets/icons/uninstall+delete.png");
const FileDownload = require("js-file-download");

export class EntManage extends Component {
  constructor(props) {
    super(props);
    if (this.props.history.location.state !== undefined) {
      this.state = {
        entapp: [],
        sortField: "versionCode",
        sortOrder: -1,
        visible: false,
        vvisible: false,
        deletevisible: false,
        ...this.props.history.location.state.e,
      };
    } else {
    }
    this.alldata = new AllData();
  }

  callDelete = ({
    appId = this.state.appId,
    deleteType = DeleteType.APPDELETE,
    actionType = ActionType.DELETE_UNINSTALL,
  } = {}) => {
    let data = {
      appId: appId,
      deleteType: deleteType,
      actionType: actionType,
    };
    api
      .delete(getAppManagemnetUrl, {
        data,
        //headers: { aa: "aa" },
      })
      .then((res) => {
        console.log("uninstall response", res);
        if (res.data.result.isError === false) {
          this.setState({ showLoader: false }, () => {
            confirmAlert({
              title: "Success Message",
              message: <p className="mod-sp">Deleted App Successfully</p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    window.location =
                      process.env.PUBLIC_URL + "/app-management";
                  },
                },
              ],
            });
          });
          //this.props.history.push("/app-management");
        } else if (res.data.result.isError === true) {
          this.setState({ showLoader: false }, () => {
            confirmAlert({
              title: "Error Message",
              message: (
                <p className="mod-p">
                  {res.data.result.statusCode + " " + res.data.result.errorMsg}
                </p>
              ),
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {},
                },
              ],
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  callVersionList = () => {
    api
      .get(getAppManagementVersionListUrl, {
        params: { appId: this.state.appId },
      })
      .then((res) => {
        console.log("res", res.data);
        if (res.data.result.isError === false) {
          //dataArray.push(res.data.data.content);
          this.setState({
            entapp: res.data.data.content,
            ...this.props.history.location.state.e,
            showLoader: false,
          });
          //console.log(dataArray);
        }
      });
  };
  componentDidMount() {
    // dataArray.push({ ...this.state });

    this.callVersionList();
    //this.callDelete();
  }
  appIconLocation = (rowData, column) => {
    var src = `data:image/jpeg;base64,${this.state.appLogoBase64}`;
    return <img src={src} alt={this.state.appName} width="40px" />;
  };

  actionTemplate = (rowData, column) => {
    return (
      <div>
        <NavLink
          to="#"
          onClick={(e) => {
            e.preventDefault();
            this.setState({ vvisible: true, rowData: rowData });
            //this.actionToPerform(rowData, column, "delete");
          }}
          className="tablelink"
        >
          {/* Delete */}
          <Tooltip
            target=".vDelete"
            position="top"
            mouseTrack
            mouseTrackTop={20}
          />
          <i
            className="pi pi-trash vDelete"
            data-pr-tooltip="Version Delete"
            style={{ fontSize: "1em" }}
          ></i>
          {/* <img
            className="vDelete"
            src={vDelete}
            width="15px"
            data-pr-tooltip="Version Delete"
            alt="Version Delete"
          /> */}
        </NavLink>
        <Assign
          name={`Uninstall App ${rowData.appName}`}
          fromModule="appManagement"
          dataToSend={rowData}
          actionType={ActionAssign.UNINSTALL}
          versionCall="version-list"
        />
        <NavLink
          to="#"
          onClick={(e) => {
            e.preventDefault();
            this.setState({ showLoader: true });
            //this.actionToPerform(rowData, column, "download");
            let urltoDownload = baseUrl + rowData.appDownloadUrl;
            api
              .get(urltoDownload, {
                responseType: "blob",
              })
              .then((res) => {
                this.setState({ showLoader: false }, () => {
                  FileDownload(res.data, `${rowData.appName}.apk`);
                });
                // FileDownload(res.data, `${rowData.appName}.apk`);
              })
              .catch((err) => {
                console.log(err);
              });
          }}
          className="tablelink"
        >
          {/* Download */}
          <Tooltip
            target=".vDownload"
            mouseTrack
            position="top"
            mouseTrackTop={20}
          />
          {/* <img
            src={vDownload}
            className="vDownload"
            data-pr-tooltip="Download"
            width="15px"
            alt="Version Download"
          /> */}
          <i
            className="pi pi-download vDownload"
            data-pr-tooltip="Download"
            style={{ fontSize: "1em" }}
          ></i>
        </NavLink>

        <EntPopupForm showDetails={true} ObjectToShow={rowData} />

        {/* <NavLink
          to="#"
          onClick={(e) => {
            e.preventDefault();
            this.actionToPerform(rowData, column, "assign");
          }}
          className="tablelink"
        >
          Assign{" "}
        </NavLink> */}
        <Assign
          name={`Assign for App ${rowData.appName}`}
          fromModule="appManagement"
          dataToSend={rowData}
          actionType={ActionAssign.ASSIGN}
          versionCall="version-list"
        />
        <Assign
          name={`Un-Assign for App ${rowData.appName}`}
          fromModule="appManagement"
          dataToSend={rowData}
          actionType={ActionAssign.UNASSIGN}
          versionCall="version-list"
        />
      </div>
    );
  };
  onDialogueHide = () => {
    this.setState({ showLoader: false, visible: false });
  };

  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Enterprise App", url: "app-management" },
      { label: "Manage" },
    ];
    const footer = (
      <div>
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-success"
          onClick={() => {
            this.setState(
              {
                visible: false,
                showLoader: true,
                textInLoader: "Delete & Uninstall",
              },
              () => {
                this.callDelete();
              }
            );
          }}
        />
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ visible: false });
          }}
        />
      </div>
    );
    const deleteDialogue = (
      <Dialog
        header="Uninstall & Delete"
        visible={this.state.visible}
        style={{ width: "30vw" }}
        modal={true}
        footer={footer}
        onHide={() => this.setState({ visible: false })}
      >
        <p
          class="text-danger"
          style={{ textAlign: "center", fontWeight: "bold" }}
        >
          Are you want to Uninstall + Delete ?
        </p>
      </Dialog>
    );

    let footer2 = (
      <div>
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-success"
          onClick={() => {
            this.setState(
              {
                vvisible: false,
                showLoader: true,
                textInLoader: "Delete Version",
              },
              () => {
                let deletedata = {
                  id: this.state.rowData.id,
                  deleteType: DeleteType.APPVERSIONDELETE,
                  actionType: ActionType.DELETE,
                };
                console.log("data", deletedata);
                api
                  .delete(getAppManagemnetUrl, {
                    data: deletedata,
                  })
                  .then((res) => {
                    if (res.data.result.isError === false) {
                      this.setState({ showLoader: false }, () => {
                        confirmAlert({
                          title: "Success Message",
                          message: (
                            <p className="mod-sp">Deleted App Successfully</p>
                          ),
                          buttons: [
                            {
                              label: "Ok",
                              onClick: () => {
                                window.location =
                                  process.env.PUBLIC_URL + "/app-management";
                              },
                            },
                          ],
                        });
                        this.callVersionList();
                      });
                    } else if (res.data.result.isError === true) {
                      this.setState({ showLoader: false }, () => {
                        confirmAlert({
                          title: "Error Message",
                          message: (
                            <p className="mod-p">
                              {res.data.result.statusCode +
                                " " +
                                res.data.result.errorMsg}
                            </p>
                          ),
                          buttons: [
                            {
                              label: "Ok",
                              onClick: () => {},
                            },
                          ],
                        });
                      });
                    }
                  });
              }
            );
          }}
        />
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ vvisible: false });
          }}
        />
      </div>
    );
    const vdeleteDialogue = (
      <Dialog
        header="Version Delete"
        visible={this.state.vvisible}
        style={{ width: "30vw" }}
        modal={true}
        footer={footer2}
        onHide={() => this.setState({ vvisible: false })}
      >
        <p
          class="text-danger"
          style={{ textAlign: "center", fontWeight: "bold" }}
        >
          Do you want to Delete this Version ?
        </p>
      </Dialog>
    );
    const footer1 = (
      <div>
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-success"
          onClick={() => {
            this.setState(
              {
                deletevisible: false,
                showLoader: true,
                textInLoader: "Delete",
              },
              () => {
                api
                  .delete(getAppManagemnetUrl, {
                    data: {
                      appId: this.state.appId,
                      deleteType: DeleteType.APPDELETE,
                      actionType: ActionType.DELETE,
                    },
                  })
                  .then((res) => {
                    console.log("Delete response", res);
                    if (res.data.result.isError === false) {
                      this.setState({ showLoader: false }, () => {
                        confirmAlert({
                          title: "Success Message",
                          message: (
                            <p className="mod-sp">Deleted App Successfully</p>
                          ),
                          buttons: [
                            {
                              label: "Ok",
                              onClick: () => {},
                            },
                          ],
                        });
                      });
                      //this.props.history.push("/app-management");
                      window.location =
                        process.env.PUBLIC_URL + "/app-management";
                    } else if (res.data.result.isError === true) {
                      this.setState({ showLoader: false }, () => {
                        confirmAlert({
                          title: "Error Message",
                          message: (
                            <p className="mod-p">
                              {res.data.result.statusCode +
                                " " +
                                res.data.result.errorMsg}
                            </p>
                          ),
                          buttons: [
                            {
                              label: "Ok",
                              onClick: () => {},
                            },
                          ],
                        });
                      });
                    }
                  });
              }
            );
          }}
        />
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ deletevisible: false });
          }}
        />
      </div>
    );
    const deleteApp = (
      <Dialog
        header="Delete App"
        visible={this.state.deletevisible}
        style={{ width: "30vw" }}
        modal={true}
        footer={footer1}
        onHide={() => this.setState({ deletevisible: false })}
      >
        <p
          class="text-danger"
          style={{ textAlign: "center", fontWeight: "bold" }}
        >
          Do you want to Delete this App?
        </p>
      </Dialog>
    );
    const home = { icon: "pi pi-home", url: "dashboard" };
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 ">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="p-grid bg-col p-lg-12">
          <div className="p-col">
            <div className="p-col-12 p-lg-12 ">
              {deleteDialogue}
              {vdeleteDialogue}
              {deleteApp}
              <Dialog
                visible={this.state.showLoader}
                modal={true}
                style={{ width: "20vw" }}
                closable={false}
                showHeader={false}
                onHide={this.onDialogueHide}
              >
                <Loader />
              </Dialog>
              <Dialog
                visible={this.state.isSuccess}
                modal={true}
                style={{ width: "25vw" }}
                closable
                showHeader
                onHide={this.onDialogueHideSuccess}
              >
                <SuccessDialog successMessage="Deleted App Successfully" />
              </Dialog>
              <div className="col-5 p-z" style={{ display: "inline-block" }}>
                <div className="col-lg-12">
                  <div className="assign-list">
                    <div
                      className="assign-blcok"
                      style={{ background: "transparent" }}
                    >
                      <img
                        src={`data:image/jpeg;base64,${this.state.appLogoBase64}`}
                        alt={this.state.appName}
                        width="55px"
                        className="assign-img"
                      />
                    </div>
                    <span className="assign-title">{""}</span>
                    <span className="assign-subtitle">
                      <b>AppId:</b> {this.state.appId}
                    </span>
                    <span className="assign-subtitle">
                      <b> Version:</b> {this.state.versionName}
                    </span>
                    <span className="assign-subtitle">
                      <b> Version Code:</b> {this.state.versionCode}
                    </span>
                  </div>
                </div>
              </div>
              <div className="p-lg-7" style={{ display: "inline-block" }}>
                <div className="col-lg-12" style={{ textAlign: "right" }}>
                  <Assign
                    name={`Un-Assign for App ${this.state.appId}`}
                    fromModule="appManagement"
                    dataToSend={this.state}
                    actionType={ActionAssign.UNASSIGN}
                  />

                  <button
                    type="button"
                    className="btn btn-primary cus-btn p-21"
                    onClick={() => {
                      this.setState({ deletevisible: true });
                    }}
                  >
                    <Tooltip
                      target=".Delete"
                      position="top"
                      mouseTrack
                      mouseTrackTop={20}
                    />
                    <i
                      className="pi pi-trash Delete"
                      data-pr-tooltip="Delete"
                      style={{ fontSize: "1em" }}
                    ></i>
                    {/* <img
                      src={deleteIcon}
                      width="15px"
                      data-pr-tooltip="Delete"
                      className="Delete"
                      alt="Delete"
                    /> */}
                  </button>

                  {/* <button
                    type="button"
                    className="btn btn-primary cus-btn p-21"
                    onClick={() => {
                      this.setState(
                        { showLoader: true, textInLoader: "Uninstalling" },
                        () => {
                          api
                            .delete(getAppManagemnetUrl, {
                              data: {
                                appId: this.state.appId,
                                // deleteType: DeleteType.APPVERSIONDELETE,
                                actionType: ActionType.UNINSTALL,
                              },
                            })
                            .then((res) => {
                              console.log("uninstall response", res);
                              if (res.data.result.isError === false) {
                                this.props.history.push("/app-management");
                                // window.location =
                                //   process.env.PUBLIC_URL + "/app-management";
                              } else {
                                this.setState({
                                  textInLoader:
                                    res.data.result.statusCode +
                                    " " +
                                    res.data.result.errorMsg,
                                });
                              }
                            });
                        }
                      );
                    }}
                  >
                    <img src={uninstallIcon} width="15px" />
                  </button> */}
                  <button
                    type="button"
                    className="btn btn-primary cus-btn p-21"
                    onClick={() => {
                      this.setState({
                        visible: true,
                        // showLoader: true,
                        // textInLoader: "Delete & Uninstall",
                      });
                    }}
                  >
                    <Tooltip
                      target=".uninstallDeleteIcon"
                      position="top"
                      mouseTrack
                      mouseTrackTop={20}
                    />
                    <img
                      src={uninstallDeleteIcon}
                      width="50px"
                      alt="Delete+Uninstall"
                      data-pr-tooltip="Uninstall + Delete"
                      className="uninstallDeleteIcon"
                    />
                  </button>
                  <EntPopupForm
                    textToShow="Upload New Version"
                    ObjectToShow={this.state}
                  />
                </div>
              </div>
            </div>
          </div>
          <DataTable
            value={this.state.entapp}
            style={{ marginBottom: "20px" }}
            responsive={true}
            emptyMessage="No records found"
            sortField={this.state.sortField}
            sortOrder={this.state.sortOrder}
            onSort={(e) =>
              this.setState({ sortField: e.sortField, sortOrder: e.sortOrder })
            }
          >
            <Column
              field="app_icon"
              header="App Icon"
              body={this.appIconLocation}
              style={{ textAlign: "center" }}
            />
            <Column
              field="versionName"
              header="Version"
              style={{ textAlign: "center" }}
            />
            <Column
              field="versionCode"
              header="Version Code"
              style={{ textAlign: "center" }}
            />
            <Column
              field="appName"
              header="App Name"
              style={{ textAlign: "center" }}
            />
            {/* <Column field="size" header="Size" /> */}
            <Column
              header="Action"
              body={this.actionTemplate.bind(this)}
              style={{ textAlign: "center", width: "30em" }}
            />
          </DataTable>
        </div>
      </div>
    );
  }
}
