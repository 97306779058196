import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Link } from "react-router-dom";
const org = require("../assets/org.png");
const userm = require("../assets/userm.png");
const rolem = require("../assets/rolem.png");
const setting = require("../assets/global-setting.png");
export class HomeGround extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  webMenuDynamic = () => {
    console.log("calling", "webMenuDynamic");
    let imagemap = {
      "/organization": org,
      "/user-management": userm,
      "/role-management": rolem,
      "/setup-android-enterprise": setting,
    };
    return (
      this.props.extraProp !== null &&
      this.props.extraProp !== undefined &&
      this.props.extraProp.map((e, v) => {
        if (e.feature.category === 0) {
          if (e.feature.title !== "Dashboard") {
            let helpingMenu = imagemap[e.feature.protectedUrl];
            let icon =
              helpingMenu !== undefined ? helpingMenu : "pi pi-fw pi-dollar";
            return (
              <div key={"mo-" + v} className="p-col-12 p-md-3 p-xl-3 p-sm-6">
                <div key={"ch1-" + v} className="small-box bg-aqua">
                  <div key={"ch11-" + v} className="icon">
                    <img
                      src={icon}
                      width="60px"
                      style={{ width: "60px", height: "60px" }}
                      className="mx-auto d-block"
                    />
                    {/* <i
                      className={icon + " " + "mx-auto d-block"}
                      style={{
                        width: "60px",
                        height: "60px",
                        color: "#ffffff",
                        fontSize: "3.5em",
                      }}
                    ></i> */}
                  </div>
                  <Link
                    to={e.feature.protectedUrl}
                    className="small-box-footer"
                  >
                    {e.feature.title}
                  </Link>
                </div>
              </div>
            );
          }
        }
      })
    );
  };

  render() {
    const items = [{ label: "Dashboard", url: "/" }, { label: "Home Ground" }];

    const home = { icon: "pi pi-home", url: "/" };
    return (
      <div className="p-grid p-fluid dashboard">
        <div className="p-col-12 p-lg-12">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        {this.props.extraProp !== undefined && this.webMenuDynamic()}
        {/* <div className="p-col-12 p-md-3 p-xl-3 p-sm-6">
          <div className="small-box bg-aqua">
            <div className="icon">
              <img
                src={org}
                width="60px"
                style={{ width: "60px", height: "60px" }}
                className="mx-auto d-block"
              />
            </div>
            <Link to="/organization" className="small-box-footer">
              ORGANIGATION
            </Link>
          </div>
        </div>
        <div className="p-col-12 p-md-3 p-xl-3 p-sm-6">
          <div className="small-box bg-aqua">
            <div className="icon">
              <img
                src={userm}
                width="60px"
                style={{ width: "60px", height: "60px" }}
                className="mx-auto d-block"
              />
            </div>
            <Link to="/user-management" className="small-box-footer">
              USER MANAGEMENT
            </Link>
          </div>
        </div>
        <div className="p-col-12 p-md-3 p-xl-3 p-sm-6">
          <div className="small-box bg-aqua">
            <div className="icon">
              <img
                src={rolem}
                width="60px"
                style={{ width: "60px", height: "60px" }}
                className="mx-auto d-block"
              />
            </div>
            <Link to="/role-management" className="small-box-footer">
              ROLE MANAGEMENT
            </Link>
          </div>
        </div>
        <div className="p-col-12 p-md-3 p-xl-3 p-sm-6">
          <div className="small-box bg-aqua">
            <div className="icon">
              <img
                src={setting}
                width="60px"
                style={{ width: "60px", height: "60px" }}
                className="mx-auto d-block"
              />
            </div>
            <Link to="/setup-android-enterprise" className="small-box-footer">
              SETUP ANDROID ENTERPRISE
            </Link>
          </div>
        </div> */}
      </div>
    );
  }
}
