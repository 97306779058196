import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { ServicesofAxios } from "../webservices/servicesofaxios";
import { GlobalSettingsType } from "../Enums";
import AssignInDiv from "../components/AssignInDiv";

class DeactiveLauncher extends Component {
  constructor(props) {
    super(props);
    this.webservice = new ServicesofAxios();

    this.state = {};
  }

  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Global Settings", url: "global-settings" },
      { label: "Deactive Launcher" },
    ];
    const home = { icon: "pi pi-home", url: "dashboard" };

    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 ">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="p-col-12 row align-items-start">
          <div className="col-12 bg-col2">
            <div className="col-12 p-left">
              <p>
                <b>Deactive Launcher</b>
              </p>
              <p>
                Deactive the Mobionizer Launcher for selected profiles / groups
                / devices.
              </p>
            </div>
            <div className="row align-items-start p-left">
              <div className="col-12  p-r">
                <p
                  className="col-12 mt-3"
                  style={{ borderTop: "1px solid #d8d5d5", paddingTop: "10px" }}
                >
                  <b>Choose Recipients</b>
                </p>
                <AssignInDiv
                  dataToSend={{
                    id: GlobalSettingsType.DEACTIVATELAUNCHER,
                    ...this.state,
                  }}
                  type={GlobalSettingsType.DEACTIVATELAUNCHER}
                  fromModule="global"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DeactiveLauncher;
