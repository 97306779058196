import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Fieldset } from "primereact/fieldset";
import api from "../service/ApiService";
import { baseUrl } from "../service/ApiUrls";
import { confirmAlert } from "react-confirm-alert";
import Loader from "../components/Loader";
import { Dialog } from "primereact/dialog";
export class MyPlan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      price: 120,
      total: 0,
      devices: 250,
      features: [],
      isEdit: true,
      isLoading: false,
    };
  }
  changeHandler = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  onDialogueHide = () => {
    this.setState({ isLoading: false });
  };
  webcallPutRequest = () => {
    this.setState({ isLoading: true }, () => {
      let dataToSend = {
        licenseId: this.state.id,
        contact: {
          address1: this.state.address1,
          address2: this.state.address2,
          city: this.state.city,
          state: this.state.state,
          postalCode: this.state.postalCode,
          country: this.state.country,
          email: this.state.email,
          mobileNO: this.state.mobileNO,
        },
        maxDeviceCount: this.state.maxDeviceCount,
      };
      console.log("datatosend", dataToSend);
      api
        .put(baseUrl + "/license/edit-organization-license", dataToSend)
        .then((res) => {
          if (res.data.result.isError === false) {
            this.setState({ isEdit: true, isLoading: false }, () => {
              this.webcallGetApi();
            });

            //this.props.history.push("/dashboard");
          } else if (res.data.result.isError === true) {
            this.setState({ isLoading: false }, () => {
              confirmAlert({
                title: "Error Message",
                message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {
                      // this.webcallGetUSerList();
                    },
                  },
                ],
              });
            });
          }
        });
    });
  };

  webcallGetApi = () => {
    this.setState({ isLoading: true }, () => {
      api.get(baseUrl + "/license/get-organization-license").then((res) => {
        if (res.data.result.isError === false) {
          if (res.data.data !== null) {
            this.setState({
              isLoading: false,
              ...res.data.data,
              ...res.data.data.licenseDetail,
              ...res.data.data.contact,
            });
          } else {
            this.setState({ isLoading: false });
          }
        } else if (res.data.result.isError === true) {
          this.setState({ isLoading: false }, () => {
            confirmAlert({
              title: "Error Message",
              message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    // this.webcallGetUSerList();
                  },
                },
              ],
            });
          });
        }
      });
    });
  };

  componentDidMount() {
    this.webcallGetApi();
    // this.alldata
    //   .getBasicFeatures()
    //   .then((data) => this.setState({ features: data, active: data.status }));
  }

  render() {
    const colourMapper = {
      Basic: "blue",
      Premium: "green",
      Ultimate: "red",
    };
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "My Plan" },
    ];
    const home = { icon: "pi pi-home", url: "dashboard" };
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 ">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="p-grid bg-col p-col-12 p-lg-12">
          <Fieldset
            legend="Selected Plan"
            style={{ width: "100%", marginBottom: "15px" }}
            toggleable
          >
            <div className="p-grid p-col-12 p-lg-12 justify-content-md-center">
              <div className="p-col-12 p-lg-8">
                <div
                  className={"pricing-plan " + colourMapper[this.state.type]}
                  style={{ width: "100%", marginTop: "20px" }}
                >
                  <div className="pricingTable-header ">
                    <h3 className="title">
                      {this.state.type !== undefined && this.state.type}
                    </h3>
                    <div className="price-value">
                      {" "}
                      {this.state.price} {this.state.priceUnit}
                      / Per device
                      <br /> per {this.state.pricePeriod}
                    </div>
                  </div>
                  <div className="p-col-12 h-div">
                    <div
                      className="p-col-12 col-md-4 d-inline-block"
                      style={{ borderRight: "1px solid #c7c7c7" }}
                    >
                      Status:{" "}
                      <span style={{ color: "green", fontWeight: "bold" }}>
                        {this.state.statusSt}
                      </span>
                    </div>
                    <div
                      className="p-col-12 col-md-4 d-inline-block"
                      style={{ borderRight: "1px solid #c7c7c7" }}
                    >
                      <span>
                        Start Date:{" "}
                        <b>
                          {this.state.startDate !== null
                            ? this.state.startDate
                            : "N/A"}
                        </b>
                      </span>
                    </div>
                    <div className="p-col-12 col-md-4 d-inline-block">
                      <span>
                        Expire Date:{" "}
                        <b>
                          {this.state.expireDate !== null
                            ? this.state.expireDate
                            : "N/A"}
                        </b>
                      </span>
                    </div>
                  </div>
                  <table
                    className="table table-bordered"
                    style={{ marginBottom: "-1em" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">Features</th>
                        <th style={{ textAlign: "center" }} scope="col">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.features.map((e, i) => {
                        if (e.availability === true) {
                          e["status"] = "pi-check";
                        } else {
                          e["status"] = "pi-times";
                        }
                        if (e.restrictionData !== null) {
                          e["extradata"] = e.restrictionData;
                        }
                        if (e.restrictionUnit !== null) {
                          e["status"] =
                            e["extradata"] + " " + e.restrictionUnit;
                          e["extradata"] = undefined;
                        }
                        if (
                          e.restrictionData !== null &&
                          e.restrictionUnit === null
                        ) {
                          e["status"] = e["extradata"];
                          e["extradata"] = undefined;
                        }
                        if (
                          e.restrictionData === null &&
                          e.restrictionUnit === null
                        ) {
                          e["extradata"] = undefined;
                        }
                        return (
                          <>
                            <tr key={"my-" + i}>
                              <td style={{ width: "70%" }}>
                                {e.featureTitle +
                                  (e["extradata"] !== undefined
                                    ? " ( " + e["extradata"] + " )"
                                    : "")}
                              </td>
                              <td style={{ width: "30%", textAlign: "center" }}>
                                {e.status === "pi-check" ? (
                                  <>
                                    <i className="pi pi-check right-check p-mr-2"></i>
                                  </>
                                ) : (
                                  <>
                                    {e.status === "pi-times" ? (
                                      <>
                                        <i className="pi pi-times cross-check p-mr-2"></i>
                                      </>
                                    ) : (
                                      <>{e.status}</>
                                    )}
                                  </>
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                  {/* <ul className="plan-features">
                    {this.state.features.map((e, i) => {
                      return (
                        <>
                          <li key={i}>
                            {e.status === "pi-check" ? (
                              <>
                                <i className="pi pi-check right-check p-mr-2"></i>{" "}
                                {e.name}
                              </>
                            ) : (
                              <>
                                {e.status === "pi-times" ? (
                                  <>
                                    <i className="pi pi-times cross-check p-mr-2"></i>{" "}
                                    {e.name}
                                  </>
                                ) : (
                                  <>
                                    <i className="pi pi-check right-check p-mr-2"></i>{" "}
                                    {e.name + " (" + e.status + ")"}
                                  </>
                                )}
                              </>
                            )}
                          </li>
                        </>
                      );
                    })}
                  </ul> */}
                </div>
                <button
                  style={{ float: "right", marginTop: "10px" }}
                  className="btn btn-primary"
                  onClick={() => {
                    this.props.history.push("/pricing-plan");
                  }}
                >
                  Change Plan
                </button>
              </div>
            </div>
          </Fieldset>
          <Fieldset legend="Device Status" toggleable style={{ width: "100%" }}>
            <div className="p-grid p-col-12 p-lg-12 justify-content-md-center">
              <div className="p-col-12 p-md-2 p-lg-2">
                <div className="form-group">
                  <label htmlFor="devices">Devices</label>
                  <input
                    disabled
                    type="number"
                    className="form-control"
                    id="maxDeviceCount"
                    onChange={this.changeHandler}
                    placeholder="Devices Count"
                    value={this.state.maxDeviceCount}
                  />
                </div>
              </div>
              <div
                className="p-col-12 p-md-1 p-lg-1"
                style={{
                  marginTop: "37px",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                *
              </div>
              <div className="p-col-12 p-md-2 p-lg-2">
                <div className="form-group">
                  <label htmlFor="prices">Prices</label>
                  <input
                    type="number"
                    className="form-control"
                    id="prices"
                    onChange={this.changeHandler}
                    placeholder="Enter Prices"
                    value={this.state.price}
                    disabled
                  />
                </div>
              </div>
              <div
                className="p-col-12 p-md-1 p-lg-1"
                style={{
                  marginTop: "37px",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                =
              </div>
              <div className="p-col-12 p-md-2 p-lg-2">
                <label htmlFor="total">Total Amount</label>
                <div className="input-group mb-2">
                  <input
                    type="number"
                    className="form-control"
                    id="total"
                    onChange={this.changeHandler}
                    placeholder="Total"
                    value={this.state.maxDeviceCount * this.state.price}
                    disabled
                  />
                  <div className="input-group-prepend">
                    <div className="input-group-text">BDT</div>
                  </div>
                </div>
              </div>
              <div className="p-col-12 p-md-8 p-lg-8">
                <p style={{ fontSize: "18px" }}>
                  Enrolled Devices: <b>{this.state.enrolledDeviceCount}</b>
                </p>
              </div>
            </div>
          </Fieldset>
          <Fieldset
            legend="Contact Information"
            style={{ width: "100%", marginTop: "20px" }}
            toggleable
          >
            <div className="p-grid p-col-12 p-lg-12">
              <div className="p-col-12 p-md-12 p-lg-12">
                <div
                  className={this.state.isEdit === true ? "" : "d-none"}
                  style={{ textAlign: "right" }}
                >
                  <i
                    className="pi pi-pencil edit-icon"
                    onClick={() => {
                      this.setState({ isEdit: false });
                    }}
                  ></i>
                  {/* <button
                    type="button"
                    onClick={() => {
                      this.setState({ isEdit: false });
                    }}
                    className="btn btn-primary"
                  >
                    Edit
                  </button> */}
                </div>
              </div>
              <div className="p-col-12 p-md-6 p-lg-6">
                <div className="form-group">
                  <label htmlFor="address1">Address 1</label>
                  <textarea
                    className="form-control"
                    onChange={this.changeHandler}
                    id="address1"
                    placeholder="Enter Address 1"
                    rows="3"
                    value={this.state.address1}
                    disabled={this.state.isEdit}
                  ></textarea>
                </div>
              </div>
              <div className="p-col-12 p-md-6 p-lg-6">
                <div className="form-group">
                  <label htmlFor="address2">Address 2</label>
                  <textarea
                    className="form-control"
                    onChange={this.changeHandler}
                    id="address2"
                    placeholder="Enter Address 2"
                    rows="4"
                    value={this.state.address2}
                    disabled={this.state.isEdit}
                  ></textarea>
                </div>
              </div>
              <div className="p-col-12 p-md-4 p-lg-4">
                <div className="form-group">
                  <label htmlFor="city">City</label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    onChange={this.changeHandler}
                    placeholder="Enter City"
                    value={this.state.city}
                    disabled={this.state.isEdit}
                  />
                </div>
              </div>
              <div className="p-col-12 p-md-4 p-lg-4">
                <div className="form-group">
                  <label htmlFor="state">State</label>
                  <input
                    type="text"
                    className="form-control"
                    id="state"
                    onChange={this.changeHandler}
                    placeholder="Enter State"
                    value={this.state.state}
                    disabled={this.state.isEdit}
                  />
                </div>
              </div>
              <div className="p-col-12 p-md-4 p-lg-4">
                <div className="form-group">
                  <label htmlFor="city">Postal Code</label>
                  <input
                    type="text"
                    className="form-control"
                    id="postalCode"
                    onChange={this.changeHandler}
                    placeholder="Enter Postal Code"
                    value={this.state.postalCode}
                    disabled={this.state.isEdit}
                  />
                </div>
              </div>
              <div className="p-col-12 p-md-4 p-lg-4">
                <div className="form-group">
                  <label for="country">Country</label>
                  <select className="form-control" id="country" disabled>
                    <option>Bangladesh</option>
                  </select>
                </div>
              </div>
              <div className="p-col-12 p-md-4 p-lg-4">
                <div className="form-group">
                  <label htmlFor="city">Contact Email</label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    onChange={this.changeHandler}
                    placeholder="Enter Email Address"
                    value={this.state.email}
                    disabled={this.state.isEdit}
                  />
                </div>
              </div>
              <div className="p-col-12 p-md-4 p-lg-4">
                <div className="form-group">
                  <label htmlFor="city">Contact Mobile No</label>
                  <input
                    type="text"
                    className="form-control"
                    id="mobileNO"
                    onChange={this.changeHandler}
                    placeholder="Enter Mobile Number"
                    value={this.state.mobileNO}
                    disabled={this.state.isEdit}
                  />
                </div>
              </div>
              <div className="p-col-12 p-md-12 p-lg-12 mt-2">
                <div
                  className={
                    this.state.isEdit === false ? "form-group" : "d-none"
                  }
                  style={{ textAlign: "right" }}
                >
                  <button
                    type="button"
                    style={{ marginRight: "5px" }}
                    onClick={() => {
                      this.setState({ isEdit: true });
                    }}
                    className="btn btn-danger"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      this.webcallPutRequest();
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </Fieldset>
          <Dialog
            visible={this.state.isLoading}
            modal={true}
            closable={false}
            showHeader={false}
            style={{ width: "20vw" }}
            onHide={this.onDialogueHide}
          >
            <Loader />
          </Dialog>
        </div>
      </div>
    );
  }
}
