import axios from "axios";
// import * as AxiosLogger from 'axios-logger';

// creating a axios instance with baseURL, timeout and specific custom headers
const axios_instance = axios.create({
  baseURL: "https://jsonplaceholder.typicode.com/",
  timeout: 5000,
  headers: { "X-Custom-Header": "foobar" },
});

//set global token for the instance created all request
axios_instance.defaults.headers.common["Authorization"] = "AUTH_TOKEN";
axios_instance.defaults.headers.post["Content-Type"] =
  "application/json; charset=utf-8";

//set interceptor for the instance

axios_instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    console.log(
      `${config.method.toUpperCase()} sent request to ${
        config.url
      } at time ${new Date()}`
    );
    return config;
  },
  function (error) {
    // Do something with request error
    console.log(error);
    return Promise.reject(error);
  }
);

//axios_instance.interceptors.request.use(AxiosLogger.requestLogger,AxiosLogger.errorLogger);
//axios_instance.interceptors.response.use(AxiosLogger.responseLogger,AxiosLogger.errorLogger);

axios_instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export class ServicesofAxios {
  //get request
  testingTodoGet() {
    return axios_instance
      .get("todos", {
        params: {
          _limit: 5,
        },
      })
      .then((res) => res.data)
      .catch(function (error) {
        console.log(error);
      });
  }

  //post request
  testingTodoPost(paramdata) {
    return axios_instance
      .post("posts", paramdata)
      .then((res) => res.data)
      .catch((err) => console.error({ err }));
  }

  // put request

  // patch request
}
