import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Panel } from "primereact/panel";
import api from "../service/ApiService";
import { getUserProfileUrl } from "../service/ApiUrls";
import { OSTypeEnum, EnrollmentTypeEnum } from "../Enums";
import { Dialog } from "primereact/dialog";
import Loader from "../components/Loader";
import ErrorDialog from "../components/ErrorDialog";
import SuccessDialog from "../components/SuccessDialog";
import ImgUpload from "../components/ImgUpload";
const userImg = require("../assets/logodummy.png");

class Organization extends Component {
  constructor() {
    super();
    this.state = {
      selectedProfile: null,
      selectedGroup: null,
      file: "",
      imagePreviewUrl: userImg,
    };

    this.profiles = [
      { name: "Profile 1", code: "Profile1" },
      { name: "Profile 2", code: "Profile2" },
      { name: "Profile 3", code: "Profile3" },
      { name: "Profile 5", code: "Profile4" },
      { name: "Profile 6", code: "Profile5" },
    ];
    this.groups = [
      { name: "Group 1", code: "Group1" },
      { name: "Group 2", code: "Group2" },
      { name: "Group 3", code: "Group3" },
      { name: "Group 5", code: "Group4" },
      { name: "Group 6", code: "Group5" },
    ];
  }
  photoUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };
  webcallForProfile = async () => {
    this.setState({ isLoading: true }, async () => {
      try {
        let {
          data: {
            data,
            result: { isError, errorMsg, statusCode },
          },
        } = await api.get(getUserProfileUrl);
        if (isError === false) this.setState({ ...data, isLoading: false });
        else {
          this.setState({ isLoading: false });
        }
        //console.log("res ", res.data);
      } catch (err) {
        this.setState({ isLoading: false });
        // localStorage.setItem("loggedIn", false);
        // window.location = process.env.PUBLIC_URL + "/login";
      }
    });
  };

  webcallForSaveorUpdate = () => {
    this.setState({ isLoading: true }, () => {
      // let data = {
      //   fullName: this.state.fullName,
      //   email: this.state.email,
      //   phoneNo: this.state.phoneNo,
      //   organization: this.state.organizationName,
      //   username : this.state
      // };
      //console.log("data", data);
      api
        .put(getUserProfileUrl, this.state)
        .then((res) => {
          if (res.data.result.isError === false) {
            this.setState({ isLoading: false, isSuccess: true });
          } else if (res.data.result.isError) {
            this.setState({ textInLoader: res.data.result.errorMsg });
          }
        })
        .catch((err) => {
          console.log(err);
          //this.setState({ isLoading: false });
        });
    });
  };

  componentDidMount() {
    this.webcallForProfile();
  }

  onDialogueHide = () => {
    this.setState({ isLoading: false });
  };

  changeHandler = (e) => {
    console.log("e", e.target);
    this.setState({ [e.target.id]: e.target.value });
  };
  onDialogueHideSuccess = () => {
    this.setState({ isSuccess: false });
  };
  render() {
    const { imagePreviewUrl } = this.state;
    console.log("props in profile", this.props.location);
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Home Ground", url: "home-ground" },
      { label: "Organization" },
    ];
    const home = { icon: "pi pi-home", url: "dashboard" };
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 ">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
          <Dialog
            visible={this.state.isSuccess}
            modal={true}
            style={{ width: "25vw" }}
            closable
            showHeader
            onHide={this.onDialogueHideSuccess}
          >
            <SuccessDialog successMessage="Profile Updated Successfully" />
          </Dialog>
        </div>
        <div className="p-col-12 p-lg-12 ">
          <Dialog
            visible={this.state.isLoading}
            modal={true}
            style={{ width: "15vw" }}
            closable
            showHeader
            onHide={this.onDialogueHide}
          >
            <Loader textToShow={this.state.textInLoader} />
          </Dialog>
          <div id="custom-div" className="row align-items-start bg-col">
            <div className="p-col-12 p-lg-3 p-md-3">
              <div className="p-col-12">
                <div className="text-center mt-3">
                  <ImgUpload
                    onChange={this.photoUpload}
                    src={imagePreviewUrl}
                  />
                </div>
              </div>
            </div>
            <div className="p-col-12 p-lg-9 p-md-9">
              <div className="p-col-12">
                <p className="cus-t">
                  <span>Basic Information</span>
                </p>
                <div
                  className="form-group p-col-12 p-lg-4 p-md-4"
                  style={{ display: "inline-block" }}
                >
                  <label htmlFor="Organization">Organization Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    placeholder="Enter Organization Name"
                    onChange={this.changeHandler}
                    value={this.state.orgname}
                  />
                </div>
                <div
                  className="form-group p-col-12 p-lg-4 p-md-4"
                  style={{ display: "inline-block" }}
                >
                  <label htmlFor="Website">Website url</label>
                  <input
                    type="text"
                    className="form-control"
                    id="fullName"
                    onChange={this.changeHandler}
                    placeholder="Enter Website url"
                    value={this.state.weburl}
                  />
                </div>
                <div
                  className="form-group p-col-12 p-lg-4 p-md-4"
                  style={{ display: "inline-block" }}
                >
                  <label htmlFor="Location">Location</label>
                  <input
                    type="text"
                    className="form-control"
                    id="Location"
                    onChange={this.changeHandler}
                    placeholder="Enter Location"
                    value={this.state.location}
                  />
                </div>
                <p className="cus-t">
                  <span>Contact Information</span>
                </p>
                <div
                  className="form-group p-col-12 p-lg-4 p-md-4"
                  style={{ display: "inline-block" }}
                >
                  <label htmlFor="person">Cantact person</label>
                  <input
                    type="text"
                    className="form-control"
                    id="person"
                    onChange={this.changeHandler}
                    placeholder="Enter Cantact Person Name"
                    value={this.state.person}
                  />
                </div>
                <div
                  className="form-group p-col-12 p-lg-4 p-md-4"
                  style={{ display: "inline-block" }}
                >
                  <label htmlFor="email">Contact Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    onChange={this.changeHandler}
                    placeholder="Enter Email Address"
                    value={this.state.cemail}
                  />
                </div>
                <div
                  className="form-group p-col-12 p-lg-4 p-md-4"
                  style={{ display: "inline-block" }}
                >
                  <label htmlFor="Contact">Contact Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="nid"
                    onChange={this.changeHandler}
                    placeholder="Enter Contact Number"
                    value={this.state.contact}
                  />
                </div>
              </div>
            </div>
            <div className="p-col-12 mt-4 mb-4" style={{ textAlign: "center" }}>
              <button className="btn btn-primary mr-3">Submit</button>
              <button className="btn btn-danger">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Organization;
