import React, { Component } from "react";

export class Spinner extends Component {
  render() {
    return (
      <div>
        <div className="splash-screen">
          <div className="splash-container">
            <div className="splash-double-bounce1"></div>
            <div className="splash-double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Spinner;
