import React, { Component } from "react";
import { Modal, Alert, Spinner } from "react-bootstrap";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import api from "../service/ApiService";
import Loader from "../components/Loader";
import { Dialog } from "primereact/dialog";
import { confirmAlert } from "react-confirm-alert";
import { getDeviceSearchData, baseUrl } from "../service/ApiUrls";

class AssignInDiv extends Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.showSuccess = this.showSuccess.bind(this);
    //this.webservice = new ServicesofAxios();

    this.dropOption = [
      {
        name: "Group " + (props.fromModule === "profile" ? "" : " & Profile"),
        code: "GP",
      },
      { name: "Device", code: "DV" },
    ];

    this.state = {
      opt: this.dropOption[0],
      first: 0,
      pfirst: 0,
      gfirst: 0,
      dfirst: 0,
      grows: 10,
      prows: 10,
      drows: 10,
      groupcities: [],
      profilecities: [],
      checkedDevices: [],
      isLoading: false,
      pcurrentPage: 1,
      gcurrentPage: 1,
      dcurrentPage: 1,
    };
    this.dropOptionChange = this.dropOptionChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
  }
  onDialogueHide = () => {
    this.setState({ isLoading: false });
  };

  callgetProfileListGroup = ({ profileId, first = 0, limit = 10 } = {}) => {
    api
      .get(
        baseUrl + "/" + "global" + "/" + this.props.dataToSend.id + "/assign",
        {
          params: {
            profileId: profileId,
            criteria: 1,
            first: first,
            limit: limit,
          },
        }
      )
      .then((res) => {
        this.setState(
          {
            groups: res.data.data.groups.content,
            // grows: res.data.data.groups.rows,
            gtotalRecords: res.data.data.groups.totalRecords,
            // gFirst: res.data.data.groups.first,
          },
          () => {
            let x = [];
            this.state.groups.map((e, i) => {
              if (e.isSelected) x.push(e);
            });
            this.setState({ groupcities: x });
          }
        );
      })
      .catch((err) => console.log(err));
  };

  callgetProfileListDevice = ({
    profileId,
    imei = null,
    groupId = null,
    first = 0,
    limit = 10,
  } = {}) => {
    api
      .get(
        baseUrl + "/" + "global" + "/" + this.props.dataToSend.id + "/assign",
        {
          params: {
            profileId: profileId,
            imei: imei,
            groupId: groupId,
            criteria: 2,
            first: first,
            limit: limit,
          },
        }
      )
      .then((res) => {
        this.setState({
          devices: res.data.data.devices.content,
          // drows: res.data.data.devices.rows,
          dtotalRecords: res.data.data.devices.totalRecords,
          // dFirst: res.data.data.devices.first,
        });
      })
      .catch((err) => console.log(err));
  };

  callgetThemeListProfile = ({ first = 0, limit = 10 } = {}) => {
    api
      .get(
        baseUrl + "/" + "global" + "/" + this.props.dataToSend.id + "/assign",
        {
          params: { criteria: 0, first: first, limit: limit },
        }
      )
      .then((res) => {
        this.setState(
          {
            profiles: res.data.data.profiles.content,
            // prows: res.data.data.profiles.rows,
            ptotalRecords: res.data.data.profiles.totalRecords,
            // pfirst: res.data.data.profiles.first,
          },
          () => {
            let x = [];
            this.state.profiles.map((e, i) => {
              if (e.isSelected) x.push(e);
            });
            this.setState({ profilecities: x });
          }
        );
      })
      .catch((err) => console.log(err));
  };

  callDeviceSearchData = () => {
    api
      .get(getDeviceSearchData)
      .then((res) => {
        if (res.data.result.isError === false) {
          //console.log("Inside false");
          this.setState(
            {
              profileList: res.data.data.profileList,
              groupList: res.data.data.groupList,
              isSubmitted: true,
            },
            () => {
              this.callgetProfileListGroup();
              this.callgetProfileListDevice();
              this.callgetThemeListProfile();
              // this.setState({ selectedProfile: this.state.profileList[0] });
            }
          );
        } else if (res.data.result.isError) {
          confirmAlert({
            title: "Error Message",
            message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  // this.webcallGetUSerList();
                },
              },
            ],
          });
        }
      })
      .catch((err) => {
        localStorage.setItem("loggedIn", false);
        // window.location = process.env.PUBLIC_URL + "/login";
      });
  };
  cusPopup = (val) => () => {
    //alert("Hello");
    switch (val) {
      case 0:
        this.callgetThemeListProfile();
        // if (this.props.fromModule === "appManagement") {
        //   this.callgetThemeListProfile("app-management");
        // } else if (this.props.fromModule === "contentPush") {
        //   this.callgetThemeListProfile("content-push");
        // } else if (this.props.fromModule === "geo") {
        //   this.callgetThemeListProfile("locations/geofences");
        // }
        break;
      case 1:
        this.callgetProfileListGroup();
        // if (this.props.fromModule === "appManagement") {
        //   this.callgetProfileListGroup("app-management");
        // } else if (this.props.fromModule === "contentPush") {
        //   this.callgetProfileListGroup("content-push");
        // } else if (this.props.fromModule === "geo") {
        //   this.callgetProfileListGroup("locations/geofences");
        // }
        break;
      case 2:
        break;
      default:
        break;
    }
  };
  componentDidMount() {
    this.callDeviceSearchData();
  }

  onBody = (property) => (rowData, column) => {
    return rowData[column.field][property];
  };

  dropOptionChange(e) {
    this.setState({ opt: e.value }, () => {
      if (this.state.opt.code === "DV") {
        this.callgetProfileListDevice();
      } else {
        if (this.state.opt.name === "Profile") {
          this.callgetThemeListProfile();
        } else {
          this.callgetProfileListGroup();
        }
      }
    });
  }
  onPageChange(event) {
    this.setState({
      first: event.first,
      rows: event.rows,
    });
  }
  showSuccess() {
    this.messages.show({
      severity: "success",
      summary: "Geofence",
      detail: "Added Successfully",
      life: 5000,
      closable: false,
    });
  }
  handleClose() {
    this.setState({ show: false });
  }
  onPageChange2 = (event) => {
    console.log(event);
    this.setState(
      {
        pfirst: event.first,
        page: event.page,
        prows: event.rows,
        pcurrentPage: event.page + 1,
      },
      () => {
        this.callgetThemeListProfile({
          first: event.page,
          limit: this.state.prows,
        });
      }
    );
  };
  onPageChange3 = (event) => {
    console.log(event);
    this.setState(
      {
        gfirst: event.first,
        page: event.page,
        grows: event.rows,
        gcurrentPage: event.page + 1,
      },
      () => {
        this.callgetProfileListGroup({
          first: event.page,
          limit: this.state.grows,
        });
      }
    );
  };
  onPageChange4 = (event) => {
    console.log(event);
    this.setState(
      {
        dfirst: event.first,
        page: event.page,
        drows: event.rows,
        gcurrentPage: event.page + 1,
      },
      () => {
        this.callgetProfileListDevice({
          first: event.page,
          limit: this.state.drows,
        });
      }
    );
  };

  handleShow() {
    this.setState({ show: true });
    if (this.props.fromModule === "profile") {
      //this.callgetProfileListGroup();
      //this.callgetProfileListDevice();
      this.callDeviceSearchData();
      //this.callgetThemeListProfile();
    }
  }

  submitHandler = (e) => {
    e.preventDefault();
    let data,
      callapi = false;
    switch (this.props.type) {
      case 10:
        if (
          (this.props.dataToSend.msgBody !== undefined &&
            this.props.dataToSend.msgBody === "") ||
          (this.props.dataToSend.messageTitle !== undefined &&
            this.props.dataToSend.messageTitle === "")
        ) {
          this.props.callParent(true);
          callapi = false;
        } else if (
          this.state.profilecities.length === 0 &&
          this.state.groupcities.length === 0 &&
          this.state.checkedDevices.length === 0
        ) {
          callapi = false;
          this.setState({ isLoading: false, isSubmitted: false }, () => {
            confirmAlert({
              title: "Error Message",
              message: (
                <p className="mod-p">
                  Please Select Profile or Group or Device
                </p>
              ),
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {},
                },
              ],
            });
          });
        } else {
          data = {
            type: this.props.type,
            content: {
              title: this.props.dataToSend.messageTitle,
              message: this.props.dataToSend.msgBody,
            },
          };
          callapi = true;
        }
        break;
      case 11:
        if (
          this.state.profilecities.length === 0 &&
          this.state.groupcities.length === 0 &&
          this.state.checkedDevices.length === 0
        ) {
          callapi = false;
          this.setState({ isLoading: false, isSubmitted: false }, () => {
            confirmAlert({
              title: "Error Message",
              message: (
                <p className="mod-p">
                  Please Select Profile or Group or Device
                </p>
              ),
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {},
                },
              ],
            });
          });
        } else {
          callapi = true;
          data = { type: this.props.type };
        }
        break;
      case 13:
        if (
          this.state.profilecities.length === 0 &&
          this.state.groupcities.length === 0 &&
          this.state.checkedDevices.length === 0
        ) {
          callapi = false;
          this.setState({ isLoading: false, isSubmitted: false }, () => {
            confirmAlert({
              title: "Error Message",
              message: (
                <p className="mod-p">
                  Please Select Profile or Group or Device
                </p>
              ),
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {},
                },
              ],
            });
          });
        } else {
          callapi = true;
          data = { type: this.props.type };
        }
        break;
      case 14:
        if (
          this.state.profilecities.length === 0 &&
          this.state.groupcities.length === 0 &&
          this.state.checkedDevices.length === 0
        ) {
          callapi = false;
          this.setState({ isLoading: false, isSubmitted: false }, () => {
            confirmAlert({
              title: "Error Message",
              message: (
                <p className="mod-p">
                  Please Select Profile or Group or Device
                </p>
              ),
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {},
                },
              ],
            });
          });
        } else {
          data = { type: this.props.type };
          callapi = true;
        }
        break;
      case 12:
        if (
          this.state.profilecities.length === 0 &&
          this.state.groupcities.length === 0 &&
          this.state.checkedDevices.length === 0
        ) {
          callapi = false;
          this.setState({ isLoading: false, isSubmitted: false }, () => {
            confirmAlert({
              title: "Error Message",
              message: (
                <p className="mod-p">
                  Please Select Profile or Group or Device
                </p>
              ),
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {},
                },
              ],
            });
          });
        } else {
          callapi = true;
          data = {
            type: this.props.type,
            content: {
              isLock: this.props.dataToSend.isLock,
              lockPassword: this.props.dataToSend.lockPassword,
            },
          };
        }
        break;
      case 15:
        if (
          this.props.dataToSend.clearOwnFile == false &&
          this.props.dataToSend.clearFileSD === false
        ) {
          this.props.callParent(true);
          callapi = false;
        } else if (
          this.state.profilecities.length === 0 &&
          this.state.groupcities.length === 0 &&
          this.state.checkedDevices.length === 0
        ) {
          callapi = false;
          this.setState({ isLoading: false, isSubmitted: false }, () => {
            confirmAlert({
              title: "Error Message",
              message: (
                <p className="mod-p">
                  Please Select Profile or Group or Device
                </p>
              ),
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {},
                },
              ],
            });
          });
        } else {
          callapi = true;
          data = {
            type: this.props.type,
            content: {
              clearOwnFiles: this.props.dataToSend.clearOwnFile,
              clearSDFiles: this.props.dataToSend.clearFileSD,
            },
          };
        }

        break;
        defalut: break;
    }
    data = {
      data: { ...data },
      devices: this.state.checkedDevices,
      groups: this.state.checkedGroups,
      profiles: this.state.checkedProfiles,
    };
    if (callapi) {
      this.setState({ isLoading: true }, () => {
        api
          .post(`${baseUrl}/global/${this.props.type}/assign`, data)
          .then((res) => {
            if (res.data.result.isError === false) {
              this.setState({ isLoading: false }, () => {
                confirmAlert({
                  title: "Success Message",
                  message: (
                    <p className="mod-sp">
                      {this.props.type === 10
                        ? "Message sent successfully"
                        : this.props.type === 11
                        ? "Factory reset: command send successfully"
                        : this.props.type === 12
                        ? "Device Lock Successfully"
                        : this.props.type === 13
                        ? "Deactive Launcher Successfully"
                        : this.props.type === 14
                        ? "Clear Launcher Data Successfully"
                        : this.props.type === 15
                        ? "Clear Files from Device Successfully"
                        : "Updated Successfully"}
                    </p>
                  ),
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => {
                        window.location =
                          process.env.PUBLIC_URL + "/global-settings";
                      },
                    },
                  ],
                });
              });
              // this.props.history.push("/global-setting");
            } else if (res.data.result.isError) {
              this.setState(
                {
                  isLoading: false,
                },
                () => {
                  confirmAlert({
                    title: "Error Message",
                    message: (
                      <p className="mod-p"> {res.data.result.errorMsg} </p>
                    ),
                    buttons: [
                      {
                        label: "Ok",
                        onClick: () => {},
                      },
                    ],
                  });
                }
              );
            }
          })
          .catch((err) => console.log(err));
      });
    }
    // if (
    //   (this.props.dataToSend.msgBody !== undefined &&
    //     this.props.dataToSend.msgBody === "") ||
    //   (this.props.dataToSend.messageTitle !== undefined &&
    //     this.props.dataToSend.messageTitle === "") ||
    //   this.state.profilecities.length === 0
    // ) {
    //   this.setState({
    //     isLoading: false,
    //     isSubmitted: false,
    //   });
    // } else if (
    //   this.props.dataToSend.clearOwnFile === undefined ||
    //   this.props.dataToSend.clearFileSD === undefined ||
    //   this.state.profilecities.length === 0
    // ) {
    //   this.setState({
    //     isLoading: false,
    //     isSubmitted: false,
    //   });
    // } else {
    //   this.setState({
    //     isLoading: true,
    //     isSubmitted: false,
    //   });
    // }
  };

  render() {
    const groupList = (
      <div className="datatable-paginator-demo">
        <DataTable
          value={this.state.groups !== undefined ? this.state.groups : null}
          style={{ marginBottom: "20px" }}
          responsive={true}
          //footer={this.displaySelection(this.state.selectedCars3)}
          selection={this.state.groupcities}
          onSelectionChange={(e) => {
            let checkedGroups = [];
            let ids = e.value.map((v, i) =>
              checkedGroups.push(v.assignedObject.id)
            );
            this.setState({
              groupcities: e.value,
              checkedGroups: checkedGroups,
            });
          }}
          paginator={true}
          emptyMessage="No records found"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          rows={this.state.grows}
          currentPage={this.state.gcurrentPage}
          totalRecords={this.state.gtotalRecords}
          rowsPerPageOptions={[1, 5, 10, 20, 30, 50, 100]}
          first={this.state.gfirst}
          onPage={this.onPageChange3}
          lazy
          // onPage={(event) => {
          //   console.log("device Event", event);
          //   this.callgetProfileListGroup({
          //     first: event.page,
          //     limit: this.state.grows,
          //   });
          //   setTimeout(() => {
          //     const { first, rows, page } = event;

          //     this.setState({
          //       gFirst: first,
          //       // page,
          //       grows: rows,
          //       currentPage: page + 1,
          //     });
          //   }, 500);
          // }}
        >
          <Column selectionMode="multiple" style={{ width: "3em" }} />
          <Column
            field={"assignedObject"}
            body={this.onBody("name")}
            header="Group Name"
            style={{}}
          />
          <Column
            field={"assignedObject"}
            body={this.onBody("profileName")}
            header="Profile Name"
            style={{}}
          />
        </DataTable>
      </div>
    );

    const deviceList = (
      <div className="datatable-paginator-demo">
        <DataTable
          value={this.state.devices !== undefined ? this.state.devices : null}
          style={{ marginBottom: "20px" }}
          responsive={true}
          //footer={this.displaySelection(this.state.selectedCars3)}
          selection={this.state.selectedCars3}
          onSelectionChange={(e) => {
            let selectedDevices = [];
            let ids = e.value.map((v, i) =>
              selectedDevices.push(v.assignedObject.id)
            );
            this.setState({
              selectedCars3: e.value,
              checkedDevices: selectedDevices,
            });
          }}
          paginator={true}
          lazy
          // onPage={(event) => {
          //   console.log("device Event", event);
          //   this.callgetProfileListDevice({
          //     first: event.page,
          //     limit: this.state.drows,
          //   });
          //   setTimeout(() => {
          //     const { first, rows, page } = event;

          //     this.setState({
          //       dFirst: first,
          //       // page,
          //       drows: rows,
          //       currentPage: page + 1,
          //     });
          //   }, 500);
          // }}
          emptyMessage="No records found"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          first={this.state.dfirst}
          totalRecords={this.state.dtotalRecords}
          currentPage={this.state.dcurrentPage}
          rows={this.state.drows}
          rowsPerPageOptions={[1, 5, 10, 20, 30, 50, 100]}
          onPage={this.onPageChange4}
        >
          <Column selectionMode="multiple" style={{ width: "3em" }} />
          <Column
            field={"assignedObject"}
            body={this.onBody("imei1")}
            header="IMEI"
            style={{ width: "30%" }}
          />
          <Column
            field={"assignedObject"}
            body={this.onBody("groupName")}
            header="Group"
            style={{ width: "30%" }}
          />
          <Column
            field={"assignedObject"}
            body={this.onBody("profileName")}
            header="Profile"
            style={{ width: "30%" }}
          />
        </DataTable>
      </div>
    );

    const profilesList = (
      <div className="datatable-paginator-demo">
        <DataTable
          value={this.state.profiles !== undefined ? this.state.profiles : null}
          style={{ marginBottom: "20px" }}
          responsive={true}
          lazy
          //footer={this.displaySelection(this.state.selectedCars3)}
          selection={this.state.profilecities}
          onSelectionChange={(e) => {
            let checkedProfiles = [];
            let ids = e.value.map((v, i) =>
              checkedProfiles.push(v.assignedObject.id)
            );
            this.setState({
              profilecities: e.value,
              checkedProfiles: checkedProfiles,
            });
          }}
          paginator={true}
          emptyMessage="No records found"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          onPage={this.onPageChange2}
          rows={this.state.prows}
          // onPage={(event) => {
          //   console.log("profile Event", event);
          //   this.callgetThemeListProfile({
          //     first: event.page,
          //     limit: this.state.prows,
          //   });
          //   setTimeout(() => {
          //     const { first, rows, page } = event;

          //     this.setState({
          //       pFirst: first,
          //       // page,
          //       prows: rows,
          //       currentPage: page + 1,
          //     });
          //   }, 500);
          // }}
          totalRecords={this.state.ptotalRecords}
          first={this.state.pfirst}
          currentPage={this.state.pcurrentPage}
          rowsPerPageOptions={[1, 5, 10, 20, 30, 50, 100]}
        >
          <Column selectionMode="multiple" style={{ width: "3em" }} />
          <Column
            field={"assignedObject"}
            body={this.onBody("name")}
            header="Profile Name"
            style={{}}
          />
          <Column
            field={"assignedObject"}
            body={this.onBody("groupName")}
            header="OS Type"
            style={{}}
          />
        </DataTable>
      </div>
    );

    const profileListDropDown = this.state.profileList;
    let profileOptions =
      profileListDropDown !== undefined &&
      profileListDropDown.length > 0 &&
      profileListDropDown.map((e, i) => {
        //console.log("profile", e);
        return (
          <option key={i} value={e.id}>
            {e.name}
          </option>
        );
      });
    const groupListDropDown = this.state.groupList;
    let groupOptions =
      groupListDropDown !== undefined &&
      groupListDropDown.length > 0 &&
      groupListDropDown.map((e, i) => {
        //console.log("profile", e);
        return (
          <option key={i} value={e.id}>
            {e.name}
          </option>
        );
      });

    let varients = [
      "primary",
      "secondary",
      "success",
      "danger",
      "warning",
      "light",
    ];
    let spinnerList = varients.map((e, i) => {
      return <Spinner animation="grow" variant={e} />;
    });
    const groupProfile = (
      <div className="p-col-12 cus-nav">
        <ul
          className="nav nav-pills center justify-content-md-center mb-3 mt-3"
          id="pills-tab"
          role="tablist"
          style={{
            borderBottom: "1px solid gray",
            paddingBottom: "10px",
          }}
        >
          <li className="nav-item">
            <a
              className="nav-link"
              id="enabled-app"
              data-toggle="pill"
              href="#pills-enabled"
              role="tab"
              aria-controls="pills-enabled"
              aria-selected="true"
              onClick={this.cusPopup(1)}
            >
              Group
            </a>
          </li>
          {this.props.fromModule === "profile" ? (
            ""
          ) : (
            <li className="nav-item">
              <a
                className="nav-link active"
                id="all-apps"
                data-toggle="pill"
                href="#pills-all-apps"
                role="tab"
                aria-controls="pills-all-apps"
                aria-selected="false"
                onClick={this.cusPopup(0)}
              >
                Profile
              </a>
            </li>
          )}
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade"
            id="pills-enabled"
            role="tabpanel"
            aria-labelledby="enabled-app"
          >
            <div className="row align-items-start">
              <div className="col">{groupList}</div>
            </div>
          </div>
          <div
            className="tab-pane fade show active"
            id="pills-all-apps"
            role="tabpanel"
            aria-labelledby="all-apps"
          >
            <div className="row align-items-start">
              <div className="col">{profilesList}</div>
            </div>
          </div>
        </div>
      </div>
    );
    const device = (
      <div className="p-col-12 cus-nav">
        <ul
          className="nav nav-pills center justify-content-md-center mb-3 mt-3"
          id="pills-tab"
          role="tablist"
          style={{
            borderBottom: "1px solid gray",
            paddingBottom: "10px",
          }}
        >
          <li className="nav-item">
            <a
              className="nav-link active"
              id="enabled-device"
              data-toggle="pill"
              href="#pills-device"
              role="tab"
              aria-controls="enabled-device"
              aria-selected="true"
            >
              Device
            </a>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane show active"
            id="pills-device"
            role="tabpanel"
            aria-labelledby="enabled-device"
          >
            <div className="row align-items-start">
              <div className="col-12">{deviceList}</div>
            </div>
          </div>
        </div>
      </div>
    );

    const profileSelect = (
      <>
        {" "}
        <select
          className="form-control"
          onChange={(e) => {
            //console.log("e in profile ", e.target);
            let profileObject = this.state.profileList.find((obj) => {
              //  console.log("object", obj.id);
              return obj.id === e.target.value;
            });
            // console.log("profile object", profileObject);
            this.setState(
              {
                ...this.state,
                selectedProfile:
                  profileObject !== undefined ? profileObject : null,
              },
              () => {
                if (this.state.selectedProfile !== undefined) {
                  this.callgetProfileListDevice({
                    profileId:
                      this.state.selectedProfile !== null
                        ? this.state.selectedProfile.id
                        : null,
                  });
                }
              }
            );
          }}
          id="selectedProfile"
          name="selectedProfile"
          value={
            this.state.selectedProfile !== null &&
            this.state.selectedProfile !== undefined
              ? this.state.selectedProfile.id
              : null
          }
        >
          <option>Select Profile</option>
          {profileOptions}
        </select>
      </>
    );

    const groupSelect = (
      <>
        <select
          className="form-control"
          onChange={(e) => {
            let groupobject = this.state.groupList.find((obj) => {
              return obj.id === e.target.value;
            });
            this.setState(
              {
                ...this.state,
                selectedGroup: groupobject !== undefined ? groupobject : null,
              },
              () => {
                if (this.state.selectedGroup !== undefined) {
                  this.callgetProfileListDevice({
                    groupId:
                      this.state.selectedGroup !== null
                        ? this.state.selectedGroup.id
                        : null,
                  });
                }
              }
            );
          }}
          id="selectedGroup"
          name="selectedGroup"
          value={
            this.state.selectedGroup !== undefined &&
            this.state.selectedGroup !== null
              ? this.state.selectedGroup.id
              : null
          }
        >
          <option>Select Group</option>
          {groupOptions}
        </select>
      </>
    );

    let component = null;
    switch (this.state.opt !== null && this.state.opt.code) {
      case "GP":
        component = groupProfile;
        break;
      case "DV":
        component = device;
        break;
      default:
        component = groupProfile;
        break;
    }
    const deviceSearch = (
      <>
        <div
          className="col-3 p-m"
          style={{ marginBottom: "0px", display: "inline-block" }}
        >
          <div className="form-group">{profileSelect}</div>
        </div>
        <div
          className="col-3 p-m"
          style={{ marginBottom: "0px", display: "inline-block" }}
        >
          <div className="form-group">{groupSelect}</div>
        </div>
        <div
          className="col-3 p-m"
          style={{ marginBottom: "0px", display: "inline-block" }}
        >
          <div className="form-group">
            <form
              // onSubmit={this.submitHandler}
              className="form-inline d-flex justify-content-center md-form form-sm mt-0 b-input"
              style={{ padding: "2px" }}
            >
              <input
                className="form-control form-control-sm mr-2 b-n"
                type="text"
                placeholder="Enter IMEI"
                aria-label="Search"
                style={{ width: "80%" }}
                onChange={(e) => {
                  this.setState({
                    imei: e.target.value,
                  });
                }}
                onKeyDown={(e) => {
                  //console.log("inOnkeyDown", e);
                  if (e.key === "Enter") {
                    console.log(
                      "object",
                      this.state.imei,
                      this.state.selectedProfile,
                      this.state.selectedGroup
                    );
                    if (this.state.selectedProfile !== undefined) {
                      if (this.props.fromModule === "profile") {
                        this.callgetProfileListDevice("profile-list", {
                          profileId: this.state.selectedProfile.id,
                          imei: this.state.imei,
                          groupId: this.state.selectedGroup,
                        });
                      } else if (this.props.fromModule === "theme") {
                        this.callgetProfileListDevice("theme-list", {
                          profileId: this.state.selectedProfile.id,
                          imei: this.state.imei,
                          groupId: this.state.selectedGroup,
                        });
                      }
                    }
                  }
                }}
                value={this.state.imei !== undefined ? this.state.imei : ""}
              />
              <i
                className="pi pi-search"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  if (this.state.selectedProfile !== undefined) {
                    if (this.props.fromModule === "profile") {
                      this.callgetProfileListDevice("profile-list", {
                        profileId: this.state.selectedProfile.id,
                        imei: this.state.imei,
                        groupId: this.state.selectedGroup,
                      });
                    } else if (this.props.fromModule === "theme") {
                      this.callgetProfileListDevice("theme-list", {
                        profileId: this.state.selectedProfile.id,
                        imei: this.state.imei,
                        groupId: this.state.selectedGroup,
                      });
                    }
                  }
                }}
              ></i>
            </form>
          </div>
        </div>
      </>
    );
    const defaultSearch = (
      <div
        className="col-3 p-m"
        style={{ marginBottom: "0px", display: "inline-block" }}
      >
        <div className="form-group">
          <form
            className="form-inline d-flex justify-content-center md-form form-sm mt-0 b-input"
            style={{ padding: "2px" }}
          >
            <input
              className="form-control form-control-sm mr-2 b-n"
              type="text"
              placeholder="Search"
              aria-label="Search"
              style={{ width: "80%" }}
              onChange={(e) => {}}
            />
            <i className="pi pi-search" aria-hidden="true"></i>
          </form>
        </div>
      </div>
    );
    let searchCom = null;
    switch (this.state.opt !== null && this.state.opt.code) {
      case "GP":
        searchCom = "";
        break;
      case "DV":
        searchCom = deviceSearch;
        break;
      default:
        searchCom = "";
        break;
    }
    const footer = (
      <div>
        <Button
          label="Ok"
          icon="pi pi-check"
          className="p-button-success"
          onClick={() => {
            this.setState({ isLoading: false }, () => {
              window.location = process.env.PUBLIC_URL + "/global-settings";
            });
          }}
        />
      </div>
    );

    return (
      <form onSubmit={this.submitHandler}>
        <div className="row align-items-start col-12 p-z">
          <div className="col-12">
            <Dialog
              visible={this.state.isLoading}
              modal={true}
              style={{ width: "20vw" }}
              closable={false}
              showHeader={false}
              onHide={this.onDialogueHide}
            >
              <Loader />
            </Dialog>
            <div className="col-3 p-m" style={{ display: "inline-block" }}>
              <div className="form-group">
                <Dropdown
                  value={this.state.opt}
                  options={this.dropOption}
                  onChange={this.dropOptionChange}
                  style={{
                    width: "100%",
                    padding: "1px",
                    border: "1px solid #ced4da",
                    borderRadius: "0.25em",
                  }}
                  optionLabel="name"
                  placeholder="Select Option"
                  className="custom-d"
                />
              </div>
            </div>
            {searchCom}
          </div>
          <div className="col-12">{component}</div>
          <div className="col-12" style={{ textAlign: "center" }}>
            <button
              type="submit"
              className="btn btn-primary mt-3"
              // onClick={(e) => {
              //   let data,
              //     callapi = true;
              //   switch (this.props.type) {
              //     case 10:
              //       if (
              //         (this.props.dataToSend.msgBody !== undefined &&
              //           this.props.dataToSend.msgBody === "") ||
              //         (this.props.dataToSend.messageTitle !== undefined &&
              //           this.props.dataToSend.messageTitle === "") ||
              //         this.state.profilecities.length === 0
              //       ) {
              //         this.props.callParent(true);
              //         callapi = false;
              //         this.setState({ isLoading: false }, () => {
              //           confirmAlert({
              //             title: "Error Message",
              //             message: (
              //               <p className="mod-p">
              //                 Please Select Profile or Group or Device
              //               </p>
              //             ),
              //             buttons: [
              //               {
              //                 label: "Ok",
              //                 onClick: () => {
              //                   // window.location =
              //                   //   process.env.PUBLIC_URL + "/global-settings";
              //                 },
              //               },
              //             ],
              //           });
              //         });
              //       } else {
              //         data = {
              //           type: this.props.type,
              //           content: {
              //             title: this.props.dataToSend.messageTitle,
              //             message: this.props.dataToSend.msgBody,
              //           },
              //         };
              //         callapi = true;
              //       }
              //       break;
              //     case 11:
              //     case 13:
              //     case 14:
              //       data = { type: this.props.type };
              //       callapi = true;
              //       break;
              //     case 12:
              //       callapi = true;
              //       data = {
              //         type: this.props.type,
              //         content: {
              //           isLock: this.props.dataToSend.isLock,
              //           lockPassword: this.props.dataToSend.lockPassword,
              //         },
              //       };
              //       break;
              //     case 15:
              //       if (
              //         this.props.dataToSend.clearOwnFile === undefined ||
              //         this.props.dataToSend.clearFileSD === undefined ||
              //         this.state.profilecities.length === 0
              //       ) {
              //         this.props.callParent(true);
              //         callapi = true;
              //         data = {
              //           type: this.props.type,
              //           content: {
              //             clearOwnFiles: this.props.dataToSend.clearOwnFile,
              //             clearSDFiles: this.props.dataToSend.clearFileSD,
              //           },
              //         };
              //       } else {
              //         callapi = false;
              //         this.setState(
              //           { isLoading: false, isSubmitted: false },
              //           () => {
              //             confirmAlert({
              //               title: "Error Message",
              //               message: (
              //                 <p className="mod-p">
              //                   Please Select Profile or Group or Device
              //                 </p>
              //               ),
              //               buttons: [
              //                 {
              //                   label: "Ok",
              //                   onClick: () => {
              //                     // window.location =
              //                     //   process.env.PUBLIC_URL + "/global-settings";
              //                   },
              //                 },
              //               ],
              //             });
              //           }
              //         );
              //       }

              //       // break;
              //       defalut: break;
              //   }

              //   data = {
              //     data: { ...data },
              //     devices: this.state.checkedDevices,
              //     groups: this.state.checkedGroups,
              //     profiles: this.state.checkedProfiles,
              //   };
              //   console.log("data", data);
              //   if (callapi) {
              //     this.setState({ isLoading: true }, () => {
              //       api
              //         .post(`${baseUrl}/global/${this.props.type}/assign`, data)
              //         .then((res) => {
              //           if (res.data.result.isError === false) {
              //             this.setState({ isLoading: false }, () => {
              //               confirmAlert({
              //                 title: "Success Message",
              //                 message: (
              //                   <p className="mod-sp">
              //                     {this.props.type === 10
              //                       ? "Message sent successfully"
              //                       : this.props.type === 11
              //                       ? "Factory reset: command send successfully"
              //                       : this.props.type === 12
              //                       ? "Device Lock Successfully"
              //                       : this.props.type === 13
              //                       ? "Deactive Launcher Successfully"
              //                       : this.props.type === 14
              //                       ? "Clear Launcher Data Successfully"
              //                       : this.props.type === 15
              //                       ? "Clear Files from Device Successfully"
              //                       : "Updated Successfully"}
              //                   </p>
              //                 ),
              //                 buttons: [
              //                   {
              //                     label: "Ok",
              //                     onClick: () => {
              //                       window.location =
              //                         process.env.PUBLIC_URL +
              //                         "/global-settings";
              //                     },
              //                   },
              //                 ],
              //               });
              //             });
              //             // this.props.history.push("/global-setting");
              //           } else if (res.data.result.isError) {
              //             this.setState(
              //               {
              //                 isLoading: false,
              //               },
              //               () => {
              //                 confirmAlert({
              //                   title: "Error Message",
              //                   message: (
              //                     <p className="mod-p">
              //                       {" "}
              //                       {res.data.result.errorMsg}{" "}
              //                     </p>
              //                   ),
              //                   buttons: [
              //                     {
              //                       label: "Ok",
              //                       onClick: () => {},
              //                     },
              //                   ],
              //                 });
              //               }
              //             );
              //           }
              //         })
              //         .catch((err) => console.log(err));
              //     });
              //   }
              // }}
            >
              {this.props.type === 10 ? "Send Message" : "Submit"}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default AssignInDiv;
