import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Link } from "react-router-dom";
import { AllData } from "../service/AllData";
import api from "../service/ApiService";
import { baseUrl } from "../service/ApiUrls";
import { confirmAlert } from "react-confirm-alert";
import Loader from "../components/Loader";
import { Dialog } from "primereact/dialog";

export class PricingPlans extends Component {
  constructor(props) {
    super(props);

    this.state = {
      basic: [],
      premium: [],
      ultimate: [],
      basicPlan: false,
      premiumPlan: false,
      ultimatePlan: true,
      isLoading: false,
    };
    this.alldata = new AllData();
  }
  onDialogueHide = () => {
    this.setState({ isLoading: false });
  };
  webcallgetlicenceData = () => {
    this.setState({ isLoading: true }, () => {
      api.get(baseUrl + "/license").then((res) => {
        if (res.data.result.isError === false) {
          this.setState({ ...res.data, isLoading: false }, () => {
            let basic = this.state.data.find((e) => {
              return e.type === "Basic";
            });
            let premium = this.state.data.find((e) => {
              return e.type === "Premium";
            });
            let ultimate = this.state.data.find((e) => {
              return e.type === "Ultimate";
            });
            this.setState({
              basicObject: basic,
              basic: basic.features,
              premiumObject: premium,
              premium: premium.features,
              ultimateObject: ultimate,
              ultimate: ultimate.features,
            });
          });
        } else if (res.data.result.isError === true) {
          this.setState({ isLoading: false }, () => {
            confirmAlert({
              title: "Error Message",
              message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    // this.webcallGetUSerList();
                  },
                },
              ],
            });
          });
        }
      });
    });
  };
  componentDidMount = () => {
    this.webcallgetlicenceData();
    // this.alldata
    //   .getBasicFeatures()
    //   .then((data) => this.setState({ basic: data, active: data.status }));
    // this.alldata
    //   .getPremiumFeatures()
    //   .then((data) => this.setState({ premium: data, active: data.status }));
    // this.alldata
    //   .getUltimateFeatures()
    //   .then((data) => this.setState({ ultimate: data, active: data.status }));
  };

  getTheObject = () => {
    if (this.state.basicPlan === true) {
      return this.state.basicObject;
    } else if (this.state.premiumPlan === true) {
      return this.state.premiumObject;
    } else {
      return this.state.ultimateObject;
    }
  };

  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Pricing & Plans" },
    ];
    const home = { icon: "pi pi-home", url: "dashboard" };
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 ">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div
          className="p-grid bg-col p-col-12 p-lg-12 "
          style={{ padding: "70px 30px 20px" }}
        >
          <div className="p-grid  p-col-12 p-lg-12 ">
            <div
              className={
                this.state.basicObject !== undefined &&
                this.state.basicObject !== null &&
                this.state.basicObject.isSelected === true
                  ? "pricing-plan featured-plan blue"
                  : "pricing-plan blue"
              }
            >
              <div className="pricingTable-header ">
                <h3 className="title">Basic</h3>
                <div className="price-value">
                  {" "}
                  {}
                  {this.state.basicObject !== undefined &&
                    this.state.basicObject.price}{" "}
                  {this.state.basicObject !== undefined &&
                    this.state.basicObject.priceUnit}
                  / Per device
                  <br /> per{" "}
                  {this.state.basicObject !== undefined &&
                    this.state.basicObject.pricePeriod}
                </div>
              </div>
              <ul className="plan-features">
                {this.state.basic.map((e, i) => {
                  if (e.availability === true) {
                    e["status"] = "pi-check";
                  } else {
                    e["status"] = "pi-times";
                  }
                  if (e.restrictionData !== null) {
                    e["extradata"] = e.restrictionData;
                  }
                  if (e.restrictionUnit !== null) {
                    e["extradata"] = e["extradata"] + e.restrictionUnit;
                  }
                  if (
                    e.restrictionData === null &&
                    e.restrictionUnit === null
                  ) {
                    e["extradata"] = undefined;
                  }
                  return (
                    <>
                      <li key={i}>
                        {e.status === "pi-check" ? (
                          <>
                            <i className="pi pi-check right-check p-mr-2"></i>{" "}
                            {e.featureTitle +
                              (e["extradata"] !== undefined
                                ? " ( " + e["extradata"] + " )"
                                : "")}
                          </>
                        ) : (
                          <>
                            {e.status === "pi-times" ? (
                              <>
                                <i className="pi pi-times cross-check p-mr-2"></i>{" "}
                                {e.featureTitle +
                                  (e["extradata"] !== undefined
                                    ? " ( " + e["extradata"] + " )"
                                    : "")}
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </li>
                      <tr key={"b-" + i}></tr>
                    </>
                  );
                })}
              </ul>
              <button
                style={{ border: "none" }}
                className="btn-plan"
                onClick={() => {
                  this.setState({
                    basicPlan: true,
                    premiumPlan: false,
                    ultimatePlan: false,
                    basicObject: {
                      ...this.state.basicObject,
                      isSelected: true,
                    },
                    premiumObject: {
                      ...this.state.premiumObject,
                      isSelected: false,
                    },
                    ultimateObject: {
                      ...this.state.ultimateObject,
                      isSelected: false,
                    },
                  });
                }}
              >
                {this.state.basicObject !== undefined &&
                this.state.basicObject !== null &&
                this.state.basicObject.isSelected === true
                  ? "Selected Plan"
                  : "Purchase Now"}
              </button>
            </div>
            <div
              className={
                this.state.premiumObject !== undefined &&
                this.state.premiumObject !== null &&
                this.state.premiumObject.isSelected === true
                  ? "pricing-plan featured-plan green"
                  : "pricing-plan green"
              }
            >
              <div className="pricingTable-header">
                <h3 className="title">Premium</h3>
                <div className="price-value">
                  {" "}
                  {this.state.premiumObject &&
                    this.state.premiumObject.price}{" "}
                  {this.state.premiumObject &&
                    this.state.premiumObject.priceUnit}
                  / Per device
                  <br /> per{" "}
                  {this.state.premiumObject &&
                    this.state.premiumObject.pricePeriod}
                </div>
              </div>
              <ul className="plan-features">
                {this.state.premium.map((e, i) => {
                  if (e.availability === true) {
                    e["status"] = "pi-check";
                  } else {
                    e["status"] = "pi-times";
                  }
                  if (e.restrictionData !== null) {
                    e["extradata"] = e.restrictionData;
                  }
                  if (e.restrictionUnit !== null) {
                    e["extradata"] = e["extradata"] + e.restrictionUnit;
                  }
                  if (
                    e.restrictionData === null &&
                    e.restrictionUnit === null
                  ) {
                    e["extradata"] = undefined;
                  }
                  return (
                    <>
                      <li key={i}>
                        {e.status === "pi-check" ? (
                          <>
                            <i className="pi pi-check right-check p-mr-2"></i>{" "}
                            {e.featureTitle +
                              (e["extradata"] !== undefined
                                ? " ( " + e["extradata"] + " )"
                                : "")}
                          </>
                        ) : (
                          <>
                            {e.status === "pi-times" ? (
                              <>
                                <i className="pi pi-times cross-check p-mr-2"></i>{" "}
                                {e.featureTitle +
                                  (e["extradata"] !== undefined
                                    ? " ( " + e["extradata"] + " )"
                                    : "")}
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </li>
                      <tr key={"p-" + i}></tr>
                    </>
                  );
                })}
              </ul>
              <button
                style={{ border: "none" }}
                className="btn-plan"
                onClick={() => {
                  this.setState({
                    basicPlan: false,
                    premiumPlan: true,
                    ultimatePlan: false,
                    basicObject: {
                      ...this.state.basicObject,
                      isSelected: false,
                    },
                    premiumObject: {
                      ...this.state.premiumObject,
                      isSelected: true,
                    },
                    ultimateObject: {
                      ...this.state.ultimateObject,
                      isSelected: false,
                    },
                  });
                }}
              >
                {this.state.premiumObject !== undefined &&
                this.state.premiumObject !== null &&
                this.state.premiumObject.isSelected === true
                  ? "Selected Plan"
                  : "Purchase Now"}
              </button>
            </div>
            <div
              className={
                this.state.ultimateObject !== undefined &&
                this.state.ultimateObject !== null &&
                this.state.ultimateObject.isSelected === true
                  ? "pricing-plan featured-plan red"
                  : "pricing-plan red"
              }
            >
              <div className="pricingTable-header">
                <h3 className="title">Ultimate</h3>
                <div className="price-value">
                  {" "}
                  {this.state.ultimateObject &&
                    this.state.ultimateObject.price}{" "}
                  {this.state.ultimateObject &&
                    this.state.ultimateObject.priceUnit}
                  / Per device
                  <br /> per{" "}
                  {this.state.ultimateObject &&
                    this.state.ultimateObject.pricePeriod}
                </div>
              </div>
              <ul className="plan-features">
                {this.state.ultimate.map((e, i) => {
                  if (e.availability === true) {
                    e["status"] = "pi-check";
                  } else {
                    e["status"] = "pi-times";
                  }
                  if (e.restrictionData !== null) {
                    e["extradata"] = e.restrictionData;
                  }
                  if (e.restrictionUnit !== null) {
                    e["extradata"] = e["extradata"] + e.restrictionUnit;
                  }
                  if (
                    e.restrictionData === null &&
                    e.restrictionUnit === null
                  ) {
                    e["extradata"] = undefined;
                  }
                  return (
                    <>
                      <li key={i}>
                        {e.status === "pi-check" ? (
                          <>
                            <i className="pi pi-check right-check p-mr-2"></i>{" "}
                            {e.featureTitle +
                              (e["extradata"] !== undefined
                                ? " ( " + e["extradata"] + " )"
                                : "")}
                          </>
                        ) : (
                          <>
                            {e.status === "pi-times" ? (
                              <>
                                <i className="pi pi-times cross-check p-mr-2"></i>{" "}
                                {e.featureTitle +
                                  (e["extradata"] !== undefined
                                    ? " ( " + e["extradata"] + " )"
                                    : "")}
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </li>
                      <tr key={"u-" + i}></tr>
                    </>
                  );
                })}
              </ul>
              <button
                style={{ border: "none" }}
                className="btn-plan"
                onClick={() => {
                  this.setState({
                    basicPlan: false,
                    premiumPlan: false,
                    ultimatePlan: true,
                    basicObject: {
                      ...this.state.basicObject,
                      isSelected: false,
                    },
                    premiumObject: {
                      ...this.state.premiumObject,
                      isSelected: false,
                    },
                    ultimateObject: {
                      ...this.state.ultimateObject,
                      isSelected: true,
                    },
                  });
                }}
              >
                {this.state.ultimateObject !== undefined &&
                this.state.ultimateObject !== null &&
                this.state.ultimateObject.isSelected === true
                  ? "Selected Plan"
                  : "Purchase Now"}
              </button>
            </div>
          </div>

          <div className="p-col-12 p-md-12 p-lg-12 mt-5">
            <div className="form-group" style={{ textAlign: "right" }}>
              <Link
                className="btn-plan"
                to={{
                  pathname: "/purchase",
                  state: { licenceToPurchase: this.getTheObject() },
                }}
              >
                <button type="button" className="btn btn-primary">
                  Proceed
                </button>
              </Link>
            </div>
          </div>
          <Dialog
            visible={this.state.isLoading}
            modal={true}
            closable={false}
            showHeader={false}
            style={{ width: "20vw" }}
            onHide={this.onDialogueHide}
          >
            <Loader />
          </Dialog>
        </div>
      </div>
    );
  }
}
