import React from "react";

export const SaveComponent = (props) => {
  return (
    <div>
      <p>
        <input
          type="text"
          className="form-control"
          onChange={props.handleChange}
          name="fileName"
          id="fileName"
          value={props.selectedCont}
          //   value={this.state.selectedCont.name}
          placeholder="Enter File Name"
        />
      </p>
      <button onClick={props.handleSave}>Save</button>
    </div>
  );
};
export const EditComponent = (props) => {
  return <button onClick={props.handleEdit}>Edit</button>;
};
export const AssignData = (props) => {
  let [groups, devices] = props.grouplist;
  let { group } = groups;
  let { profile } = devices;
  console.log("groups ", group, "devices", devices);
  let groupComponent = group.map((e, i) => {
    return (
      <div className="custom-control custom-checkbox" key={i}>
        <input type="checkbox" className="custom-control-input" id={e.value} />
        <label className="custom-control-label" htmlFor={e.value}>
          {e.name}
        </label>
      </div>
    );
  });
  let deviceComponent = profile.map((ev, j) => {
    return (
      <div className="custom-control custom-checkbox" key={j}>
        <input type="checkbox" className="custom-control-input" id={ev.value} />
        <label className="custom-control-label" htmlFor={ev.value}>
          {ev.name}
        </label>
      </div>
    );
  });
  return (
    <>
      <div className="p-col-12 p-lg-12 ">
        <div
          className="p-col-6 p-lg-6 "
          style={{ display: "inline-block", padding: "5px" }}
        >
          <div className="form-group">
            <select className="form-control" id="exampleFormControlSelect1">
              <option>Group & Profile</option>
              <option> Device </option>
            </select>
          </div>
        </div>
        <div
          className="p-col-6 p-lg-6 "
          style={{ display: "inline-block", padding: "5px" }}
        >
          <form
            className="form-inline d-flex justify-content-center md-form form-sm mt-0 b-input"
            style={{ padding: "3px 0px" }}
          >
            <input
              className="form-control form-control-sm mr-2 b-n"
              type="text"
              placeholder="Enter Search Terms"
              aria-label="Search"
              style={{ width: "80%" }}
            />
            <i className="pi pi-search" aria-hidden="true"></i>
          </form>
        </div>
      </div>
      <div className="p-col-12" style={{ width: "100%" }}>
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <a
              className="nav-item nav-link active"
              id="nav-home-tab"
              data-toggle="tab"
              href="#nav-home"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Group
            </a>
            <a
              className="nav-item nav-link"
              id="nav-profile-tab"
              data-toggle="tab"
              href="#nav-profile"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              Profile
            </a>
          </div>
        </nav>
        <div
          className="tab-content"
          id="nav-tabContent"
          style={{ textAlign: "left", padding: "2px", marginTop: "3px" }}
        >
          <div
            className="tab-pane fade show active"
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            {groupComponent}
          </div>
          <div
            className="tab-pane fade"
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="nav-profile-tab"
          >
            {deviceComponent}
          </div>
        </div>
      </div>
    </>
  );
};
export const ListHeader = (props) => {
  return (
    <div className="p-col-12">
      <div>
        <div className="p-grid" style={{ background: "#c3c3c3" }}>
          <div className="p-col-12 effect1">
            <div className="p-col-6" style={{ display: "inline-block" }}>
              <span className="">
                <b>Name</b>
              </span>
            </div>
            <div className="p-col-2" style={{ display: "inline-block" }}>
              <span className="mx-auto d-block">
                <b>Uploaded</b>
              </span>
            </div>
            <div className="p-col-2" style={{ display: "inline-block" }}>
              <span className="mx-auto d-block">
                <b>File Size (MB)</b>
              </span>
            </div>
            <div
              className="p-col-2"
              style={{ display: "inline-block", textAlign: "center" }}
            >
              <span className="mx-auto d-block">
                <b>Action</b>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export const HeaderContent = (props) => {
  const setLayoutView = (index) => {
    props.layoutHandler(index);
  };
  const passOnChangeValue = (value) => {
    //console.log("passonChange in child ", value.target.value);
    props.valueChanged(value.target.value);
  };

  return (
    <div
      className="p-grid"
      style={{ padding: "5px", margin: "2px 0px 5px 0px" }}
    >
      <div className="p-col-4" style={{ textAlign: "left" }}>
        <h2 className="p-h2">File Manager</h2>
      </div>
      <div className="p-col-4" style={{ textAlign: "left" }}>
        <form className="form-inline d-flex justify-content-center md-form form-sm mt-0 b-input">
          <input
            className="form-control form-control-sm mr-2 b-n"
            type="text"
            placeholder="Enter Search Terms"
            aria-label="Search"
            style={{ width: "85%" }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                //console.log("eee", e);
                passOnChangeValue(e);
              }
            }}
            onChange={(e) => {
              passOnChangeValue(e);
            }}
          />
          <i className="pi pi-search" aria-hidden="true"></i>
        </form>
      </div>
      <div className="p-col-4" style={{ textAlign: "right" }}>
        <i
          className="pi pi-list"
          style={{
            fontSize: "1.5em",
            paddingRight: "10px",
            paddingTop: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            setLayoutView(false);
          }}
        ></i>
        <i
          className="pi pi-th-large"
          style={{ fontSize: "1.5em", paddingLeft: "10px", cursor: "pointer" }}
          onClick={() => {
            setLayoutView(true);
          }}
        ></i>
        <button
          className="btn5"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          + New
        </button>
        <div className="dropdown-menu">
          <a className="dropdown-item" href="#" onClick={props.newFileH}>
            <i className="pi pi-upload icon-p"></i>
            Upload File
          </a>
          <a className="dropdown-item" href="#" onClick={props.newFolderH}>
            <i className="pi pi-folder icon-p"></i>
            New Folder
          </a>
        </div>
      </div>
    </div>
  );
};
