import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Loader from "../components/Loader";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import Upload from "../components/Upload";
import { Button } from "primereact/button";
import api from "../service/ApiService";
import { InputText } from "primereact/inputtext";
import { EnrollmentTypeEnum } from "../Enums";
import {
  getEnrollMentUrl,
  getDevicesUrl,
  errorCompute,
  baseUrl,
} from "../service/ApiUrls";
import { confirmAlert } from "react-confirm-alert";
const FileDownload = require("js-file-download");
let oldValues = [];
export class ImeiUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      configType: "Default Config",
      status: [
        { label: "Active", value: "Active" },
        { label: "Deactive", value: "Deactive" },
      ],
      selectedProducts: null,
      currentPage: 1,
      drows: 20,
      dfirst: 0,
      deleteall: false,
    };
    // const [selectedProducts, setSelectedProducts] = useState(null);
    this.configTypeChange = this.configTypeChange.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
  }
  onDialogueHide = () => {
    this.setState({ isLoading: false });
  };
  downloadFile = () => {
    api
      .get(baseUrl + "/downloadsample", {
        responseType: "blob",
      })
      .then((res) => {
        FileDownload(res.data, `downloaddata.xlsx`);
      })
      .catch((err) => {});
  };

  callgetDevices = ({
    first = 0,
    limit = 10,
    profileId = null,
    imei = null,
    groupId = null,
  } = {}) => {
    this.setState({ isLoading: true }, () => {
      api
        .get(getDevicesUrl, {
          params: {
            first: first,
            limit: limit,
            filter: 1,
            profileId: profileId,
            imei: imei,
            groupId: groupId,
          },
        })
        .then((res) => {
          this.setState({
            deviceInfo: res.data.data.content,
            ...res.data.data,
            isLoading: false,
          });
        })
        .catch((err) => {
          errorCompute(err);
          // localStorage.setItem("loggedIn", false);
          // window.location = process.env.PUBLIC_URL + "/login";
        });
    });
  };

  componentDidMount = async () => {
    this.callgetQREnrollMentParameter();

    // this.alldata.getEmiNo().then((data) => this.setState({ emi: data }));
  };
  configTypeChange(e) {
    this.setState({ configType: e.value });
  }
  onStatusChange(event) {
    this.dt.filter(event.value, "status", "equals");
    this.setState({ selectedBrand: event.value });
  }

  callDeleteDevice = ({ ids, from }) => {
    api
      .delete(getDevicesUrl, { params: { ids: ids } })
      .then((res) => {
        if (res.data.result.isError === false) {
          this.setState(
            {
              isSuccess: true,
              isLoading: false,
              selectedProducts:
                from === "topdelete" ? null : this.state.selectedProducts,
              ids: from === "topdelete" ? null : this.state.ids,
            },
            () => {
              confirmAlert({
                title: "Success Message",
                message: <p className="mod-sp">Deleted Device Successfully</p>,
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {},
                  },
                ],
              });
              oldValues = [];
              this.callgetDevices();
            }
          );
        } else if (res.data.result.isError) {
          this.setState(
            {
              isLoading: false,
              isSuccess: false,
              isError: true,
              errorMessage: res.data.result.errorMsg,
            },
            () => {
              confirmAlert({
                title: "Error Message",
                message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {},
                  },
                ],
              });
            }
          );
        }
      })
      .catch((err) => {
        errorCompute(err);
      });
  };

  actionTemplate(rowData, column) {
    return (
      <div>
        <i
          className="pi pi-trash"
          onClick={(e) => {
            // this.callDeleteDevice({ ids: rowData.id });
            this.setState({ visible: true, rowData: rowData });
          }}
          style={{ fontSize: "1.3em", cursor: "pointer" }}
        ></i>
      </div>
    );
  }

  callgetQREnrollMentParameter = async ({
    type = EnrollmentTypeEnum.QR,
  } = {}) => {
    api
      .get(getEnrollMentUrl, {
        params: {
          type,
        },
      })
      .then((res) => {
        console.log(res, "status", res.status);
        const {
          data: { result, data },
          status,
          config,
        } = res;

        console.log(
          "result",
          result,
          "data",
          data,
          "config",
          config,
          "status",
          status
        );
        if (status !== 200) {
          ///Some Error Message needs to be shown
        } else {
          const { isError } = result;
          console.log(typeof isError, "isError", isError);
          if (!isError) {
            const {
              content,
              totalRecords,
              first,
              rows,
              sortField,
              sortOrder,
            } = data;

            let testConfig = [];
            for (let i = 0; i < content.length; i++) {
              //console.log("content [", i, "] =", content[i]);
              testConfig.push(content[i]);
            }

            this.setState(
              {
                configName: testConfig,
                configType: content[0],
              },
              () => {
                this.callgetDevices();
              }
            );
            // this.callgetQRImage(content[0].id).then((res) => {
            //   this.setState({ configType: content[0], qr: res });
            // });
          }
        }
      });
  };

  submitHandler = async ({ data, result }, AllFiles) => {
    console.log("response", { data, result }, "Allfiles", AllFiles);
    if (result.isError === false) {
      window.location = `${process.env.PUBLIC_URL}/imei-upload`;
    } else {
      confirmAlert({
        title: "Error Message",
        message: <p className="mod-p"> {result.errorMsg} </p>,
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              window.location = `${process.env.PUBLIC_URL}/imei-upload`;
            },
          },
        ],
      });
    }
  };

  setSelectedProducts = (e) => {
    console.log("in selected products ", e.originalEvent.target);
    oldValues = this.state.selectedProducts;
    let object = oldValues.find((v) => {
      return v.id === e.id;
    });
    if (object === null || object === undefined) {
      oldValues.push(e);
    }
    this.setState({ selectedProducts: oldValues });
  };

  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Enroll", url: "enroll" },
      { label: "Enroll with IMEI Code" },
    ];
    const configName = [
      { name: "Default Config", code: "50" },
      { name: "Custom Config", code: "75" },
      { name: "Own Config", code: "100" },
    ];
    const statusFilter = (
      <Dropdown
        style={{ width: "100%" }}
        placeholder="Select a Brand"
        value={this.state.selectedBrand}
        options={this.state.status}
        onChange={this.onStatusChange}
        showClear
      />
    );
    const footer1 = (
      <div>
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-success"
          onClick={(rowData) => {
            this.setState({ deleteall: false, isLoading: true }, () => {
              this.callDeleteDevice({ ids: this.state.ids, from: "topdelete" });
            });
          }}
        />
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ deleteall: false });
          }}
        />
      </div>
    );
    const deleteAllDialogue = (
      <Dialog
        header="Delete Device"
        visible={this.state.deleteall}
        style={{ width: "35vw" }}
        modal={true}
        footer={footer1}
        onHide={() => this.setState({ deleteall: false })}
      >
        <p
          style={{
            fontWeight: "bold",
            paddingBottom: "10px",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          Do you want to Delete Selected Devices?
        </p>
      </Dialog>
    );
    const header = (
      <div className="row justify-content-end">
        <div className="p-col-12 p-md-4 p-lg-4">
          <h2 style={{ textAlign: "left", paddingTop: "10px" }}>
            Uploaded IMEI of Device
          </h2>
        </div>
        <div className="p-col-12 p-md-4 p-lg-4">
          <div id="search" className="p-inputgroup">
            <InputText
              placeholder="Enter Search Item"
              onInput={(e) => this.setState({ globalFilter: e.target.value })}
            />
            <Button
              icon="pi pi-search"
              className="p-button-primary s-icon s-btn"
            />
          </div>
        </div>
        <div className="p-col-12 p-md-4 p-lg-4" style={{ textAlign: "right" }}>
          <button
            className="btn btn-danger"
            type="button"
            name="Delete"
            onClick={() => {
              this.setState({ deleteall: true });
              // this.callDeleteDevice({ ids: this.state.ids, from: "topdelete" });
            }}
          >
            Delete
          </button>
        </div>
      </div>
    );
    const footer = (
      <div>
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-success"
          onClick={(rowData) => {
            this.setState({ visible: false, isLoading: true }, () => {
              this.callDeleteDevice({ ids: this.state.rowData.id });
            });
          }}
        />
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ visible: false });
          }}
        />
      </div>
    );
    const deleteDialogue = (
      <Dialog
        header="Delete Device"
        visible={this.state.visible}
        style={{ width: "35vw" }}
        modal={true}
        footer={footer}
        onHide={() => this.setState({ visible: false })}
      >
        <p
          style={{
            fontWeight: "bold",
            paddingBottom: "10px",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          Do you want to Delete this Device?
        </p>
      </Dialog>
    );
    const home = { icon: "pi pi-home", url: "dashboard" };
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 ">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="p-grid p-col-12 p-lg-12 ">
          {deleteDialogue}
          {deleteAllDialogue}
          <div className="p-lg-6">
            <div
              className="bg-col p-col"
              style={{
                marginRight: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "10px",
                background: "#fbfbfb",
              }}
            >
              <div className="p-col-12 p-md-12 p-z">
                <a
                  onClick={() => {
                    this.downloadFile();
                  }}
                  style={{
                    color: "#000000",
                    textDecoration: "underline",
                    float: "right",
                  }}
                >
                  Sample File
                </a>
              </div>
              <Upload
                handleSubmit={this.submitHandler}
                from="imeiUpload"
                parentId={
                  this.state.configType.id !== undefined &&
                  this.state.configType.id !== null &&
                  this.state.configType.id
                }
              />
              <div className="row justify-content-md-center">
                <div className="p-col-12 p-md-8" style={{ paddingTop: "18px" }}>
                  <Dropdown
                    value={this.state.configType}
                    options={this.state.configName}
                    onChange={this.configTypeChange}
                    placeholder="Select Config"
                    optionLabel="name"
                    filter
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="p-lg-6">
            <div
              className="bg-col p-col"
              style={{
                marginLeft: "10px",
                paddingTop: "60px",
                paddingLeft: "25px",
                paddingBottom: "55px",
              }}
            >
              <p>
                <b>Step 1: Upload Document</b>
              </p>
              <p>
                Upload document containing devices' imei1 and imei2 number. You
                can check sample file for format.
              </p>
              <p>
                <b>Step 2: Install Mobionizer</b>
              </p>
              <p>
                On your device, open Google Play Store and Install Mobionizer
                app. Don’t have Google Play Store?{" "}
                <a href="https://www.mobionizer.com:8443/mdm/downloadapk">
                  Download apk
                </a>
                .
              </p>
              <p>
                <b>Step 3: Auto Login</b>
              </p>
              <p>
                After installing, open the app and click on Enroll button. Allow
                the permissions and click to Auto Login.
              </p>
              <p>
                <b>Step 4: Complete Setup</b>
              </p>
              <p>
                After complete setup, Mobionizer will be set as Default Launcher
                on your Enrolled Device.
              </p>
            </div>
          </div>
        </div>
        <div className="p-grid bg-col p-lg-12">
          <DataTable
            ref={(el) => (this.dt = el)}
            value={this.state.content}
            style={{ marginBottom: "20px" }}
            header={header}
            responsive={true}
            selection={this.state.selectedProducts}
            onSelectionChange={(e) =>
              this.setState({ selectedProducts: e.value }, () => {
                let ids = [];
                this.state.selectedProducts.map((e, v) => {
                  ids.push(e.id);
                });
                console.log(ids.join());
                this.setState({ ids: ids.join() });
              })
            }
            globalFilter={this.state.globalFilter}
            emptyMessage="No records found"
            paginator
            lazy
            onPage={(event) => {
              //console.log("Event", event);
              this.callgetDevices({
                first: event.page,
                limit: this.state.drows,
              });
              setTimeout(() => {
                const { first, rows, page } = event;

                this.setState({
                  dfirst: first,
                  page,
                  drows: rows,
                  currentPage: page + 1,
                });
              }, 500);
            }}
            first={this.state.dfirst}
            currentPage={this.state.currentPage}
            totalRecords={this.state.totalRecords}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            rows={this.state.drows}
            rowsPerPageOptions={[10, 20, 30, 50, 100]}
          >
            <Column
              selectionMode="multiple"
              headerStyle={{ width: "3rem" }}
            ></Column>
            <Column
              field="imei1"
              header="Imei 1"
              style={{ textAlign: "center" }}
            />
            <Column
              field="imei2"
              header="Imei 2"
              style={{ textAlign: "center" }}
            />
            <Column
              field="profileName"
              header="Profile Name"
              style={{ textAlign: "center" }}
            />

            <Column
              field="enrollmentStatusSt"
              header="Enrollment Status"
              style={{ textAlign: "center" }}
            />
            <Column
              header="Action"
              body={this.actionTemplate.bind(this)}
              style={{ textAlign: "center" }}
            />
          </DataTable>
        </div>
        <Dialog
          visible={this.state.isLoading}
          modal={true}
          closable={false}
          showHeader={false}
          style={{ width: "15vw" }}
          onHide={this.onDialogueHide}
        >
          <Loader />
        </Dialog>
      </div>
    );
  }
}
