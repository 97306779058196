import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Panel } from "primereact/panel";
import api from "../service/ApiService";
import { getUserProfileUrl, baseUrl } from "../service/ApiUrls";
import {
  OSTypeEnum,
  EnrollmentTypeEnum,
  ModuleNameEnum,
  UploadActionEnum,
} from "../Enums";
import { Dialog } from "primereact/dialog";
import Loader from "../components/Loader";
import ErrorDialog from "../components/ErrorDialog";
import SuccessDialog from "../components/SuccessDialog";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import ImgUpload from "../components/ImgUpload";
import { confirmAlert } from "react-confirm-alert";
const userImg = require("../assets/user_profile.png");

class NewUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProfile: null,
      selectedGroup: null,
      opt: this.dropOption,
      file: "",
      imagePreviewUrl: userImg,
      isEdit:
        props.history.location.state !== undefined &&
        props.history.location.state.rowData !== undefined,
    };

    this.profiles = [
      { name: "Profile 1", code: "Profile1" },
      { name: "Profile 2", code: "Profile2" },
      { name: "Profile 3", code: "Profile3" },
      { name: "Profile 5", code: "Profile4" },
      { name: "Profile 6", code: "Profile5" },
    ];
    this.groups = [
      { name: "Group 1", code: "Group1" },
      { name: "Group 2", code: "Group2" },
      { name: "Group 3", code: "Group3" },
      { name: "Group 5", code: "Group4" },
      { name: "Group 6", code: "Group5" },
    ];
    this.dropOption = [
      {
        name: "Admin",
        code: "AD",
      },
      { name: "Profile Admin", code: "PA" },
      { name: "Group Admin", code: "GA" },
    ];
    this.dropOptionChange = this.dropOptionChange.bind(this);
  }

  webPhotoupload = ({ file } = {}) => {
    const formData = new FormData();
    formData.append("uploadActionType", UploadActionEnum.IMAGEUPLOAD);
    formData.append("moduleName", ModuleNameEnum.USERPROFILE);
    formData.append("file", file);

    api.post(baseUrl + "/uploads", formData).then((res) => {
      if (res.data.result.isError === false) {
        this.setState({
          ...res.data.data,
          profilePictureTempId: res.data.data.fileTempId,
          profilePictureBase64St: res.data.data.imageBase64,
          //"data:image/png;base64," + res.data.data.imageBase64,
        });
      } else {
        confirmAlert({
          title: "Error Message",
          message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                // this.webcallGetUSerList();
              },
            },
          ],
        });
      }
    });
  };

  photoUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      this.setState(
        {
          file: file,
          imagePreviewUrl: reader.result,
        },
        () => {
          this.webPhotoupload({ file: this.state.file });
        }
      );
    };
    if (file !== null && file !== undefined) {
      reader.readAsDataURL(file);
    }
  };
  dropOptionChange(e) {
    this.setState({ opt: e.value, roleId: e.value.id }, () => {
      if (this.state.opt.code === "AD") {
        console.log("Hello");
      } else {
        if (this.state.opt.code === "PA") {
          console.log("Hi");
        } else {
          console.log("Duu");
        }
      }
    });
  }

  webcallPutProfile = () => {
    let dataTosend = {
      id: this.props.history.location.state.rowData.id,
      fullName: this.state.fullName,
      email: this.state.email,
      phoneNo: this.state.phoneNo,
      nid: this.state.nid,
      roleId: this.state.roleId,
      deviceProfiles: this.state.selectedProfile,
      deviceGroups: this.state.selectedGroup,
      accType: this.props.history.location.state.admin === true ? 0 : 1,
      profilePictureTempId: this.state.profilePictureTempId,
      //profilePictureBase64St: this.state.profilePictureBase64St,
    };

    api.put(baseUrl + "/user-list/" + dataTosend.id, dataTosend).then((res) => {
      if (res.data.result.isError === false) {
        this.props.history.push("/user-management");
      } else {
        confirmAlert({
          title: "Error Message",
          message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                // this.webcallGetUSerList();
              },
            },
          ],
        });
      }
    });
  };

  webcallPostProfile = () => {
    let dataTosend = {
      fullName: this.state.fullName,
      email: this.state.email,
      phoneNo: this.state.phoneNo,
      nid: this.state.nid,
      roleId: this.state.roleId,
      deviceProfiles: this.state.selectedProfile,
      deviceGroups: this.state.selectedGroup,
      accType: this.props.history.location.state.admin === true ? 0 : 1,
      profilePictureTempId: this.state.profilePictureTempId,
      //profilePictureBase64St: this.state.profilePictureBase64St,
    };

    api.post(baseUrl + "/user-list/add", dataTosend).then((res) => {
      if (res.data.result.isError === false) {
        this.props.history.push("/user-management");
      } else {
        confirmAlert({
          title: "Error Message",
          message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                // this.webcallGetUSerList();
              },
            },
          ],
        });
      }
    });
  };

  webcallgeteditprofile = () => {
    let id = this.props.history.location.state.rowData.id;

    api.get(baseUrl + "/user-list/" + id).then((res) => {
      if (res.data.result.isError === false) {
        let roleId = res.data.data.content.roleId;

        let selectedopt = res.data.data.roleList.find((e) => {
          return e.id === roleId;
        });
        console.log("roleID", roleId, "Id", selectedopt);
        this.setState({
          ...res.data.data,
          ...res.data.data.content,
          opt: selectedopt,
          selectedProfile: res.data.data.content.deviceProfiles,
          selectedGroup: res.data.data.content.deviceGroups,
          imagePreviewUrl:
            res.data.data.content.profilePictureBase64St !== null
              ? "data:image/jpeg;base64," +
                res.data.data.content.profilePictureBase64St
              : userImg,
        });
      } else {
        confirmAlert({
          title: "Error Message",
          message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                // this.webcallGetUSerList();
              },
            },
          ],
        });
      }
    });
  };
  webcallGetForProfile = ({ type = 0 } = {}) => {
    api
      .get(baseUrl + "/user-list/add", { params: { type: type } })
      .then((res) => {
        if (res.data.result.isError === false) {
          let roleId = res.data.data.roleId;

          let selectedopt = res.data.data.roleList.find((e) => {
            return e.id === roleId;
          });
          console.log("roleID", roleId, "Id", selectedopt);
          this.setState({
            ...res.data.data,
            ...res.data.data.content,
            opt: selectedopt,
          });
        } else {
          confirmAlert({
            title: "Error Message",
            message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  // this.webcallGetUSerList();
                },
              },
            ],
          });
        }
      });
  };

  webcallForProfile = async () => {
    this.setState({ isLoading: true }, async () => {
      try {
        let {
          data: {
            data,
            result: { isError, errorMsg, statusCode },
          },
        } = await api.get(getUserProfileUrl);
        if (isError === false) this.setState({ ...data, isLoading: false });
        else {
          this.setState({ isLoading: false });
        }
        //console.log("res ", res.data);
      } catch (err) {
        this.setState({ isLoading: false });
        // localStorage.setItem("loggedIn", false);
        // window.location = process.env.PUBLIC_URL + "/login";
      }
    });
  };

  componentDidMount() {
    if (this.state.isEdit) {
      this.webcallgeteditprofile();
    } else {
      this.webcallGetForProfile({
        type: this.props.history.location.state.admin === true ? 0 : 1,
      });
    }
  }

  onDialogueHide = () => {
    this.setState({ isLoading: false });
  };

  changeHandler = (e) => {
    console.log("e", e.target);
    this.setState({ [e.target.id]: e.target.value });
  };
  onDialogueHideSuccess = () => {
    this.setState({ isSuccess: false });
  };
  render() {
    const { imagePreviewUrl } = this.state;
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Home Ground", url: "home-ground" },
      { label: "User Management", url: "user-management" },
      {
        label:
          this.props.location.state.isEdit === true ? "Edit User" : "New User",
      },
    ];
    const group = (
      <div
        className="form-group p-col-12 p-lg-6 p-md-6"
        style={{ display: "inline-block" }}
      >
        <label htmlFor="organizationName">Device Group</label>
        <MultiSelect
          style={{ width: "100%" }}
          value={this.state.selectedGroup}
          options={this.state.groupList}
          onChange={(e) => this.setState({ selectedGroup: e.value })}
          optionLabel="name"
          filter
          placeholder="Select Device Group"
        />
      </div>
    );
    const profile = (
      <div
        className="form-group p-col-12 p-lg-6 p-md-6"
        style={{ display: "inline-block" }}
      >
        <label htmlFor="organizationName">Device Profile</label>
        <MultiSelect
          style={{ width: "100%" }}
          value={this.state.selectedProfile}
          options={this.state.profileList}
          onChange={(e) => this.setState({ selectedProfile: e.value })}
          optionLabel="name"
          filter
          placeholder="Select Device Profile"
        />
      </div>
    );
    let component = null;
    switch (
      this.state.opt !== undefined &&
      this.state.opt.responsibleFor !== undefined &&
      this.state.opt.responsibleFor
    ) {
      case 2:
        component = group;
        break;
      case 1:
        component = profile;
        break;
      default:
        component = "";
        break;
    }
    const home = { icon: "pi pi-home", url: "dashboard" };
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 ">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
          <Dialog
            visible={this.state.isSuccess}
            modal={true}
            style={{ width: "25vw" }}
            closable
            showHeader
            onHide={this.onDialogueHideSuccess}
          >
            <SuccessDialog successMessage="Profile Updated Successfully" />
          </Dialog>
        </div>
        <div className="p-col-12 p-lg-12 ">
          <Dialog
            visible={this.state.isLoading}
            modal={true}
            style={{ width: "15vw" }}
            closable
            showHeader
            onHide={this.onDialogueHide}
          >
            <Loader textToShow={this.state.textInLoader} />
          </Dialog>
          <div id="custom-div" className="row align-items-start bg-col">
            <div className="p-col-12">
              <p className="cus-t">
                <span style={{ fontSize: "16px" }}>{this.state.accTypeSt}</span>
              </p>
            </div>
            <div className="p-col-12 p-lg-3 p-md-3">
              <div className="p-col-12">
                <div className="text-center mt-3">
                  <ImgUpload
                    onChange={this.photoUpload}
                    src={imagePreviewUrl}
                  />
                </div>
                {/* <div className="text-center mt-3">
                  <img
                    src={userImg}
                    className="mx-auto d-block"
                    width="150"
                    alt="User Image"
                    style={{
                      borderRadius: "100px",
                      border: "1px solid #ffffff",
                    }}
                  />
                  <button
                    style={{
                      background: "rgb(22, 111, 138)",
                      borderRadius: "20px",
                      padding: "5px 10px",
                      color: "#ffffff",
                      border: "none",
                    }}
                  >
                    Upload
                  </button>
                </div> */}
              </div>
            </div>
            <div className="p-col-12 p-lg-9 p-md-9">
              <div className="p-col-12">
                <div
                  className="form-group p-col-12 p-lg-6 p-md-6"
                  style={{ display: "inline-block" }}
                >
                  <label htmlFor="fullName">Full Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="fullName"
                    onChange={this.changeHandler}
                    placeholder="Enter Full Name"
                    value={
                      this.state.fullName !== null ? this.state.fullName : ""
                    }
                  />
                </div>
                <div
                  className="form-group p-col-12 p-lg-6 p-md-6"
                  style={{ display: "inline-block" }}
                >
                  <label htmlFor="email">Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    onChange={this.changeHandler}
                    placeholder="Enter Email Address"
                    value={this.state.email}
                  />
                </div>
                <div
                  className="form-group p-col-12 p-lg-6 p-md-6"
                  style={{ display: "inline-block" }}
                >
                  <label htmlFor="phoneNo">Phone Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="phoneNo"
                    onChange={this.changeHandler}
                    placeholder="Enter Phone Number"
                    value={
                      this.state.phoneNo !== null ? this.state.phoneNo : ""
                    }
                  />
                </div>
                <div
                  className="form-group p-col-12 p-lg-6 p-md-6"
                  style={{ display: "inline-block" }}
                >
                  <label htmlFor="nid">NID</label>
                  <input
                    type="text"
                    className="form-control"
                    id="nid"
                    onChange={this.changeHandler}
                    placeholder="Enter NID Number"
                    value={this.state.nid}
                  />
                </div>
                <div
                  className="form-group p-col-12 p-lg-6 p-md-6"
                  style={{ display: "inline-block" }}
                >
                  <label htmlFor="nid">Role</label>
                  <Dropdown
                    value={this.state.opt}
                    options={this.state.roleList}
                    onChange={this.dropOptionChange}
                    style={{
                      width: "100%",
                      padding: "1px",
                      border: "1px solid #ced4da",
                      borderRadius: "0.25em",
                    }}
                    optionLabel="name"
                    placeholder="Select Option"
                    className="custom-d"
                  />
                </div>
                {component !== undefined && component !== null && component}
              </div>
            </div>
            <div className="p-col-12 mt-4 mb-4" style={{ textAlign: "center" }}>
              <button
                className="btn btn-primary mr-3"
                onClick={() => {
                  if (this.state.isEdit === false) {
                    this.webcallPostProfile();
                  } else {
                    this.webcallPutProfile();
                  }
                }}
              >
                Submit
              </button>
              <button
                className="btn btn-danger"
                onClick={() => {
                  this.props.history.push("/user-management");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewUsers;
