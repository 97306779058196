import axios from "axios";

export class AllData {
  getConfig() {
    return axios
      .get(`${process.env.PUBLIC_URL}/data/configprofile.json`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => res.data.data);
  }
  getUsers() {
    return axios
      .get(`${process.env.PUBLIC_URL}/data/userlist.json`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => res.data.data);
  }
  getRemote() {
    return axios
      .get(`${process.env.PUBLIC_URL}/data/remotecast.json`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => res.data.data);
  }
  getRoles() {
    return axios
      .get(`${process.env.PUBLIC_URL}/data/rolelist.json`)
      .then((res) => res.data.data);
  }
  getBasicFeatures() {
    return axios
      .get(`${process.env.PUBLIC_URL}/data/basic_features.json`)
      .then((res) => res.data.data);
  }
  getPremiumFeatures() {
    return axios
      .get(`${process.env.PUBLIC_URL}/data/premium_features.json`)
      .then((res) => res.data.data);
  }
  getUltimateFeatures() {
    return axios
      .get(`${process.env.PUBLIC_URL}/data/ultimate_features.json`)
      .then((res) => res.data.data);
  }
  getRoleList() {
    return axios
      .get(`${process.env.PUBLIC_URL}/data/roles.json`)
      .then((res) => res.data.root);
  }
  getWhiteList() {
    return axios
      .get(`${process.env.PUBLIC_URL}/data/whitelist.json`)
      .then((res) => res.data.data);
  }
  getFIle() {
    return axios
      .get(`${process.env.PUBLIC_URL}/data/content-push.json`)
      .then((res) => res.data.root);
  }
  getEmiNo() {
    return axios
      .get(`${process.env.PUBLIC_URL}/data/deviceimei.json`)
      .then((res) => res.data.data);
  }
  getGroupList() {
    return axios
      .get(`${process.env.PUBLIC_URL}/data/grouplist.json`)
      .then((res) => res.data.data);
  }
  getNewGroup() {
    return axios
      .get(`${process.env.PUBLIC_URL}/data/newgroup.json`)
      .then((res) => res.data.data);
  }
  getProfileList() {
    return axios
      .get(`${process.env.PUBLIC_URL}/data/profilelist.json`)
      .then((res) => res.data.data);
  }
  getGeofence() {
    return axios
      .get(`${process.env.PUBLIC_URL}/data/geofence.json`)
      .then((res) => res.data.data);
  }
  getEntApp() {
    return axios
      .get(`${process.env.PUBLIC_URL}/data/enterprise-app-list.json`)
      .then((res) => res.data.data);
  }
  getEntAppManage() {
    return axios
      .get(`${process.env.PUBLIC_URL}/data/enterprise-app-manage.json`)
      .then((res) => res.data.data);
  }
}

export class CarService {
  cars = {
    data: {
      content: [
        {
          enrollmentId: "123",
          profileId: "7686",
          profileName: "sample android profile1",
          groupId: "5786879",
          groupName: "group1",
          isDefault: true,
          type: 0, //QR
          configName: "config1",
        },
        {
          enrollmentId: "1234",
          profileId: "7686",
          profileName: "sample android profile1",
          groupId: "578687",
          groupName: "group1",
          isDefault: true,
          type: 0, //QR
          configName: "config1",
        },
        {
          enrollmentId: "1234",
          profileId: "7686",
          profileName: "sample android profile1",
          groupId: "5786871",
          groupName: "group1",
          isDefault: true,
          type: 0, //QR
          configName: "config1",
        },
        {
          enrollmentId: "4123",
          profileId: "7686",
          profileName: "sample android profile1",
          groupId: "5786872",
          groupName: "group1",
          isDefault: true,
          type: 0, //QR
          configName: "config1",
        },
        {
          enrollmentId: "5123",
          profileId: "7686",
          profileName: "sample android profile1",
          groupId: "578687",
          groupName: "group1",
          isDefault: true,
          type: 0, //QR
          configName: "config1",
        },
        {
          enrollmentId: "6123",
          profileId: "7686",
          profileName: "sample android profile1",
          groupId: "578687",
          groupName: "group1",
          isDefault: true,
          type: 0, //QR
          configName: "config1",
        },
        {
          enrollmentId: "9123",
          profileId: "7686",
          profileName: "sample android profile1",
          groupId: "578687",
          groupName: "group1",
          isDefault: true,
          type: 0, //QR
          configName: "config1",
        },
        {
          enrollmentId: "9123",
          profileId: "7686",
          profileName: "sample android profile1",
          groupId: "578687",
          groupName: "group1",
          isDefault: true,
          type: 0, //QR
          configName: "config1",
        },
        {
          enrollmentId: "3123",
          profileId: "7686",
          profileName: "sample android profile1",
          groupId: "578687",
          groupName: "group1",
          isDefault: true,
          type: 0, //QR
          configName: "config1",
        },
        {
          enrollmentId: "2123",
          profileId: "7686",
          profileName: "sample android profile1",
          groupId: "578687",
          groupName: "group1",
          isDefault: true,
          type: 0, //QR
          configName: "config1",
        },
      ],
      totalRecords: 100,
      first: 0,
      rows: 5,
      sortField: "configName",
      sortOrder: 0, //asc
    },
    result: {
      isError: false,
      errorMsg: "",
      statusCode: 200,
    },
  };

  getCars() {
    return this.cars;
  }

  getFixColumns() {
    return ["name", "profileName", "groupName", "isDefault", "osType", "type"];
  }

  getFilteredCars(filter) {
    // filter the cars based on the filter
    // an example filter could be: ?name=TestName&page=1
    console.log("Filter", filter);
    return this.cars;
  }
}
