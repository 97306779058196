import React from "react";

const SuccessDialog = ({ successMessage = "Update Successfully" }) => {
  //console.log("Inside loader", textToShow);
  return (
    <div
      className="text-center"
      style={{ margin: "30px 5px", fontWeight: "bold" }}
    >
      <span className="text-success" style={{ paddingLeft: "10px" }}>
        {successMessage}
      </span>
    </div>
  );
};

export default SuccessDialog;
