import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import { AllData } from "../service/AllData";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import api from "../service/ApiService";
import { Dialog } from "primereact/dialog";
import ErrorDialog from "../components/ErrorDialog";
import SuccessDialog from "../components/SuccessDialog";
import Loader from "../components/Loader";
import {
  getGroupListUrl,
  getGroupListDevice,
  getDeviceSearchData,
} from "../service/ApiUrls";
import { OverlayPanel } from "primereact/overlaypanel";
const helpIcon = require("../assets/icons/help_icon.png");

export class DeviceGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group: null,
      errorMessage: "Something went wrong!",
      isLoading: false,
      isError: false,
      visible: false,
      currentPage: 1,
      drows: 20,
      dfirst: 0,
    };
    this.alldata = new AllData();
    this.cloneGroup = {};
    this.editorForRowEditing = this.editorForRowEditing.bind(this);
    this.onRowEditorValidator = this.onRowEditorValidator.bind(this);
    this.onRowEditInit = this.onRowEditInit.bind(this);
    this.onRowEditSave = this.onRowEditSave.bind(this);
    this.onRowEditCancel = this.onRowEditCancel.bind(this);
  }
  onDialogueHide = () => {
    this.setState({ isError: false });
  };
  callDeviceSearchData = () => {
    api
      .get(getDeviceSearchData)
      .then((res) => {
        if (res.data.result.isError === false) {
          console.log("Inside false");
          this.setState(
            {
              profileList: res.data.data.profileList,
              groupList: res.data.data.groupList,
            },
            () => {
              this.callGroupList();
              // this.setState(
              //   { selectedProfile: this.state.profileList[0] },
              //   () => {
              //     this.callGroupList();
              //   }
              // );
            }
          );
        } else if (res.data.result.isError) {
          this.setState({
            isError: true,
            errorMessage: res.data.result.errorMsg,
          });
        }
      })
      .catch((err) => {
        localStorage.setItem("loggedIn", false);
        //window.location = process.env.PUBLIC_URL + "/login";
      });
  };

  callGroupList = ({ first = 0, limit = 10, filter, profileId } = {}) => {
    api
      .get(getGroupListUrl, { params: { first, limit, filter, profileId } })
      .then((res) => {
        if (res.data.result.isError === false) {
          this.setState({
            ...res.data.data,
            group: res.data.data.content,
            // rows: res.data.data.totalRecords,
          });
        } else if (res.data.result.isError) {
          this.setState({
            isError: true,
            errorMessage: res.data.result.errorMsg,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  callDeleteGroupApi = ({ id, rowData }) => {
    api
      .delete(getGroupListUrl + "/" + id)
      .then((res) => {
        if (res.data.result.isError === false) {
          var array = [...this.state.group]; // make a separate copy of the array
          var index = array.indexOf(rowData);
          if (index !== -1) {
            array.splice(index, 1);
            this.setState(
              { group: array, isSuccess: true },
              () => {}
              //console.log("new state", this.state.cars)
            );
          }
        } else if (res.data.result.isError) {
          this.setState({
            isError: true,
            errorMessage: res.data.result.errorMsg,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.callDeviceSearchData();
    //this.alldata.getGroupList().then((data) => this.setState({ group: data }));
  }
  onDialogueHideSuccess = () => {
    this.setState({ isSuccess: false });
  };
  /* Row Editing */
  onEditorValueChangeForRowEditing(props, value) {
    let updatedGroup = [...props.value];
    updatedGroup[props.rowIndex][props.field] = value;
    this.setState({ group: updatedGroup });
  }

  editorForRowEditing(props, field) {
    return (
      <InputText
        type="text"
        value={props.rowData[field]}
        onChange={(e) =>
          this.onEditorValueChangeForRowEditing(props, e.target.value)
        }
      />
    );
  }
  onRowEditorValidator(rowData) {
    let value = rowData["group_name"];
    return value.length > 0;
  }
  onRowEditInit(event) {
    this.clonedGroup[event.data.group_name] = { ...event.data };
  }
  onRowEditSave(event) {
    if (this.onRowEditorValidator(event.data)) {
      delete this.clonedGroup[event.data.group_name];
      this.growl.show({
        severity: "success",
        summary: "Success",
        detail: "Group is updated",
      });
    } else {
      this.growl.show({
        severity: "error",
        summary: "Error",
        detail: "Profile is required",
      });
    }
  }
  onRowEditCancel(event) {
    let groups = [...this.state.group];
    groups[event.index] = this.cloneGroup[event.data.group_name];
    delete this.cloneGroup[event.data.group_name];
    this.setState({
      group: groups,
    });
  }

  rowDelete = (rowData, column) => {
    api
      .delete(
        getGroupListUrl +
          "/" +
          rowData.id +
          "?preserve=" +
          this.state.prechecked
      )
      .then((res) => {
        if (res.data.result.isError === false) {
          this.setState({ isSuccess: true, isLoading: false });
          window.location = process.env.PUBLIC_URL + "/device-group";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onPage = (event) => {
    //console.log("Event", event);
    this.callGroupList({ first: event.page, limit: this.state.drows });
    setTimeout(() => {
      const { first, rows, page } = event;

      this.setState({
        dfirst: first,
        page,
        drows: rows,
        currentPage: page + 1,
      });
    }, 500);
  };
  // this.setState({ loading: true });

  // //imitate delay of a backend call
  // setTimeout(() => {
  //     const { first, rows } = event;

  //     this.setState({
  //         first,
  //         customers: this.datasource.slice(first, first + rows),
  //         loading: false
  //     });
  // }, 500);

  actionTemplate(rowData, column) {
    return (
      <div>
        <i
          className="pi pi-pencil icon-p"
          onClick={() => {
            this.props.history.push({
              pathname: "/create_device_group",
              state: {
                rowData: rowData,
                stateData: this.state,
                isEdit: true,
              },
            });
          }}
          style={{ fontSize: "1.1em", color: "#000000", paddingRight: "5px" }}
        ></i>
        <i
          className="pi pi-trash icon-p"
          onClick={() => {
            // console.log("onClick", rowData);
            this.setState({ visible: true, rowData: rowData });
          }}
          style={{ fontSize: "1.1em", color: "#000000", paddingLeft: "5px" }}
        ></i>
      </div>
    );
  }
  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Device Outline", url: "device-outline" },
      { label: "Device Group" },
    ];
    const profileList = this.state.profileList;
    let profileOptions =
      profileList !== undefined &&
      profileList.length > 0 &&
      profileList.map((e, i) => {
        //console.log("profile", e);
        return (
          <option key={i} value={e.id}>
            {e.name}
          </option>
        );
      });
    const header = (
      <div id="cus-p" className="row justify-content-end">
        <div className="p-col-12 p-md-3 p-lg-3">
          <h2 style={{ textAlign: "left" }}>Group List</h2>
        </div>
        <div className="p-col-12 p-md-3 p-lg-3">
          <div className="form-group">
            <select
              className="form-control"
              // id="exampleFormControlSelect1"
              onChange={(e) => {
                console.log("e in profile ", e.target);
                let profileObject = profileList.find((obj) => {
                  //console.log("object", obj.id);
                  return obj.id === e.target.value;
                });
                //console.log("profile object", profileObject);
                this.setState(
                  {
                    ...this.state,
                    selectedProfile: profileObject,
                  },
                  () => {
                    this.callGroupList({
                      profileId:
                        this.state.selectedProfile !== null &&
                        this.state.selectedProfile !== undefined
                          ? this.state.selectedProfile.id
                          : null,
                    });
                  }
                );
              }}
              id="selectedProfile"
              name="selectedProfile"
              value={
                this.state.selectedProfile !== null &&
                this.state.selectedProfile !== undefined
                  ? this.state.selectedProfile.id
                  : ""
              }
            >
              <option value={null}>Select Profile</option>
              {profileOptions}
            </select>
          </div>
        </div>
        <div className="p-col-12 p-md-3 p-lg-3" style={{ padding: "1em" }}>
          <div id="search" className="p-inputgroup">
            <InputText
              placeholder="Enter Search Item Here"
              onKeyDown={(e) => {
                if (e.keyCode == 13) {
                  this.callGroupList({ filter: e.target.value });
                }
              }}
              aria-label="Search"
            />
            <Button
              icon="pi pi-search"
              className="p-button-primary s-icon s-btn"
            />
          </div>
        </div>
        <div className="p-col-12 p-md-3 p-lg-3">
          <Link to="/create_device_group">
            <button className="btn btn-primary2" style={{ float: "right" }}>
              + New Device Group
            </button>
          </Link>
        </div>
      </div>
    );
    const footer = (
      <div>
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-success"
          onClick={() => {
            this.setState({ visible: false, isLoading: true });
            this.rowDelete(this.state.rowData);
          }}
        />
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ visible: false });
          }}
        />
      </div>
    );
    const deleteDialogue = (
      <Dialog
        header="Delete Device Group"
        visible={this.state.visible}
        style={{ width: "35vw" }}
        modal={true}
        footer={footer}
        onHide={() => this.setState({ visible: false })}
      >
        <p style={{ textAlign: "center" }}>
          <p
            style={{
              fontWeight: "bold",
              paddingBottom: "10px",
              marginBottom: "10px",
            }}
          >
            All the group members will be shifted to profile.
          </p>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              onClick={(e) => {
                this.setState({ prechecked: e.target.checked });
              }}
            />
            <label className="form-check-label" for="exampleCheck1">
              Preserve the group's apps and manage those device-wise
            </label>
          </div>
        </p>
      </Dialog>
    );
    const home = { icon: "pi pi-home", url: "dashboard" };
    const paginatorLeft = <Button icon="pi pi-refresh" />;
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 p-ll">
          <div className="p-col-9 inline-b p-ll">
            <BreadCrumb model={items} home={home} />
          </div>
          <div className="p-col-3 inline-b p-zz">
            <img
              src={helpIcon}
              width="20"
              height="20"
              onClick={(e) => this.op.toggle(e)}
              style={{
                marginBottom: "-10px",
                float: "right",
                cursor: "pointer",
              }}
            />
          </div>
          <OverlayPanel
            ref={(el) => (this.op = el)}
            id="overlay_panel"
            showCloseIcon={true}
          >
            <div
              className="col"
              style={{
                width: "fit-content",
                maxWidth: "300px",
                padding: "0px",
              }}
            >
              <div className="helptip-title">
                <b>How to : Device Group</b>
              </div>
              <div className="helptip-des">
                Users have option to manage devices by group rather than
                individual. One device belongs to only one group and each group
                must have a device profile. Once devices are bound to a group,
                the device profile of that group are automatically applied on
                those devices.
              </div>
            </div>
          </OverlayPanel>
        </div>
        {deleteDialogue}
        <Dialog
          visible={this.state.isLoading}
          modal={true}
          closable={false}
          showHeader={false}
          style={{ width: "20vw" }}
          onHide={this.onDialogueHide}
        >
          <Loader />
        </Dialog>
        <Dialog
          visible={this.state.isSuccess}
          modal={true}
          style={{ width: "25vw" }}
          closable
          showHeader
          onHide={this.onDialogueHideSuccess}
        >
          <SuccessDialog successMessage="Deleted Group Successfully" />
        </Dialog>
        <div className="p-grid bg-col p-lg-12">
          <div className="p-col">
            <div className="p-grid bg-col p-lg-12">
              <div className="datatable-paginator-demo">
                <DataTable
                  value={this.state.group}
                  style={{ marginBottom: "20px" }}
                  responsive={true}
                  header={header}
                  editMode="row"
                  emptyMessage="No records found"
                  rowEditorValidator={this.onRowEditorValidator}
                  onRowEditInit={this.onRowEditInit}
                  onRowEditSave={this.onRowEditSave}
                  onRowEditCancel={this.onRowEditCancel}
                  paginator
                  totalRecords={this.state.totalRecords}
                  onPage={this.onPage}
                  first={this.state.dfirst}
                  currentPage={this.state.currentPage}
                  lazy
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  rows={this.state.drows}
                  rowsPerPageOptions={[10, 20, 30, 50, 100]}
                >
                  <Column
                    field="name"
                    header="Group Name"
                    editor={(props) => this.editorForRowEditing(props, "name")}
                    style={{ height: "3.5em" }}
                  />
                  <Column
                    field="profileName"
                    header="Profile"
                    editor={(props) =>
                      this.editorForRowEditing(props, "profileName")
                    }
                    style={{ height: "3.5em" }}
                  />
                  <Column
                    field="deviceCount"
                    header="Devie Count"
                    editor={(props) =>
                      this.editorForRowEditing(props, "deviceCount")
                    }
                    style={{ height: "3.5em" }}
                  />
                  {/* <Column
                  field="available_app"
                  header="Available Apps"
                  editor={(props) =>
                    this.editorForRowEditing(props, "available_app")
                  }
                  style={{ height: "3.5em" }}
                /> */}
                  <Column
                    header="Action"
                    style={{
                      textAlign: "center",
                      color: "#000000",
                      width: "10%",
                    }}
                    body={this.actionTemplate.bind(this)}
                  ></Column>
                </DataTable>
              </div>
            </div>
            <Dialog
              visible={this.state.isError}
              modal
              style={{ width: "20vw" }}
              closable
              showHeader
              onHide={this.onDialogueHide}
            >
              <ErrorDialog errorMessage={this.state.errorMessage} />
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}
