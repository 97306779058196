import React from "react";
import html2canvas from "html2canvas";
import { connect, startRTC, offer, disconnect, startRTC2 } from "./videoCall2";

var RecordRTC = require("recordrtc");

function downloadBase64File(contentType, base64Data, fileName) {
  let linkSource = base64Data; //`data:${contentType};base64,${base64Data}`;
  console.log("linksource", linkSource);
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

function test() {
  let object = document.getElementById("remoteView");
  let canvas = document.getElementById("canvas"); // declare a canvas element in your html
  let ctx = canvas.getContext("2d");
  let videos = object;
  let w, h;

  const v = videos;
  if (!v.src) {
  } // no video here
  try {
    w = v.videoWidth;
    h = v.videoHeight;
    canvas.width = w;
    canvas.height = h;
    ctx.fillRect(0, 0, w, h);
    ctx.drawImage(v, 0, 0, w, h);
    v.style.backgroundImage = `url(${canvas.toDataURL()})`; // here is the magic
    v.style.backgroundSize = "cover";
    // ctx.clearRect(0, 0, w, h); // clean the canvas
  } catch (e) {
    console.log(e);
  }

  html2canvas(canvas).then(function (canvas) {
    //document.body.appendChild(canvas);
    let base64URL = canvas
      .toDataURL("image/jpeg")
      .replace("image/jpeg", "image/png");
    downloadBase64File("image/png", base64URL, "scr-image-" + new Date());
    //console.log(base64URL);
  });
}

// function test2(){
//   var video=document.querySelector('video');
//   var canvas=document.querySelector('canvas');
//   var context=canvas.getContext('2d');
//   var w,h,ratio;

//   //add loadedmetadata which will helps to identify video attributes

//   video.addEventListener('loadedmetadata', function() {
//     ratio = video.videoWidth/video.videoHeight;
//     w = video.videoWidth-100;
//     h = parseInt(w/ratio,10);
//     canvas.width = w;
//     canvas.height = h;
//   },false);

//   function snap() {
//     context.fillRect(0,0,w,h);
//     context.drawImage(video,0,0,w,h);
//   }
// }
let recorder;
function recording() {
  // let stream = await navigator.mediaDevices.getUserMedia({
  //   video: true,
  //   audio: true,
  // });

  let object = document.getElementById("remoteView");
  console.log(object);
  // Set the source of one <video> element to be a stream from another.
  var stream = object.captureStream
    ? object.captureStream()
    : object.mozCaptureStream();
  // if (sUsrAg.indexOf("Firefox") > -1) {
  //   console.log("Firefox");
  //   stream = object.mozCaptureStream();
  // } else {
  //   console.log("Other");
  //   stream = object.captureStream();
  // }

  console.log("stream", stream);
  recorder = RecordRTC(stream, {
    type: "video",
    mimeType: "video/webm",
  });
  recorder.startRecording();

  // const sleep = (m) => new Promise((r) => setTimeout(r, m));
  // await sleep(15000);
}

function stopRecording(recorder) {
  recorder.stopRecording(function () {
    let blob = recorder.getBlob();
    RecordRTC.invokeSaveAsDialog(blob, "video.webm");
  });
}

function RemoteVideo() {
  return (
    <div>
      {/* <br />
      <br />
      <br />
      <br />
      <button id="connect1" onClick={() => connect("1")}>
        {" "}
        connect 1st
      </button>
      <button id="stc" onClick={() => startRTC()}>
        {" "}
        startRTC1
      </button>
      <button id="offer1" onClick={() => offer("2")}>
        {" "}
        offer 2nd
      </button>
      <button id="offer1" onClick={() => disconnect()}>
        {" "}
        disconnect
      </button>

      <label id="lbl"></label>

      <button id="connect2" onClick={() => connect("2")}>
        {" "}
        connect 2nd
      </button>
      <button id="stc2" onClick={() => startRTC()}>
        {" "}
        startRTC 2
      </button>
      <button id="offer2" onClick={() => offer("1")}>
        {" "}
        offer 1st
      </button>
      <button id="offer1" onClick={() => disconnect()}>
        {" "}
        disconnect
      </button> */}

      <br />
      <br />
      <br />
      <br />
      <button
        id="connect1"
        onClick={() => {
          connect("mdm", {
            enableVoiceCall: false,
            enableFileShare: false,
            allowToStopCast: false,
          });
        }}
      >
        {" "}
        connect 1st
      </button>
      <button id="stc" onClick={() => startRTC()}>
        {" "}
        startRTC1
      </button>
      <button id="offer1" onClick={() => offer("nazmun")}>
        {" "}
        offer 2nd
      </button>
      <button
        id="offer1"
        onClick={() => {
          disconnect();
        }}
      >
        {" "}
        disconnect
      </button>

      <br />
      <label id="lbl"></label>
      <br />
      <br />
      <br />

      <button id="connect2" onClick={() => connect("nazmun")}>
        {" "}
        connect 2nd
      </button>
      <button id="stc2" onClick={() => startRTC2()}>
        {" "}
        startRTC 2
      </button>
      <button id="offer2" onClick={() => offer("omi")}>
        {" "}
        offer 1st
      </button>
      <button id="offer1" onClick={() => disconnect()}>
        {" "}
        disconnect
      </button>

      <button id="scrsc1" onClick={() => test()}>
        {" "}
        Screenshot
      </button>
      <button id="screenrecord" onClick={() => recording()}>
        {" "}
        Start Recording
      </button>
      <button id="screenrecord" onClick={() => stopRecording(recorder)}>
        {" "}
        Stop Recording
      </button>

      <div style={{ display: "inline-block" }}>
        <video
          id="remoteView"
          width="640"
          height="480"
          autoPlay
          style={{ display: "inline" }}
        ></video>
        <canvas id="canvas" width="640" height="480"></canvas>
      </div>

      <div>
        <video
          id="selfView"
          width="320"
          height="240"
          autoPlay
          style={{ display: "inline" }}
        ></video>
      </div>
    </div>
  );
}

export default RemoteVideo;
