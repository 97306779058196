import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import Loader from "../components/Loader";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import api from "../service/ApiService";
import { baseUrl } from "../service/ApiUrls";
import { confirmAlert } from "react-confirm-alert";
import Iframe from "react-iframe";
import { Checkbox } from "primereact/checkbox";

export class SetupAndroidEnterprise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qrlists: [],
      isLoading: false,
      isSuccess: false,
      deviceActive: false,
      status: false,
      enroll: false,
      check: false,
      id: "6023b347690cfa09c051028d",
      deleted: false,
      iframes: false,
      accunts: false,
      agreementAccepted: true,
    };
  }
  onHandleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  callAndroidEnterprise = () => {
    this.setState({ isLoading: true }, () => {
      let datatosend = {
        name: this.state.name,
        enterpriseEmail: this.state.email,
        agreementAccepted: this.state.agreementAccepted,
      };
      console.log("datatosend", datatosend);
      api
        .post(baseUrl + "/android-enterprise", datatosend)
        .then((res) => {
          if (res.data.result.isError === false) {
            this.setState({ isLoading: false }, () => {
              confirmAlert({
                title: "Success Message",
                message: (
                  <p className="mod-sp">
                    Create Android Enterprise Successfully
                  </p>
                ),
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {
                      this.callGetAndroidEnterprise();
                    },
                  },
                ],
              });
            });
          } else {
            this.setState({ isLoading: false }, () => {
              confirmAlert({
                title: "Error Message",
                message: <p className="mod-p">{res.data.result.errorMsg}</p>,
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {
                      this.callGetAndroidEnterprise();
                    },
                  },
                ],
              });
            });
          }
        })
        .catch((err) => console.log(err));
    });
  };
  callGetAndroidEnterprise = () => {
    api
      .get(baseUrl + "/android-enterprise")
      .then((res) => {
        if (res.data.result.isError === false) {
          if (res.data.data === null) {
            this.setState({ enroll: false });
          } else {
            this.setState({ ...res.data.data, enroll: true });
          }
        }
      })
      .catch((err) => console.log(err));
  };

  callPostAndroidEnterprise = () => {
    let datatosend = {
      callBackUrl:
        (window.location.host.startsWith("localhost")
          ? "http://"
          : "https://") +
        window.location.host +
        "/setup-android-enterprise",
    };
    console.log("datatosend", datatosend);
    api
      .post(baseUrl + "/android-enterprise/signup-url", datatosend)
      .then((res) => {
        if (res.data.result.isError === false) {
          this.setState({ isLoading: true });
          window.location.replace(res.data.data.url);
        }
      })
      .catch((err) => console.log(err));
  };

  callDeactiveAndroidEnterprise = () => {
    this.setState({ isLoading: true }, () => {
      let datatosend = {
        status: 0,
      };
      console.log("datatosend in put ", datatosend);
      if (this.state.id !== undefined) {
        api
          .put(baseUrl + "/android-enterprise/" + this.state.id, datatosend)
          .then((res) => {
            if (res.data.result.isError === false) {
              this.setState({ isLoading: false }, () => {
                this.callGetAndroidEnterprise();
              });
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };
  callActiveAndroidEnterprise = () => {
    this.setState({ isLoading: true }, () => {
      let datatosend = {
        status: 1,
      };
      console.log("datatosend in put ", datatosend);
      if (this.state.id !== undefined) {
        api
          .put(baseUrl + "/android-enterprise/" + this.state.id, datatosend)
          .then((res) => {
            if (res.data.result.isError === false) {
              this.setState({ isLoading: false }, () => {
                this.callGetAndroidEnterprise();
              });
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };
  callDeleteAndroidEnterprise = () => {
    this.setState({ deleted: false, isLoading: true }, () => {
      let datatosend = {
        status: 2,
      };
      console.log("datatosend in put ", datatosend);
      if (this.state.id !== undefined) {
        api
          .put(baseUrl + "/android-enterprise/" + this.state.id, datatosend)
          .then((res) => {
            if (res.data.result.isError === false) {
              this.setState({ isLoading: false }, () => {
                confirmAlert({
                  title: "Error Message",
                  message: (
                    <p className="mod-sp">
                      Deleted Android Enterprise Successfully
                    </p>
                  ),
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => {
                        this.callGetAndroidEnterprise();
                      },
                    },
                  ],
                });
              });
            } else if (res.data.result.isError === true) {
              this.setState({ isLoading: false }, () => {
                confirmAlert({
                  title: "Error Message",
                  message: <p className="mod-p">{res.data.result.errorMsg}</p>,
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => {},
                    },
                  ],
                });
              });
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };
  componentDidMount = () => {
    //get api
    this.callGetAndroidEnterprise();
    // window.location.replace(
    //   "http://localhost:3000/factory-test?email=xyz@gmail.com"
    // );
  };
  androidSetup = (e) => {
    this.setState({ isSuccess: true });
  };
  deviceActive = (e) => {
    this.setState({ deviceActive: true });
  };
  handleCheckbox = (e) => {
    console.log("e", e);
  };
  submitHandler = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
  };
  onDialogueHide = () => {
    this.setState({ isLoading: false, isSuccess: false, deviceActive: false });
  };

  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Home Ground", url: "home-ground" },
      { label: "Setup Android Enterprise" },
    ];

    const home = { icon: "pi pi-home", url: "dashboard" };
    const footerAccount = (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ accunts: false });
          }}
        />
        <Button
          label="Submit"
          icon="pi pi-check"
          className="p-button-success"
          onClick={(e) =>
            this.setState({ accunts: false }, () => {
              this.callAndroidEnterprise();
            })
          }
        />
      </div>
    );
    const createAccount = (
      <Dialog
        header="Create Android Enterprise"
        visible={this.state.accunts}
        style={{ width: "30vw" }}
        modal={true}
        footer={footerAccount}
        onHide={() => this.setState({ accunts: false })}
      >
        <div className="form-group p-col-12" style={{ marginBottom: "0.5rem" }}>
          <label htmlFor="name">Enterprise Name</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            placeholder="Enter Enterprise Name"
            onChange={this.onHandleChange}
          />
        </div>
        <div className="form-group p-col-12" style={{ marginBottom: "0.5rem" }}>
          <label htmlFor="email">Contact Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            placeholder="Enter Contact Email"
            onChange={this.onHandleChange}
          />
        </div>
        <div className="form-group p-col-12" style={{ marginBottom: "0.5rem" }}>
          <Checkbox
            inputId="agreement"
            checked={this.state.agreementAccepted}
            // onChange={(e) => this.setState({ agreementAccepted: e.checked })}
            style={{ marginRight: "5px" }}
            disabled
          />
          <label htmlFor="agreement">
            {" "}
            Managed Google Play Agreement Accepted
          </label>
        </div>
      </Dialog>
    );
    const footerf = (
      <div>
        <Button
          label="Reject"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ iframes: false });
          }}
        />
        <Button
          label="Accept"
          icon="pi pi-check"
          className="p-button-success"
          onClick={(e) => this.setState({ accunts: true, iframes: false })}
        />
      </div>
    );
    const iframeshow = (
      <Dialog
        header="Managed Google Play Agreement"
        visible={this.state.iframes}
        style={{ width: "80%" }}
        modal={true}
        footer={footerf}
        onHide={() => this.setState({ iframes: false })}
      >
        <Iframe
          url="https://www.android.com/enterprise/terms/"
          width="100%"
          height="450px"
          id="myId"
          className="myClassname"
          display="initial"
          position="relative"
        />
      </Dialog>
    );
    const footer1 = (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ deleted: false });
          }}
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-success"
          onClick={(e) => this.callDeleteAndroidEnterprise()}
        />
      </div>
    );
    const deleteDialogue = (
      <Dialog
        header="Delete Android Enterprise"
        visible={this.state.deleted}
        style={{ width: "35vw" }}
        modal={true}
        footer={footer1}
        onHide={() => this.setState({ deleted: false })}
      >
        <p
          style={{
            fontWeight: "bold",
            paddingBottom: "10px",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          "All your emm setup for this enterprise will be removed. All you
          devices registered under this android enterprise will get a automatic
          factory reset."{" "}
          <p style={{ marginTop: "10px", color: "red" }}>
            Do you want to proceed?
          </p>
        </p>
      </Dialog>
    );
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 ">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="p-grid bg-col p-lg-12">
          <div className="p-col-12">
            <h2 style={{ paddingLeft: "12px" }}>Setup Android Enterprise</h2>
          </div>
          <div className={this.state.enroll ? "p-col-12 d-none" : "p-col-12"}>
            <p style={{ paddingLeft: "12px" }}>
              If you are here for first time, click{" "}
              <a href="#" onClick={this.androidSetup}>
                info
              </a>{" "}
              for detail about Android Enterprise.
            </p>
            <div className="p-col-12" style={{ textAlign: "center" }}>
              <button
                className="btn6"
                onClick={() => this.setState({ iframes: true })}
              >
                Create Android Enterprise
              </button>
            </div>
          </div>
          <div className={this.state.enroll ? "p-col-12" : "p-col-12 d-none"}>
            <p style={{ paddingLeft: "12px" }}>
              To enroll android devices, please click{" "}
              <a href="#" onClick={this.deviceActive}>
                info
              </a>{" "}
              for detail.
            </p>
            <div className="p-col-12" style={{ paddingLeft: "12px" }}>
              <p>
                Enrollment Date:{" "}
                <span style={{ fontWeight: "bold", paddingLeft: "2px" }}>
                  {this.state.activationDate !== undefined
                    ? this.state.activationDate
                    : " "}
                </span>
              </p>
              <p>
                Enterprise Name:{" "}
                <span style={{ fontWeight: "bold", paddingLeft: "2px" }}>
                  {this.state.displayName !== undefined
                    ? this.state.displayName
                    : " "}
                </span>
              </p>
              <p>
                Enterprise Email:{" "}
                <span style={{ fontWeight: "bold", paddingLeft: "2px" }}>
                  {this.state.enterpriseEmail !== undefined
                    ? this.state.enterpriseEmail
                    : " "}
                </span>
              </p>
              <p>
                Status:{" "}
                <span style={{ fontWeight: "bold", paddingLeft: "2px" }}>
                  {this.state.status === 1 ? "Active" : "Deactived"}
                </span>
              </p>
            </div>
            <div className="p-col-12" style={{ textAlign: "center" }}>
              <button
                className={this.state.status === 1 ? "btn7" : "btn6"}
                onClick={() => {
                  if (this.state.status === 0) {
                    this.callActiveAndroidEnterprise();
                  } else {
                    this.callDeactiveAndroidEnterprise();
                  }
                }}
              >
                {this.state.status === 1 ? "Deativate" : "Activate"}
              </button>
              <button
                className="btn7 ml-2"
                onClick={() => this.setState({ deleted: true })}
              >
                Delete
              </button>
            </div>
          </div>
          <Dialog
            visible={this.state.isLoading}
            modal={true}
            closable={false}
            showHeader={false}
            style={{ width: "20vw" }}
            onHide={this.onDialogueHide}
          >
            <Loader textToShow="Saving..." />
          </Dialog>
          <Dialog
            visible={this.state.isSuccess}
            modal={true}
            style={{ width: "30vw" }}
            closable
            showHeader
            header="Android Enterprise"
            onHide={this.onDialogueHide}
          >
            <div className="" style={{ margin: "10px 5px" }}>
              <p>
                Android Enterprise securely keeps corporate data and enables a
                corporate-owned profile on devices. Once devices are
                provisioned, you will get following features:
              </p>
              <p>
                <ul>
                  <li>
                    Default 'Mobionizer' app is being installed when you
                    first-time setup your devices
                  </li>
                  <li>Silent app install from playstore</li>
                  <li>Remote vpn configuration</li>
                  <li>Whitelisting websites</li>
                </ul>
              </p>
            </div>
          </Dialog>
          <Dialog
            visible={this.state.deviceActive}
            modal={true}
            style={{ width: "25vw" }}
            closable
            showHeader
            header="Enroll Android Devices"
            onHide={this.onDialogueHide}
          >
            <div className="" style={{ margin: "10px 5px" }}>
              <p>
                <ul>
                  <li>Tap welcome screen 6 times after factory reset.</li>
                  <li>A QR code scanner will be appeared in your device.</li>
                  <li>
                    In your admin web-portal, go to Enroll{">"} QR-Registration
                  </li>
                  <li>Scan the QR code present in the screen.</li>
                  <li>
                    Then follow on-screen promt to complete enrollment process
                  </li>
                </ul>
              </p>
            </div>
          </Dialog>
          {deleteDialogue}
          {iframeshow}
          {createAccount}
        </div>
      </div>
    );
  }
}
